export default Object.freeze({
  STACK_AUTHCHECKSCREEN: "STACK_AuthCheckScreen",
  STACK_INTERNETCHECKERSCREEN: "STACK_InterNetCheckScreen",

  STACK_DEPARTMENTSCREEN: "STACK_DepartmentDeciderScreen",

  STACK_ALLDEPARTMENTSCREEN: "STACK_AllDepartmentScreen",

  STACK_ITROOTSCREEN: "STACK_ITRootScreen",
  STACK_ITADMINHOMESCREEN: "STACK_ITAdminHomeScreen",

  STACK_FORMSLOGINROOTSCREEN: "STACK_FormsLoginRootScreen",
  STACK_FORMSROOTSCREEN: "STACK_FormsRootScreen",

  STACK_ACCOUNTROOTSCREEN: "STACK_AccountRootScreen",
  STACK_ACCOUNT_HOMESCREEN: "STACK_AccountHomeScreen",

  STACK_Attendance_ROOTSCREEN: "STACK_AttendanceRootScreen",
  STACK_ATTENDANCE_HOMESCREEN: "STACK_AttendanceHomeScreen",

  STACK_THERAPY_MASTERADMINHOMESCREEN: "AccessFoundation_MasterAdminHomeScreen",

  STACK_THERAPY_ADMINHOMESCREEN: "AccessFoundation_ADMINHOMESCREEN",
  STACK_ADMINSESSIONMAINSCREEN: "AccessFoundation_AdminSessionMainScreen",

  STACK_THERAPISTHOMESCREEN: "AccessFoundation_TherapistHOMESCREEN",
  STACK_SESSIONMAINSCREEN: "AccessFoundation_SessionHOMESCREEN",
});
