import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import AppText from "./AppText";
import Colors from "../config/Colors";

function PickerItem({ label, onPress, labelWidth = "50%" }) {
  return (
    <TouchableOpacity
      style={[styles.container, { width: labelWidth }]}
      onPress={onPress}
    >
      <AppText style={styles.text} label={label.name} />
      {label.account && (
        <AppText
          style={{
            fontSize: "100%",
            padding: 10,
            borderRadius: 10,
            marginRight: 10,
            alignItems: "center",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor:
              label.account == "active" ? Colors.green : Colors.red,
          }}
          label={label.account}
        />
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginHorizontal: 15,
    marginVertical: 5,
    backgroundColor: "white",
    borderRadius: 5,
    alignSelf: "center",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 5,
  },
  text: {
    padding: 5,
    fontSize: "100%",
  },
});

export default PickerItem;
