import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
} from "react-native";

import listingAPI from "../../api/listings";
import Colors from "../../config/Colors";

const TherapistCard = ({ items }) => {
  let leftHours = ~~(items.totalmins / 60);
  let leftMin = ~~(items.totalmins % 60);

  return (
    <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
      <Text style={{ width: "40%" }}>{items.therapist_name}</Text>
      <Text style={{ width: "40%" }}>
        {leftHours}:{leftMin < 10 ? `0${leftMin}` : leftMin}
      </Text>
    </View>
  );
};

const ServiceList = ({ item }) => {
  const [open, setOpen] = useState(false);
  const [LayoutHeight, setLayoutHeight] = useState(0);
  const [allTherapist, setAllTherapist] = useState([{ therapist_name: "" }]);

  useEffect(() => {
    if (open) {
      setLayoutHeight(null);
    } else {
      setLayoutHeight(0);
    }
  }, [open]);

  const totalMins = parseInt(item.total_hours) * 60 + parseInt(item.total_mins);
  const usedMins = parseInt(item.used_hours) * 60 + parseInt(item.used_mins);

  const leftmins = totalMins - usedMins;

  let leftHours = ~~(leftmins / 60);
  let leftMin = ~~(leftmins % 60);

  const OnPressGetTherapistHandler = async (item) => {
    setOpen(!open);

    const myJsonServices = JSON.stringify({
      participant_main_id: item.participant_main_id,
      allocate_services_main_id: item.services_main_id,
      currentPlanNumver: item.currentPlanNumver,
    });
    const { data } = await listingAPI.AllocateTherapistPost({
      endPoint:
        "/select/all/Allocate/Therapists/of/selected/participantand/selectedServices",
      data: myJsonServices,
    });

    if (data.length > 0) {
      setAllTherapist(data);
    } else {
      setAllTherapist([{ therapist_name: "no data found" }]);
    }
  };

  return (
    <View>
      <TouchableOpacity
        style={styles.ServicesViewList}
        onPress={() => OnPressGetTherapistHandler(item)}
      >
        <Text style={styles.txtLabelStyles}>{item.name}</Text>
        <Text style={styles.txtLabelStyles}>
          {item.total_hours}:
          {item.total_mins > -10 && item.total_mins < 10
            ? `0${item.total_mins}`
            : item.total_mins}
        </Text>
        <Text style={styles.txtLabelStyles}>
          {item.used_hours}:
          {item.used_mins > -10 && item.used_mins < 10
            ? `0${item.used_mins}`
            : item.used_mins}
        </Text>
        <Text style={styles.txtLabelStyles}>
          {leftHours}:{leftMin > -10 && leftMin < 10 ? `0${leftMin}` : leftMin}
        </Text>
      </TouchableOpacity>
      <View style={{ height: LayoutHeight, overflow: "hidden" }}>
        <FlatList
          data={allTherapist}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => <TherapistCard items={item} />}
        />
      </View>
    </View>
  );
};

function AppServicesListCard({ items }) {
  return (
    <View>
      <View style={styles.ServicesViewList}>
        <Text style={styles.txtLabelStyles}>Service</Text>
        <Text style={styles.txtLabelStyles}>Total Hours</Text>
        <Text style={styles.txtLabelStyles}>Used Hours</Text>
        <Text style={styles.txtLabelStyles}>Left Hours</Text>
      </View>
      <FlatList
        data={items}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => <ServiceList item={item} />}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  ServicesViewList: {
    width: "90%",
    padding: 10,
    backgroundColor: Colors.InputText,
    marginVertical: 3,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  txtLabelStyles: {
    fontWeight: "bold",
    width: "30%",
  },
});

export default AppServicesListCard;
