import React from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import AppText from "../components/AppText";
import Colors from "../config/Colors";

function AppButton({
  border = 0,
  onPress,
  height = 30,
  width = "30%",
  color = "yellow",
  label = "Add",
  labelStyles,
  style,
}) {
  const { height: h, width: w } = useWindowDimensions();

  let windowHeight = h + w;

  // if (h < w) {
  //   windowHeight = w;
  // } else {
  //   windowHeight = h;
  // }

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.container,
        { borderWidth: border },
        { backgroundColor: Colors[color] },
        //{ width: windowHeight / 20 },
        { width: width },
        { height },
        style,
      ]}
    >
      <AppText
        style={[labelStyles, { fontSize: `${windowHeight / 30}%` }]}
        label={label}
      />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    // height: 30,
    // width: "10%",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 5,
  },
});

export default AppButton;
