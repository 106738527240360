import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  Alert,
  ScrollView,
  Dimensions,
} from "react-native";
import moment from "moment";

import listingAPI from "../../api/listings";
import Colors from "../../config/Colors";
import AppProgressbar from "../../components/AppProgressbar";
import AppButton from "../../components/AppButton";
import AppAlert from "../../components/AppAlert";
import AppCalander from "../../components/AppCalander";
import AppMultiFilesPicker from "../../components/AppMultiFilesPicker";
import AppInputText from "../../components/AppInputText";
import AppPicker from "../../components/AppPicker";
import AppGetDocumentCard from "../../components/AppGetDocumentCard";
import Routes from "../../navigation/routes";

function AppSessionUpdateCard({ items, refreshData }) {
  const [hhSession, setHHSession] = useState({
    name: items.totalSession_hours,
  });
  const [mmSession, setMMSession] = useState({ name: items.totalSession_mins });

  const [goingKM, setGoingKM] = useState(items.goingToParticipantKM);
  const [comingBackKM, setComingBackKM] = useState(
    items.comingBackToParticipantKM
  );
  const [parkingCharges, setParkingCharges] = useState(
    items.parkingCharges
  );

  const [hhTravel, setHHTravel] = useState({ name: items.totalTravel_hours });
  const [mmTravel, setMMTravel] = useState({ name: items.totalTravel_mins });

  const [chargeHH, setChargeHH] = useState({ name: items.chargeTravel_hours });
  const [chargeMM, setChargeMM] = useState({ name: items.chargeTravel_mins });

  const [sessionDate, setSessionDate] = useState(
    moment(items.sessionDate, "YYYY/MM/DD").format("DD/MM/YYYY")
  );
  const [brifTask, setbrifTask] = useState(items.briefNote);
  const [alert, setAlert] = useState({ modal: false, alert: "" });

  const [mainSubject, setMainSubject] = useState(items.mainSubject);

  let totalHHSession = [{ name: 0 }];
  let totalMMSession = [{ name: 0 }];

  let totalHHTravel = [{ name: 0 }];
  let totalMMTravel = [{ name: 0 }];

  let totalChargeHHTravel = [{ name: 0 }];
  let totalChargeMMTravel = [{ name: 0 }];

  for (let i = 1; i <= 10; i++) {
    totalHHSession.push({ name: i });
  }
  for (let i = 1; i <= 59; i++) {
    totalMMSession.push({ name: i });
  }

  for (let i = 1; i <= 10; i++) {
    totalHHTravel.push({ name: i });
  }
  for (let i = 1; i <= 59; i++) {
    totalMMTravel.push({ name: i });
  }

  for (let i = 1; i <= 10; i++) {
    totalChargeHHTravel.push({ name: i });
  }
  for (let i = 1; i <= 59; i++) {
    totalChargeMMTravel.push({ name: i });
  }

  const OnPressUpdateHandler = async () => {
    let goingToParticipentKM = goingKM == undefined ? 0 : goingKM;

    let comingBackKMToParticipentKM =
      comingBackKM == undefined ? 0 : comingBackKM;

    let TotalKM =
      parseInt(goingToParticipentKM) + parseInt(comingBackKMToParticipentKM);

    if (sessionDate == "dd/mm/yyyy") {
      setAlert({ modal: true, alert: "Please Select Session date." });
      return;
    } else if (hhSession.name == 0 && mmSession.name == 0) {
      setAlert({ modal: true, alert: "Please Add Session time." });
      return;
    }

    let totalTravelTime = hhTravel.name * 60 + mmTravel.name;
    let totalChargeTravelTime = chargeHH.name * 60 + chargeMM.name;

    if (totalTravelTime < totalChargeTravelTime) {
      setAlert({
        modal: true,
        alert: "Charge Travel time must be less than total travel time.",
      });
      return;
    }

    const myJsonServices = JSON.stringify({
      participant_main_id: items.participant_main_id,
      services_main_id: items.services_main_id,
      previousSession_hours: items.totalSession_hours,
      previousSession_mins: items.totalSession_mins,
      previousChargeTravel_hours: items.chargeTravel_hours,
      previousChargeTravel_mins: items.chargeTravel_mins,
      sessionDate: moment(sessionDate, "DD/MM/YYYY").format("YYYY/MM/DD"),
      mainSubject: mainSubject != undefined ? mainSubject.trim() : "",
      services_CurrentPrice: items.services_CurrentPrice,
      totalSession_hours: hhSession.name,
      totalSession_mins: mmSession.name,
      totalTravel_hours: hhTravel.name,
      totalTravel_mins: mmTravel.name,
      chargeTravel_hours: chargeHH.name,
      chargeTravel_mins: chargeMM.name,
      briefNote: brifTask,
      status: "active",
      session_main_id: items.main_id,

      totalExtraExpense: TotalKM,
      totalExtraExpense_Price: TotalKM * parseFloat(items.extraExpense_price),
      goingToParticipantKM: parseFloat(goingToParticipentKM),
      comingBackToParticipantKM: parseFloat(comingBackKMToParticipentKM),
      parkingCharges: parseFloat(parkingCharges ?? 0)
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint: "/update/selected/unclame/session/of/selected/participants",
      data: myJsonServices,
    });

    if (data == true) {
      setAlert({
        modal: true,
        alert: "Session has been updated successfully.",
      });
    } else {
      setAlert({ modal: true, alert: "Something went wrong." });
    }
    //refreshData({ refreshSessions: 1 });
  };

  return (
    <View style={styles.container}>
      <AppAlert
        alertLabel=""
        alert={alert}
        okBtnPress={() => setAlert({ modal: false, alert: "" })}
      />
      <ScrollView>
        <View style={styles.mainViewStyles}>
          <View style={styles.titleStyles}>
            <Text style={{ fontSize: 25, fontWeight: "bold" }}>
              Today : {moment(Date.now()).format("DD/MM/YYYY")}
            </Text>
          </View>
          <View style={styles.dataViewStyles}>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <AppCalander
                width="30%"
                label="Session Date : "
                datePlaceholder={sessionDate}
                selectedDate={(date) => setSessionDate(date)}
                maxDate={new Date()}
              />
              <AppInputText
                label="Subject : "
                iconName="note"
                placeholder="Subject : "
                width="30%"
                value={mainSubject ?? ""}
                onChangeText={(txt) => setMainSubject(txt)}
              />
            </View>
            <View style={styles.sessionTimeSelection}>
              <Text style={styles.txtLabelStyles}>Session Time</Text>
              <View style={styles.timeViewStyles}>
                <AppPicker
                  numColumns={2}
                  labelWidth="30%"
                  label="HH"
                  width="40%"
                  items={totalHHSession}
                  selectedItem={hhSession}
                  onSelectItem={(item) => setHHSession(item)}
                />
                <AppPicker
                  numColumns={6}
                  labelWidth="10%"
                  label="MM"
                  width="40%"
                  items={totalMMSession}
                  selectedItem={mmSession}
                  onSelectItem={(item) => setMMSession(item)}
                />
              </View>
            </View>

            <View style={styles.sessionTimeSelection}>
              <Text style={styles.txtLabelStyles}>Total Travel Time</Text>
              <View style={styles.timeViewStyles}>
                <AppPicker
                  numColumns={2}
                  labelWidth="30%"
                  label="HH"
                  width="40%"
                  items={totalHHTravel}
                  selectedItem={hhTravel}
                  onSelectItem={(item) => setHHTravel(item)}
                />
                <AppPicker
                  numColumns={6}
                  labelWidth="10%"
                  label="MM"
                  width="40%"
                  items={totalMMTravel}
                  selectedItem={mmTravel}
                  onSelectItem={(item) => setMMTravel(item)}
                />
              </View>
            </View>

            <View style={styles.sessionTimeSelection}>
              <Text style={styles.txtLabelStyles}>Charge Travel Time</Text>
              <View style={styles.timeViewStyles}>
                <AppPicker
                  label="HH"
                  width="40%"
                  numColumns={2}
                  labelWidth="30%"
                  items={totalChargeHHTravel}
                  selectedItem={chargeHH}
                  onSelectItem={(item) => setChargeHH(item)}
                />
                <AppPicker
                  label="MM"
                  width="40%"
                  numColumns={6}
                  labelWidth="10%"
                  items={totalChargeMMTravel}
                  selectedItem={chargeMM}
                  onSelectItem={(item) => setChargeMM(item)}
                />
              </View>
            </View>

            <View style={styles.sessionTimeSelection}>
              <Text style={styles.txtLabelStyles}>KMs Charges</Text>
              {/* <View style={{ alignItems: "center" }}>
                <AppPicker
                  numColumns={2}
                  labelWidth="30%"
                  label="Select"
                  width="40%"
                  items={extraExpenses}
                  selectedItem={selectedExtraExpenses}
                  onSelectItem={(item) => setSelectedExtraExpenses(item)}
                />
              </View> */}
              <View style={styles.timeViewStyles}>
                <AppInputText
                  label="Going to participents"
                  width="30%"
                  placeholder="Total KMs : "
                  iconName="car-settings"
                  keyboardType="numeric"
                  pattern="[0-9]*"
                  value={goingKM ?? ""}
                  onChangeText={(txt) =>
                    setGoingKM(
                      txt.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
                    )
                  }
                />
                <AppInputText
                  label="Coming back from participant's"
                  width="30%"
                  placeholder="Total KMs : "
                  iconName="car-settings"
                  keyboardType="numeric"
                  pattern="[0-9]*"
                  value={comingBackKM ?? ""}
                  onChangeText={(txt) =>
                    setComingBackKM(
                      txt.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
                    )
                  }
                />
                 <AppInputText
                  label="Parking Charges"
                  width="30%"
                  placeholder="Charges : "
                  iconName="car-settings"
                  keyboardType="numeric"
                  pattern="[0-9]*"
                  value={parkingCharges ?? ""}
                  onChangeText={(txt) =>
                    setParkingCharges(
                      txt.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
                    )
                  }
                />
              </View>
            </View>
            <AppInputText
              iconName="note"
              placeholder="Brief Notes : "
              multiline
              height={200}
              width="100%"
              value={brifTask ?? ""}
              onChangeText={(txt) => setbrifTask(txt)}
            />

            <AppButton
              width="50%"
              label="Update"
              style={{ alignSelf: "center" }}
              onPress={() => OnPressUpdateHandler()}
            />
            {/* <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      alignSelf: "flex-end",
                    }}
                  >
                    Document List
                  </Text>
                  <AppButton
                    style={styles.btnUploasDocumentStyles}
                    label="Upload Documents"
                    color="pink"
                    width="20%"
                    onPress={() => setDocumentListShow(true)}
                  />
                </View>
                <View style={styles.documentListViewStyles}>
                  {allDocuments.length <= 0 ? (
                    <Text style={{ alignSelf: "center", fontSize: 25 }}>
                      There is no documents.
                    </Text>
                  ) : (
                    <AppGetDocumentCard
                      borderWidth={0}
                      height="100%"
                      items={allDocuments}
                      deleteEnable
                      OnClickDeleteDocumentHandler={(data) =>
                        OnClickDeleteDocumentHandler(data)
                      }
                    />
                  )}
                </View> */}
          </View>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
  },
  btnUploasDocumentStyles: {
    alignSelf: "center",
    marginVertical: 20,
  },
  mainViewStyles: {
    height: "100%",
    width: "100%",
  },
  titleStyles: {
    height: "5%",
    alignItems: "center",
    width: "100%",
  },

  dataViewStyles: {
    width: "90%",
    height: "80%",
    alignSelf: "center",
  },
  timeViewStyles: {
    marginTop: 10,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    alignSelf: "center",
  },
  sessionTimeSelection: {
    width: "100%",
    marginVertical: 10,
    alignSelf: "center",
    borderWidth: 1,
    padding: 5,
  },
  uploadFileViewStyles: {
    height: "70%",
    marginTop: 20,
  },
  txtLabelStyles: {
    alignSelf: "center",
    fontSize: 25,
    fontWeight: "bold",
  },
  btnViewStyles: {
    //bottom: 5,
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    //position: "absolute",
    alignSelf: "center",
  },
  btnStyles: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around",
  },
  documentListViewStyles: {
    height: "30%",
    marginTop: 20,
    borderWidth: 1,
  },
  fileListViewStyles: {
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "space-between",
    borderRadius: 25,
    paddingHorizontal: 20,
    margin: 5,
    flexDirection: "row",
    width: "70%",
    backgroundColor: Colors.InputText,
  },
});

export default AppSessionUpdateCard;
