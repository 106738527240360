import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text } from "react-native";

import AppFolder from "../../components/AppFolder";

function UploadFile({
  participantDetails,
  switchEnable,
  deleteEnable,
  access,
}) {
  return (
    <View style={styles.container}>
      <AppFolder
        participantDetails={participantDetails}
        switchEnable={switchEnable}
        deleteEnable={deleteEnable}
        access={access}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
  },
});

export default UploadFile;
