import React, { useState, useContext, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  FlatList,
  Platform,
  Dimensions,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import * as WebBrowser from "expo-web-browser";

import AuthContext from "../../../auth/context";
import DepartmentDeciderScreen from "../../departments/DepartmentDeciderScreen";
import listingAPI from "../../../api/listings";

import MainServicesScreen from "./Services/MainServicesScreen";
import MainExtraExpensesScreen from "./ExtraExpenses/MainExtraExpensesScreen";
import TherapistListScreen from "./therapy/TherapistListScreen";
import AllocateServicesScreen from "./Participant/AllocateServicesScreen";
import ParticipantListScreen from "./Participant/ParticipantListScreen";
import ReportScreen from "./reports/ReportScreen";
import TemplatesHomeScreen from "./Templates/TemplatesHomeScreen";
import WorkTrackerScreen from "./TherapistWorkTracker/WorkTrackerScreen";

import AppDrawerTypeBtn from "../../../components/AppDrawerTypeBtn";
import Cache from "../../../utility/cache";
import Colors from "../../../config/Colors";

function AdminHomeScreen({ navigation }) {
  const authContext = useContext(AuthContext);
  const [therapistDetails, setTherapistDetails] = useState({ name: "" });
  const [currentScreen, setCurrentScreen] = useState("Participant-List");

  const { height, width } = useWindowDimensions();

  let windowHeight = width + height;

  let Screen;
  if (currentScreen == "Services") {
    Screen = MainServicesScreen;
  } else if (currentScreen == "ExtraExpenses") {
    Screen = MainExtraExpensesScreen;
  } else if (currentScreen == "Therapist-List") {
    Screen = TherapistListScreen;
  } else if (currentScreen == "AllocateService") {
    Screen = AllocateServicesScreen;
  } else if (currentScreen == "Participant-List") {
    Screen = ParticipantListScreen;
  } else if (currentScreen == "Report") {
    Screen = ReportScreen;
  } else if (currentScreen == "Templates") {
    Screen = TemplatesHomeScreen;
  } else if (currentScreen == "worktracker") {
    Screen = WorkTrackerScreen;
  } else if (currentScreen == "SignOut") {
    Screen = DepartmentDeciderScreen;
  }

  useEffect(() => {
    GetParticipantAllName();
  }, []);

  const GetParticipantAllName = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      therapist_id: therapist_main_id,
    });
    const { data } = await listingAPI.TherapyEmpPost({
      endPoint: "/selected/allDetails/of/TherapyEmp/status/notDeleted",
      data: myJsonServices,
    });

    if (data.length > 0) {
      setTherapistDetails(data[0]);
    } else {
      setTherapistDetails({ name: "" });
    }
  };

  const SignOutHandler = async () => {
    await Cache.store("signOut", true);
    await Cache.removeItem("u_department");
    authContext.setUser("login");
  };

  const OutlookLoginHandler = async () => {
    let result = await WebBrowser.openBrowserAsync(
      "https://login.live.com/login.srf?wa=wsignin1.0&rpsnv=13&ct=1655456536&rver=7.0.6737.0&wp=MBI_SSL&wreply=https%3a%2f%2foutlook.live.com%2fowa%2f%3fnlp%3d1%26RpsCsrfState%3d812f5175-8afc-1c49-9076-0015d08561d3&id=292841&aadredir=1&CBCXT=out&lw=1&fl=dob%2cflname%2cwld&cobrandid=90015"
    );
  };

  return (
    <LinearGradient
      style={[styles.container, { height, width }]}
      colors={["#97D9E100", "#D9AFD900"]}
    >
      <LinearGradient
        style={[styles.leftViewStyles]}
        colors={["rgba(255, 255, 255, 0.9)"]}
      >
        <View style={[styles.ProfilesStylesView]}>
          <TouchableOpacity>
            <Image
              style={[
                styles.ProfileImageStyles,
                { height: windowHeight / 45, width: windowHeight / 45 },
              ]}
              source={require("../../../config/img/profile.png")}
            />
          </TouchableOpacity>
          <View style={{ width: "50%" }}>
            <div>
              <marquee
                scrollamount="4"
                style={{ color: "#fff", fontSize: "100%", fontWeight: "bold" }}
              >
                {therapistDetails.name}
              </marquee>
            </div>
            {/* <View
              style={{
                alignItems: "flex-start",
                width: "30%",
              }}
            >
              <Text style={styles.onlineTxtStyles}>Online</Text>
            </View> */}
          </View>
        </View>
        <ScrollView
          style={{ width: "100%", alignSelf: "center" }}
          showsVerticalScrollIndicator={false}
        >
          <AppDrawerTypeBtn
            border={1}
            iconName="office-building"
            label="Services"
            onPress={() => setCurrentScreen("Services")}
            backgroundColor={currentScreen == "Services" ? Colors.white : ""}
          />
          <AppDrawerTypeBtn
            border={1}
            iconName="office-building"
            label="Extra Expenses"
            onPress={() => setCurrentScreen("ExtraExpenses")}
            backgroundColor={
              currentScreen == "ExtraExpenses" ? Colors.white : ""
            }
          />
          <AppDrawerTypeBtn
            border={1}
            iconName="account-group"
            label="Therapist-List"
            onPress={() => setCurrentScreen("Therapist-List")}
            backgroundColor={
              currentScreen == "Therapist-List" ? Colors.white : ""
            }
          />
          <AppDrawerTypeBtn
            border={1}
            iconName="wheelchair"
            IconType="FontAwesome"
            label="Allocate Service"
            onPress={() => setCurrentScreen("AllocateService")}
            backgroundColor={
              currentScreen == "AllocateService" ? Colors.white : ""
            }
          />
          <AppDrawerTypeBtn
            border={1}
            iconName="account-group"
            label="Participant"
            onPress={() => setCurrentScreen("Participant-List")}
            backgroundColor={
              currentScreen == "Participant-List" ? Colors.white : ""
            }
          />
          <AppDrawerTypeBtn
            border={1}
            iconName="wheelchair"
            IconType="FontAwesome"
            label="Work Tracker"
            onPress={() => setCurrentScreen("worktracker")}
            backgroundColor={currentScreen == "worktracker" ? Colors.white : ""}
          />
          <AppDrawerTypeBtn
            border={1}
            iconName="wheelchair"
            IconType="FontAwesome"
            label="Templates"
            onPress={() => setCurrentScreen("Templates")}
            backgroundColor={currentScreen == "Templates" ? Colors.white : ""}
          />

          {/* <AppDrawerTypeBtn
            border={1}
            iconName="wheelchair"
            IconType="FontAwesome"
            label="Report"
            onPress={() => setCurrentScreen("Report")}
            backgroundColor={currentScreen == "Report" ? Colors.white : ""}
          /> */}
          {/* <AppDrawerTypeBtn
            border={1}
            iconName="email"
            label="OutLook"
            //onPress={() => setCurrentScreen("OutLook")}
            backgroundColor={currentScreen == "OutLook" ? Colors.white : ""}
            onPress={() => OutlookLoginHandler()}
          /> */}
        </ScrollView>
        <AppDrawerTypeBtn
          style={styles.btnSignOutStyles}
          border={1}
          IconType="Entypo"
          iconName="log-out"
          label="Sign Out"
          onPress={() => SignOutHandler("SignOut")}
          backgroundColor={currentScreen == "SignOut" ? Colors.white : ""}
        />
      </LinearGradient>

      <View style={[styles.rightViewStyles]}>
        <Screen navigation={navigation} />
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#eeeee4",
  },
  leftViewStyles: {
    height: "90%",
    width: "15%",
    borderRadius: 20,
    borderWidth: 2,
    alignItems: "center",
    paddingHorizontal: 10,

    backgroundColor: "rgba(0,0,0, 0.4) 100%",
  },
  rightViewStyles: {
    alignItems: "center",
    justifyContent: "center",
    width: "83%",
    height: "90%",
    borderRadius: 5,
    backgroundColor: Colors.lightDark,
    borderRadius: 20,
    borderWidth: 2,
    backgroundColor: "rgba(0,0,0,0.2) 100%",
    //backgroundColor: "rgba(52, 52, 52, alpha)",
  },
  btnSignOutStyles: {
    position: "absolute",
    bottom: 0,
  },
  webview: {
    flex: 1,
  },
  ProfilesStylesView: {
    // height: "10%",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 5,
  },
  ProfileImageStyles: {
    // height: 70,
    // width: 70,
    borderRadius: 20,
    borderWidth: 1,
    marginHorizontal: 10,
    backgroundColor: "#fff",
  },
  onlineTxtStyles: {
    color: "green",
    backgroundColor: "#fff",
    fontSize: "50%",
    borderRadius: 10,
    alignSelf: "center",
    padding: 5,
  },
});

export default AdminHomeScreen;
