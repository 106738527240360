import React, { useEffect } from "react";
import { View, StyleSheet, Text } from "react-native";

const link =
  "https://docs.google.com/document/d/1SqWrLVSCeqybPTH3K3kduHSIjYvg-82o/edit?usp=sharing&ouid=106883759549278778598&rtpof=true&sd=true";

function Test({}) {
  const onError = (e) => {
    Logger.logError(e, "error in file-viewer");
  };

  return (
    <View style={styles.container}>
      <Text>hi...</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "white",
    padding: 10,
  },
});

export default Test;
