import React, { useState, useContext, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  FlatList,
  Platform,
  Image,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import * as WebBrowser from "expo-web-browser";

import PendingClaimScreen from "./PendingClaimScreen";
import ReceiptGeneratorScreen from "./ReceiptGeneratorScreen";
import ReportGeneratorScreen from "./ReportGeneratorScreen";
import ClaimedLogScreen from "./ClaimedLogScreen";

import Routes from "../../../navigation/routes";
import AppDrawerTypeBtn from "../../../components/AppDrawerTypeBtn";
import Colors from "../../../config/Colors";
import Cache from "../../../utility/cache";

function AccountHomeScreen({ navigation }) {
  const [currentScreen, setCurrentScreen] = useState("PendingClames");
  const [therapistDetails, setTherapistDetails] = useState({ name: "" });

  const { height, width } = useWindowDimensions();

  let Screen;

  if (currentScreen == "PendingClames") {
    Screen = PendingClaimScreen;
  } else if (currentScreen == "ReceiptGeneratorScreen") {
    Screen = ReceiptGeneratorScreen;
  } else if (currentScreen == "ReportGeneratorScreen") {
    Screen = ReportGeneratorScreen;
  } else if (currentScreen == "ClaimedLogScreen") {
    Screen = ClaimedLogScreen;
  }

  const OutlookLoginHandler = async () => {
    let result = await WebBrowser.openBrowserAsync(
      "https://login.live.com/login.srf?wa=wsignin1.0&rpsnv=13&ct=1655456536&rver=7.0.6737.0&wp=MBI_SSL&wreply=https%3a%2f%2foutlook.live.com%2fowa%2f%3fnlp%3d1%26RpsCsrfState%3d812f5175-8afc-1c49-9076-0015d08561d3&id=292841&aadredir=1&CBCXT=out&lw=1&fl=dob%2cflname%2cwld&cobrandid=90015"
    );
  };

  const SignOutHandler = async () => {
    await Cache.removeItem("u_department");
    await Cache.store("signOut", true);
    navigation.navigate(Routes.STACK_ACCOUNTROOTSCREEN);
  };

  return (
    <LinearGradient
      style={[styles.container, { height, width }]}
      colors={["#97D9E100", "#D9AFD900"]}
    >
      <LinearGradient
        style={styles.leftViewStyles}
        colors={["rgba(255, 255, 255, 0.9)"]}
      >
        <View style={styles.ProfilesStylesView}>
          <Text
            style={{
              color: "#fff",
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            Account Team
          </Text>
        </View>
        <ScrollView
          style={{ width: "100%", alignSelf: "center" }}
          showsVerticalScrollIndicator={false}
        >
          <AppDrawerTypeBtn
            border={1}
            iconName="account"
            label="Pending Claim"
            onPress={() => setCurrentScreen("PendingClames")}
            backgroundColor={
              currentScreen == "PendingClames" ? Colors.white : ""
            }
          />
          <AppDrawerTypeBtn
            border={1}
            iconName="file"
            label="Receipt Generator"
            onPress={() => setCurrentScreen("ReceiptGeneratorScreen")}
            backgroundColor={
              currentScreen == "ReceiptGeneratorScreen" ? Colors.white : ""
            }
          />
          <AppDrawerTypeBtn
            border={1}
            iconName="file"
            label="Report Generator"
            onPress={() => setCurrentScreen("ReportGeneratorScreen")}
            backgroundColor={
              currentScreen == "ReportGeneratorScreen" ? Colors.white : ""
            }
          />
          <AppDrawerTypeBtn
            border={1}
            iconName="file"
            label="Claimed Log"
            onPress={() => setCurrentScreen("ClaimedLogScreen")}
            backgroundColor={
              currentScreen == "ClaimedLogScreen" ? Colors.white : ""
            }
          />
          <AppDrawerTypeBtn
            border={1}
            iconName="email"
            label="OutLook"
            backgroundColor={currentScreen == "OutLook" ? Colors.white : ""}
            onPress={() => OutlookLoginHandler()}
          />
        </ScrollView>
        <AppDrawerTypeBtn
          style={styles.btnSignOutStyles}
          border={1}
          IconType="Entypo"
          iconName="log-out"
          label="Sign Out"
          onPress={() => SignOutHandler("SignOut")}
          backgroundColor={currentScreen == "SignOut" ? Colors.white : ""}
        />
      </LinearGradient>

      <View style={styles.rightViewStyles}>
        <Screen navigation={navigation} />
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#eeeee4",
  },
  ProfilesStylesView: {
    height: "5%",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  ProfileImageStyles: {
    height: 70,
    width: 70,
    borderRadius: 20,
    borderWidth: 1,
    marginHorizontal: 10,
    backgroundColor: "#fff",
  },
  onlineTxtStyles: {
    color: "green",
    backgroundColor: "#fff",
    fontSize: 15,
    paddingVertical: 5,
    borderRadius: 10,
    alignSelf: "center",
    padding: 5,
  },
  leftViewStyles: {
    height: "90%",
    width: "15%",
    borderRadius: 20,
    borderWidth: 2,
    alignItems: "center",
    paddingHorizontal: 10,
    backgroundColor: "rgba(0,0,0, 0.4) 100%",
  },
  rightViewStyles: {
    height: "90%",
    width: "83%",
    borderRadius: 5,
    backgroundColor: Colors.lightDark,
    borderRadius: 20,
    borderWidth: 2,
    backgroundColor: "rgba(0,0,0,0.2) 100%",
    padding: 10,
    //backgroundColor: "rgba(52, 52, 52, alpha)",
  },
  btnSignOutStyles: {
    position: "absolute",
    bottom: 0,
  },
  webview: {
    flex: 1,
  },
});

export default AccountHomeScreen;
