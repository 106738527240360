import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  TouchableOpacity,
} from "react-native";
import { Menu, MenuItem, MenuDivider } from "react-native-material-menu";

import AppIcon from "../components/AppIcon";

function AppMenuItems({
  items,
  iconEnable = false,
  selectedMenu,
  menuWidth = 250,
  label = "Show menu",
  height = "100%",
  width = "90%",
}) {
  const [visible, setVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState({
    main_id: 0,
    name: label,
  });

  const hideMenu = () => setVisible(false);
  const showMenu = () => setVisible(true);

  const selectedMenuHandler = (item) => {
    setSelectedItems(item);
    selectedMenu(item);
    hideMenu();
  };

  return (
    <View style={[styles.menuStyles, { height: height, width: width }]}>
      <TouchableOpacity style={styles.touchAbleStyles} onPress={showMenu}>
        <Menu
          style={{ width: menuWidth }}
          visible={visible}
          anchor={
            iconEnable ? (
              <AppIcon backgroundColor="" name="share" />
            ) : (
              <Text>{selectedItems.name.toUpperCase()}</Text>
            )
          }
          onRequestClose={hideMenu}
        >
          <View style={{ backgroundColor: "#DCE1E1" }}>
            <FlatList
              data={items}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item }) => (
                <MenuItem
                  key={item.name}
                  onPress={() => selectedMenuHandler(item)}
                >
                  {item.name.toUpperCase()}
                </MenuItem>
              )}
            />
          </View>

          {/* 
        <MenuItem onPress={() => selectedMenuHandler("Menu item 2")}>
          Menu item 2
        </MenuItem>
        <MenuItem disabled>Disabled item</MenuItem>
        <MenuDivider />
        <MenuItem onPress={() => selectedMenuHandler("Menu item 4")}>
          Menu item 4
        </MenuItem> */}
        </Menu>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  menuStyles: {
    alignItems: "center",
    justifyContent: "center",
  },
  touchAbleStyles: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },
});
export default AppMenuItems;
