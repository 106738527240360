import React, { useState, useEffect } from "react";
import { View, StyleSheet, FlatList, Text } from "react-native";
import Modal from "react-native-modal";
import moment from "moment";

import listingAPI from "../../../../api/listings";

import AppInputText from "../../../../components/AppInputText";
import AppAlert from "../../../../components/AppAlert";
import AppSearchBar from "../../../../components/AppSearchBar";
import AppServicesListCard from "../../../../components/cards/AppServicesListCard";
import AppParticipantDetails from "../../../../components/cards/AppParticipantDetails";
import AppGetDocumentCard from "../../../../components/AppGetDocumentCard";
import AppAlertYesNo from "../../../../components/AppAlertYesNo";
import UploadFile from "../../../../components/uploadDocument_In_Folder/UploadFile";
import Cache from "../../../../utility/cache";
import Routes from "../../../../navigation/routes";
import AppButton from "../../../../components/AppButton";

function TherapistParticipantListScreen({ navigation, data }) {
  const [allParticipant, setAllParticipant] = useState([]);
  const [attentionReqAllParticipant, setAttentionReqAllParticipant] = useState(
    []
  );
  const [allNewParticipant, setAllNewParticipant] = useState([]);
  const [allBirthdayParticipant, setAllBirthdayParticipant] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailsShow, setDetailsShow] = useState(false);
  const [details, setDetails] = useState([]);

  const [searchParticipant, setSearchParticipant] = useState();
  const [filterdData, setFilterdData] = useState([]);
  const [masterData, setMasterData] = useState([]);

  const [allServices, setAllServices] = useState([]);
  const [allServicesShow, setAllServicesShow] = useState(false);
  const [participantDetails, setParticipantDetails] = useState();
  const [choiceBtnSetter, setChoiceBtnSetter] = useState("ParticipantsList");
  const [alertDetails, setAlertDetails] = useState({
    modal: false,
    alert: "",
  });

  useEffect(() => {
    let isMounted = true;

    // GetAllFridgedParticipantsDetails();
    // GetAllBirthdayParticipants();
    // GetAllNewParticipants();

    GetAllNewParticipantsForNum();
    GetAllBirthdayParticipantsForNum();
    GetAllFridgedParticipantsDetailsForNum();
    GetParticipantAllShortByDate();

    return () => {
      isMounted = false;
    };
  }, []);

  const SearchFilter = (text) => {
    if (text) {
      const newData = masterData.filter((item) => {
        const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });

      setFilterdData(newData);
      setSearchParticipant(text);
    } else {
      setFilterdData(masterData);
      setSearchParticipant(text);
    }
  };

  const GetParticipantAllShortByDate = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      therapist_main_id: therapist_main_id,
    });
    const { data } = await listingAPI.ParticipantForTherapisPost({
      endPoint: "/selected/therapist/allParticipants/status/notDeleted",
      data: myJsonServices,
    });

    setFilterdData(data);
    setMasterData(data);
    setAllParticipant(data);
  };

  const GetListOfDocuments = async (item) => {
    setParticipantDetails(item);
    setChoiceBtnSetter("DocumentList");
  };

  const OnClickSubmitSessionHandlers = (item) => {
    navigation.navigate(Routes.STACK_SESSIONMAINSCREEN, { items: item });
  };

  const onClickGetSelectedItemHandler = (item) => {
    setDetailsShow(true);
    setDetails([item]);
  };

  const GetParticipantAllDetailsOrderByName = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    if (choiceBtnSetter == "ParticipantsList") {
      const myJsonServices = JSON.stringify({
        therapist_main_id: therapist_main_id,
      });
      const { data } = await listingAPI.ParticipantForTherapisPost({
        endPoint:
          "/selected/therapist/allParticipants/status/notDeleted/shortBy/name",
        data: myJsonServices,
      });

      setFilterdData(data);
      setMasterData(data);
      setAllParticipant(data);
    }
  };

  const onClickGetServiceList = async (item) => {
    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
      currentPlanNumver: item.currentPlanNumver,
    });
    const { data } = await listingAPI.AllocateServicesPost({
      endPoint: "/select/all/Allocated/services/name/of/selected/participant",
      data: myJsonServices,
    });
    setAllServices(data);
    setAllServicesShow(true);
  };

  const GetAllFridgedParticipantsDetails = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      therapist_main_id: therapist_main_id,
    });
    const { data } = await listingAPI.ParticipantForTherapisPost({
      endPoint:
        "/select/all/fridged/Participants/status/notDeleted/as/per/selected/therapist",
      data: myJsonServices,
    });

    setFilterdData(data);
    setMasterData(data);
    // setAllParticipant(data);
    setAttentionReqAllParticipant(data);
  };

  const GetAllFridgedParticipantsDetailsForNum = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      therapist_main_id: therapist_main_id,
    });
    const { data } = await listingAPI.ParticipantForTherapisPost({
      endPoint:
        "/select/all/fridged/Participants/status/notDeleted/as/per/selected/therapist",
      data: myJsonServices,
    });

    // setFilterdData(data);
    // setMasterData(data);
    // setAllParticipant(data);
    setAttentionReqAllParticipant(data);
  };

  const GetAllBirthdayParticipants = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      therapist_main_id: therapist_main_id,
      todayDate: moment(new Date()).format("MM-DD"),
    });
    const { data } = await listingAPI.ParticipantForTherapisPost({
      endPoint:
        "/select/all/Participants/status/notDeleted/hows/Birthday/today",
      data: myJsonServices,
    });

    setFilterdData(data);
    setMasterData(data);
    // setAllParticipant(data);
    setAllBirthdayParticipant(data);
  };

  const GetAllBirthdayParticipantsForNum = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      therapist_main_id: therapist_main_id,
      todayDate: moment(new Date()).format("MM-DD"),
    });
    const { data } = await listingAPI.ParticipantForTherapisPost({
      endPoint:
        "/select/all/Participants/status/notDeleted/hows/Birthday/today",
      data: myJsonServices,
    });

    // setFilterdData(data);
    // setMasterData(data);
    // setAllParticipant(data);
    setAllBirthdayParticipant(data);
  };

  const GetAllNewParticipants = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      therapist_main_id: therapist_main_id,
    });
    const { data } = await listingAPI.ParticipantForTherapisPost({
      endPoint:
        "/selected/therapist/allNewParticipants/status/notDeleted/shortBy/name",
      data: myJsonServices,
    });

    setFilterdData(data);
    setMasterData(data);
    //setAllParticipant(data);
    setAllNewParticipant(data);
  };

  const GetAllNewParticipantsForNum = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      therapist_main_id: therapist_main_id,
    });
    const { data } = await listingAPI.ParticipantForTherapisPost({
      endPoint:
        "/selected/therapist/allNewParticipants/status/notDeleted/shortBy/name",
      data: myJsonServices,
    });

    // setFilterdData(data);
    // setMasterData(data);
    // setAllParticipant(data);
    setAllNewParticipant(data);
  };

  return (
    <View style={styles.container}>
      <AppAlertYesNo
        mainViewStyles={{ width: "90%", height: "50%" }}
        isModalVisible={allServicesShow}
        noBtnPress={() => setAllServicesShow(false)}
        yesBtnEnable={false}
        leftBtnLabel="Back"
        Alert="Service List"
      >
        <View style={{ width: "100%", height: "80%" }}>
          <AppServicesListCard items={allServices} />
        </View>
      </AppAlertYesNo>
      <AppAlertYesNo
        mainViewStyles={{ width: "90%" }}
        isModalVisible={deleteModal}
        leftBtnLabel="No"
        noBtnPress={() => setDeleteModal(false)}
        yesBtnPress={() => OnClickYesHandler()}
        rightBtnLabel="Yes"
        Alert="Are you sure, Do you want to delete ?"
      />

      <AppAlert
        alert={alertDetails}
        okBtnPress={() => setAlertDetails({ modal: false })}
      />

      <View style={styles.choiceBtnStyles}>
        <AppButton
          width="20%"
          style={{ borderWidth: 1 }}
          label={`Participant's List ${allParticipant.length}`}
          color={choiceBtnSetter == "ParticipantsList" ? "white" : ""}
          onPress={() => {
            setChoiceBtnSetter("ParticipantsList");
            GetParticipantAllShortByDate();
          }}
        />
        <AppButton
          width="20%"
          style={{ borderWidth: 1 }}
          label={`Attention Required ${attentionReqAllParticipant.length}`}
          color={
            choiceBtnSetter == "AttentionRequired"
              ? "white"
              : attentionReqAllParticipant.length > 0
              ? "darkRed"
              : ""
          }
          onPress={() => {
            GetAllFridgedParticipantsDetails();
            setChoiceBtnSetter("AttentionRequired");
          }}
        />

        <AppButton
          width="20%"
          style={{ borderWidth: 1 }}
          label={`New Participants ${allNewParticipant.length}`}
          color={
            choiceBtnSetter == "NewParticipants"
              ? "white"
              : allNewParticipant.length > 0
              ? "darkRed"
              : ""
          }
          onPress={() => {
            setChoiceBtnSetter("NewParticipants");
            GetAllNewParticipants();
          }}
        />

        <AppButton
          width="20%"
          style={{ borderWidth: 1 }}
          label={`Birthday ${allBirthdayParticipant.length}`}
          color={
            choiceBtnSetter == "Birthday"
              ? "white"
              : allBirthdayParticipant.length > 0
              ? "darkRed"
              : ""
          }
          onPress={() => {
            setChoiceBtnSetter("Birthday");
            GetAllBirthdayParticipants();
          }}
        />
      </View>

      {choiceBtnSetter == "AttentionRequired" && (
        <View style={styles.BottomViewStyles}>
          {filterdData.length == 0 ? (
            <Text style={{ alignSelf: "center", fontSize: 35 }}>No Data</Text>
          ) : (
            <>
              <View style={{ height: "10%", width: "100%" }}>
                <Text style={{ fontSize: 25, fontWeight: "bold" }}>
                  Total :{filterdData.length}
                </Text>
                <AppInputText
                  width="100%"
                  mainInputViewStyles={{
                    alignSelf: "center",
                  }}
                  iconName="search"
                  IconType="FontAwesome5"
                  placeholder="Search Participant..."
                  value={searchParticipant ?? ""}
                  onChangeText={(txt) => SearchFilter(txt)}
                />
              </View>

              <View style={{ height: "90%", width: "100%" }}>
                <AppParticipantDetails
                  items={filterdData}
                  onPressDocumentList={(items) => GetListOfDocuments(items)}
                  onPressDate={() => GetParticipantAllShortByDate()}
                  onPressName={() => GetParticipantAllDetailsOrderByName()}
                  onPressServiceList={(item) => onClickGetServiceList(item)}
                  onPressAddFileEnable={(items) =>
                    OnClickSubmitSessionHandlers(items)
                  }
                />
              </View>
            </>
          )}
        </View>
      )}

      {choiceBtnSetter == "ParticipantsList" && (
        <View style={styles.BottomViewStyles}>
          <Text style={{ fontSize: 25, fontWeight: "bold" }}>
            Total :{filterdData.length}
          </Text>
          <>
            <View style={{ height: "10%", width: "100%" }}>
              <AppInputText
                width="100%"
                mainInputViewStyles={{
                  alignSelf: "center",
                }}
                iconName="search"
                IconType="FontAwesome5"
                placeholder="Search Participant..."
                value={searchParticipant ?? ""}
                onChangeText={(txt) => SearchFilter(txt)}
              />
            </View>
            {filterdData.length == 0 ? (
              <Text style={{ alignSelf: "center", fontSize: 35 }}>No Data</Text>
            ) : (
              <View style={{ height: "90%", width: "100%" }}>
                <AppParticipantDetails
                  items={filterdData}
                  onPressDocumentList={(items) => GetListOfDocuments(items)}
                  onPressDate={() => GetParticipantAllShortByDate()}
                  onPressName={() => GetParticipantAllDetailsOrderByName()}
                  onPressServiceList={(item) => onClickGetServiceList(item)}
                  onPressAddFileEnable={(items) =>
                    OnClickSubmitSessionHandlers(items)
                  }
                />
              </View>
            )}
          </>
        </View>
      )}

      {choiceBtnSetter == "Birthday" && (
        <View style={styles.BottomViewStyles}>
          {filterdData.length == 0 ? (
            <Text style={{ alignSelf: "center", fontSize: 35 }}>No Data</Text>
          ) : (
            <>
              <View style={{ height: "10%", width: "100%" }}>
                <Text style={{ fontSize: 25, fontWeight: "bold" }}>
                  Total :{filterdData.length}
                </Text>
                <AppInputText
                  width="100%"
                  mainInputViewStyles={{
                    alignSelf: "center",
                  }}
                  iconName="search"
                  IconType="FontAwesome5"
                  placeholder="Search Participant..."
                  value={searchParticipant ?? ""}
                  onChangeText={(txt) => SearchFilter(txt)}
                />
              </View>

              <View style={{ height: "90%", width: "100%" }}>
                <AppParticipantDetails
                  items={filterdData}
                  onPressDocumentList={(items) => GetListOfDocuments(items)}
                  onPressDate={() => GetParticipantAllShortByDate()}
                  onPressName={() => GetParticipantAllDetailsOrderByName()}
                  onPressServiceList={(item) => onClickGetServiceList(item)}
                  onPressAddFileEnable={(items) =>
                    OnClickSubmitSessionHandlers(items)
                  }
                />
              </View>
            </>
          )}
        </View>
      )}

      {choiceBtnSetter == "NewParticipants" && (
        <View style={styles.BottomViewStyles}>
          {filterdData.length == 0 ? (
            <Text style={{ alignSelf: "center", fontSize: 35 }}>No Data</Text>
          ) : (
            <>
              <View style={{ height: "10%", width: "100%" }}>
                <Text style={{ fontSize: 25, fontWeight: "bold" }}>
                  Total :{filterdData.length}
                </Text>
                <AppInputText
                  width="100%"
                  mainInputViewStyles={{
                    alignSelf: "center",
                  }}
                  iconName="search"
                  IconType="FontAwesome5"
                  placeholder="Search Participant..."
                  value={searchParticipant ?? ""}
                  onChangeText={(txt) => SearchFilter(txt)}
                />
              </View>

              <View style={{ height: "90%", width: "100%" }}>
                <AppParticipantDetails
                  items={filterdData}
                  onPressDocumentList={(items) => GetListOfDocuments(items)}
                  onPressDate={() => GetParticipantAllShortByDate()}
                  onPressName={() => GetParticipantAllDetailsOrderByName()}
                  onPressServiceList={(item) => onClickGetServiceList(item)}
                  onPressAddFileEnable={(items) =>
                    OnClickSubmitSessionHandlers(items)
                  }
                />
              </View>
            </>
          )}
        </View>
      )}

      {choiceBtnSetter == "DocumentList" && (
        <View style={styles.BottomViewStyles}>
          <UploadFile
            participantDetails={participantDetails}
            access="limited"
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    alignItems: "center",
  },
  BottomViewStyles: {
    height: "95%",
    width: "99%",
    padding: 10,
    backgroundColor: "white",
    borderRadius: 10,
  },
  mainViewStyles: {
    height: "90%",
    width: "90%",
  },
  modalStyles: {
    height: "90%",
    width: "90%",
  },
  cardStyles: {
    width: "50%",
    justifyContent: "space-around",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 50,
  },
  documentModalViewStyles: {
    height: "50%",
    width: "90%",
    backgroundColor: "#fff",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  choiceBtnStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
});

export default TherapistParticipantListScreen;
