import React from "react";

import { createStackNavigator } from "@react-navigation/stack";

import DepartmentDeciderScreen from "../../screens/departments/DepartmentDeciderScreen";
import ITRootScreen from "../../screens/ITAdmin/ITRootScreen";
import ITAdminHomeScreen from "../../screens/ITAdmin/admin/ITAdminHomeScreen";

const Stack = createStackNavigator();

const StackNavigator = () => (
  <Stack.Navigator
    presentation="modal"
    initialRouteName="STACK_ITRootScreen"
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen
      name="STACK_ITRootScreen"
      component={ITRootScreen}
      options={{ title: "Access Foundation" }}
    />
    <Stack.Screen
      name="STACK_ITAdminHomeScreen"
      component={ITAdminHomeScreen}
      options={{ title: "Access Foundation" }}
    />
    <Stack.Screen
      name="STACK_AllDepartmentScreen"
      component={DepartmentDeciderScreen}
      options={{ title: "Access Foundation" }}
    />
  </Stack.Navigator>
);

export default StackNavigator;
