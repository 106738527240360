import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text, ScrollView } from "react-native";

import AppButton from "../../../components/AppButton";
import AppClamiedSessionCard from "../../../components/cards/AppClamiedSessionCard";
import AppClamiedBySessionCard from "../../../components/cards/AppClamiedBySessionCard";
import AppSessionClamiedYearSelecter from "../../../components/cards/AppSessionClamiedYearSelecter";
import Cache from "../../../utility/cache";
import listingAPI from "../../../api/listings";

function ClaimedLogScreen({}) {
  const [btnChoice, setBtnChoice] = useState(false);
  const [allSession, setAllSession] = useState([{ sessionClamiedYear: "" }]);

  useEffect(() => {
    // GetAllMyClamiedSession();
    GetAllMyClamiedSessionGroupByYears();
  }, []);

  const GetAllMyClamiedSessionGroupByYears = async () => {
    const loginedBy_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      clamied_by_main_id: loginedBy_main_id,
    });
    const { data } = await listingAPI.ClamiedBySession_POST({
      endPoint:
        "/select/all/my/clamied/group/by/date/session/details/groupBy/Year",
      data: myJsonServices,
    });

    setAllSession(data);
  };

  const GetAllMyClamiedSession = async () => {
    const loginedBy_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      clamied_by_main_id: loginedBy_main_id,
    });
    const { data } = await listingAPI.ClamiedBySession_POST({
      endPoint: "/select/all/my/clamied/group/by/date/session/details",
      data: myJsonServices,
    });

    setAllSession(data);
  };

  const GetAllOthersClamiedSessionGroupByYears = async () => {
    const loginedBy_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      clamied_by_main_id: loginedBy_main_id,
    });
    const { data } = await listingAPI.ClamiedBySession_POST({
      endPoint:
        "/select/all/others/clamied/group/by/date/session/details/groupBy/Year",
      data: myJsonServices,
    });

    setAllSession(data);
  };

  const GetAllOthersClamiedSession = async () => {
    const loginedBy_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      clamied_by_main_id: loginedBy_main_id,
    });
    const { data } = await listingAPI.ClamiedBySession_POST({
      endPoint: "/select/all/others/clamied/session/details",
      data: myJsonServices,
    });

    setAllSession(data);
  };

  return (
    <View style={styles.container}>
      <View style={styles.TopViewStyles}>
        <AppButton
          border={1}
          color={!btnChoice ? "InputText" : ""}
          label="My Claim"
          onPress={() => {
            setBtnChoice(false);
            GetAllMyClamiedSessionGroupByYears();
            //GetAllMyClamiedSession();
          }}
        />
        <AppButton
          border={1}
          color={btnChoice ? "InputText" : ""}
          label="Other's Claim"
          onPress={() => {
            setBtnChoice(true);
            //GetAllOthersClamiedSession();
            GetAllOthersClamiedSessionGroupByYears();
          }}
        />
      </View>
      <View style={styles.BottomViewStyles}>
        <ScrollView>
          {allSession.length > 0 ? (
            allSession.map((item) => (
              <AppSessionClamiedYearSelecter
                key={item.sessionClamiedYear}
                item={item}
                editAndDelete={!btnChoice}
              />
            ))
          ) : (
            <Text style={{ alignSelf: "center", fontWeight: "bold" }}>
              No data found
            </Text>
          )}
        </ScrollView>
        {/* <AppClamiedBySessionCard
          items={allSession}
          clamiedDesider={btnChoice}
        /> */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 10,
  },
  TopViewStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottomWidth: 1,
  },
  BottomViewStyles: {
    height: "95%",
    width: "100%",
  },
});

export default ClaimedLogScreen;
