import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  FlatList,
  Dimensions,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import moment from "moment";

import listingAPI from "../api/listings";

import Cache from "../utility/cache";
import Colors from "../config/Colors";
import AppMenuItems from "../components/AppMenuItems";
import AppAlertYesNo from "../components/AppAlertYesNo";
import AppMultiFilesPicker from "../components/AppMultiFilesPicker";
import AppGetDocumentCard from "../components/AppGetDocumentCard";
import AppProgressbar from "../components/AppProgressbar";
import AppAlert from "../components/AppAlert";
import AppInputText from "../components/AppInputText";
import AppButton from "../components/AppButton";
import AppSearchBar from "../components/AppSearchBar";
import AppIcon from "./AppIcon";

const ListOfFolders = ({
  items,
  onPressFolder,
  shareOption = true,
  OnPressEditFolderName,
  OnPressDeleteFolde,
  editFolderAndDelete = false,
}) => {
  const [selectedOptions, setSelectedOptions] = useState({ modal: false });

  const options = [{ main_id: 1, name: "share", modal: true }];

  let uploadDate = moment(items.created_date).format("DD/MM/YYYY");

  let documentName = items.documentPath.slice(0, -1);
  let fileName = documentName.substring(documentName.indexOf("/") + 1);

  const onClickGetSelectedItemHandler = (item) => {};

  return (
    <View style={[styles.folderListMainViewStyles]}>
      <AppAlertYesNo
        isModalVisible={selectedOptions.modal}
        mainViewStyles={{ height: "100%", width: "100%" }}
        noBtnPress={() => setSelectedOptions({ modal: false })}
      >
        <AppSearchBar
          selectedAllEmployee
          getSelectedItem={(item) => onClickGetSelectedItemHandler(item)}
        />
      </AppAlertYesNo>
      <View style={styles.folderNameMainViewStyles}>
        <TouchableOpacity
          style={styles.folderNameStyles}
          onPress={() => onPressFolder(items)}
        >
          <MaterialCommunityIcons
            touchAble
            name="folder"
            size="200%"
            color="#F8D775"
          />
          <Text style={styles.txtNameStyles}>{fileName}</Text>
        </TouchableOpacity>
        <View style={{ width: "10%" }}>
          {shareOption ? (
            <AppMenuItems
              iconEnable
              items={options}
              selectedMenu={(item) => setSelectedOptions(item)}
            />
          ) : (
            <Text></Text>
          )}
        </View>
      </View>

      <Text style={[styles.txtStyles, { width: "40%" }]}>{uploadDate}</Text>

      {editFolderAndDelete ? (
        <View style={styles.IconViewStyles}>
          <AppIcon
            touchAble
            backgroundColor=""
            name="pen"
            onPress={() => OnPressEditFolderName(items)}
          />
          <AppIcon
            touchAble
            backgroundColor=""
            name="delete"
            iconColor="red"
            onPress={() => OnPressDeleteFolde(items)}
          />
        </View>
      ) : (
        <View style={styles.IconViewStyles}></View>
      )}
    </View>
  );
};

const ListOfFolderCreatedByName = ({
  items,
  AllFolders,
  onPressFolder,
  access,
  editFolderAndDelete,
}) => {
  const [open, setOpen] = useState(false);
  const [LayoutHeight, setLayoutHeight] = useState(0);

  useEffect(() => {
    if (open) {
      setLayoutHeight(null);
    } else {
      setLayoutHeight(0);
    }
  }, [open]);

  const onClickHandler = () => {
    setOpen(!open);
  };

  let Folders = AllFolders.filter(
    (i) => i.created_by_main_id === items.created_by_main_id
  );

  return (
    <View>
      <TouchableOpacity
        style={styles.CreatedByStyles}
        onPress={() => onClickHandler()}
      >
        <Text style={{ fontWeight: "bold" }}>{items.uploadBy}</Text>
      </TouchableOpacity>
      <View
        style={{
          height: LayoutHeight,
          overflow: "hidden",
        }}
      >
        <FlatList
          showsHorizontalScrollIndicator={false}
          data={Folders}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <ListOfFolders
              items={item}
              onPressFolder={() => onPressFolder(item)}
              shareOption={access == "full" ? true : false}
              editFolderAndDelete={editFolderAndDelete}
            />
          )}
        />
      </View>
    </View>
  );
};

function AppFolder({ participantDetails, onPressShare, switchEnable, access }) {
  const [newFolderName, setNewFolderName] = useState();
  const [myFolders, setMyFolders] = useState();
  const [othersFolders, setOthersFolders] = useState();
  const [documentModal, setDocumentModal] = useState(false);
  const [uploadDocumentHandler, setUploadDocumentHandler] = useState(false);
  const [allDocuments, setAllDocuments] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState({ documentPath: "" });
  const [fileUris, setFileUris] = useState([]);
  const [progressBar, setProgressBar] = useState(false);
  const [alertModal, setAlertModal] = useState({ alert: "", modal: false });

  const [btnSelecter, setBtnSelecter] = useState(true);
  const [createFolderModal, setCreateFolderModal] = useState(false);
  const [listOfCreatedByFolder, setlistOfCreatedByFolder] = useState([]);
  const [documentUploadAccess, setDocumentUploadAccess] = useState(false);
  const [deleteFolderModal, setDeleteFolderModal] = useState({
    modal: false,
    item: "",
  });
  const [updateFolderName, setUpdateFolderName] = useState({
    update: false,
    item: "",
  });

  useEffect(() => {
    GetAllFoldersHandler();
    GetAllFolders_As_per_Createdby_Handler();
  }, []);

  const GetAllFoldersHandler = async () => {
    const department = await Cache.get("selected_department");
    const loginBymain_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      participant_main_id: participantDetails.main_id,
      departmentMain_id: department,
      // created_by_main_id: loginBymain_id,
    });

    const { data } = await listingAPI.DocumnetHandler({
      endPoint:
        "/select/AllDocuments/of/selectedDepartment/of/uploadby/loginedin/person/notDeleted",
      data: myJsonServices,
    });

    let myFolder = data.filter((i) => i.created_by_main_id === loginBymain_id);
    let othersFolder = data.filter(
      (i) => i.created_by_main_id != loginBymain_id
    );

    setMyFolders(myFolder);
    setOthersFolders(othersFolder);
  };

  const GetAllFolders_As_per_Createdby_Handler = async () => {
    const department = await Cache.get("selected_department");
    const loginBymain_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      participant_main_id: participantDetails.main_id,
      departmentMain_id: department,
      // created_by_main_id: loginBymain_id,
    });

    const { data } = await listingAPI.DocumnetHandler({
      endPoint:
        "/select/AllDocuments/of/selectedDepartment/of/uploadby/loginedin/person/notDeleted/groupBy/createBy",
      data: myJsonServices,
    });

    let othersFolder = data.filter(
      (i) => i.created_by_main_id != loginBymain_id
    );

    setlistOfCreatedByFolder(othersFolder);
  };

  const OnClickCreateFolderHandler = async () => {
    const loginBymain_id = await Cache.get("loginedBy_main_id");
    const department = await Cache.get("selected_department");

    if (!newFolderName) {
      setAlertModal({ alert: "Please, Add folder name.", modal: true });
      return;
    }

    if (updateFolderName.update == false) {
      const myJsonServices = JSON.stringify({
        participant_main_id: participantDetails.main_id,
        departmentMain_id: department,
        created_by_main_id: loginBymain_id,
        documentPath: `${newFolderName.toUpperCase().trim() + "/"}`,
      });

      const { data } = await listingAPI.DocumnetHandler({
        endPoint: "/Insert/folder",
        data: myJsonServices,
      });

      if (data == "duplicate") {
        setAlertModal({
          modal: true,
          alert: "This file is already exist, Please rename.",
        });
      } else {
        let myFolder = data.filter(
          (i) => i.created_by_main_id === loginBymain_id
        );

        let othersFolder = data.filter(
          (i) => i.created_by_main_id != loginBymain_id
        );

        setMyFolders(myFolder);
        setOthersFolders(othersFolder);
      }
      setNewFolderName("");
      setCreateFolderModal(false);
    } else if (updateFolderName.update == true) {
      const myJsonServices = JSON.stringify({
        participant_main_id: participantDetails.main_id,
        departmentMain_id: department,
        created_by_main_id: loginBymain_id,
        documentPath: `${newFolderName.toUpperCase().trim() + "/"}`,
        main_id: updateFolderName.item.main_id,
      });

      const { data } = await listingAPI.DocumnetHandler({
        endPoint: "/Update/folder/name",
        data: myJsonServices,
      });

      if (data == "duplicate") {
        setAlertModal({
          modal: true,
          alert: "This file is already exist, Please rename.",
        });
      } else {
        let myFolder = data.filter(
          (i) => i.created_by_main_id === loginBymain_id
        );

        let othersFolder = data.filter(
          (i) => i.created_by_main_id != loginBymain_id
        );

        setMyFolders(myFolder);
        setOthersFolders(othersFolder);
      }
      setNewFolderName("");
      setCreateFolderModal(false);
    }
  };

  const DeleteFolderConformation = (item) => {
    setDeleteFolderModal({ modal: true, item: item });
  };

  const OnPressDeleteFolde = async (item) => {
    const loginBymain_id = await Cache.get("loginedBy_main_id");
    const department = await Cache.get("selected_department");

    const myJsonServices = JSON.stringify({
      participant_main_id: participantDetails.main_id,
      departmentMain_id: department,
      main_id: item.main_id,
    });

    const { data } = await listingAPI.DocumnetHandler({
      endPoint: "/delete/selected/folder",
      data: myJsonServices,
    });

    let myFolder = data.filter((i) => i.created_by_main_id === loginBymain_id);

    let othersFolder = data.filter(
      (i) => i.created_by_main_id != loginBymain_id
    );

    setMyFolders(myFolder);
    setOthersFolders(othersFolder);

    setDeleteFolderModal({ modal: false, item: "" });
  };

  const documentUpload_cloud_Handler = async () => {
    setProgressBar(true);
    const myJsonServices = fileUris;

    for (let i = 0; i < myJsonServices.length; i++) {
      let department = await Cache.get("selected_department");
      let loginedBy_main_id = await Cache.get("loginedBy_main_id");

      const { data } = await listingAPI.FileUploadInFolderPost({
        endPoint: "/file/Upload/InFolder",
        data: myJsonServices[i],
        Folder: `${participantDetails.main_id + "/"}${department + "/"}${
          loginedBy_main_id + "/"
        }${selectedFolder.documentPath}`,
      });

      if (data == null) {
        setProgressBar(false);
        // setAlertDetails({
        //   modal: true,
        //   alert:
        //     "Something went wrong, File may be not supported or File size is more than 20MB.",
        // });
        return;
      } else {
        documentDataHandler_inTable(data.name);
        setUploadedFile(data);
      }
    }
    setProgressBar(false);
    //ClearUseStateHandler();
  };

  const documentDataHandler_inTable = async (documentName) => {
    if (access == "limited") {
      const loginBymain_id = await Cache.get("loginedBy_main_id");

      const myJsonServices = JSON.stringify({
        participant_main_id: participantDetails.main_id,
        folderMain_id: selectedFolder.main_id,
        services_main_id: null,
        uploadPersion_main_id: loginBymain_id,
        uploadBy: "therapist",
        uploadDate: moment(Date.now()).format("YYYY/MM/DD"),
        documentName: documentName,
        status: "active",
      });

      const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
        endPoint: "/Insert/each/participant/documents",
        data: myJsonServices,
      });
    } else if (access == "full") {
      const loginBymain_id = await Cache.get("loginedBy_main_id");

      const myJsonServices = JSON.stringify({
        participant_main_id: participantDetails.main_id,
        folderMain_id: selectedFolder.main_id,
        services_main_id: null,
        uploadPersion_main_id: loginBymain_id,
        uploadBy: "therapyAdmin",
        uploadDate: moment(Date.now()).format("YYYY/MM/DD"),
        documentName: documentName,
        status: "inactive",
      });

      const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
        endPoint: "/Insert/each/participant/documents",
        data: myJsonServices,
      });
    }
  };

  const UploadDocumentWithFolderHandler = async (item) => {
    setSelectedFolder(item);
    setDocumentModal(true);
    GetListOfDocuments(item);
  };

  const GetListOfDocuments = async (folder) => {
    if (access == "limited") {
      const myJsonServices = JSON.stringify({
        participant_main_id: participantDetails.main_id,
        folderMain_id: folder.main_id,
      });
      const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
        endPoint:
          "/Select/All/documents/from/selected/folder/of/participant/for/Therapist",
        data: myJsonServices,
      });
      setAllDocuments(data);
    } else if (access == "full") {
      const myJsonServices = JSON.stringify({
        participant_main_id: participantDetails.main_id,
        folderMain_id: folder.main_id,
      });
      const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
        endPoint:
          "/Select/All/documents/from/selected/folder/of/participant/for/Admin",
        data: myJsonServices,
      });
      setAllDocuments(data);
    }
  };

  const UpdateDocumentStatusHandler = async (documentDetails) => {
    const myJsonServices = JSON.stringify({
      participant_main_id: documentDetails.participant_main_id,
      status: documentDetails.status == "active" ? "inactive" : "active",
      main_id: documentDetails.main_id,
      folderMain_id: documentDetails.folderMain_id,
    });
    const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
      endPoint: "/update/participant/document/status/From/Folder",
      data: myJsonServices,
    });

    setAllDocuments(data);
  };

  const DocumentDeleteFromTable = async (item) => {
    const myJsonServices = JSON.stringify({
      main_id: item.main_id,
      participant_main_id: item.participant_main_id,
      uploadBy: "therapyAdmin",
      folderMain_id: item.folderMain_id,
    });
    const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
      endPoint: "/delete/document/by/Admin/from/folder",
      data: myJsonServices,
    });

    setAllDocuments(data);
  };

  const OnPressEditFolderNameHandler = (item) => {
    let documentName = item.documentPath.slice(0, -1);
    let fileName = documentName.substring(documentName.indexOf("/") + 1);

    setNewFolderName(fileName);
    setUpdateFolderName({ update: true, item: item });
    setCreateFolderModal(true);
  };

  return (
    <View style={styles.container}>
      <AppAlertYesNo
        mainViewStyles={{ height: "30%" }}
        isModalVisible={deleteFolderModal.modal}
        Alert="Are You Sure, Do you want to delete this folder ?"
        leftBtnLabel="Cancel"
        noBtnPress={() => setDeleteFolderModal({ modal: false, item: "" })}
        yesBtnPress={() => OnPressDeleteFolde(deleteFolderModal.item)}
      />
      <AppAlertYesNo
        mainViewStyles={{ height: "30%" }}
        isModalVisible={createFolderModal}
        Alert={
          updateFolderName.update ? "Update Folder Name" : "Create New Folder"
        }
        rightBtnLabel={updateFolderName.update ? "Update" : "Create"}
        leftBtnLabel="Cancel"
        noBtnPress={() => {
          setUpdateFolderName({ update: false, item: "" });
          setCreateFolderModal(false);
          setNewFolderName("");
        }}
        yesBtnPress={() => OnClickCreateFolderHandler()}
      >
        <View
          style={{
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AppInputText
            width="60%"
            iconName="file"
            placeholder="Folder Name : "
            value={newFolderName ?? ""}
            onChangeText={(txt) => {
              setNewFolderName(txt);
            }}
          />
        </View>
      </AppAlertYesNo>
      <AppAlertYesNo
        mainViewStyles={{ width: "90%", height: "90%" }}
        isModalVisible={documentModal}
        noBtnPress={() => {
          setUploadDocumentHandler(false);
          setDocumentModal(false);
        }}
        yesBtnEnable={false}
        leftBtnLabel="Back"
        Alert=""
      >
        <View style={{ width: "100%", height: "100%" }}>
          <Text style={{ fontWeight: "bold", fontSize: "100%" }}>
            Selected Folder : {selectedFolder.documentPath}
          </Text>
          <View style={styles.BtnViewStyles}>
            <AppButton
              style={{ borderWidth: 1 }}
              color={uploadDocumentHandler ? "" : "InputText"}
              label="Documents List"
              onPress={() => {
                GetListOfDocuments(selectedFolder);
                setUploadDocumentHandler(false);
              }}
            />
            {documentUploadAccess && (
              <AppButton
                style={{ borderWidth: 1 }}
                color={uploadDocumentHandler ? "InputText" : ""}
                label="Upload Documents"
                onPress={() => setUploadDocumentHandler(true)}
              />
            )}
          </View>

          <View style={styles.documentVierStyles}>
            {uploadDocumentHandler ? (
              <AppMultiFilesPicker
                height="80%"
                btnUploadEnable
                uploadedFiles={uploadedFile}
                onPressUploadHandler={() => documentUpload_cloud_Handler()}
                getAllFiles={(files) => setFileUris(files)}
              />
            ) : (
              <AppGetDocumentCard
                popUpStyles={{ height: "30%" }}
                height="80%"
                items={allDocuments}
                switchEnable={switchEnable}
                deleteEnable={access == "full" ? true : documentUploadAccess}
                switchBtnChange={(sw) => UpdateDocumentStatusHandler(sw)}
                OnClickDeleteDocumentHandler={(details) => {
                  DocumentDeleteFromTable(details);
                }}
              />
            )}
          </View>
        </View>
      </AppAlertYesNo>
      <AppAlert
        alert={alertModal}
        okBtnPress={() => setAlertModal({ modal: false, alert: "" })}
      />
      <AppProgressbar modalVisible={progressBar} />
      <Text style={{ fontSize: "100%", fontWeight: "bold" }}>
        {participantDetails.name}
      </Text>

      <View style={styles.mainViewStyles}>
        <View style={styles.topBtnViewStyles}>
          <AppButton
            label="My Files"
            color={btnSelecter ? "InputText" : ""}
            border={1}
            onPress={() => setBtnSelecter(true)}
          />
          <AppButton
            label="Others"
            color={!btnSelecter ? "InputText" : ""}
            border={1}
            onPress={() => setBtnSelecter(false)}
          />
        </View>
        {btnSelecter ? (
          <View style={{ paddingHorizontal: 10 }}>
            <AppButton
              width="20%"
              label="Create Folder"
              color="blue"
              onPress={() => setCreateFolderModal(true)}
            />

            <View style={{ height: "90%", width: "100%" }}>
              <FlatList
                showsHorizontalScrollIndicator={false}
                data={myFolders}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item }) => (
                  <ListOfFolders
                    items={item}
                    editFolderAndDelete={true}
                    shareOption={access == "full" ? true : false}
                    onPressShare={onPressShare}
                    onPressFolder={() => {
                      setDocumentUploadAccess(true);
                      UploadDocumentWithFolderHandler(item);
                    }}
                    OnPressDeleteFolde={(item) =>
                      DeleteFolderConformation(item)
                    }
                    OnPressEditFolderName={(item) =>
                      OnPressEditFolderNameHandler(item)
                    }
                  />
                )}
              />
            </View>
          </View>
        ) : (
          <View style={{ height: "90%", width: "100%" }}>
            <FlatList
              showsHorizontalScrollIndicator={false}
              data={listOfCreatedByFolder}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item }) => (
                <ListOfFolderCreatedByName
                  editFolderAndDelete={access == "full" ? true : false}
                  AllFolders={othersFolders}
                  items={item}
                  access={access}
                  onPressFolder={(item) => {
                    setDocumentUploadAccess(false);
                    UploadDocumentWithFolderHandler(item);
                  }}
                />
              )}
            />
            {/* <View style={{ height: "90%", width: "100%" }}>
              <FlatList
                showsHorizontalScrollIndicator={false}
                data={othersFolders}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item }) => (
                  <ListOfFolders
                    items={item}
                    onPressFolder={() => {
                      setDocumentUploadAccess(false);
                      UploadDocumentWithFolderHandler(item);
                    }}
                    shareOption={access == "full" ? true : false}
                    onPressShare={onPressShare}
                  />
                )}
              />
            </View> */}
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    paddingVertical: 5,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  mainViewStyles: {
    height: "90%",
    width: "100%",
  },
  topBtnViewStyles: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 5,
  },
  folderListMainViewStyles: {
    margin: 1,
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    backgroundColor: Colors.InputText,
    width: "75%",
    alignSelf: "center",
  },
  folderNameMainViewStyles: {
    flexDirection: "row",
    width: "40%",
    alignSelf: "flex-start",
  },
  folderNameStyles: {
    width: "80%",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  shareIconViewStyles: {
    width: "1%",
    alignItems: "center",
  },
  txtNameStyles: {
    textAlign: "center",
    fontSize: "100%",
  },

  txtStyles: {
    textAlign: "center",
    fontSize: "100%",
  },

  labelViewStyles: {
    height: "3%",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  txtLabelStyles: {
    fontWeight: "bold",
    fontSize: "100%",
    textAlign: "center",
  },
  // CreateFolderViewStyles: {
  //   width: "100%",
  //   flexDirection: "row",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  BtnViewStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  documentVierStyles: {
    height: "100%",
    width: "100%",
  },
  CreatedByStyles: {
    backgroundColor: "#CEF9F9",
    marginVertical: 5,
    padding: 10,
    width: "90%",
    alignSelf: "center",
  },
  IconViewStyles: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "20%",
  },
});

export default AppFolder;
