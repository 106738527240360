import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
} from "react-native";

import moment from "moment";

import listingAPI from "../../api/listings";
import AppSessionDetailsCard from "./AppSessionDetailsCard";

import Colors from "../../config/Colors";
import AppButton from "../AppButton";

const AppCard = ({ item, date }) => {
  const [open, setOpen] = useState(false);
  const [LayoutHeight, setLayoutHeight] = useState(0);
  const [touchClame, setTouchClame] = useState(true);
  const [allUnClames, setAllUnClames] = useState([]);

  useEffect(() => {
    if (open) {
      setLayoutHeight(null);
    } else {
      setLayoutHeight(0);
    }
  }, [open]);

  const onClickHandler = async () => {
    setOpen(!open);
    let p_startDate = moment(date[0].from, "DD/MM/YYYY").format("YYYY/MM/DD");
    let p_endDate = moment(date[0].to, "DD/MM/YYYY").format("YYYY/MM/DD");

    if (!open) {
      const myJsonServices = JSON.stringify({
        sessionDateFromDate: p_startDate,
        sessionDateToDate: p_endDate,
        participant_main_id: item.participant_main_id,
        therapist_main_id: item.therapist_main_id,
      });
      const { data } = await listingAPI.EveryDaySessionHandler({
        endPoint:
          "/select/all/sessions/between/selected/date/of/selected/Participants",
        data: myJsonServices,
      });

      setAllUnClames(data);
    }
  };

  let Hours = ~~(item.fullSessionMins / 60);
  let mins = ~~(item.fullSessionMins % 60);

  let TouchView;

  if (touchClame) {
    TouchView = TouchableOpacity;
  } else {
    TouchView = View;
  }

  return (
    <View style={{ height: "50%" }}>
      <TouchView
        style={[
          styles.cardViewStyles,
          { backgroundColor: touchClame ? "#CDC9CA" : Colors.InputText },
        ]}
        onPress={() => {
          onClickHandler();
        }}
      >
        <Text style={[styles.labelStyles]}>{item.ndisNo}</Text>
        <Text style={[styles.labelStyles]}>{item.partisipamntName}</Text>
        <Text style={[styles.labelStyles]}>
          {Hours}:{mins > -10 && mins < 10 ? `0${mins}` : mins}
        </Text>
        <Text style={[styles.labelStyles]}>{item.totalKmTravelled ?? 0}</Text>
      </TouchView>

      <View style={{ height: LayoutHeight, overflow: "hidden" }}>
        <AppSessionDetailsCard items={allUnClames} />
      </View>
    </View>
  );
};

function AppClameParticipantCard({ items = [], date }) {
  return (
    <View style={styles.container}>
      <View style={[styles.cardViewStyles, { backgroundColor: Colors.blue }]}>
        <Text style={styles.headerStyles}>NDIS No.</Text>
        <Text style={styles.headerStyles}>Participant</Text>
        <Text style={styles.headerStyles}>Total Hours</Text>
        <Text style={styles.headerStyles}>Total Distance</Text>
      </View>
      <FlatList
        data={items}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => <AppCard item={item} date={date} />}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
  },
  cardViewStyles: {
    marginVertical: 5,
    padding: 5,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  headerStyles: {
    fontWeight: "bold",
    fontSize: "100%",
    width: "25%",
    marginHorizontal: 2,
  },
  labelStyles: {
    fontSize: "100%",
    width: "25%",
    marginHorizontal: 2,
  }
});

export default AppClameParticipantCard;
