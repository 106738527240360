import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  MaterialCommunityIcons,
  Entypo,
  FontAwesome5,
  FontAwesome,
  MaterialIcons,
  Feather,
  Fontisto,
  Ionicons,
} from "@expo/vector-icons";

function AppIcon({
  style,
  name = "email",
  size = 40,
  backgroundColor = "#fff",
  iconColor = "#000",
  onPress,
  touchAble = false,
  IconType = MaterialCommunityIcons,
}) {
  let Touch;
  if (touchAble) {
    Touch = TouchableOpacity;
  } else {
    Touch = View;
  }

  if (IconType === "Entypo") {
    IconType = Entypo;
  } else if (IconType === "FontAwesome5") {
    IconType = FontAwesome5;
  } else if (IconType === "MaterialIcons") {
    IconType = MaterialIcons;
  } else if (IconType === "FontAwesome") {
    IconType = FontAwesome;
  } else if (IconType === "Feather") {
    IconType = Feather;
  } else if (IconType === "Fontisto") {
    IconType = Fontisto;
  } else if (IconType === "Ionicons") {
    IconType = Ionicons;
  } else {
    IconType = MaterialCommunityIcons;
  }

  return (
    <Touch
      style={[
        style,
        styles.container,
        { backgroundColor: backgroundColor },
        { height: size },
        { width: size },
        { borderRadius: size / 2 },
      ]}
      onPress={onPress}
    >
      <IconType name={name} size={size / 2} color={iconColor} />
    </Touch>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
});

export default AppIcon;
