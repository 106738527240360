import React, { useState } from "react";
import { View, StyleSheet, Text, FlatList } from "react-native";
import moment from "moment";

import listingAPI from "../../api/listings";
import Colors from "../../config/Colors";
import AppButton from "../AppButton";
import Cache from "../../utility/cache";

const AppCard = ({
  item,
  btnEnable = true,
  btnLabel = "Claim",
  onPressOutsideHandler,
  onPressOutsideHandlerEnable = false,
  clamiedChecker = false,
}) => {
  const [click, setClick] = useState(true);

  let Hours = ~~(item.fullSessionMins / 60);
  let mins = ~~(item.fullSessionMins % 60);

  let sessionDate = moment(item.sessionDate).format("DD-MM-YYYY");

  const OnPressIndividuanHandler = async (item) => {
    const loginedBy_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      sessionClamed: click ? "true" : "false",
      main_id: item.main_id,
      clamied_by: loginedBy_main_id,
    });

    await listingAPI.EveryDaySessionHandler({
      endPoint: "/update/selected/unclame/sessions",
      data: myJsonServices,
    });
    setClick(!click);
  };

  return (
    <View style={[styles.cardViewStyles]}>
      <Text style={{ width: "5%", fontSize: "80%" }}>{item.main_id}</Text>
      <Text style={[styles.labelStyles]}>{sessionDate}</Text>
      <Text numberOfLines={1} style={[styles.labelStyles]}>
        {item.therapistName}
      </Text>
      <Text numberOfLines={1} style={[styles.labelStyles]}>
        {item.serviceName}
      </Text>
      {/* <Text numberOfLines={1} style={[styles.labelStyles]}>
        {item.mainSubject}
      </Text> */}
      <Text style={[styles.labelStyles]}>{item.totalExtraExpense ?? 0} kms</Text>
      <Text style={[styles.labelStyles]}>${item.extraExpense_price ?? 0}/km</Text>
      <Text style={[styles.labelStyles]}>{item.totalExtraExpense_Price ?? 0} $</Text>
      <Text style={[styles.labelStyles]}>{item.services_CurrentPrice} $</Text>
      <Text style={[styles.labelStyles]}>
        {item.totalSession_hours}:
        {item.totalSession_mins < 10
          ? `0${item.totalSession_mins}`
          : item.totalSession_mins} h
      </Text>
      <Text style={[styles.labelStyles]}>
        {item.chargeTravel_hours}:
        {item.chargeTravel_mins < 10
          ? `0${item.chargeTravel_mins}`
          : item.chargeTravel_mins} h
      </Text>
      <Text style={[styles.labelStyles]}>
        {Hours}:{mins < 10 ? `0${mins}` : mins} h
      </Text>
      <Text style={[styles.labelStyles]}>${item.totalSessionPrice}</Text>
      {btnEnable && (
        <AppButton
          width="10%"
          color={click ? "yellow" : "blue"}
          label={click ? btnLabel : "Undo"}
          onPress={() => {
            if (onPressOutsideHandlerEnable) {
              onPressOutsideHandler(item);
            } else {
              OnPressIndividuanHandler(item);
            }
          }}
        />
      )}
      {clamiedChecker && (
        <Text style={{ color: item.sessionClamed == "true" ? "blue" : "red" }}>
          {item.sessionClamed == "true" ? "Claimed" : "Unclaimed"}
        </Text>
      )}
      {
        !btnEnable && !clamiedChecker && <Text style={[styles.labelStyles]}></Text>
      }
    </View>
  );
};

function AppClameDetailsCard({
  items,
  btnEnable,
  onPressOutsideHandlerEnable,
  onPressOutsideHandler,
  clamiedChecker,
}) {
  return (
    <View style={styles.container}>
      <View style={styles.cardViewStyles}>
        <Text style={{ width: "5%", fontWeight: "bold", fontSize: "80%" }}>
          S. Id
        </Text>
        <Text numberOfLines={1} style={styles.headerStyles}>
          Session Date
        </Text>
        <Text numberOfLines={1} style={styles.headerStyles}>
          Therapist
        </Text>
        <Text style={styles.headerStyles}>Service</Text>
        {/* <Text style={styles.headerStyles}>Sub</Text> */}
        <Text style={styles.headerStyles}>Total Kms</Text>
        <Text style={styles.headerStyles}>$/Km</Text>
        <Text style={styles.headerStyles}>Total Charges(kms)</Text>
        <Text style={styles.headerStyles}>$/h</Text>
        <Text style={styles.headerStyles}>Session Time</Text>
        <Text style={styles.headerStyles}>Travel</Text>
        <Text style={styles.headerStyles}>Total Hours</Text>
        <Text style={styles.headerStyles}>Total Amount</Text>
        <Text style={styles.headerStyles} />
      </View>
      <FlatList
        data={items}
        keyExtractor={(item, index) => item.main_id.toString()}
        renderItem={({ item }) => (
          <AppCard
            clamiedChecker={clamiedChecker}
            btnEnable={btnEnable}
            item={item}
            onPressOutsideHandlerEnable={onPressOutsideHandlerEnable}
            onPressOutsideHandler={onPressOutsideHandler}
          />
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "90%",
    width: "100%",
  },
  cardViewStyles: {
    backgroundColor: Colors.InputText,
    marginVertical: 5,
    padding: 5,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  headerStyles: {
    fontWeight: "bold",
    fontSize: "80%",
    width: "10%",
  },
  labelStyles: {
    fontSize: "80%",
    width: "10%",
  },
});

export default AppClameDetailsCard;
