import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
} from "react-native";
import moment from "moment";

import listingAPI from "../api/listings";
import Colors from "../config/Colors";
import AppGetDocumentCard from "../components/AppGetDocumentCard";
import AppAlertYesNo from "../components/AppAlertYesNo";
import AppSessionUpdateCard from "../components/cards/AppSessionUpdateCard";
import AppIcon from "../components/AppIcon";

const SessionCard = ({ item, refreshData, editAndDelete = false }) => {
  const [onClick, setOnClick] = useState(0);
  const [updateModal, setUpdateModal] = useState({ modal: false, item: "" });
  const [deleteModal, setDeleteModal] = useState({ modal: false, item: "" });
  const [allDocuments, setAllDocuments] = useState([]);

  const DocumentDeleteFromTable = async (item) => {
    const myJsonServices = JSON.stringify({
      main_id: item.main_id,
      sessionMain_id: item.sessionMain_id,
    });
    const { data } = await listingAPI.ParticipentsSessionDocument_dataIntoTable(
      {
        endPoint: "/delete/documents/of/selected/session/of/participant",
        data: myJsonServices,
      }
    );

    setAllDocuments(data);
  };

  const OnClickHandler = async (item) => {
    setOnClick(onClick == null ? 0 : null);

    const myJsonServices = JSON.stringify({
      sessionMain_id: item.main_id,
    });
    const { data } = await listingAPI.ParticipentsSessionDocument_dataIntoTable(
      {
        endPoint:
          "/Select/All/documents/of/selected/participant/as/per/selected/session",
        data: myJsonServices,
      }
    );

    setAllDocuments(data);
  };

  const SessionEditHandler = (item) => {
    setUpdateModal({ modal: true, item: item });
  };

  const OnClickDeleteHandler = async () => {
    const myJsonServices = JSON.stringify({
      participant_main_id: deleteModal.item.participant_main_id,
      services_main_id: deleteModal.item.services_main_id,

      previousSession_hours: deleteModal.item.totalSession_hours,
      previousSession_mins: deleteModal.item.totalSession_mins,
      previousChargeTravel_hours: deleteModal.item.chargeTravel_hours,
      previousChargeTravel_mins: deleteModal.item.chargeTravel_mins,

      sessionDate: moment(deleteModal.item.sessionDate, "DD/MM/YYYY").format(
        "YYYY/MM/DD"
      ),
      mainSubject:
        deleteModal.item.mainSubject != undefined
          ? deleteModal.item.mainSubject.trim()
          : "",
      services_CurrentPrice: deleteModal.item.services_CurrentPrice,
      totalSession_hours: 0,
      totalSession_mins: 0,
      totalTravel_hours: 0,
      totalTravel_mins: 0,
      chargeTravel_hours: 0,
      chargeTravel_mins: 0,
      briefNote: "",
      status: "delete",
      session_main_id: deleteModal.item.main_id,
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint: "/update/selected/unclame/session/of/selected/participants",
      data: myJsonServices,
    });

    setDeleteModal({ modal: false, item: item });
    // refreshData({ refreshSessions: 1 });
  };

  return (
    <>
      <AppAlertYesNo
        Alert={`Update Session No ${item.main_id}`}
        isModalVisible={updateModal.modal}
        yesBtnEnable={false}
        leftBtnLabel="Cancel"
        noBtnPress={() => setUpdateModal({ modal: false, item: "" })}
        mainViewStyles={{ width: "100%", height: "100%" }}
      >
        <AppSessionUpdateCard
          items={updateModal.item}
          refreshData={refreshData}
        />
      </AppAlertYesNo>

      <AppAlertYesNo
        Alert={`Are you sure. Do you want to delete Session No ${item.main_id} ?`}
        isModalVisible={deleteModal.modal}
        rightBtnLabel="Delete"
        leftBtnLabel="Cancel"
        yesBtnPress={() => OnClickDeleteHandler()}
        noBtnPress={() => setDeleteModal({ modal: false, item: "" })}
        mainViewStyles={{ width: "100%", height: "50%" }}
      />

      <TouchableOpacity
        style={styles.CardViewStyles}
        onPress={() => OnClickHandler(item)}
      >
        <View style={styles.labelViewStyle}>
          <Text style={styles.txtItemStyles}>{item.main_id}</Text>
          <Text style={styles.txtItemStyles}>{item.therapistName}</Text>
          <Text style={styles.txtItemStyles}>
            {moment(item.sessionDate, "YYYY/MM/DD").format("DD/MM/YYYY")}
          </Text>
          <Text style={styles.txtItemStyles}>{item.serviceName}</Text>
          <Text style={styles.txtItemStyles}>{item.totalExtraExpense}/kms</Text>
          <Text style={styles.txtItemStyles}>${item.extraExpense_price}</Text>
          <Text style={styles.txtItemStyles}>
            ${item.totalExtraExpense_Price}
          </Text>
          <Text style={styles.txtItemStyles}>
            {item.totalSession_hours}:{item.totalSession_mins}
          </Text>
          <Text style={styles.txtItemStyles}>
            {item.totalTravel_hours}:{item.totalTravel_mins}
          </Text>
          <Text style={styles.txtItemStyles}>
            {item.chargeTravel_hours}:{item.chargeTravel_mins}
          </Text>
          {editAndDelete && (
            <>
              {item.sessionClamed == "false" ? (
                <View style={styles.iconStyles}>
                  <AppIcon
                    touchAble
                    backgroundColor=""
                    name="pen"
                    onPress={() => SessionEditHandler(item)}
                  />
                  <AppIcon
                    touchAble
                    backgroundColor=""
                    name="delete"
                    iconColor="red"
                    onPress={() => setDeleteModal({ modal: true, item: item })}
                  />
                </View>
              ) : (
                <View style={styles.iconStyles}></View>
              )}
            </>
          )}
        </View>
      </TouchableOpacity>
      {onClick == null ? (
        <View
          style={[
            {
              height: onClick,
              width: "100%",
            },
          ]}
        >
          <View style={{ width: "100%" }}>
            <Text style={{ borderWidth: 1, padding: 5 }}>{item.briefNote}</Text>
          </View>
          <View style={{ width: "100%" }}>
            <AppGetDocumentCard
              popUpStyles={{ height: "20%" }}
              items={allDocuments}
              borderWidth={1}
              width="100%"
              height="100%"
              deleteEnable
              OnClickDeleteDocumentHandler={(data) =>
                DocumentDeleteFromTable(data)
              }
            />
          </View>
        </View>
      ) : (
        <Text></Text>
      )}
    </>
  );
};

function AppSeccionCard({
  items = [],
  refreshData,
  editAndDelete,
  OnClickSessionDateHandler,
}) {
  return (
    <View style={styles.container}>
      <View style={styles.cardLabelStyles}>
        <Text style={[styles.labelStyles, { width: "20%" }]}>Session id</Text>
        <Text style={[styles.labelStyles, { width: "20%" }]}>Therapist</Text>
        <TouchableOpacity
          style={{ width: "10%" }}
          onPress={() => OnClickSessionDateHandler()}
        >
          <Text style={[styles.labelStyles, { width: "100%" }]}>
            Session Date
          </Text>
        </TouchableOpacity>
        <Text style={[styles.labelStyles, { width: "20%" }]}>Service</Text>
        <Text style={[styles.labelStyles, { width: "20%" }]}>Total Kms</Text>
        <Text style={[styles.labelStyles, { width: "20%" }]}>$/Km</Text>
        <Text style={[styles.labelStyles, { width: "20%" }]}>
          Total Charges(kms)
        </Text>
        <Text style={[styles.labelStyles, { width: "20%" }]}>Session Time</Text>
        <Text style={[styles.labelStyles, { width: "20%" }]}>
          Traveling Time
        </Text>
        <Text style={[styles.labelStyles, { width: "20%" }]}>
          Charge Traveling
        </Text>
        <View style={styles.iconStyles}>
          <Text></Text>
          <Text></Text>
        </View>
      </View>
      <FlatList
        data={items}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => (
          <SessionCard
            item={item}
            // refreshData={refreshData}
            editAndDelete={editAndDelete}
          />
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
  },
  CardViewStyles: {
    width: "100%",
    borderRadius: 10,
    backgroundColor: Colors.txtDark,
    padding: 10,
    marginVertical: 10,
  },
  labelViewStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    alignItems: "center",
  },
  labelStyles: {
    fontWeight: "bold",
    fontSize: "70%",
  },
  txtItemStyles: {
    width: "20%",
    margin: 2,
  },
  cardLabelStyles: {
    height: 50,
    // width: "100%",
    borderRadius: 10,
    backgroundColor: Colors.txtDark,
    padding: 10,
    marginVertical: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    alignItems: "center",
  },
  iconStyles: {
    flexDirection: "row",
    width: "10%",
    justifyContent: "space-around",
  },
});

export default AppSeccionCard;
