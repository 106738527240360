import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  Image,
  TouchableWithoutFeedback,
  Modal,
  Text,
} from "react-native";
import * as Network from "expo-network";
import * as Location from "expo-location";

import Colors from "../../config/Colors";
import AppIcon from "../../components/AppIcon";
import AppButton from "../../components/AppButton";
import AppInputText from "../../components/AppInputText";
import AppText from "../../components/AppText";
import AppTouchableLabel from "../../components/AppTouchableLabel";
import AppAlert from "../../components/AppAlert";
import listingAPI from "../../api/listings";

const place = [
  { main_id: 1, name: "Office" },
  { main_id: 2, name: "Work From Home" },
];

const LocalCard = ({ items, onPressDelete, OnClickPositionStatusHandler }) => {
  return (
    <View style={styles.officeCardStyles}>
      <Text style={{ width: "20%", fontWeight: "bold" }}>{items.name}</Text>
      <Text style={{ width: "30%", fontWeight: "bold" }}>{items.email}</Text>
      <Text style={{ width: "20%", fontWeight: "bold" }}>
        {items.placeType}
      </Text>
      <Text style={{ width: "20%", fontWeight: "bold" }}>{items.phone}</Text>
      <View style={styles.btnStylesView}>
        {items.status != "active" ? (
          <AppButton
            label={items.status == "active" ? "cative" : "inactive"}
            color={items.status == "active" ? "blue" : "red"}
            onPress={() => OnClickPositionStatusHandler(items)}
          />
        ) : (
          <AppText label="Active" />
        )}

        <AppIcon
          touchAble
          backgroundColor=""
          name="delete"
          onPress={() => onPressDelete(items)}
        />
      </View>
    </View>
  );
};

function AuthCheckScreen({}) {
  const [reqSubmited, setReqSubmited] = useState(false);
  const [name, setName] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [alert, setAlert] = useState({
    modal: false,
    alert: "",
  });
  const [location, setLocation] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [selectedPlaceType, setSelectedPlaceType] = useState();

  const [loginAccess, setLoginAccess] = useState(false);
  const [allOffices, setAllOffices] = useState();

  const [modal, setModal] = useState(false);
  const [clickCounter, setClickCounter] = useState(0);

  useEffect(() => {
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        setErrorMsg("Permission to access location was denied");
        return;
      }

      let location = await Location.getCurrentPositionAsync({});
      setLocation(location);
    })();
  }, []);

  useEffect(() => {
    GetAllCurOffices();
  }, []);

  const GetAllCurOffices = async () => {
    const IP = await Network.getIpAddressAsync();
    const myJsonServices = JSON.stringify({
      ipAddress: IP.trim(),
      status: "inactive",
    });

    const { data } = await listingAPI.LocationChecker_POST({
      endPoint: "/Get/Selected/inactive/offices",
      data: myJsonServices,
    });

    if (data.length > 0) {
      setReqSubmited(true);
    } else {
      setAlert({
        modal: true,
        alert: "Please make sure to enable location to use this software.",
      });
    }
  };

  const GetAllNotDeletedOffices = async () => {
    const myJsonServices = JSON.stringify({});

    const { data } = await listingAPI.LocationChecker_POST({
      endPoint: "/Select/All/offices/not/deleted",
      data: myJsonServices,
    });

    setAllOffices(data);
  };

  const OnPressSubmitHandler = async () => {
    const IP = await Network.getIpAddressAsync();
    if (!name) {
      setAlert({
        modal: true,
        alert: "Please Enter your name.",
      });
      return;
    } else if (!email) {
      setAlert({
        modal: true,
        alert: "Please Enter your email.",
      });
      return;
    } else if (!phone) {
      setAlert({
        modal: true,
        alert: "Please Enter your phone.",
      });
      return;
    } else if (!selectedPlaceType) {
      setAlert({
        modal: true,
        alert: "Please select Place.",
      });
      return;
    }

    const myJsonServices = JSON.stringify({
      name: name,
      email: email.toUpperCase().trim(),
      phone: phone,
      ipAddress: IP.trim(),
      placeType: selectedPlaceType.name,
      longitude: location != null ? location.coords.longitude : "",
      latitude: location != null ? location.coords.latitude : "",
    });

    const { data } = await listingAPI.LocationChecker_POST({
      endPoint: "/Insert/locationChecker",
      data: myJsonServices,
    });
    setReqSubmited(true);
  };

  const OnPreshLogoAccessHandler = () => {
    setClickCounter(clickCounter + 1);
    if (clickCounter == 10) {
      GetAllNotDeletedOffices();
      setModal(true);
    }
  };

  const OnClickPositionStatusHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      status: item.status == "active" ? "inactive" : "active",
      main_id: item.main_id,
    });

    const { data } = await listingAPI.LocationChecker_POST({
      endPoint: "/update/selected/office/status",
      data: myJsonServices,
    });

    setAllOffices(data);
  };

  const OnClickDeleteHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      main_id: item.main_id,
    });

    const { data } = await listingAPI.LocationChecker_POST({
      endPoint: "/delete/selected/office",
      data: myJsonServices,
    });

    setAllOffices(data);
  };

  const setOnPressLoginHandler = () => {
    if (name == "Admin" && password == "Access@123") {
      setLoginAccess(true);
    } else {
      setAlert({ modal: true, alert: "Username or Password is Wrong." });
    }
  };

  return (
    <View style={styles.container}>
      <Modal animationType="slide" transparent={true} visible={modal}>
        <View
          style={{
            alignSelf: "center",
            height: "90%",
            width: "90%",
            backgroundColor: "#fff",
          }}
        >
          {loginAccess ? (
            <FlatList
              data={allOffices}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item }) => (
                <LocalCard
                  items={item}
                  OnClickPositionStatusHandler={(item) =>
                    OnClickPositionStatusHandler(item)
                  }
                  onPressDelete={(item) => OnClickDeleteHandler(item)}
                />
              )}
            />
          ) : (
            <>
              <View
                style={[
                  styles.topViewStyles,
                  { width: "100%", alignItems: "center" },
                ]}
              >
                <AppInputText
                  width="40%"
                  placeholder="Name  : "
                  label="Name : *"
                  iconName="account"
                  value={name ?? ""}
                  onChangeText={(txt) => setName(txt)}
                />

                <AppInputText
                  width="40%"
                  placeholder="Password : "
                  iconName="lock"
                  touchAble
                  label="Password"
                  value={password ?? ""}
                  onChangeText={(txt) => setPassword(txt)}
                />
              </View>
              <View
                style={[
                  styles.topViewStyles,
                  { width: "100%", alignItems: "center" },
                ]}
              >
                <AppButton
                  label="Cancel"
                  onPress={() => {
                    setClickCounter(0);
                    setModal(false);
                  }}
                />
                <AppButton
                  label="Login"
                  color="blue"
                  onPress={() => setOnPressLoginHandler()}
                />
              </View>
            </>
          )}
        </View>
      </Modal>

      <AppAlert
        alert={alert}
        okBtnPress={() => setAlert({ modal: false, alert: "" })}
      />
      {reqSubmited ? (
        <View
          style={{
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TouchableWithoutFeedback onPress={() => OnPreshLogoAccessHandler()}>
            <Image
              source={require("../../config/img/accessfoundationLogo.jpeg")}
              style={{
                width: "35%",
                height: "20%",
                borderRadius: 20,
              }}
            />
          </TouchableWithoutFeedback>
          <AppText label="Your request has been sent." />
        </View>
      ) : (
        <>
          <AppText label="It's looks like you don't have an access." />
          <AppText label="Please contact to IT department." />
          <View style={styles.topViewStyles}>
            <AppInputText
              width="40%"
              placeholder="Name  : "
              label="Name : *"
              iconName="account"
              value={name ?? ""}
              onChangeText={(txt) => setName(txt)}
            />

            <AppInputText
              width="40%"
              placeholder="Email  : "
              label="Email : *"
              iconName="email"
              value={email ?? ""}
              onChangeText={(txt) => setEmail(txt)}
            />
          </View>
          <View style={styles.topViewStyles}>
            <AppInputText
              width="40%"
              placeholder="Phone  : "
              label="Phone : *"
              iconName="phone"
              keyboardType="numeric"
              pattern="[0-9]*"
              maxLength={10}
              value={phone ?? ""}
              onChangeText={(txt) => setPhone(txt.replace(/[^0-9]/g, ""))}
            />

            <AppTouchableLabel
              items={place}
              width="40%"
              placeholder="Place Type : "
              label="Place Type : "
              iconName="account"
              menuLabel={
                !selectedPlaceType ? "Select Place" : selectedPlaceType
              }
              selectedMenu={(item) => setSelectedPlaceType(item)}
            />
          </View>
          <AppButton
            label="Submit"
            color="blue"
            onPress={() => OnPressSubmitHandler()}
          />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    padding: 50,
  },
  topViewStyles: {
    height: "5%",
    width: "70%",
    flexDirection: "row",
    justifyContent: "space-around",
    marginVertical: 10,
  },
  officeCardStyles: {
    backgroundColor: Colors.InputText,
    padding: 5,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginVertical: 5,
  },
  btnStylesView: {
    width: "20%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
});

export default AuthCheckScreen;
