import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  Switch,
  TouchableOpacity,
} from "react-native";

import listingAPI from "../../api/listings";
import AppIcon from "../../components/AppIcon";
import Colors from "../../config/Colors";

const LocalSubCard = ({
  items,
  onPressDeleteAllocatedDepartment,
  departmentStatusHandler,
}) => {
  const [swBtn, setSWBtn] = useState(items.status == "active" ? true : false);

  return (
    <View
      style={[
        styles.officeCardStyles,
        { width: "90%", alignSelf: "center", backgroundColor: "#CEF4F9" },
      ]}
    >
      <Text style={{ width: "60%" }}>{items.department_name}</Text>
      <View style={styles.btnStylesView}>
        <Switch
          value={swBtn}
          onValueChange={() => {
            setSWBtn(!swBtn);
            departmentStatusHandler(items);
          }}
        />
        <AppIcon
          touchAble
          backgroundColor=""
          name="delete"
          onPress={() => onPressDeleteAllocatedDepartment(items)}
        />
      </View>
    </View>
  );
};

const LocalCard = ({
  items,
  subItem,
  touchAbleCard = true,
  onPressEdit,
  onPressDelete,
  OnClickPositionStatusHandler,
  departmentStatusHandler,
  onPressDeleteAllocatedDepartment,
  onPressAllocate,
  allocate = false,
}) => {
  const [open, setOpen] = useState(false);
  const [LayoutHeight, setLayoutHeight] = useState(0);

  let TouchComponent;

  if (touchAbleCard) {
    TouchComponent = TouchableOpacity;
  } else {
    TouchComponent = View;
  }

  useEffect(() => {
    if (open) {
      setLayoutHeight(null);
    } else {
      setLayoutHeight(0);
    }
  }, [open]);

  let departmentFilter = [];

  if (subItem != undefined) {
    departmentFilter = subItem.filter(
      (i) => i.OfficeList_main_id === items.main_id
    );
  }

  return (
    <View>
      <TouchComponent
        onPress={() => {
          setOpen(!open);
        }}
      >
        <View style={styles.officeCardStyles}>
          <Text style={{ width: "60%", fontWeight: "bold" }}>{items.name}</Text>
          <View style={styles.btnStylesView}>
            {allocate && (
              <AppIcon
                touchAble
                backgroundColor=""
                IconType="Entypo"
                name="colours"
                onPress={() => onPressAllocate(items)}
              />
            )}
            <Switch
              value={items.status == "active" ? true : false}
              onValueChange={() => OnClickPositionStatusHandler(items)}
            />

            <AppIcon
              touchAble
              backgroundColor=""
              name="pen"
              onPress={() => onPressEdit(items)}
            />
            <AppIcon
              touchAble
              backgroundColor=""
              name="delete"
              onPress={() => onPressDelete(items)}
            />
          </View>
        </View>
      </TouchComponent>
      <View style={{ height: LayoutHeight, overflow: "hidden" }}>
        <FlatList
          data={departmentFilter}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <LocalSubCard
              items={item}
              touchAbleCard={touchAbleCard}
              onPressAllocate={onPressAllocate}
              allocate={allocate}
              onPressEdit={onPressEdit}
              onPressDelete={onPressDelete}
              OnClickPositionStatusHandler={OnClickPositionStatusHandler}
              departmentStatusHandler={departmentStatusHandler}
              onPressDeleteAllocatedDepartment={
                onPressDeleteAllocatedDepartment
              }
            />
          )}
        />
      </View>
    </View>
  );
};

function AppOfficeCard({
  items,
  subItem,
  touchAbleCard,
  onPressEdit,
  onPressDelete,
  OnClickPositionStatusHandler,
  onPressDeleteAllocatedDepartment,
  departmentStatusHandler,
  onPressAllocate,
  allocate,
}) {
  return (
    <View style={styles.container}>
      <FlatList
        data={items}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => (
          <LocalCard
            items={item}
            subItem={subItem}
            touchAbleCard={touchAbleCard}
            onPressAllocate={onPressAllocate}
            allocate={allocate}
            onPressEdit={onPressEdit}
            onPressDelete={onPressDelete}
            onPressDeleteAllocatedDepartment={onPressDeleteAllocatedDepartment}
            OnClickPositionStatusHandler={OnClickPositionStatusHandler}
            departmentStatusHandler={departmentStatusHandler}
          />
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
  },
  officeCardStyles: {
    backgroundColor: Colors.InputText,
    padding: 5,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginVertical: 5,
  },
  btnStylesView: {
    width: "40%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
});

export default AppOfficeCard;
