import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  Text,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import moment from "moment";

import AddParticipantScreen from "../../../TherapyTeam/admin/Participant/AddParticipantScreen";
import PlanManagement from "./planManage/PlanManagementHomeScreen";

import listingAPI from "../../../../api/listings";

import Routes from "../../../../navigation/routes";
import Cache from "../../../../utility/cache";
import AppMultiFilesPicker from "../../../../components/AppMultiFilesPicker";
import AppGetDocumentCard from "../../../../components/AppGetDocumentCard";
import AppAlert from "../../../../components/AppAlert";
import AppCalander from "../../../../components/AppCalander";
import AppInputText from "../../../../components/AppInputText";
import AppPicker from "../../../../components/AppPicker";
import AppParticipantDetails from "../../../../components/cards/AppParticipantDetails";
import AppAlertYesNo from "../../../../components/AppAlertYesNo";
import AppProgressbar from "../../../../components/AppProgressbar";
import Colors from "../../../../config/Colors";
import AppButton from "../../../../components/AppButton";
import AppTouchableLabel from "../../../../components/AppTouchableLabel";
import AppServicesListCard from "../../../../components/cards/AppServicesListCard";
import UploadFile from "../../../../components/uploadDocument_In_Folder/UploadFile";

const TherapistCard = ({ items }) => {
  let leftHours = ~~(items.totalmins / 60);
  let leftMin = ~~(items.totalmins % 60);

  return (
    <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
      <Text style={{ width: "40%" }}>{items.therapist_name}</Text>
      <Text style={{ width: "40%" }}>
        {leftHours}:{leftMin}
      </Text>
    </View>
  );
};

const ServiceList = ({ item }) => {
  const [open, setOpen] = useState(false);
  const [LayoutHeight, setLayoutHeight] = useState(0);
  const [allTherapist, setAllTherapist] = useState([
    {
      therapist_name: "",
    },
  ]);

  useEffect(() => {
    if (open) {
      setLayoutHeight(null);
    } else {
      setLayoutHeight(0);
    }
  }, [open]);

  const totalMins = parseInt(item.total_hours) * 60 + parseInt(item.total_mins);
  const usedMins = parseInt(item.used_hours) * 60 + parseInt(item.used_mins);

  const leftmins = totalMins - usedMins;

  let leftHours = ~~(leftmins / 60);
  let leftMin = ~~(leftmins % 60);

  const OnPressGetTherapistHandler = async (item) => {
    setOpen(!open);

    const myJsonServices = JSON.stringify({
      participant_main_id: item.participant_main_id,
      allocate_services_main_id: item.services_main_id,
    });
    const { data } = await listingAPI.AllocateTherapistPost({
      endPoint:
        "/select/all/Allocate/Therapists/of/selected/participantand/selectedServices",
      data: myJsonServices,
    });

    if (data.length > 0) {
      setAllTherapist(data);
    } else {
      setAllTherapist([{ therapist_name: "no data found" }]);
    }
  };

  return (
    <View>
      <TouchableOpacity
        style={styles.ServicesViewList}
        onPress={() => OnPressGetTherapistHandler(item)}
      >
        <Text style={styles.txtLabelStyles}>{item.name}</Text>
        <Text style={styles.txtLabelStyles}>
          {item.total_hours}:{item.total_mins}
        </Text>
        <Text style={styles.txtLabelStyles}>
          {item.used_hours}:{item.used_mins}
        </Text>
        <Text style={styles.txtLabelStyles}>
          {leftHours}:{leftMin}
        </Text>
      </TouchableOpacity>
      <View style={{ height: LayoutHeight, overflow: "hidden" }}>
        <FlatList
          data={allTherapist}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => <TherapistCard items={item} />}
        />
      </View>
    </View>
  );
};

function ParticipantListScreen({ navigation }) {
  const [allParticipant, setAllParticipant] = useState([]);
  const [allInActiveParticipants, setallInActiveParticipants] = useState([]);
  const [allFridgedParticipants, setAllFridgedParticipants] = useState([]);
  const [allWaitingParticipants, setAllWaitingParticipants] = useState([]);

  const [participantDetails, setParticipantDetails] = useState();
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [itemForDelete, setItemForDelete] = useState();
  const [search, setSearch] = useState(false);
  const [searchParticipant, setSearchParticipant] = useState();
  const [uploadDocumentHandler, setUploadDocumentHandler] = useState(false);

  const [allDocuments, setAllDocuments] = useState([]);
  const [selectedParticipantDetails, setSelectedParticipantDetails] =
    useState();
  const [allServices, setAllServices] = useState([]);
  const [allServicesShow, setAllServicesShow] = useState(false);
  const [name, setName] = useState();
  const [contact, setContact] = useState();
  const [address, setAddress] = useState();
  const [guardianName, setGuardianName] = useState();
  const [guardianContact, setGuardianContact] = useState();
  const [referralDate, setReferralDate] = useState();
  const [birthDate, setBirthDate] = useState();
  const [selectedGender, setSelectedGender] = useState("");
  const [participantDiagnosis, setParticipantDiagnosis] = useState();
  const [task, setTask] = useState();
  const [planStartDate, setPlanStartDate] = useState();
  const [planEndDate, setPlanEndDate] = useState();
  const [ndisNo, setNdisNo] = useState();
  const [main_id, setMain_id] = useState();
  const [detailsShow, setDetailsShow] = useState([]);
  const [fileUris, setFileUris] = useState([]);
  const [progressBar, setProgressBar] = useState(false);
  const [documentModal, setDocumentModal] = useState(false);
  const [participantEmail, setParticipantEmail] = useState();
  const [guardianEmail, setGuardianEmail] = useState();
  const [guardianAddress, setGuardianAddress] = useState();
  const [supportCoordinatorName, setSupportCoordinatorName] = useState();
  const [supportCoordinatorNumber, setSupportCoordinatorNumber] = useState();
  const [supportCoordinatorEmail, setSupportCoordinatorEmail] = useState();
  const [supportCoordinatorAddress, setSupportCoordinatorAddress] = useState();
  const [allTotalWaitingParticipants, setAllTotalWaitingParticipants] =
    useState([]);

  const [choiceBtnSetter, setChoiceBtnSetter] = useState("ParticipantsList");
  const [waitingChoiceBtnSetter, setWaitingChoiceBtnSetter] = useState(false);
  const [currentPlanNumver, setCurrentPlanNumver] = useState();

  const [filterdData, setFilterdData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [allDepartment, setAllDepartment] = useState([]);
  const [serviceDepartmentId, setServiceDepartmentId] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);

  const [selectDepartmentName, setSelectDepartmentName] = useState({
    name: "Select Department",
    departmentId: 0,
  });
  const [alertDetails, setAlertDetails] = useState({
    modal: false,
    alert: "",
  });

  const genderList = [
    { main_id: 1, name: "Male" },
    { main_id: 2, name: "Female" },
    { main_id: 3, name: "Others" },
    { main_id: 4, name: "don't disclose" },
  ];

  useEffect(() => {
    GetAllTherapyDepartment();
  }, []);

  useEffect(() => {
    if (selectDepartmentName.departmentId !== 0) {
      GetParticipantAllDetailsAsPerDepartment(
        selectDepartmentName.departmentId
      );
    }
  }, [selectDepartmentName.departmentId]);

  useEffect(async () => {
    setProgressBar(true);
    // follow function one by one ...
    GetTotalNumberOfInactiveParticipans();
    GetTotalNumOfFridgedParticipantsDetails();
    GetTotalGetAllWaitingParticipantsForTherapist();
    NoFilterHandlerGetParticipantAllDetailsOrderByDate();
    //setProgressBar(false);
  }, []);

  const GetAllTherapyDepartment = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");
    const myJsonServices = JSON.stringify({
      therapist_main_id: therapist_main_id,
    });
    const { data } = await listingAPI.TherapyDepartment({
      endPoint: "/Select/All/therapyDepartment/of/selected/admin",
      data: myJsonServices,
    });

    setAllDepartment(data);

    let prosess = false;

    for (let i = 0; i < data.length; i++) {
      if (data[0].main_id == 1) {
        prosess = true;
      }
    }

    if (!prosess) {
      setProgressBar(false);
      GetParticipantAllDetailsAsPerDepartment(data[0].main_id);
    }
  };

  const SearchFilter = (text) => {
    if (text) {
      const newData = masterData.filter((item) => {
        const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });

      setFilterdData(newData);
      setSearchParticipant(text);
    } else {
      setFilterdData(masterData);
      setSearchParticipant(text);
    }
  };

  const GetParticipantAllDetailsOrderByDate = async () => {
    const serviceDepart_Id = await Cache.get("serviceDepartmentId");
    setServiceDepartmentId(serviceDepart_Id);

    if (serviceDepart_Id == 1) {
      if (selectDepartmentName.departmentId == 0) {
        const myJsonServices = JSON.stringify({});
        const { data } = await listingAPI.ParticipantPost({
          endPoint:
            "/select/allParticipants/status/notDeleted/orderBy/planEndDate",
          data: myJsonServices,
        });
        setFilterdData(data);
        setMasterData(data);

        setAllParticipant(data);
      } else {
        GetParticipantAllDetailsAsPerDepartmentOrderByPlanDate(
          selectDepartmentName.departmentId
        );
      }
    }
  };

  const NoFilterHandlerGetParticipantAllDetailsOrderByDate = async () => {
    const serviceDepart_Id = await Cache.get("serviceDepartmentId");
    setServiceDepartmentId(serviceDepart_Id);

    if (serviceDepart_Id == 1) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.ParticipantPost({
        endPoint:
          "/select/allParticipants/status/notDeleted/orderBy/planEndDate",
        data: myJsonServices,
      });
      setFilterdData(data);
      setMasterData(data);
      setAllParticipant(data);
      setProgressBar(false);
    }
  };

  const GetParticipantAllDetailsAsPerDepartment = async (departmentId) => {
    const myJsonServices = JSON.stringify({
      department_main_id: departmentId,
    });
    const { data } = await listingAPI.ParticipantPost({
      endPoint:
        "/select/allParticipants/as/per/department/status/notDeleted/orderBy/planEndDate",
      data: myJsonServices,
    });
    setFilterdData(data);
    setMasterData(data);

    setAllParticipant(data);
  };

  const GetParticipantAllDetailsAsPerDepartmentOrderByName = async (
    departmentId
  ) => {
    const myJsonServices = JSON.stringify({
      department_main_id: departmentId,
    });
    const { data } = await listingAPI.ParticipantPost({
      endPoint:
        "/select/allParticipants/as/per/department/status/notDeleted/orderBy/name",
      data: myJsonServices,
    });
    setFilterdData(data);
    setMasterData(data);

    setAllParticipant(data);
  };

  const GetParticipantAllDetailsAsPerDepartmentOrderByPlanDate = async (
    departmentId
  ) => {
    const myJsonServices = JSON.stringify({
      department_main_id: departmentId,
    });
    const { data } = await listingAPI.ParticipantPost({
      endPoint:
        "/select/allParticipants/as/per/department/status/notDeleted/orderBy/planEndDate",
      data: myJsonServices,
    });
    setFilterdData(data);
    setMasterData(data);

    setAllParticipant(data);
  };

  const GetParticipantAllDetailsOrderByActiveInactive = async () => {
    const serviceDepart_Id = await Cache.get("serviceDepartmentId");
    setServiceDepartmentId(serviceDepart_Id);

    if (serviceDepart_Id == 1) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.ParticipantPost({
        endPoint:
          "/select/allParticipants/status/notDeleted/orderBy/activeInactive",
        data: myJsonServices,
      });
      setFilterdData(data);
      setMasterData(data);

      setallInActiveParticipants(data);
    }

    // GetAllFridgedParticipantsDetails();
  };

  const GetTotalNumberOfInactiveParticipans = async () => {
    const serviceDepart_Id = await Cache.get("serviceDepartmentId");
    setServiceDepartmentId(serviceDepart_Id);

    if (serviceDepart_Id == 1) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.ParticipantPost({
        endPoint:
          "/select/allParticipants/status/notDeleted/orderBy/activeInactive",
        data: myJsonServices,
      });

      setallInActiveParticipants(data);
    }
  };

  const GetTotalGetAllWaitingParticipantsForTherapist = async () => {
    const serviceDepart_Id = await Cache.get("serviceDepartmentId");
    setServiceDepartmentId(serviceDepart_Id);

    if (serviceDepart_Id == 1) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.ParticipantPost({
        endPoint:
          "/select/all/waiting/Participants/for/services/status/notDeleted/orderBy",
        data: myJsonServices,
      });

      setAllTotalWaitingParticipants(data);
    }
  };

  const GetAllWaitingParticipantsForTherapist = async () => {
    const serviceDepart_Id = await Cache.get("serviceDepartmentId");
    setServiceDepartmentId(serviceDepart_Id);

    if (serviceDepart_Id == 1) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.ParticipantPost({
        endPoint:
          "/select/all/waiting/Participants/for/therapist/status/notDeleted/orderBy",
        data: myJsonServices,
      });
      setFilterdData(data);
      setMasterData(data);

      setAllWaitingParticipants(data);
    }
  };

  const GetAllWaitingParticipantsForServices = async () => {
    const serviceDepart_Id = await Cache.get("serviceDepartmentId");
    setServiceDepartmentId(serviceDepart_Id);

    if (serviceDepart_Id == 1) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.ParticipantPost({
        endPoint:
          "/select/all/waiting/Participants/for/services/status/notDeleted/orderBy",
        data: myJsonServices,
      });
      setFilterdData(data);
      setMasterData(data);

      setAllWaitingParticipants(data);
    }
  };

  const GetAllInactiveParticipantDetailsOrderByName = async () => {
    const serviceDepart_Id = await Cache.get("serviceDepartmentId");
    setServiceDepartmentId(serviceDepart_Id);

    if (serviceDepart_Id == 1) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.ParticipantPost({
        endPoint:
          "/select/allParticipants/status/notDeleted/activeInactive/orderbyname",
        data: myJsonServices,
      });

      setallInActiveParticipants(data);
    }
  };

  const GetAllFridgedParticipantsDetails = async () => {
    const serviceDepart_Id = await Cache.get("serviceDepartmentId");
    setServiceDepartmentId(serviceDepart_Id);

    if (serviceDepart_Id == 1) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.ParticipantPost({
        endPoint: "/select/all/fridged/Participants/status/notDeleted",
        data: myJsonServices,
      });

      setFilterdData(data);
      setMasterData(data);

      setAllFridgedParticipants(data);
    }

    //NoFilterHandlerGetParticipantAllDetailsOrderByDate();
  };

  const GetTotalNumOfFridgedParticipantsDetails = async () => {
    const serviceDepart_Id = await Cache.get("serviceDepartmentId");
    setServiceDepartmentId(serviceDepart_Id);

    if (serviceDepart_Id == 1) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.ParticipantPost({
        endPoint: "/select/all/fridged/Participants/status/notDeleted",
        data: myJsonServices,
      });

      setAllFridgedParticipants(data);
    }

    //NoFilterHandlerGetParticipantAllDetailsOrderByDate();
  };

  const GetFridgedAllDetailsOrderByDate = async () => {
    const serviceDepart_Id = await Cache.get("serviceDepartmentId");
    setServiceDepartmentId(serviceDepart_Id);

    if (serviceDepart_Id == 1) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.ParticipantPost({
        endPoint: "/select/all/fridged/Participants/status/notDeleted",
        data: myJsonServices,
      });

      setAllFridgedParticipants(data);
    }
  };

  const GetFridgedAllDetailsOrderByName = async () => {
    const serviceDepart_Id = await Cache.get("serviceDepartmentId");
    setServiceDepartmentId(serviceDepart_Id);

    if (serviceDepart_Id == 1) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.ParticipantPost({
        endPoint:
          "/select/all/fridged/Participants/status/notDeleted/orderBy/name",
        data: myJsonServices,
      });

      setAllFridgedParticipants(data);
    }
  };

  const GetParticipantAllDetailsOrderByName = async () => {
    if (selectDepartmentName.departmentId == 0) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.ParticipantPost({
        endPoint: "/select/allParticipants/status/notDeleted/orderBy/name",
        data: myJsonServices,
      });
      setFilterdData(data);
      setMasterData(data);
      setAllParticipant(data);
    } else {
      GetParticipantAllDetailsAsPerDepartmentOrderByName(
        selectDepartmentName.departmentId
      );
    }
  };

  const onClickGetServiceList = async (item) => {
    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
      currentPlanNumver: item.currentPlanNumver,
    });
    const { data } = await listingAPI.AllocateServicesPost({
      endPoint: "/select/all/Allocated/services/name/of/selected/participant",
      data: myJsonServices,
    });

    setAllServices(data);
    setAllServicesShow(true);
  };

  const documentDataHandler_inTable = async (documentName) => {
    const loginBymain_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      participant_main_id: main_id,
      services_main_id: null,
      uploadPersion_main_id: loginBymain_id,
      uploadBy: "therapyAdmin",
      uploadDate: moment(Date.now()).format("YYYY/MM/DD"),
      documentName: documentName,
      status: "inactive",
    });

    const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
      endPoint: "/Insert/each/participant/documents",
      data: myJsonServices,
    });
  };

  const UpdateDocumentStatusHandler = async (documentDetails) => {
    const myJsonServices = JSON.stringify({
      participant_main_id: documentDetails.participant_main_id,
      status: documentDetails.status == "active" ? "inactive" : "active",
      main_id: documentDetails.main_id,
    });
    const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
      endPoint: "/update/participant/document/status",
      data: myJsonServices,
    });

    setAllDocuments(data);
  };

  const documentUpload_cloud_Handler = async () => {
    setProgressBar(true);
    const myJsonServices = fileUris;

    for (let i = 0; i < myJsonServices.length; i++) {
      const { data } = await listingAPI.ImageTryPost({
        endPoint: "/file/Upload/Services",
        data: myJsonServices[i],
      });

      if (data == null) {
        setProgressBar(false);
        setAlertDetails({
          modal: true,
          alert:
            "Something went wrong, File may be not supported or File size is more than 20MB.",
        });
        return;
      } else {
        documentDataHandler_inTable(data.name);
        setUploadedFile(data);
      }
    }
    setProgressBar(false);
    //ClearUseStateHandler();
  };

  const OnPressDocumentsHandler = (item) => {
    setMain_id(item.main_id);

    setParticipantDetails(item);
    setChoiceBtnSetter("DocumentList");
  };

  const onPressPlanManageHandler = (item) => {
    setSelectedParticipantDetails(item);
    setChoiceBtnSetter("PlanManagement");
  };

  const OnclickEditHandler = (item) => {
    setSelectDepartmentName({
      name: item.departmentName,
      departmentId: item.department_main_id,
    });

    setCurrentPlanNumver(item.currentPlanNumver);
    setName(item.name);
    setContact(item.contactNumber);
    setParticipantEmail(item.ParticipantEmail);
    setAddress(item.ParticipantAddress);
    setBirthDate(
      item.ParticipantBirthDate == null
        ? "DD/MM/YYYY"
        : moment(item.ParticipantBirthDate).format("DD/MM/YYYY")
    );
    setSelectedGender({
      name:
        item.ParticipantGender == null
          ? "Select Gender"
          : item.ParticipantGender,
    });
    setParticipantDiagnosis(item.ParticipantDiagnosis);
    setGuardianName(item.guardianName);
    setGuardianContact(item.guardianContact);
    setGuardianEmail(item.guardianEmail);
    setGuardianAddress(item.guardianAddress);
    setSupportCoordinatorName(item.supportCoordinatorName);
    setSupportCoordinatorNumber(item.supportCoordinatorNumber);
    setSupportCoordinatorEmail(item.supportCoordinatorEmail);
    setSupportCoordinatorAddress(item.supportCoordinatorAddress);
    setReferralDate(
      item.referralDate != undefined
        ? moment(item.referralDate).format("DD/MM/YYYY")
        : "DD/MM/YYYY"
    );
    setTask(item.briefTask);
    setPlanStartDate(
      moment(item.planStartDate).format("DD/MM/YYYY")
    );
    setPlanEndDate(moment(item.planEndDate).format("DD/MM/YYYY"));
    setNdisNo(item.ndisNo);
    setMain_id(item.main_id);

    setModal(true);
  };

  const OnClickUpdateHandler = async () => {
    let p_startDate = moment(planStartDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    let p_endDate = moment(planEndDate, "DD/MM/YYYY").format("YYYY-MM-DD");

    if (p_startDate >= p_endDate) {
      setAlertDetails({
        modal: true,
        alert: "'Plan End date' must be after 'Plan start start date'.",
      });
      return;
    }
    if (selectDepartmentName.departmentId == 0) {
      setAlertDetails({
        modal: true,
        alert: "Please, Select Department",
      });
      return;
    } else if (!name) {
      setAlertDetails({
        modal: true,
        alert: "Please, Add Participant's name.",
      });
      return;
    } else if (!birthDate || birthDate == "DD/MM/YYYY") {
      setAlertDetails({
        modal: true,
        alert: "Please, Select BirthDate.",
      });
      return;
    } else if (!selectedGender || selectedGender == "Select Gender") {
      setAlertDetails({
        modal: true,
        alert: "Please, Select Participant Gender.",
      });
      return;
    } else if (!planStartDate) {
      setAlertDetails({
        modal: true,
        alert: "Please, Select plan start Date.",
      });
      return;
    } else if (!planEndDate) {
      setAlertDetails({
        modal: true,
        alert: "Please, Select plan end Date.",
      });
      return;
    } else if (!ndisNo) {
      setAlertDetails({
        modal: true,
        alert: "Please, Add NDIS No.",
      });
      return;
    }

    const myJsonServices = JSON.stringify({
      department_main_id: selectDepartmentName.departmentId,
      name: name && name.toUpperCase(),
      contactNumber: contact,
      ParticipantEmail:
        participantEmail != undefined ? participantEmail.trim() : "",
      ParticipantAddress: address && address.toUpperCase(),
      ParticipantBirthDate: moment(birthDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
      currentPlanNumver: currentPlanNumver,
      ParticipantGender: selectedGender.name,
      ParticipantDiagnosis: participantDiagnosis,
      guardianName: guardianName && guardianName.toUpperCase(),
      guardianContact: guardianContact,
      guardianEmail: guardianEmail,
      guardianAddress: guardianAddress,
      supportCoordinatorName: supportCoordinatorName,
      supportCoordinatorNumber: supportCoordinatorNumber,
      supportCoordinatorEmail: supportCoordinatorEmail,
      supportCoordinatorAddress: supportCoordinatorAddress,
      referralDate:
        referralDate != "DD/MM/YYYY"
          ? moment(referralDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
      briefTask: task,
      planStartDate: moment(planStartDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      planEndDate: moment(planEndDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      ndisNo: ndisNo.trim(),
      main_id: main_id,
    });

    const { data } = await listingAPI.ParticipantPost({
      endPoint: "/update/participant/all/details",
      data: myJsonServices,
    });

    if (data == "duplicate") {
      setAlertDetails({
        modal: true,
        alert:
          "Sorry, this request has been declined. Because this NDIS No has been already register.",
      });
      return;
    }

    setAllParticipant(data);
    setModal(false);
  };

  const OnClickDeleteHandler = async (item) => {
    setDeleteModal(true);
    setItemForDelete(item);
  };

  const OnClickYesHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      main_id: itemForDelete.main_id,
    });

    const { data } = await listingAPI.ParticipantPost({
      endPoint: "/delete/selected/participant",
      data: myJsonServices,
    });

    setAllParticipant(data);
    setDeleteModal(false);
  };

  const OnClickChangeBtnHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      status: item.status == "active" ? "inactive" : "active",
      main_id: item.main_id,
    });

    const { data } = await listingAPI.ParticipantPost({
      endPoint: "/update/selected/participant/status",
      data: myJsonServices,
    });

    if (data.length == 0) {
      setallInActiveParticipants([]);
      setAllParticipant([]);
      return;
    }

    setallInActiveParticipants(data);
    setAllParticipant(data);
  };

  const onClickGetSelectedItemHandler = (item) => {
    setSearch(true);
    setDetailsShow([item]);
  };

  const onClickSessionHandler = (item) => {
    navigation.navigate(Routes.STACK_ADMINSESSIONMAINSCREEN, { items: item });
  };

  return (
    <View style={styles.container}>
      <AppAlertYesNo
        mainViewStyles={{ width: "90%", height: "50%" }}
        isModalVisible={allServicesShow}
        noBtnPress={() => setAllServicesShow(false)}
        yesBtnEnable={false}
        leftBtnLabel="Back"
        Alert="Service List"
      >
        <View style={{ width: "100%", height: "80%" }}>
          <AppServicesListCard items={allServices} />
        </View>
      </AppAlertYesNo>

      <AppAlertYesNo
        mainViewStyles={{ width: "90%", height: "30%" }}
        isModalVisible={deleteModal}
        leftBtnLabel="No"
        noBtnPress={() => setDeleteModal(false)}
        yesBtnPress={() => OnClickYesHandler()}
        rightBtnLabel="Yes"
        Alert="Are you sure, Do you want to delete ?"
      />

      <AppAlertYesNo
        mainViewStyles={styles.mainViewStyles}
        isModalVisible={modal}
        leftBtnLabel="Cancel"
        noBtnEnable={!uploadDocumentHandler}
        yesBtnEnable={!uploadDocumentHandler}
        noBtnPress={() => setModal(false)}
        yesBtnPress={() => OnClickUpdateHandler()}
        rightBtnLabel="Update"
        Alert="Update Credentials."
      >
        <ScrollView style={{ width: "90%" }}>
          <View style={{ width: "100%" }}>
            <AppPicker
              width="40%"
              label="Select Department : "
              items={allDepartment}
              selectedItem={selectDepartmentName}
              onSelectItem={(item) =>
                setSelectDepartmentName({
                  name: item.name,
                  departmentId: item.main_id,
                })
              }
            />
          </View>
          <View style={styles.cardStyles}>
            <View style={styles.viewStylesRowDesign}>
              <AppInputText
                width="20%"
                placeholder="Participant Name  : "
                label="Participant Name : *"
                iconName="account"
                value={name ?? ""}
                onChangeText={(txt) => setName(txt.toUpperCase())}
              />
              <AppInputText
                width="20%"
                placeholder="Contact : "
                label="Contact : "
                iconName="phone"
                keyboardType="numeric"
                pattern="[0-9]*"
                maxLength={10}
                value={contact ?? ""}
                onChangeText={(txt) => setContact(txt.replace(/[^0-9]/g, ""))}
              />
              <AppInputText
                width="20%"
                placeholder="Participant Email : "
                label="Participant Email : "
                value={participantEmail ?? ""}
                onChangeText={(txt) => setParticipantEmail(txt.toUpperCase())}
              />
              <AppInputText
                width="20%"
                placeholder="Address : "
                label="Address : "
                iconName="location-pin"
                IconType="Entypo"
                value={address ?? ""}
                onChangeText={(txt) => setAddress(txt.toUpperCase())}
              />
            </View>
            <View style={styles.viewStylesRowDesign}>
              <AppCalander
                width="20%"
                label="Participant Birth Date : *"
                datePlaceholder={birthDate}
                selectedDate={(date) => setBirthDate(date)}
                maxDate={new Date()}
              />

              <AppPicker
                width="20%"
                label="Participant Gender : "
                items={genderList}
                selectedItem={selectedGender}
                onSelectItem={(item) => {
                  console.log(item);
                  setSelectedGender(item);
                }}
              />

              {/* <AppTouchableLabel
                items={genderList}
                width="20%"
                placeholder="Participant Gender : "
                label="Participant Gender : "
                iconName="account"
                menuLabel={selectedGender}
                selectedMenu={(item) => setSelectedGender(item)}
              /> */}
              <AppInputText
                iconName="tasks"
                IconType="FontAwesome"
                width="45%"
                placeholder="Participant Diagnosis : "
                label="Participant Diagnosis : "
                multiline
                height={150}
                value={participantDiagnosis ?? ""}
                onChangeText={(txt) =>
                  setParticipantDiagnosis(txt.toUpperCase())
                }
              />
            </View>
            <View style={styles.viewStylesRowDesign}>
              <AppInputText
                width="20%"
                placeholder="Guardian / SC Name  : "
                label="Guardian / SC Name  : "
                iconName="account"
                value={guardianName ?? ""}
                onChangeText={(txt) => setGuardianName(txt.toUpperCase())}
              />
              <AppInputText
                width="20%"
                placeholder="Guardian / SC Contact : "
                label="Guardian / SC Contact "
                iconName="phone"
                keyboardType="numeric"
                pattern="[0-9]*"
                maxLength={10}
                value={guardianContact ?? ""}
                onChangeText={(txt) =>
                  setGuardianContact(txt.replace(/[^0-9]/g, ""))
                }
              />
              <AppInputText
                width="20%"
                placeholder="Guardian Email : "
                label="Guardian Email : "
                value={guardianEmail ?? ""}
                onChangeText={(txt) => setGuardianEmail(txt.toUpperCase())}
              />
              <AppInputText
                width="20%"
                placeholder="Guardian Address : "
                label="Guardian Address : "
                iconName="location-pin"
                IconType="Entypo"
                value={guardianAddress ?? ""}
                onChangeText={(txt) => setGuardianAddress(txt.toUpperCase())}
              />
            </View>

            <View style={styles.viewStylesRowDesign}>
              <AppInputText
                width="20%"
                placeholder="Support coordinator Name  : "
                label="Support coordinator Name  : "
                iconName="account"
                value={supportCoordinatorName ?? ""}
                onChangeText={(txt) =>
                  setSupportCoordinatorName(txt.toUpperCase())
                }
              />
              <AppInputText
                width="20%"
                placeholder="Support coordinator Contact : "
                label="Support coordinator Contact : "
                iconName="phone"
                keyboardType="numeric"
                pattern="[0-9]*"
                maxLength={10}
                value={supportCoordinatorNumber ?? ""}
                onChangeText={(txt) =>
                  setSupportCoordinatorNumber(txt.replace(/[^0-9]/g, ""))
                }
              />
              <AppInputText
                width="20%"
                placeholder="Support coordinator Email : "
                label="Support coordinator Email : "
                value={supportCoordinatorEmail ?? ""}
                onChangeText={(txt) =>
                  setSupportCoordinatorEmail(txt.toUpperCase())
                }
              />
              <AppInputText
                width="20%"
                placeholder="Support coordinator Address : "
                label="Support coordinator Address : "
                iconName="location-pin"
                IconType="Entypo"
                value={supportCoordinatorAddress ?? ""}
                onChangeText={(txt) =>
                  setSupportCoordinatorAddress(txt.toUpperCase())
                }
              />
            </View>

            <View style={styles.viewStylesRowDesign}>
              <AppCalander
                width="20%"
                label="Referral Date : *"
                datePlaceholder={referralDate}
                selectedDate={(date) => setReferralDate(date)}
              />

              <AppCalander
                width="20%"
                label="Plan Start Date : *"
                datePlaceholder={planStartDate}
                selectedDate={(date) => setPlanStartDate(date)}
                maxDate={new Date()}
              />
              <AppCalander
                width="20%"
                label="Plan End Date : *"
                datePlaceholder={planEndDate}
                selectedDate={(date) => setPlanEndDate(date)}
              />

              <AppInputText
                width="20%"
                placeholder="NDIS No    : *"
                label="NDIS No   : "
                iconName="numeric"
                keyboardType="numeric"
                value={ndisNo ?? ""}
                onChangeText={(txt) => setNdisNo(txt.replace(/[^0-9]/g, ""))}
              />
            </View>
            <AppInputText
              placeholder="Tasks in brief : "
              label="Tasks in brief : "
              iconName="tasks"
              IconType="FontAwesome"
              value={task ?? ""}
              onChangeText={(txt) => setTask(txt.toUpperCase())}
              multiline
              height={250}
            />
          </View>
        </ScrollView>
      </AppAlertYesNo>

      <AppAlert
        alert={alertDetails}
        okBtnPress={() => setAlertDetails({ modal: false })}
      />

      <View style={styles.choiceBtnStyles}>
        <AppButton
          width="15%"
          style={{ borderWidth: 1 }}
          label="Add New Participant"
          color={choiceBtnSetter == "AddParticipant" ? "white" : ""}
          onPress={() => setChoiceBtnSetter("AddParticipant")}
        />

        <AppButton
          width="15%"
          style={{ borderWidth: 1 }}
          label={`Participant's List ${allParticipant.length}`}
          color={choiceBtnSetter == "ParticipantsList" ? "white" : ""}
          onPress={() => {
            GetParticipantAllDetailsOrderByDate();
            setChoiceBtnSetter("ParticipantsList");
          }}
        />

        <AppButton
          width="15%"
          style={{ borderWidth: 1 }}
          label={`Inactive Participants ${allInActiveParticipants.length}`}
          color={choiceBtnSetter == "InactiveParticipants" ? "white" : ""}
          onPress={() => {
            GetParticipantAllDetailsOrderByActiveInactive();
            setChoiceBtnSetter("InactiveParticipants");
          }}
        />
        <AppButton
          width="15%"
          style={{ borderWidth: 1 }}
          label={`Waiting List ${allTotalWaitingParticipants.length}`}
          color={choiceBtnSetter == "WaitingList" ? "white" : ""}
          onPress={() => {
            GetAllWaitingParticipantsForServices();
            setChoiceBtnSetter("WaitingList");
          }}
        />
        <AppButton
          width="15%"
          style={{ borderWidth: 1 }}
          label={`Attention Required ${allFridgedParticipants.length}`}
          color={choiceBtnSetter == "AttentionRequired" ? "white" : ""}
          onPress={() => {
            GetAllFridgedParticipantsDetails();
            setChoiceBtnSetter("AttentionRequired");
          }}
        />
      </View>

      {choiceBtnSetter == "AddParticipant" && (
        <AddParticipantScreen
          refreshPage={() => {
            GetParticipantAllDetailsOrderByDate();
            setChoiceBtnSetter("ParticipantsList");
          }}
        />
      )}

      <AppProgressbar modalVisible={progressBar} message="data is loading" />

      {choiceBtnSetter == "ParticipantsList" && (
        <View style={styles.BottomViewStyles}>
          <View style={{ height: "100%", width: "100%" }}>
            <View
              style={{
                height: "10%",
                width: "90%",
                alignSelf: "center",
                marginBottom: 30,
              }}
            >
              <Text style={{ fontWeight: "bold", padding: 5 }}>
                Total : {filterdData.length}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <AppPicker
                  width="40%"
                  // label="Select Department : "
                  items={allDepartment}
                  selectedItem={selectDepartmentName}
                  onSelectItem={(item) =>
                    setSelectDepartmentName({
                      name: item.name,
                      departmentId: item.main_id,
                    })
                  }
                />
                {serviceDepartmentId == 1 && (
                  <AppButton
                    color="blue"
                    label="No Filter"
                    onPress={() => {
                      setSelectDepartmentName({
                        name: "Select Department",
                        departmentId: 0,
                      });
                      NoFilterHandlerGetParticipantAllDetailsOrderByDate();
                    }}
                  />
                )}
              </View>

              <AppInputText
                width="100%"
                mainInputViewStyles={{
                  alignSelf: "center",
                }}
                iconName="search"
                IconType="FontAwesome5"
                placeholder="Search Participant..."
                value={searchParticipant ?? ""}
                onChangeText={(txt) => SearchFilter(txt)}
              />
            </View>

            {allParticipant.length == 0 ? (
              <Text style={{ alignSelf: "center", fontSize: 35 }}>No Data</Text>
            ) : (
              <View style={{ height: "85%", width: "100%" }}>
                <AppParticipantDetails
                  items={filterdData}
                  onPressPlanManageEnable
                  onPressPlanManage={(items) => onPressPlanManageHandler(items)}
                  onPressDocumentList={(items) =>
                    OnPressDocumentsHandler(items)
                  }
                  onPressAddFileEnable={(items) => onClickSessionHandler(items)}
                  switchBtnChange={(txt) => OnClickChangeBtnHandler(txt)}
                  onPressEdit={(item) => OnclickEditHandler(item)}
                  onPressDelete={(item) => OnClickDeleteHandler(item)}
                  editEnable={true}
                  switchEnable={true}
                  deleteEnable={true}
                  EditDelete_Enable={true}
                  onPressDate={() => GetParticipantAllDetailsOrderByDate()}
                  onPressName={() => GetParticipantAllDetailsOrderByName()}
                  onPressServiceList={(item) => onClickGetServiceList(item)}
                  onPressActiveInactiveShorting={() =>
                    GetParticipantAllDetailsOrderByActiveInactive()
                  }
                  secondLabel=""
                />
              </View>
            )}
          </View>
        </View>
      )}

      {choiceBtnSetter == "InactiveParticipants" && (
        <View style={styles.BottomViewStyles}>
          {allInActiveParticipants.length == 0 ? (
            <Text style={{ alignSelf: "center", fontSize: 35 }}>No Data</Text>
          ) : (
            <>
              <Text style={{ fontWeight: "bold", padding: 5 }}>
                Total : {filterdData.length}
              </Text>

              <AppInputText
                width="90%"
                mainInputViewStyles={{
                  alignSelf: "center",
                }}
                iconName="search"
                IconType="FontAwesome5"
                placeholder="Search Participant..."
                value={searchParticipant ?? ""}
                onChangeText={(txt) => SearchFilter(txt)}
              />

              <View style={{ height: "90%", width: "100%" }}>
                <AppParticipantDetails
                  items={filterdData}
                  onPressPlanManageEnable
                  onPressPlanManage={(items) => onPressPlanManageHandler(items)}
                  onPressDocumentList={(items) =>
                    OnPressDocumentsHandler(items)
                  }
                  onPressAddFileEnable={(items) => onClickSessionHandler(items)}
                  switchBtnChange={(txt) => OnClickChangeBtnHandler(txt)}
                  onPressEdit={(item) => OnclickEditHandler(item)}
                  onPressDelete={(item) => OnClickDeleteHandler(item)}
                  editEnable={true}
                  switchEnable={true}
                  deleteEnable={true}
                  EditDelete_Enable={true}
                  onPressDate={() =>
                    GetParticipantAllDetailsOrderByActiveInactive()
                  }
                  onPressName={() =>
                    GetAllInactiveParticipantDetailsOrderByName()
                  }
                  onPressServiceList={(item) => onClickGetServiceList(item)}
                  secondLabel=""
                />
              </View>
            </>
          )}
        </View>
      )}

      {choiceBtnSetter == "WaitingList" && (
        <View style={styles.BottomViewStyles}>
          {allInActiveParticipants.length == 0 ? (
            <Text style={{ alignSelf: "center", fontSize: 35 }}>No Data</Text>
          ) : (
            <>
              <Text style={{ fontWeight: "bold", padding: 5 }}>
                Total : {filterdData.length}
              </Text>

              <View style={styles.btnWaitingListStyles}>
                <AppButton
                  color={waitingChoiceBtnSetter ? "" : "InputText"}
                  border={1}
                  //label="Waiting for Service"
                  label="Participant's Waiting List"
                  onPress={() => {
                    setWaitingChoiceBtnSetter(false);
                    GetAllWaitingParticipantsForServices();
                  }}
                />
                //
                {/* <AppButton
                  label="Waiting for Therapist"
                  color={waitingChoiceBtnSetter ? "InputText" : ""}
                  border={1}
                  onPress={() => {
                    setWaitingChoiceBtnSetter(true);
                    GetAllWaitingParticipantsForTherapist();
                  }}
                /> */}
              </View>

              <AppInputText
                width="90%"
                mainInputViewStyles={{
                  alignSelf: "center",
                }}
                iconName="search"
                IconType="FontAwesome5"
                placeholder="Search Participant..."
                value={searchParticipant ?? ""}
                onChangeText={(txt) => SearchFilter(txt)}
              />

              <View style={{ height: "85%", width: "100%" }}>
                <AppParticipantDetails
                  items={filterdData}
                  onPressPlanManageEnable
                  onPressPlanManage={(items) => onPressPlanManageHandler(items)}
                  onPressDocumentList={(items) =>
                    OnPressDocumentsHandler(items)
                  }
                  onPressAddFileEnable={(items) => onClickSessionHandler(items)}
                  switchBtnChange={(txt) => OnClickChangeBtnHandler(txt)}
                  onPressEdit={(item) => OnclickEditHandler(item)}
                  onPressDelete={(item) => OnClickDeleteHandler(item)}
                  editEnable={true}
                  switchEnable={true}
                  deleteEnable={true}
                  EditDelete_Enable={true}
                  onPressServiceList={(item) => onClickGetServiceList(item)}
                  secondLabel=""
                />
              </View>
            </>
          )}
        </View>
      )}

      {choiceBtnSetter == "AttentionRequired" && (
        <View style={styles.BottomViewStyles}>
          {allFridgedParticipants.length == 0 ? (
            <Text style={{ alignSelf: "center", fontSize: 35 }}>No Data</Text>
          ) : (
            <>
              <Text style={{ fontWeight: "bold", padding: 5 }}>
                Total : {filterdData.length}
              </Text>

              <AppInputText
                width="90%"
                mainInputViewStyles={{
                  alignSelf: "center",
                }}
                iconName="search"
                IconType="FontAwesome5"
                placeholder="Search Participant..."
                value={searchParticipant ?? ""}
                onChangeText={(txt) => SearchFilter(txt)}
              />

              <View style={{ height: "90%", width: "100%" }}>
                <AppParticipantDetails
                  items={filterdData}
                  onPressPlanManageEnable
                  onPressPlanManage={(items) => onPressPlanManageHandler(items)}
                  onPressDocumentList={(items) =>
                    OnPressDocumentsHandler(items)
                  }
                  onPressAddFileEnable={(items) => onClickSessionHandler(items)}
                  switchBtnChange={(txt) => OnClickChangeBtnHandler(txt)}
                  onPressEdit={(item) => OnclickEditHandler(item)}
                  onPressDelete={(item) => OnClickDeleteHandler(item)}
                  editEnable={true}
                  switchEnable={true}
                  deleteEnable={true}
                  EditDelete_Enable={true}
                  onPressDate={() => GetFridgedAllDetailsOrderByDate()}
                  onPressName={() => GetFridgedAllDetailsOrderByName()}
                  onPressServiceList={(item) => onClickGetServiceList(item)}
                  secondLabel=""
                />
              </View>
            </>
          )}
        </View>
      )}

      {choiceBtnSetter == "PlanManagement" && (
        <View style={styles.BottomViewStyles}>
          <AppButton
            width="20%"
            label="Back"
            onPress={() => setChoiceBtnSetter("ParticipantsList")}
          />
          <PlanManagement items={selectedParticipantDetails} />
        </View>
      )}

      {choiceBtnSetter == "DocumentList" && (
        <View style={styles.BottomViewStyles}>
          <AppButton
            width="20%"
            label="Back"
            onPress={() => setChoiceBtnSetter("ParticipantsList")}
          />
          <UploadFile
            participantDetails={participantDetails}
            switchEnable
            deleteEnable
            access="full"
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "95%",
    width: "100%",
    alignItems: "center",
  },
  BottomViewStyles: {
    height: "95%",
    width: "99%",
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 5,
  },
  mainViewStyles: {
    height: "80%",
    width: "100%",
  },
  cardStyles: {
    width: "100%",
    height: "100%",
    justifyContent: "space-around",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 50,
    paddingVertical: 20,
  },
  viewStylesRowDesign: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  documentVierStyles: {
    height: "100%",
    width: "100%",
    //marginVertical: 10,
  },
  BtnViewStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  choiceBtnStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  ServicesViewList: {
    width: "90%",
    padding: 10,
    backgroundColor: Colors.InputText,
    marginVertical: 3,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  txtLabelStyles: {
    fontWeight: "bold",
    width: "30%",
  },
  btnWaitingListStyles: {
    width: "100%",
    height: "5%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
});

export default ParticipantListScreen;
