import React from "react";

import { createStackNavigator } from "@react-navigation/stack";

import DepartmentDeciderScreen from "../../screens/departments/DepartmentDeciderScreen";
import AttendanceRootScreen from "../../screens/Attendance/AttendanceRootScreen";
import AttendanceHomeScreen from "../../screens/Attendance/AttendanceHomeScreen";

const Stack = createStackNavigator();

const StackNavigator = () => (
  <Stack.Navigator
    presentation="modal"
    initialRouteName="STACK_AttendanceRootScreen"
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen
      name="STACK_AttendanceRootScreen"
      component={AttendanceRootScreen}
      options={{ title: "Access Foundation" }}
    />
    <Stack.Screen
      name="STACK_AttendanceHomeScreen"
      component={AttendanceHomeScreen}
      options={{ title: "Access Foundation" }}
    />
    <Stack.Screen
      name="STACK_AllDepartmentScreen"
      component={DepartmentDeciderScreen}
      options={{ title: "Access Foundation" }}
    />
  </Stack.Navigator>
);

export default StackNavigator;
