import React, { useState, useEffect, Children } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  ActivityIndicator,
  Text,
  ScrollView,
  TouchableOpacity,
} from "react-native";

import listingAPI from "../api/listings";
import Colors from "../config/Colors";
import AppInputText from "../components/AppInputText";
import AppEmpCard from "../components/AppEmpCard";
import Cache from "../utility/cache";

function AppSearchBar({
  placeholder = "Search participant name...",
  children,
  getSelectedItem,
  searchAllParticipent = false,
  selectedParticipants = false,
  selectedAllActiveEmployee = false,
  selectedAllEmployee = false,
  selectedAllForms = false,
  height = "90%",
  width = "100%",
}) {
  const [search, setSearch] = useState();

  const [getSubVariety, setGetSubVariety] = useState([]);

  const [filterData, setFilterData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [layoutHeight, setLayoutHeight] = useState(0);

  useEffect(() => {
    GetAllParticipant();
  }, []);

  const GetAllParticipant = async () => {
    if (searchAllParticipent == true) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.ParticipantPost({
        endPoint: "/select/allParticipants/status/notDeleted",
        data: myJsonServices,
      });
      setGetSubVariety(data);
      setFilterData(data);
    } else if (selectedParticipants == true) {
      const therapi_main_id = await Cache.get("loginedBy_main_id");
      const myJsonServices = JSON.stringify({
        therapist_main_id: therapi_main_id,
      });
      const { data } = await listingAPI.ParticipantForTherapisPost({
        endPoint: "/selected/therapist/allParticipants/status/notDeleted",
        data: myJsonServices,
      });

      setGetSubVariety(data);
      setFilterData(data);
    } else if (selectedAllActiveEmployee == true) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.TherapyTeamPost({
        endPoint: "/Select/All/active/Employee",
        data: myJsonServices,
      });

      setGetSubVariety(data);
      setFilterData(data);
    } else if (selectedAllEmployee == true) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.TherapyTeamPost({
        endPoint: "/Select/All/Employee/status/notDeleted",
        data: myJsonServices,
      });

      setGetSubVariety(data);
      setFilterData(data);
    } else if (selectedAllForms == true) {
      const myJsonServices = JSON.stringify({});
      const { data } = await listingAPI.FormHandlerPost({
        endPoint: "/select/AllForms/notDeleted",
        data: myJsonServices,
      });

      setGetSubVariety(data);
      setFilterData(data);
    }
  };

  const searchItem = (txt) => {
    setIsLoading(true);
    setSearch(txt);
    if (txt.length == 0) {
      setLayoutHeight(0);
    } else {
      setLayoutHeight(null);
    }
    const filteredItem = filterData.filter((item) => {
      let itemLowerCase = item.name.toLowerCase();
      let searchItemLowerCase = txt.toLowerCase();

      return itemLowerCase.indexOf(searchItemLowerCase) > -1;
    });
    setGetSubVariety(filteredItem);
    setIsLoading(false);
  };
  const ItemSelectedHandler = (item) => {
    getSelectedItem(item);
    setSearch();
    setGetSubVariety([]);
    setLayoutHeight(0);
  };
  return (
    <View
      style={{
        alignSelf: "center",
        alignItems: "center",
        height: height,
        width: width,
      }}
    >
      <AppInputText
        ViewStyle={{ borderWidth: 1 }}
        backgroundColor="#fff"
        iconName="search"
        IconType="FontAwesome5"
        placeholder={placeholder}
        borderBottomWidth={0}
        value={search ?? ""}
        onChangeText={(txt) => searchItem(txt)}
      />
      <View
        style={{
          margin: 10,
          width: "100%",
          height: layoutHeight == 0 ? "1%" : "90%",
          backgroundColor:
            layoutHeight == 0 ? Colors.lightDark : Colors.darkBlue,
        }}
      >
        <ScrollView style={[styles.lableStyles, { height: layoutHeight }]}>
          <View
            style={[
              {
                overflow: "hidden",
                height: layoutHeight,
                width: "100%",
                alignItems: "center",
              },
            ]}
          >
            {isLoading && (
              <View style={{ alignItems: "center", justifyContent: "center" }}>
                <ActivityIndicator size={45} color="#bad555" />
              </View>
            )}
            <View
              style={{
                width: "100%",
              }}
            >
              {getSubVariety.map((item, key) => (
                <View
                  style={{
                    width: "100%",
                  }}
                  key={key}
                >
                  <AppEmpCard
                    items={item}
                    editEnable={false}
                    deleteEnable={false}
                    switchEnable={false}
                    addFileEnable={true}
                    onPressActivation
                    onPress={() => ItemSelectedHandler(item)}
                  />
                </View>
              ))}
            </View>
          </View>
        </ScrollView>
      </View>
      <View style={{ width: "100%" }}>{children}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  lableStyles: {
    marginVertical: 10,
  },
});

export default AppSearchBar;
