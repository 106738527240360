import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text, FlatList, Switch } from "react-native";
import * as WebBrowser from "expo-web-browser";
import moment from "moment";

import Colors from "../config/Colors";
import AppIcon from "../components/AppIcon";
import listingAPI from "../api/listings";
import AppAlertYesNo from "../components/AppAlertYesNo";

const EachFile = ({
  file,
  switchEnable = false,
  deleteEnable = false,
  switchBtnChange,
  OnClickDeleteDocumentHandler,
  popUpStyles,
}) => {
  const [modal, setModal] = useState(false);
  const [deleteItemSatter, setDeleteItemSatter] = useState("");
  const [switchBtn, setSwitchBtn] = useState(
    file.status == "active" ? true : false
  );

  const DeleteFileConfirmHandlet = (item) => {
    setDeleteItemSatter(item);
    setModal(true);
  };

  const YesBtnClickHandler = (item) => {
    OnClickDownloadHandler(item);
    OnClickDeleteDocumentHandler(item);
    setModal(false);
  };

  const OnClickPositionStatusHandler = async (items) => {
    setSwitchBtn(!switchBtn);
    switchBtnChange(items);
  };

  let fileName;
  if (file.documentName != undefined) {
    // fileName = file.documentName.split("<=>")[0];
    fileName = file.documentName.substring(
      file.documentName.indexOf("<=>") + 3
    );
  }

  const OnClickDownloadHandler = async (file) => {
    const myJsonServices = JSON.stringify({
      key: file.documentName,
    });

    const { data: url } = await listingAPI.FileDownloadPost({
      endPoint: "/get/selected/files",
      data: myJsonServices,
    });

    await WebBrowser.openBrowserAsync(url);
  };

  return (
    <View
      key={fileName}
      style={[styles.fileListViewStyles, { backgroundColor: Colors.InputText }]}
    >
      <AppAlertYesNo
        mainViewStyles={popUpStyles}
        Alert="Are you sure you want to delete this file permanently ?"
        noBtnPress={() => setModal(false)}
        yesBtnPress={() => YesBtnClickHandler(deleteItemSatter)}
        isModalVisible={modal}
      />

      <Text style={[styles.detailsStyle, { width: "42%" }]}>{fileName}</Text>
      <Text style={[styles.detailsStyle, { width: "15%" }]}>
        {file.therapistName}
      </Text>
      <Text style={[styles.detailsStyle, { width: "20%" }]}>
        {moment(file.uploadDate, "YYYY/MM/DD").format("DD/MM/YYYY")}
      </Text>

      <View style={styles.iconViewStyles}>
        <AppIcon
          size={"20%"}
          touchAble
          iconColor="red"
          name="cloud-download"
          backgroundColor={Colors.InputText}
          onPress={() => OnClickDownloadHandler(file)}
        />

        {switchEnable && (
          <>
            <Switch
              onValueChange={() => OnClickPositionStatusHandler(file)}
              value={switchBtn}
            />
          </>
        )}

        {deleteEnable && (
          <AppIcon
            size={"20%"}
            touchAble
            iconColor="red"
            name="delete"
            backgroundColor={Colors.InputText}
            onPress={() => DeleteFileConfirmHandlet(file)}
          />
        )}
      </View>
    </View>
  );
};

function AppGetDocumentCard({
  items,
  height = "50%",
  width = "90%",
  switchEnable,
  deleteEnable,
  switchBtnChange,
  OnClickDeleteDocumentHandler,
  popUpStyles,
  borderWidth = 1,
}) {
  return (
    <View style={[styles.container, { height, width, borderWidth }]}>
      {items.length <= 0 ? (
        <Text style={{ fontSize: 20, fontWeight: "bold", alignSelf: "center" }}>
          There is no document.
        </Text>
      ) : (
        <>
          <Text> Total Files : {items.length}</Text>
          <View style={styles.fileListViewStyles}>
            <Text style={[styles.labelStyles, { width: "42%" }]}>
              File Name
            </Text>
            <Text style={[styles.labelStyles, { width: "15%" }]}>
              Upload By
            </Text>
            <Text style={[styles.labelStyles, { width: "20%" }]}>
              Upload Date
            </Text>
            <Text style={[styles.labelStyles, { width: "25%" }]}></Text>
          </View>
          <View style={{ height: "80%", overflow: "hidden" }}>
            <FlatList
              data={items}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item }) => (
                <EachFile
                  file={item}
                  switchEnable={switchEnable}
                  deleteEnable={deleteEnable}
                  switchBtnChange={switchBtnChange}
                  OnClickDeleteDocumentHandler={OnClickDeleteDocumentHandler}
                  popUpStyles={popUpStyles}
                />
              )}
            />
          </View>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    paddingVertical: 5,
    margin: 10,
  },
  fileListViewStyles: {
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "space-between",
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 5,
    margin: 5,
    flexDirection: "row",
    width: "90%",
  },
  labelStyles: {
    fontWeight: "bold",
    fontSize: "100%",
  },
  detailsStyle: {
    fontSize: "100%",
  },
  iconViewStyles: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "25%",
  },
});

export default AppGetDocumentCard;
