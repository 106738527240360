import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  ScrollView,
  Dimensions,
} from "react-native";
import moment from "moment";
import Modal from "react-native-modal";

import listingAPI from "../../../../api/listings";
import AppSearchBar from "../../../../components/AppSearchBar";
import AppInputText from "../../../../components/AppInputText";
import AppPicker from "../../../../components/AppPicker";
import AppButton from "../../../../components/AppButton";
import AppAlert from "../../../../components/AppAlert";
import AppAlertYesNo from "../../../../components/AppAlertYesNo";
import Colors from "../../../../config/Colors";
import AppParticipantListCard from "../../../../components/cards/AppParticipantListCard";
import AppAllocatTherapist from "../../../../components/cards/AppAllocatTherapist";
import Cache from "../../../../utility/cache";

function AllocateServicesScreen({}) {
  const [allTherapistTeam, setAllTherapistTeam] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [price, setPrice] = useState(null);
  const [usedBudge, setUsedBudge] = useState(0);
  const [leftBudge, setLeftBudge] = useState(0);
  const [updateChecker, setUpdateChecker] = useState(false);
  const [updateTherapistChecker, setUpdateTherapistChecker] = useState(false);
  const [allocateTherapistModal, setAllocateTherapistModal] = useState(false);
  const [allocateServiceBtn, setAllocateServiceBtn] = useState(false);
  const [calculationDecider, setCalculationDecider] = useState("budge");
  const [updateMain_id, setUpdateMain_id] = useState();
  const [therapistUpdateMain_id, setTherapistUpdateMain_id] = useState();
  const [serviceAndTherapyList, setServiceAndTherapyList] = useState();
  const [participant_main_id, setParticipant_main_id] = useState();

  const [displayCalculation, setDisplayCalculation] = useState();
  const [totalHours, setTotalHours] = useState(0);
  const [totalMins, setTotalMins] = useState(0);
  const [usedHours, setUsedHours] = useState(0);
  const [totalUsedMins, setTotalUsedMins] = useState(0);

  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [selectedTherapist, setSelectedTherapist] = useState(0);
  const [selectedMainService, setSelectedMainService] = useState({ price: "" });
  const [getAllMainServices, setGetAllMainServices] = useState({ price: "" });
  const [service, setService] = useState();
  const [deleteService, setDeleteService] = useState(false);
  const [serviceAndTherapist, setServiceAndTherapist] = useState(false);
  const [deleteServiceItem, setDeleteServiceItem] = useState();

  const [alertModal, setAlertModal] = useState({
    modal: false,
    alert: "",
  });

  const labelItem = {
    service_name: "Service Name",
    therapist_name: "Left Hours",
  };

  useEffect(() => {
    GetTherapist();
    GetAllServices();
  }, []);

  useEffect(() => {
    GetServisisOfSelectedParticipant();
  }, [selectedParticipant]);

  useEffect(() => {
    BudgeCalculation();
  }, [price, totalHours, totalMins]);

  const OnclickEditHandler = (items) => {
    setUpdateChecker(true);

    setUsedHours(items.used_hours + " Hours " + items.used_mins + " Min");

    let totalUsed_min = items.used_hours * 60 + parseInt(items.used_mins);

    setTotalUsedMins(totalUsed_min);

    setParticipant_main_id(items.participant_main_id);
    setUpdateMain_id(items.main_id);
    setPrice(items.total_budge);
    setUsedBudge(items.used_budge);

    setSelectedTherapist({
      main_id: items.therapist_main_id,
      name: items.therapist_name,
    });
    setSelectedMainService({
      main_id: items.services_main_id,
      name: items.service_name,
      price: items.service_price,
    });

    let pricePerMin = items.service_price / 60;

    let totalMin = (items.total_budge - items.used_budge) / pricePerMin;
    let hours = ~~(totalMin / 60);
    let min = ~~(totalMin % 60);

    setDisplayCalculation(hours + " Hours " + min + " Min");
  };

  const BudgeCalculation = () => {
    let leftBudge = price - usedBudge;
    setLeftBudge(leftBudge.toFixed(3));

    if (calculationDecider == "budge") {
      if (selectedMainService != null) {
        let pricePerMin = selectedMainService.price / 60;

        let totalBudge_min =
          totalHours * 60 + parseInt(totalMins == "" ? 0 : totalMins);

        let left_totalMin = totalBudge_min - totalUsedMins;
        let left_hours = ~~(left_totalMin / 60);
        let left_min = ~~(left_totalMin % 60);
        setDisplayCalculation(left_hours + " Hours " + left_min + " Min");

        let total_Min = price / pricePerMin;
        let total_hours = ~~(total_Min / 60);
        let total_min = ~~(total_Min % 60);
        setTotalHours(total_hours);
        setTotalMins(total_min);
      }
    } else if (calculationDecider == "hours") {
      BudgeCalculationByHours();
    }
  };

  const BudgeCalculationByHours = () => {
    if (selectedMainService != null) {
      let pricePerMin = selectedMainService.price / 60;

      let totalMin =
        parseInt(totalHours == "" ? 0 : totalHours) * 60 +
        parseInt(totalMins == "" ? 0 : totalMins);
      let totalBudge = totalMin * pricePerMin;

      setPrice(totalBudge > 0 ? totalBudge.toFixed(3) : 0);

      //  let left_totalMin = (price - usedBudge) / pricePerMin;

      let totalBudge_min =
        totalHours * 60 + parseInt(totalMins == "" ? 0 : totalMins);
      let left_totalMin = totalBudge_min - totalUsedMins;

      let left_hours = ~~(left_totalMin / 60);
      let left_min = ~~(left_totalMin % 60);
      setDisplayCalculation(left_hours + " Hours " + left_min + " Min");
    }
  };

  const GetTherapist = async () => {
    const myJsonServices = JSON.stringify({
      services_id: 3,
    });
    const { data } = await listingAPI.TherapyEmpPost({
      endPoint: "/select/all/active/TherapyEmp/status/active",
      data: myJsonServices,
    });

    setAllTherapistTeam(data);
  };

  const GetTherapistAndServices = async () => {
    const myJsonServices = JSON.stringify({
      participant_main_id: selectedParticipant.main_id,
      currentPlanNumver: selectedParticipant.currentPlanNumver,
    });
    const { data } = await listingAPI.AllocateTherapistPost({
      endPoint:
        "/select/all/Allocate/Therapist/and/service/of/selected/participant",
      data: myJsonServices,
    });

    setServiceAndTherapyList(data);
  };

  const GetAllServices = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");
    const myJsonServices = JSON.stringify({
      therapist_main_id: therapist_main_id,
    });
    const { data } = await listingAPI.Services({
      endPoint: "/select/allServices/status/active",
      data: myJsonServices,
    });

    setGetAllMainServices(data);
  };

  const GetServisisOfSelectedParticipant = async () => {
    if (selectedParticipant == null) {
      return;
    }

    const myJsonServices = JSON.stringify({
      participant_main_id: selectedParticipant.main_id,
      currentPlanNumver: selectedParticipant.currentPlanNumver,
    });
    const { data } = await listingAPI.AllocateServicesPost({
      endPoint: "/select/all/Allocated/Services/of/selected/participant",
      data: myJsonServices,
    });

    setServiceList(data);
  };

  const OnClickAddTherapistHandler = async (item) => {
    if (!selectedTherapist) {
      setAllocateTherapistModal(false);
      setAlertModal({
        modal: true,
        alert: "Please, select therapist.",
      });
      return;
    } else if (!service) {
      setAllocateTherapistModal(false);
      setAlertModal({
        modal: true,
        alert: "Please, Select Service.",
      });
      return;
    } else if (!selectedParticipant) {
      setAllocateTherapistModal(false);
      setAlertModal({
        modal: true,
        alert: "Please, Select Participant.",
      });
      return;
    }

    if (updateTherapistChecker == false) {
      const myJsonServices = JSON.stringify({
        participant_main_id: item.main_id,
        allocate_services_main_id: service.services_main_id,
        therapist_main_id: selectedTherapist.therapist_id,
        currentPlanNumver: item.currentPlanNumver,
      });
      const { data } = await listingAPI.AllocateTherapistPost({
        endPoint:
          "/Insert/Allocate/Therapist/to/selected/participant/asPerService",
        data: myJsonServices,
      });
      if (data == "duplicate") {
        setAllocateTherapistModal(false);
        setAlertModal({
          modal: true,
          alert: `${service.name} is already allocated to ${selectedTherapist.name}`,
        });
      } else {
        setServiceAndTherapyList(data);
        setAllocateTherapistModal(false);

        SendEmailHandler();
      }
    } else {
      const myJsonServices = JSON.stringify({
        participant_main_id: item.main_id,
        allocate_services_main_id: service.services_main_id,
        therapist_main_id: selectedTherapist.therapist_id,
        main_id: therapistUpdateMain_id,
      });
      const { data } = await listingAPI.AllocateTherapistPost({
        endPoint:
          "/Update/Allocate/Therapist/to/selected/participant/asPerService",
        data: myJsonServices,
      });
      if (data == "duplicate") {
        setAllocateTherapistModal(false);
        setAlertModal({
          modal: true,
          alert: `${service.name} is already allocated to ${selectedTherapist.name}`,
        });
      } else {
        setServiceAndTherapyList(data);
        setAllocateTherapistModal(false);
        SendEmailHandler();
      }
    }
  };

  const OnClickAllocateTherapistHandler = async (item) => {
    setService(null);
    setSelectedTherapist("");

    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
      currentPlanNumver: selectedParticipant.currentPlanNumver,
    });
    const { data } = await listingAPI.AllocateServicesPost({
      endPoint: "/select/all/Allocated/services/name/of/selected/participant",
      data: myJsonServices,
    });

    setAllServices(data);
    setUpdateTherapistChecker(false);
  };

  const SendEmailHandler = async () => {
    const myJsonServices = JSON.stringify({
      to: selectedTherapist.email,
      subject: "New participant allocated",
      text: `Hi ${selectedTherapist.name},
      
Please check your new participant list, ${selectedParticipant.name} has been allocated to you successfully.

Thanks,
Therapy Department.
      `,
    });

    const { data } = await listingAPI.SendEmailHandler_POST({
      endPoint: "/Email",
      data: myJsonServices,
    });
  };

  const OnClickSubmitHandler = async () => {
    if (selectedParticipant == null) {
      setAlertModal({
        modal: true,
        alert: "Please, select Participant.",
      });
      return;
    } else if (selectedMainService == null) {
      setAlertModal({
        modal: true,
        alert: "Please, select Service.",
      });
      return;
    } else if (price == null) {
      setAlertModal({
        modal: true,
        alert: "Please, enter your budge.",
      });
      return;
    } else if (parseInt(price) < parseInt(usedBudge)) {
      setAlertModal({
        modal: true,
        alert: `You have alredy used $${usedBudge}. Please enter total budge more than that.`,
      });
      return;
    }

    if (updateChecker == true) {
      const myJsonServices = JSON.stringify({
        participant_main_id: participant_main_id,
        therapist_main_id: selectedTherapist.main_id,
        total_hours: !totalHours ? 0 : totalHours,
        total_mins: !totalMins ? 0 : totalMins,
        total_budge: price,
        currentPlanNumver: selectedParticipant.currentPlanNumver,
        main_id: updateMain_id,
      });

      const { data } = await listingAPI.AllocateServicesPost({
        endPoint: "/Update/Therapist/selected/participant",
        data: myJsonServices,
      });

      setAlertModal({
        modal: true,
        alert: `Data has been Updated successfully.`,
      });

      setServiceList(data);
      OnClickCancelHandler();
      setUpdateChecker(!updateChecker);
      setServiceAndTherapist(true);
    } else {
      const myJsonServices = JSON.stringify({
        therapist_main_id: selectedTherapist.main_id,
        participant_main_id: selectedParticipant.main_id,
        services_main_id: selectedMainService.main_id,
        total_hours: !totalHours ? 0 : totalHours,
        total_mins: !totalMins ? 0 : totalMins,
        total_budge: price,
        currentPlanNumver: selectedParticipant.currentPlanNumver,
      });
      const { data } = await listingAPI.AllocateServicesPost({
        endPoint: "/Insert/Allocate/Services/to/selected/participant",
        data: myJsonServices,
      });

      if (data == "duplicate") {
        setAlertModal({
          modal: true,
          alert: `${selectedMainService.name} is already given to ${selectedParticipant.name}`,
        });
        return;
      }

      console.log("Insert data");
      console.log(data);

      setServiceList(data);
      setAlertModal({
        modal: true,
        alert: `${selectedMainService.name} is successfully allocated to ${selectedParticipant.name}`,
      });

      setServiceAndTherapist(true);
    }
  };

  const selectedSearchItemHandler = (txt) => {
    setSelectedParticipant(txt);
  };

  const OnclickEditTherapistHandler = (item) => {
    setUpdateTherapistChecker(true);
    setAllocateTherapistModal(true);

    setTherapistUpdateMain_id(item.main_id);
    setService({
      services_main_id: item.allocate_services_main_id,
      name: item.service_name,
    });
    setSelectedTherapist({
      therapist_id: item.therapist_main_id,
      name: item.therapist_name,
    });
  };

  const OnclickDeleteServiceHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      participant_main_id: item.participant_main_id,
      allocate_services_main_id: item.services_main_id,
      main_id: item.main_id,
      currentPlanNumver: item.currentPlanNumver,
    });
    const { data } = await listingAPI.AllocateServicesPost({
      endPoint: "/delete/Therapy/service/of/selected/participant",
      data: myJsonServices,
    });
    setServiceList(data);
    setDeleteService(false);
  };

  const OnClickCancelHandler = () => {
    GetAllServices();
    setCalculationDecider("budge");
    setSelectedMainService({ price: "" });
  };

  const OnClickDeleteTherapistHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      main_id: item.main_id,
      participant_main_id: selectedParticipant.main_id,
    });
    const { data } = await listingAPI.AllocateTherapistPost({
      endPoint: "/delete/Allocate/Therapist/to/selected/participant",
      data: myJsonServices,
    });
    setServiceAndTherapyList(data);
  };

  return (
    <View style={styles.container}>
      <AppAlert
        alert={alertModal}
        okBtnPress={() => setAlertModal({ modal: false })}
      />
      <AppAlertYesNo
        mainViewStyles={{ height: "30%" }}
        isModalVisible={deleteService}
        Alert="Do you want to delete it ?"
        noBtnPress={() => setDeleteService(false)}
        yesBtnPress={() => OnclickDeleteServiceHandler(deleteServiceItem)}
      />
      <Modal isVisible={allocateTherapistModal}>
        <View style={styles.allocateTherapistModalStyles}>
          <View
            style={{ flexDirection: "row", justifyContent: "space-around" }}
          >
            <AppPicker
              width="25%"
              label="Select Services"
              items={allServices}
              selectedItem={service}
              onSelectItem={(txt) => {
                setService(txt);
              }}
            />
            <AppPicker
              width="25%"
              label="Select Therapist"
              items={allTherapistTeam}
              selectedItem={selectedTherapist}
              onSelectItem={(txt) => setSelectedTherapist(txt)}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <AppButton
              label="Cancel"
              color="blue"
              onPress={() => {
                setAllocateTherapistModal(false);
              }}
            />
            <AppButton
              label={updateTherapistChecker ? "Update" : "Add"}
              onPress={() => OnClickAddTherapistHandler(selectedParticipant)}
            />
          </View>
        </View>
      </Modal>
      {selectedParticipant != null && (
        <Text style={{ fontWeight: "bold", fontSize: 20 }}>
          {selectedParticipant.name}
        </Text>
      )}
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        <AppButton
          style={{ borderWidth: 1 }}
          color={serviceAndTherapist ? "" : "white"}
          label="Assign Budget"
          onPress={() => setServiceAndTherapist(false)}
        />
        <AppButton
          style={{ borderWidth: 1 }}
          color={!serviceAndTherapist ? "" : "white"}
          label="Therapist"
          onPress={() => {
            setServiceAndTherapist(true);
            setCalculationDecider("budge");
          }}
        />
      </View>
      <View style={[styles.topViewStyles]}>
        <AppSearchBar
          height="100%"
          searchAllParticipent
          getSelectedItem={(txt) => selectedSearchItemHandler(txt)}
        />
      </View>

      <View style={{ height: "70%", width: "100%" }}>
        {!serviceAndTherapist ? (
          <View style={styles.bottomViewStyles}>
            {selectedParticipant && (
              <>
                <View style={styles.pickerItemsStyles}>
                  <AppPicker
                    touchAble={!updateChecker}
                    width="40%"
                    label="Select Services"
                    items={getAllMainServices}
                    selectedItem={selectedMainService}
                    onSelectItem={(txt) => setSelectedMainService(txt)}
                  />
                  {selectedMainService.price != "" && (
                    <Text style={{ fontSize: 25, padding: 10 }}>
                      ${selectedMainService.price}/h
                    </Text>
                  )}
                </View>
                <View style={{ width: "90%" }}>
                  {selectedMainService.price != "" && (
                    <>
                      <View style={[styles.budgeLabelViewStyle]}>
                        <AppButton
                          style={{ borderWidth: 1 }}
                          width="10%"
                          label="Cal"
                          color={
                            calculationDecider == "budge" ? "InputText" : ""
                          }
                          onPress={() => setCalculationDecider("budge")}
                        />
                        <AppInputText
                          mainInputView={{
                            alignSelf: "flex-start",
                          }}
                          label="Total Budge "
                          width="30%"
                          placeholder="Enter Your budge : "
                          iconName="currency-usd"
                          keyboardType="numeric"
                          inputTextNotTouchAble={
                            calculationDecider == "budge" ? true : false
                          }
                          textLabel={price}
                          value={price ?? ""}
                          //onChangeText={(txt) => setPrice(txt.replace(/[^0-9,.]/g, ""))}
                          onChangeText={(txt) =>
                            setPrice(
                              txt
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*?)\..*/g, "$1")
                            )
                          }
                        />

                        <AppInputText
                          inputTextNotTouchAble={false}
                          textLabel={usedBudge}
                          label="Used Budge "
                          width="20%"
                          iconName="currency-usd"
                        />
                        <AppInputText
                          inputTextNotTouchAble={false}
                          textLabel={leftBudge}
                          label="Left Budge "
                          width="20%"
                          iconName="currency-usd"
                        />
                      </View>
                      <View style={[styles.budgeLabelViewStyle]}>
                        <AppButton
                          width="10%"
                          style={{ borderWidth: 1 }}
                          label="Cal"
                          color={
                            calculationDecider == "hours" ? "InputText" : ""
                          }
                          onPress={() => setCalculationDecider("hours")}
                        />
                        <View
                          style={{
                            width: "30%",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <AppInputText
                            mainInputView={{
                              alignSelf: "flex-start",
                            }}
                            label="Total Hours "
                            width="50%"
                            placeholder="Total Hours : "
                            iconName="clock"
                            IconType="Entypo"
                            keyboardType="numeric"
                            inputTextNotTouchAble={
                              calculationDecider == "hours" ? true : false
                            }
                            textLabel={totalHours}
                            value={totalHours ?? 0}
                            onChangeText={(txt) =>
                              setTotalHours(txt.replace(/[^0-9,.]/g, ""))
                            }
                          />
                          <AppInputText
                            mainInputView={{
                              alignSelf: "flex-start",
                            }}
                            label="Total Min "
                            width="50%"
                            inputTextNotTouchAble={
                              calculationDecider == "hours" ? true : false
                            }
                            textLabel={totalMins}
                            placeholder="Total Min : "
                            iconName="clock"
                            IconType="Entypo"
                            keyboardType="numeric"
                            value={totalMins ?? 0}
                            onChangeText={(txt) =>
                              setTotalMins(txt.replace(/[^0-9,.]/g, ""))
                            }
                          />
                        </View>
                        <AppInputText
                          inputTextNotTouchAble={false}
                          textLabel={usedHours}
                          label="Used Hours "
                          width="20%"
                          iconName="clock"
                          IconType="Entypo"
                        />
                        <AppInputText
                          inputTextNotTouchAble={false}
                          textLabel={displayCalculation}
                          label="Left Hours "
                          width="20%"
                          iconName="clock"
                          IconType="Entypo"
                        />
                      </View>
                    </>
                  )}
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <AppButton
                    label="Clear"
                    onPress={() => OnClickCancelHandler()}
                  />

                  <AppButton
                    color="blue"
                    label={updateChecker ? "Update" : "Submit"}
                    onPress={() => OnClickSubmitHandler()}
                  />
                </View>
              </>
            )}
          </View>
        ) : (
          <>
            {serviceList.length != 0 ? (
              <View style={styles.bottomViewStyles}>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Text style={styles.labelStyles}>
                    Plan Start :
                    {moment(selectedParticipant.planStartDate)
                      .format("DD-MM-YYYY")}
                  </Text>
                  <Text style={styles.labelStyles}>
                    {selectedParticipant.name}
                  </Text>
                  <Text style={styles.labelStyles}>
                    Plan End :
                    {moment(selectedParticipant.planEndDate)
                      .format("DD-MM-YYYY")}
                  </Text>
                </View>
                <View style={styles.AllocateTherapistStyles}>
                  <AppButton
                    label="Assigned Service"
                    color={allocateServiceBtn ? "" : "InputText"}
                    style={{ borderWidth: 1 }}
                    onPress={() => setAllocateServiceBtn(false)}
                  />
                  <AppButton
                    label="Assigned Therapist"
                    color={allocateServiceBtn ? "InputText" : ""}
                    style={{ borderWidth: 1 }}
                    onPress={() => {
                      OnClickAllocateTherapistHandler(selectedParticipant);
                      GetTherapistAndServices();
                      setAllocateServiceBtn(true);
                    }}
                  />
                </View>
                {!allocateServiceBtn ? (
                  <>
                    <AppParticipantListCard
                      txtBold
                      backgroundColor={Colors.white}
                      items={labelItem}
                      deleteEnable={false}
                      switchEnable={false}
                      editEnable={false}
                    />
                    <FlatList
                      data={serviceList}
                      keyExtractor={(item, index) => index.toString()}
                      renderItem={({ item }) => (
                        <AppParticipantListCard
                          backgroundColor={Colors.lightDark}
                          items={item}
                          listView
                          onPressDelete={(item) => {
                            setDeleteServiceItem(item);
                            setDeleteService(true);
                          }}
                          onPressEdit={(item) => {
                            OnclickEditHandler(item);
                            setServiceAndTherapist(false);
                          }}
                          switchEnable={false}
                        />
                      )}
                    />
                  </>
                ) : (
                  <View
                    style={{
                      height: "75%",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 25 }}>
                      Therapist List
                    </Text>
                    <View
                      style={{
                        height: "80%",
                        width: "100%",
                      }}
                    >
                      <FlatList
                        data={serviceAndTherapyList}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={({ item }) => (
                          <AppAllocatTherapist
                            backgroundColor={Colors.lightDark}
                            items={item}
                            listView
                            onPressDelete={(item) =>
                              OnClickDeleteTherapistHandler(item)
                            }
                            onPressEdit={(item) =>
                              OnclickEditTherapistHandler(item)
                            }
                            switchEnable={false}
                          />
                        )}
                      />
                    </View>
                    <AppButton
                      label="Allocate Therapist"
                      onPress={() => {
                        OnClickAllocateTherapistHandler(selectedParticipant);
                        setAllocateTherapistModal(true);
                      }}
                    />
                  </View>
                )}
              </View>
            ) : (
              <Text style={{ alignSelf: "center", fontSize: 25 }}>
                Please, Select Services.
              </Text>
            )}
          </>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "95%",
    width: "95%",
    alignItems: "center",
  },
  pickerItemsStyles: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  topViewStyles: {
    width: "100%",
    height: "25%",
    alignItems: "center",
  },
  bottomViewStyles: {
    height: "70%",
    width: "99%",
    paddingVertical: 10,
    position: "absolute",
    bottom: 35,
    backgroundColor: "#fff",
    alignSelf: "center",
    borderRadius: 10,
  },
  labelStyles: {
    alignSelf: "center",
    fontSize: 15,
    borderWidth: 1,
    padding: 5,
    borderRadius: 5,
    fontWeight: "bold",
    marginBottom: 10,
  },
  serviceMainViewStyles: {
    paddingVertical: 0,
    width: "100%",
    height: "70%",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  budgeLabelViewStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    marginVertical: 10,
    alignSelf: "center",
  },
  AllocateTherapistStyles: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  allocateTherapistModalStyles: {
    height: "30%",
    width: "80%",
    backgroundColor: "white",
    alignSelf: "center",
    justifyContent: "space-around",
    borderRadius: 5,
  },
});

export default AllocateServicesScreen;
