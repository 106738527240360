import React from "react";
import { View, StyleSheet, Text } from "react-native";

function ManageService({}) {
  return (
    <View style={styles.container}>
      <Text>Services...</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default ManageService;
