import React, { useState, useEffect } from "react";
import { View, StyleSheet, Switch, TouchableOpacity, Text } from "react-native";

import AppText from "../components/AppText";
import AppIcon from "./AppIcon";
import Colors from "../config/Colors";
import listingAPI from "../api/listings";

function AppTherapistCard({
  items,
  editEnable = true,
  switchEnable = true,
  deleteEnable = true,
  onPressDelete,
  onPressEdit,
  listView = false,
  switchBtnChange,
  backgroundColor = "#fff",
  onPressActivation = false,
  onPress,
  therapistEmailPass,
  onPressAccountActive,
}) {
  const [onClick, setOnClick] = useState(0);
  const [emailPass, setEmailPass] = useState([{ email: "", password: "" }]);
  const [switchBtn, setSwitchBtn] = useState(
    items.status == "active" ? true : false
  );

  let TouchView;

  if (listView == true || onPressActivation == true) {
    TouchView = TouchableOpacity;
  } else {
    TouchView = View;
  }

  const OnClickPositionStatusHandler = async (items) => {
    setSwitchBtn(!switchBtn);
    switchBtnChange(items);
  };

  const OnPressClickHandler = async (items) => {
    // setTherapistEmailPass(data);

    const myJsonServices = JSON.stringify({
      therapist_id: items.main_id,
    });

    const { data } = await listingAPI.TherapyEmpPost({
      endPoint: "/selected/allDetails/of/TherapyEmp/status/notDeleted",
      data: myJsonServices,
    });

    setEmailPass(data);

    //onPress(items);
    setOnClick(onClick == null ? 0 : null);
  };

  return (
    <View style={styles.container}>
      <TouchView
        style={[
          styles.mainViewStyles,
          {
            backgroundColor: backgroundColor,
          },
        ]}
        onPress={() => OnPressClickHandler(items)}
        // onPress={
        //   listView ? () => setOnClick(onClick == null ? 0 : null) : onPress
        // }
      >
        <View style={styles.nameViewStyles}>
          <AppText style={styles.txtLabelStyles} label={items.name} />
          <AppText style={styles.txtLabelStyles} label={items.position_name} />
        </View>
        <View style={styles.btnViewStyles}>
          {switchEnable && (
            <Switch
              onValueChange={() => OnClickPositionStatusHandler(items)}
              value={switchBtn}
            />
          )}

          {editEnable == true ? (
            <AppIcon
              size="30%"
              touchAble
              IconType="FontAwesome5"
              name="pen"
              onPress={() => onPressEdit(items)}
              backgroundColor={backgroundColor}
            />
          ) : (
            <Text style={{ width: "30%" }}></Text>
          )}

          {deleteEnable && (
            <AppIcon
              size="30%"
              touchAble
              name="delete"
              onPress={() => onPressDelete(items)}
            />
          )}

          <TouchableOpacity onPress={() => onPressAccountActive(items)}>
            <AppText
              style={[
                styles.textStyles,
                {
                  backgroundColor:
                    items.account == "active" ? Colors.green : Colors.darkRed,
                },
              ]}
              label={items.account}
            />
          </TouchableOpacity>
        </View>
      </TouchView>

      {onClick == null ? (
        <View
          style={[
            {
              height: onClick,
              padding: onClick == 0 ? 0 : 10,
            },
            styles.listViewStyles,
          ]}
        >
          {emailPass[0].email != "" ? (
            <>
              <Text style={{ fontSize: "80%" }}>
                Email : {emailPass[0].email}
              </Text>
              <Text style={{ fontSize: "80%" }}>
                Password : {emailPass[0].password}
              </Text>
            </>
          ) : (
            <Text>Account is not Active</Text>
          )}
        </View>
      ) : (
        <></>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 5,
    width: "90%",
    alignSelf: "center",
  },
  mainViewStyles: {
    padding: 5,
    width: "100%",
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 5,
  },

  txtLabelStyles: {
    paddingHorizontal: 10,
    width: "95%",
    //fontSize: "100%",
  },
  nameViewStyles: {
    flexDirection: "row",
    width: "70%",
    alignItems: "center",
  },
  btnViewStyles: {
    flexDirection: "row",
    width: "30%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  listViewStyles: {
    width: "100%",

    backgroundColor: Colors.blue,
    borderRadius: 10,
  },
  textStyles: {
    padding: 5,
    borderRadius: 5,
    //fontSize: "60%",
  },
});

export default AppTherapistCard;
