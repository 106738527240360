import React, { useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity, Modal, Text } from "react-native";
import CalendarPicker from "react-native-calendar-picker";
import Moment from "moment";

import AppButton from "../components/AppButton";
import Colors from "../config/Colors";
import AppIcon from "./AppIcon";
import AppText from "./AppText";

function AppCalander({
  label = "label",
  selectedDate,
  dateLabel = "",
  datePlaceholder = "dd/mm/yyyy",
  width = "100%",
  ...otherProps
}) {
  const [date, setDate] = useState();
  const [open, setOpen] = useState(false);

  const changeDate = (date) => {
    selectedDate(Moment(date).utc().format("DD/MM/YYYY"));
    setDate(date);
    setOpen(false);
  };

  return (
    <View style={[styles.container, { width: width }]}>
      {open && (
        <Modal animationType="slide" visible={open}>
          <CalendarPicker
            style
            onDateChange={(date) => changeDate(date)}
            {...otherProps}
          />
          <AppButton
            style={{ alignSelf: "center" }}
            label="Back"
            onPress={() => setOpen(false)}
          />
        </Modal>
      )}
      <Text style={{ width: "90%", marginLeft: 50 }}>{label}</Text>
      <TouchableOpacity
        style={styles.btnViewCalanderStyles}
        onPress={() => setOpen(true)}
      >
        <AppIcon
          IconType="Fontisto"
          name="date"
          labelStyles="#000"
          backgroundColor="#E7E7E7"
        />
        <AppText
          fontSize={16}
          textColor={
            datePlaceholder == "dd/mm/yyyy" ? Colors.placeHolderColor : "#000"
          }
          label={
            date == null
              ? dateLabel + datePlaceholder
              : Moment(date).utc().format("DD/MM/YYYY")
          }
        />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    // justifyContent: "center",
  },
  calendarViewStyles: {
    height: "100%",
    width: "100%",
  },
  btnViewCalanderStyles: {
    width: "90%",
    alignItems: "center",
    alignSelf: "center",
    borderRadius: 25,
    height: 40,
    backgroundColor: "#E7E7E7",
    flexDirection: "row",
  },
  btnCalanderStyles: {
    width: "90%",
    alignItems: "flex-start",
    borderRadius: 25,
    height: 50,
    backgroundColor: "#E7E7E7",
  },
});

export default AppCalander;
