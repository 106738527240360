import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  Alert,
  ScrollView,
  Dimensions,
} from "react-native";
import moment from "moment";
import listingAPI from "../../../../api/listings";

import Colors from "../../../../config/Colors";
import AppProgressbar from "../../../../components/AppProgressbar";
import AppButton from "../../../../components/AppButton";
import AppAlert from "../../../../components/AppAlert";
import AppCalander from "../../../../components/AppCalander";
import AppMultiFilesPicker from "../../../../components/AppMultiFilesPicker";
import AppInputText from "../../../../components/AppInputText";
import AppPicker from "../../../../components/AppPicker";
import AppGetDocumentCard from "../../../../components/AppGetDocumentCard";
import Routes from "../../../../navigation/routes";

function NewSessionAdd({ navigation, data }) {
  const item = data;

  const [hhSession, setHHSession] = useState({ name: 0 });
  const [mmSession, setMMSession] = useState({ name: 0 });

  const [hhTravel, setHHTravel] = useState({ name: 0 });
  const [mmTravel, setMMTravel] = useState({ name: 0 });

  const [chargeHH, setChargeHH] = useState({ name: 0 });
  const [chargeMM, setChargeMM] = useState({ name: 0 });

  const [sessionDate, setSessionDate] = useState("dd/mm/yyyy");
  const [uploadedFile, setUploadedFile] = useState([]);
  const [brifTask, setbrifTask] = useState("");
  const [progressBar, setProgressBar] = useState(false);
  const [documentListShow, setDocumentListShow] = useState(false);
  const [alert, setAlert] = useState({ modal: false, alert: "" });
  const [fileUris, setFileUris] = useState([]);
  const [allDocuments, setAllDocuments] = useState([]);
  const [mainSubject, setMainSubject] = useState();

  useEffect(() => {
    GetListOfDocuments();
  }, []);

  const GetListOfDocuments = async () => {
    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
      services_main_id: item.allocate_services_main_id,
      uploadBy: "therapist",
    });
    const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
      endPoint:
        "/Select/All/documents/of/selected/participant/as/per/service/and/selected/therapist",
      data: myJsonServices,
    });

    setAllDocuments(data);
  };

  const documentUpload_cloud_Handler = async () => {
    setProgressBar(true);
    const myJsonServices = fileUris;

    for (let i = 0; i < myJsonServices.length; i++) {
      const { data } = await listingAPI.ImageTryPost({
        endPoint: "/file/Upload/Services",
        data: myJsonServices[i],
      });

      if (data == null) {
        setProgressBar(false);
        setAlert({
          modal: true,
          alert:
            "Something went wrong, File may be not supported or File size is more than 20MB.",
        });
        return;
      } else {
        documentDataHandler_inTable(data.name);
        setUploadedFile(data);
      }
    }
    setProgressBar(false);
    ClearUseStateHandler();
  };

  const documentDataHandler_inTable = async (documentName) => {
    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
      services_main_id: item.allocate_services_main_id,
      uploadPersion_main_id: item.therapist_main_id,
      uploadBy: "therapist",
      uploadDate: moment(Date.now()).format("YYYY/MM/DD"),
      documentName: documentName,
      status: "active",
    });
    const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
      endPoint: "/Insert/each/participant/documents",
      data: myJsonServices,
    });
  };

  const OnclickSubmitHandler = async () => {
    if (sessionDate == "dd/mm/yyyy") {
      setAlert({ modal: true, alert: "Please Select Session date." });
      return;
    } else if (hhSession.name == 0 && mmSession.name == 0) {
      setAlert({ modal: true, alert: "Please Add Session time." });
      return;
    } else if (hhTravel.name == 0 && mmTravel.name == 0) {
      setAlert({ modal: true, alert: "Please Add Travel time." });
      return;
    }

    let totalTravelTime = hhTravel.name * 60 + mmTravel.name;
    let totalChargeTravelTime = chargeHH.name * 60 + chargeMM.name;

    if (totalTravelTime < totalChargeTravelTime) {
      setAlert({
        modal: true,
        alert: "Charge Travel time must be less than total travel time.",
      });
      return;
    }

    setProgressBar(true);
    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
      services_main_id: item.allocate_services_main_id,
      services_CurrentPrice: item.servicesPrice,
      therapist_main_id: item.therapist_main_id,
      sessionDate: moment(sessionDate, "DD/MM/YYYY").format("YYYY/MM/DD"),
      mainSubject: mainSubject,
      totalSession_hours: hhSession.name,
      totalSession_mins: mmSession.name,
      totalTravel_hours: hhTravel.name,
      totalTravel_mins: mmTravel.name,
      chargeTravel_hours: chargeHH.name,
      chargeTravel_mins: chargeMM.name,
      briefNote: brifTask,
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint: "/Insert/Everyday/session",
      data: myJsonServices,
    });

    if (data == true) {
      setAlert({ modal: true, alert: "Session has been saved successfully." });
    } else {
      setAlert({ modal: true, alert: "Something went wrong." });
    }

    setProgressBar(false);
    ClearUseStateHandler();
  };

  const ClearUseStateHandler = () => {
    setSessionDate();
    setHHSession({ name: 0 });
    setMMSession({ name: 0 });
    setHHTravel({ name: 0 });
    setMMTravel({ name: 0 });
    setChargeHH({ name: 0 });
    setChargeMM({ name: 0 });
    setMainSubject("");
    setbrifTask("");
  };

  const OnClickDeleteDocumentHandler = async (item) => {
    const myJsonServices = { key: item.documentName };
    const { data } = await listingAPI.ImageTryDelete({
      endPoint: "/Delete/image",
      data: myJsonServices,
    });

    if (data == "File Deleted Successfully.") {
      DocumentDeleteFromTable(item);
    }
  };

  const DocumentDeleteFromTable = async (item) => {
    const myJsonServices = JSON.stringify({
      main_id: item.main_id,
      participant_main_id: item.participant_main_id,
      uploadBy: "therapist",
    });
    const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
      endPoint: "/delete/participant/document",
      data: myJsonServices,
    });

    setAllDocuments(data);
  };

  let totalHHSession = [{ name: 0 }];
  let totalMMSession = [{ name: 0 }];

  let totalHHTravel = [{ name: 0 }];
  let totalMMTravel = [{ name: 0 }];

  let totalChargeHHTravel = [{ name: 0 }];
  let totalChargeMMTravel = [{ name: 0 }];

  for (let i = 1; i <= 10; i++) {
    totalHHSession.push({ name: i });
  }
  for (let i = 1; i <= 59; i++) {
    totalMMSession.push({ name: i });
  }

  for (let i = 1; i <= 10; i++) {
    totalHHTravel.push({ name: i });
  }
  for (let i = 1; i <= 59; i++) {
    totalMMTravel.push({ name: i });
  }

  for (let i = 1; i <= 10; i++) {
    totalChargeHHTravel.push({ name: i });
  }
  for (let i = 1; i <= 59; i++) {
    totalChargeMMTravel.push({ name: i });
  }

  const OnClickBackHandler = () => {
    navigation.navigate(Routes.STACK_THERAPISTHOMESCREEN);
  };

  return (
    <View style={[styles.container]}>
      <AppProgressbar modalVisible={progressBar} />
      <AppAlert
        alertLabel=""
        alert={alert}
        okBtnPress={() => setAlert({ modal: false, alert: "" })}
      />
      {documentListShow ? (
        <View style={styles.mainViewStyles}>
          <AppMultiFilesPicker
            uploadedFiles={uploadedFile}
            getAllFiles={(files) => setFileUris(files)}
          />
          <View style={styles.btnStylesUploadDocument}>
            <AppButton
              label="Back"
              color="blue"
              onPress={() => {
                setDocumentListShow(false);
                GetListOfDocuments();
              }}
            />
            <AppButton
              label="Upload"
              onPress={() => documentUpload_cloud_Handler()}
            />
          </View>
        </View>
      ) : (
        <>
          <ScrollView>
            <View style={styles.mainViewStyles}>
              <View style={styles.titleStyles}>
                <Text style={{ fontSize: 25, fontWeight: "bold" }}>
                  Today : {moment(Date.now()).format("DD/MM/YYYY")}
                </Text>
              </View>
              <View style={styles.dataViewStyles}>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <AppCalander
                    width="30%"
                    label="Session Date : "
                    datePlaceholder={sessionDate}
                    selectedDate={(date) => setSessionDate(date)}
                    maxDate={new Date()}
                  />
                  <AppInputText
                    label="Subject : "
                    iconName="note"
                    placeholder="Subject : "
                    width="30%"
                    value={mainSubject ?? ""}
                    onChangeText={(txt) => setMainSubject(txt)}
                  />
                </View>
                <View style={styles.sessionTimeSelection}>
                  <Text style={styles.txtLabelStyles}>Session Time</Text>
                  <View style={styles.timeViewStyles}>
                    <AppPicker
                      label="HH"
                      width="40%"
                      items={totalHHSession}
                      selectedItem={hhSession}
                      onSelectItem={(item) => setHHSession(item)}
                    />
                    <AppPicker
                      label="MM"
                      width="40%"
                      items={totalMMSession}
                      selectedItem={mmSession}
                      onSelectItem={(item) => setMMSession(item)}
                    />
                  </View>
                </View>

                <View style={styles.sessionTimeSelection}>
                  <Text style={styles.txtLabelStyles}>Total Travel Time</Text>
                  <View style={styles.timeViewStyles}>
                    <AppPicker
                      label="HH"
                      width="40%"
                      items={totalHHTravel}
                      selectedItem={hhTravel}
                      onSelectItem={(item) => setHHTravel(item)}
                    />
                    <AppPicker
                      label="MM"
                      width="40%"
                      items={totalMMTravel}
                      selectedItem={mmTravel}
                      onSelectItem={(item) => setMMTravel(item)}
                    />
                  </View>
                </View>

                <View style={styles.sessionTimeSelection}>
                  <Text style={styles.txtLabelStyles}>Charge Travel Time</Text>
                  <View style={styles.timeViewStyles}>
                    <AppPicker
                      label="HH"
                      width="40%"
                      items={totalChargeHHTravel}
                      selectedItem={chargeHH}
                      onSelectItem={(item) => setChargeHH(item)}
                    />
                    <AppPicker
                      label="MM"
                      width="40%"
                      items={totalChargeMMTravel}
                      selectedItem={chargeMM}
                      onSelectItem={(item) => setChargeMM(item)}
                    />
                  </View>
                </View>
                <AppInputText
                  iconName="note"
                  placeholder="Brief Notes : "
                  multiline
                  height={200}
                  width="100%"
                  value={brifTask ?? ""}
                  onChangeText={(txt) => setbrifTask(txt)}
                />
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      alignSelf: "flex-end",
                    }}
                  >
                    Document List
                  </Text>
                  <AppButton
                    style={styles.btnUploasDocumentStyles}
                    label="Upload Documents"
                    color="pink"
                    width="20%"
                    onPress={() => setDocumentListShow(true)}
                  />
                </View>
                <View style={styles.documentListViewStyles}>
                  {allDocuments.length <= 0 ? (
                    <Text style={{ alignSelf: "center", fontSize: 25 }}>
                      There is no documents.
                    </Text>
                  ) : (
                    <AppGetDocumentCard
                      borderWidth={0}
                      height="100%"
                      items={allDocuments}
                      deleteEnable
                      OnClickDeleteDocumentHandler={(data) =>
                        OnClickDeleteDocumentHandler(data)
                      }
                    />
                  )}
                </View>
              </View>
            </View>
          </ScrollView>
          <View style={styles.btnViewStyles}>
            <AppButton
              style={styles.btnBackStyles}
              label="Back"
              color="blue"
              onPress={() => OnClickBackHandler()}
            />
            <AppButton
              style={styles.btnBackStyles}
              label="Submit"
              onPress={() => OnclickSubmitHandler()}
            />
          </View>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingVertical: 10,
  },
  btnUploasDocumentStyles: {
    alignSelf: "center",
    marginVertical: 20,
  },
  mainViewStyles: {
    height: "100%",
    width: "100%",
  },
  titleStyles: {
    height: "5%",
    alignItems: "center",
    width: "100%",
  },
  btnStylesUploadDocument: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    position: "absolute",
    bottom: 5,
    width: "100%",
  },
  dataViewStyles: {
    width: "90%",
    height: "80%",
    alignSelf: "center",
  },
  timeViewStyles: {
    marginTop: 10,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    alignSelf: "center",
  },
  sessionTimeSelection: {
    width: "100%",
    marginVertical: 10,
    alignSelf: "center",
    borderWidth: 1,
    padding: 5,
  },
  uploadFileViewStyles: {
    height: "70%",
    marginTop: 20,
  },
  txtLabelStyles: {
    alignSelf: "center",
    fontSize: 25,
    fontWeight: "bold",
  },
  btnViewStyles: {
    //bottom: 5,
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    //position: "absolute",
    alignSelf: "center",
  },
  btnStyles: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around",
  },
  documentListViewStyles: {
    height: "30%",
    marginTop: 20,
    borderWidth: 1,
  },
  fileListViewStyles: {
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "space-between",
    borderRadius: 25,
    paddingHorizontal: 20,
    margin: 5,
    flexDirection: "row",
    width: "70%",
    backgroundColor: Colors.InputText,
  },
});

export default NewSessionAdd;
