import React from "react";
import { View, StyleSheet, Text } from "react-native";
import Modal from "react-native-modal";

import AppButton from "./AppButton";

function AppAlertYesNo({
  isModalVisible,
  Alert = "Alert label : ",
  noBtnPress,
  yesBtnPress,
  noBtnEnable = true,
  yesBtnEnable = true,
  data,
  children,
  childrenViewStyles,
  mainViewStyles,
  leftBtnLabel = "No",
  rightBtnLabel = "Yes",
}) {
  return (
    <Modal style={{ alignItems: "center" }} isVisible={isModalVisible}>
      <View style={[styles.container, mainViewStyles]}>
        <View style={styles.labelViewStyles}>
          <Text style={{ fontSize: 35 }}>{Alert}</Text>
        </View>
        <View style={[styles.childViewStyles]}>{children}</View>
        <View style={styles.btnView}>
          {noBtnEnable && (
            <AppButton label={leftBtnLabel} color="pink" onPress={noBtnPress} />
          )}
          {yesBtnEnable && (
            <AppButton
              label={rightBtnLabel}
              color="blue"
              onPress={yesBtnPress}
            />
          )}
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "60%",
    alignItems: "center",
    backgroundColor: "#fff",
    paddingVertical: 5,
    borderRadius: 20,
  },
  btnView: {
    justifyContent: "space-around",
    alignItems: "center",
    width: "80%",
    height: "10%",
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  childViewStyles: {
    height: "80%",
    width: "95%",
    alignSelf: "center",
    alignItems: "center",
  },
  labelViewStyles: {
    backgroundColor: "#fff",
    width: "60%",
    height: "10%",
    alignItems: "center",
  },
});

export default AppAlertYesNo;
