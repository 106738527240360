import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  Platform,
  useWindowDimensions,
} from "react-native";

import listingAPI from "../../../../api/listings";

import AppPicker from "../../../../components/AppPicker";
import AppButton from "../../../../components/AppButton";
import AppInputText from "../../../../components/AppInputText";
import AppServicesCard from "../../../../components/cards/AppServicesCard";
import AppAlertYesNo from "../../../../components/AppAlertYesNo";
import AppAlert from "../../../../components/AppAlert";
import Cache from "../../../../utility/cache";

function MainServicesScreen({}) {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [popItems, setPopItems] = useState([]);
  const [modalVisable, setModalVisable] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [updateBtn, setupdateBtn] = useState(false);
  const [main_idUpdate, setMain_idUpdate] = useState();
  const [allDepartment, setAllDepartment] = useState([]);
  const [alert, setAlert] = useState({ modal: false, alert: "label" });
  const [selectDepartmentName, setSelectDepartmentName] = useState({
    name: "",
    departmentId: 0,
  });

  let cardLabel = {
    name: "Name",
    price: "Price",
  };

  // const { height, width } = useWindowDimensions();

  // let windowHeight = width + height;

  useEffect(() => {
    GelAllServicesData();
  }, []);

  useEffect(() => {
    GetAllTherapyDepartment();
  }, []);

  const GetAllTherapyDepartment = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");
    const myJsonServices = JSON.stringify({
      therapist_main_id: therapist_main_id,
    });
    const { data } = await listingAPI.TherapyDepartment({
      endPoint: "/Select/All/therapyDepartment/of/selected/admin",
      data: myJsonServices,
    });

    setAllDepartment(data);
  };

  const StatusChangedHandler = async (item) => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");
    const myJsonServices = JSON.stringify({
      status: item.status == "active" ? "inactive" : "active",
      main_id: item.main_id,
      therapist_main_id: therapist_main_id,
    });

    const { data } = await listingAPI.Services({
      endPoint: "/update/selected/service/status",
      data: myJsonServices,
    });

    setAllServices(data);
    // setModalVisable(false);
  };

  const GelAllServicesData = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");
    const myJsonServices = JSON.stringify({
      therapist_main_id: therapist_main_id,
    });

    const { data } = await listingAPI.Services({
      endPoint: "/select/allServices/status/notDeleted",
      data: myJsonServices,
    });
    setAllServices(data);
  };

  const onPressDeleteHandler = async (item) => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      main_id: popItems.main_id,
      therapist_main_id: therapist_main_id,
    });

    const { data } = await listingAPI.Services({
      endPoint: "/delete/selected/service",
      data: myJsonServices,
    });

    setAllServices(data);
    setModalVisable(false);
  };

  const onClickPopUpHandler = (item) => {
    setPopItems(item);
    setModalVisable(true);
  };

  const OnClickAddServiceHandler = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    if (selectDepartmentName.departmentId == 0) {
      setAlert({ modal: true, alert: "Please Select Your Department." });
      return;
    } else if (name == "") {
      setAlert({ modal: true, alert: "Please add service name" });
      return;
    } else if (price == "") {
      setAlert({ modal: true, alert: "Please add Price" });
      return;
    }

    if (updateBtn) {
      const myJsonServices = JSON.stringify({
        name: name.toUpperCase(),
        price: price,
        departmentId: selectDepartmentName.departmentId,
        therapist_main_id: therapist_main_id,
        main_id: main_idUpdate,
      });

      const { data } = await listingAPI.Services({
        endPoint: "/update/selected/service",
        data: myJsonServices,
      });
      if (data == "duplicate") {
        setAlert({ modal: true, alert: "Service is already exist." });
      } else {
        setAllServices(data);
      }
    } else {
      const myJsonServices = JSON.stringify({
        name: name.toUpperCase(),
        price: price,
        departmentId: selectDepartmentName.departmentId,
        therapist_main_id: therapist_main_id,
      });

      const { data } = await listingAPI.Services({
        endPoint: "/insert/Services",
        data: myJsonServices,
      });

      if (data == "duplicate") {
        setAlert({ modal: true, alert: "Service is already exist." });
      } else {
        setAllServices(data);
      }
    }
    ClearFunction();
  };

  const ClearFunction = () => {
    setName();
    setPrice();
    setMain_idUpdate();
    setupdateBtn(false);
  };

  const OnclickEditHandler = async (item) => {
    setName(item.name);
    setPrice(item.price);
    setMain_idUpdate(item.main_id);
    setSelectDepartmentName({
      name: item.name,
      departmentId: item.departmentId,
    });
    setupdateBtn(true);
  };

  const OnClickCancelHandler = () => {
    setName();
    setPrice();
  };

  return (
    <View style={[styles.container]}>
      <AppAlert
        alertLabel="Please Fill all Field"
        okBtnPress={() => setAlert({ modal: false, alert: "Please add Price" })}
        alert={alert}
      />

      <AppAlertYesNo
        mainViewStyles={{ height: "50%" }}
        Alert="Are you sure ? do you want to delete it ?"
        isModalVisible={modalVisable}
        noBtnPress={() => setModalVisable(false)}
        yesBtnPress={() => onPressDeleteHandler()}
      />
      <View style={styles.TopViewStyles}>
        <View style={styles.servicesAddViewStyles}>
          <AppPicker
            label="Select Department : "
            width="30%"
            items={allDepartment}
            selectedItem={selectDepartmentName}
            onSelectItem={(item) =>
              setSelectDepartmentName({
                name: item.name,
                departmentId: item.main_id,
              })
            }
          />

          <AppInputText
            label="Service Name"
            width="30%"
            placeholder="Service Name : "
            iconName="yoga"
            value={name ?? ""}
            onChangeText={(txt) => setName(txt)}
          />
          <AppInputText
            label="Service Price"
            width="30%"
            placeholder="Price per hours : "
            iconName="currency-usd"
            keyboardType="numeric"
            pattern="[0-9]*"
            value={price ?? ""}
            onChangeText={(txt) =>
              setPrice(
                txt.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
              )
            }
          />
        </View>
        <View style={styles.btnViewStyles}>
          <AppButton
            label="Cancel"
            color="pink"
            onPress={() => OnClickCancelHandler()}
          />
          <AppButton
            label={updateBtn ? "Update" : "Submit"}
            color="blue"
            onPress={() => OnClickAddServiceHandler()}
          />
        </View>
      </View>
      <View style={styles.BottomViewStyles}>
        {allServices.length == 0 ? (
          <View style={styles.noDataViewStyles}>
            <Text style={{ fontSize: 30 }}>There is no data.</Text>
          </View>
        ) : (
          <>
            <AppServicesCard
              txtBold
              items={cardLabel}
              backgroundColor="#fff"
              editEnable={false}
              deleteEnable={false}
              switchEnable={false}
            />
            <FlatList
              data={allServices}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item }) => (
                <AppServicesCard
                  backgroundColor="#DBD7DC"
                  items={item}
                  onPressDelete={() => onClickPopUpHandler(item)}
                  onPressEdit={() => OnclickEditHandler(item)}
                  //switchBtnChange={(txt) => console.log(txt)}
                  switchBtnChange={(txt) => StatusChangedHandler(txt)}
                />
              )}
            />
          </>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    alignItems: "center",
  },
  TopViewStyles: {
    width: "100%",
    height: "20%",
    alignItems: "center",
    borderBottomWidth: 1,
    paddingVertical: 10,
  },
  BottomViewStyles: {
    width: "99%",
    height: "80%",
    backgroundColor: "#fff",
    alignSelf: "center",
    borderRadius: 10,
  },
  servicesAddViewStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    marginVertical: 5,
  },
  noDataViewStyles: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  btnViewStyles: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
});

export default MainServicesScreen;
