import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";

import listingAPI from "../../../../api/listings";
import AppInputText from "../../../../components/AppInputText";
import AppButton from "../../../../components/AppButton";
import AppFormsCard from "../../../../components/cards/AppFormsCard";
import AppAlertYesNo from "../../../../components/AppAlertYesNo";
import AppAlert from "../../../../components/AppAlert";

function AddFormsHandler({}) {
  const [formName, setFormName] = useState();
  const [link, setLink] = useState();
  const [allForms, setAllForms] = useState();
  const [alert, setAlert] = useState({ modal: false, alert: "" });
  const [formTestModal, setFormTestModal] = useState(false);

  useEffect(() => {
    GetAllForms();
  }, []);

  const GetAllForms = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.FormHandlerPost({
      endPoint: "/select/AllForms/notDeleted",
      data: myJsonServices,
    });
    setAllForms(data);
  };

  const onClickAddFormHandler = () => {
    if (!formName) {
      setAlert({ modal: true, alert: "Please, Add form name." });
      return;
    } else if (!link) {
      setAlert({ modal: true, alert: "Please, Add form link." });
      return;
    }
    setFormTestModal(true);
  };

  const OnClickYesHandler = async () => {
    const myJsonServices = JSON.stringify({
      name: formName && formName.toUpperCase(),
      link: link,
    });

    const { data } = await listingAPI.FormHandlerPost({
      endPoint: "/Insert/Forms",
      data: myJsonServices,
    });
    setFormTestModal(false);
    setAllForms(data);

    setFormName("");
    setLink("");
  };

  const OnClickPositionStatusHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      status: item.status == "active" ? "inactive" : "active",
      main_id: item.main_id,
    });

    const { data } = await listingAPI.FormHandlerPost({
      endPoint: "/update/selected/form/status",
      data: myJsonServices,
    });

    setAllForms(data);
  };

  const OnClickDeleteHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      main_id: item.main_id,
    });

    const { data } = await listingAPI.FormHandlerPost({
      endPoint: "/delete/selected/form/status",
      data: myJsonServices,
    });

    setAllForms(data);
  };

  return (
    <View style={styles.container}>
      <AppAlert alert={alert} okBtnPress={() => setAlert({ modal: false })} />
      <AppAlertYesNo
        Alert="Form"
        isModalVisible={formTestModal}
        mainViewStyles={{ height: "100%", width: "100%" }}
        noBtnPress={() => setFormTestModal(false)}
        yesBtnPress={() => OnClickYesHandler()}
      >
        <iframe style={{ height: "100%", width: "100%" }} src={link}></iframe>
      </AppAlertYesNo>
      <View style={styles.AddFormsViewStyles}>
        <AppInputText
          width="20%"
          iconName="file-edit"
          placeholder="Form's Name"
          value={formName ?? ""}
          onChangeText={(txt) => setFormName(txt)}
        />
        <AppInputText
          width="40%"
          iconName="link-variant"
          placeholder="Link"
          value={link ?? ""}
          onChangeText={(txt) => setLink(txt)}
        />
        <AppButton
          width="20%"
          label="Add Form"
          onPress={() => onClickAddFormHandler()}
        />
      </View>
      <View style={styles.bottomViewStyles}>
        <AppFormsCard
          btnEnable
          Items={allForms}
          switchBtnChange={(item) => OnClickPositionStatusHandler(item)}
          OnClickDeleteHandler={(item) => OnClickDeleteHandler(item)}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 10,
    alignItems: "center",
  },
  AddFormsViewStyles: {
    height: "5%",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottomWidth: 1,
  },
  bottomViewStyles: {
    width: "100%",
    height: "95%",
    overflow: "hidden",
  },
});

export default AddFormsHandler;
