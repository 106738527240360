import React from "react";
import { Text, StyleSheet, useWindowDimensions } from "react-native";

function AppText({
  label,
  fontSize = 20,
  textColor = "#000",
  style,
  ...otherProps
}) {
  const { height: h, width: w } = useWindowDimensions();
  let windowHeight = h + w;

  return (
    <Text
      style={[
        { fontSize: `${windowHeight / 30}%` },
        { color: textColor },
        style,
      ]}
      {...otherProps}
    >
      {label}
    </Text>
  );
}

export default AppText;
