import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text } from "react-native";

import listingAPI from "../../../../api/listings";
import AppInputText from "../../../../components/AppInputText";
import AppAlert from "../../../../components/AppAlert";
import AppAlertYesNo from "../../../../components/AppAlertYesNo";
import AppButton from "../../../../components/AppButton";
import AppOfficeCard from "../../../../components/cards/AppOfficeCard";
import AppTouchableLabel from "../../../../components/AppTouchableLabel";

function RegisterOffice({}) {
  const [addNewOfficeModal, setAddNewOfficeModal] = useState(false);
  const [assignDepartment, setAssignDepartment] = useState({
    modal: false,
    item: "",
  });
  const [getAllOffices, setGetAllOffices] = useState(false);
  const [officeName, setOfficeName] = useState();
  const [officeContact, setOfficeContact] = useState();
  const [officeAddress, setOfficeAddress] = useState();
  const [officeIpAddress, setOfficeIpAddress] = useState();
  const [main_idForUpdate, setMain_idForUpdate] = useState();
  const [updateInsert, setUpdateInsert] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState();
  const [deleteAllocateDepartment, setDeleteAllocateDepartment] = useState({
    modal: false,
    item: "",
  });
  const [getAll_AllocatedDepartment, setGetAll_AllocatedDepartment] =
    useState();
  const [selectedDepartment, setSelectedDepartment] = useState();

  const [getAllDepartment, setGetAllDepartment] = useState();
  const [getAll_AllocateDepartment, setGetAll_AllocateDepartment] = useState();
  const [alert, setAlert] = useState({ modal: false, alert: "" });
  const [deleteOfficeModal, setDeleteOfficeModal] = useState({
    modal: false,
    main_id: 0,
  });

  useEffect(() => {
    GetAll_AllocateDepartmentName();
    GetAllOfficeName();
    GetAll_ActiveDepartmentName();
  }, []);

  const GetAll_ActiveDepartmentName = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.DepartmentHandler_POST({
      endPoint: "/Select/All/department/active",
      data: myJsonServices,
    });

    setGetAllDepartment(data);
  };

  const GetAll_AllocateDepartmentName = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.AllocateDepartmentHandler_POST({
      endPoint: "/Select/All/Alocated/Department/not/deleted",
      data: myJsonServices,
    });

    setGetAll_AllocateDepartment(data);
  };

  const GetAllOfficeName = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.OfficesHandler_POST({
      endPoint: "/Select/All/offices/not/deleted",
      data: myJsonServices,
    });

    setGetAllOffices(data);
  };

  const AddOfficeHandler = async () => {
    if (!officeName) {
      setAlert({ modal: true, alert: "Please, Add office name" });
      return;
    } else if (!officeContact) {
      setAlert({ modal: true, alert: "Please, Add office contact" });
      return;
    } else if (!officeAddress) {
      setAlert({ modal: true, alert: "Please, Add office address" });
      return;
    } else if (!officeIpAddress) {
      setAlert({ modal: true, alert: "Please, Add office IPAddress" });
      return;
    }

    if (!updateInsert) {
      const myJsonServices = JSON.stringify({
        name: officeName ? officeName.toUpperCase().trim() : "",
        contact: officeContact,
        officeAddress: officeAddress,
        ipAddress: officeIpAddress,
      });
      const { data } = await listingAPI.OfficesHandler_POST({
        endPoint: "/Insert/office",
        data: myJsonServices,
      });

      if (data == "duplicate") {
        setAlert({
          modal: true,
          alert: "This Office is already register.",
        });
        return;
      }

      setGetAllOffices(data);
      setAddNewOfficeModal(false);
      setUpdateInsert(false);
      setOfficeName();
      setOfficeAddress();
      setOfficeContact();
      setOfficeIpAddress();
    } else if (updateInsert) {
      const myJsonServices = JSON.stringify({
        name: officeName ? officeName.toUpperCase().trim() : "",
        contact: officeContact,
        officeAddress: officeAddress,
        ipAddress: officeIpAddress,
        main_id: main_idForUpdate,
      });
      const { data } = await listingAPI.OfficesHandler_POST({
        endPoint: "/update/selected/office/details",
        data: myJsonServices,
      });

      if (data == "duplicate") {
        setAlert({
          modal: true,
          alert: "This Office is already register.",
        });
        return;
      }

      setGetAllOffices(data);
      setAddNewOfficeModal(false);
      setUpdateInsert(false);
      setOfficeName();
      setOfficeAddress();
      setOfficeContact();
      setOfficeIpAddress();
      setMain_idForUpdate();
    }
  };

  const onPressEditHandler = (item) => {
    setMain_idForUpdate(item.main_id);
    setOfficeName(item.name);
    setOfficeContact(item.contact);
    setOfficeAddress(item.officeAddress);
    setOfficeIpAddress(item.ipAddress);
    setUpdateInsert(true);
    setAddNewOfficeModal(true);
  };

  const onPressConformAllocateDepartment = (item) => {
    setAssignDepartment({ modal: true, item: item });
  };

  const onPressConformHandler = async (item) => {
    setDeleteOfficeModal({ modal: true, main_id: item.main_id });
  };

  const onPressDeleteHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      main_id: item.main_id,
    });
    const { data } = await listingAPI.OfficesHandler_POST({
      endPoint: "/delete/selected/office",
      data: myJsonServices,
    });

    setGetAllOffices(data);
    setDeleteOfficeModal({ modal: false, main_id: 0 });
  };

  const OnClickPositionStatusHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      status: item.status == "active" ? "inactive" : "active",
      main_id: item.main_id,
    });
    const { data } = await listingAPI.OfficesHandler_POST({
      endPoint: "/update/selected/office/status",
      data: myJsonServices,
    });
    setGetAllOffices(data);
  };

  const OnClickAllocateDepartHandler = async () => {
    const myJsonServices = JSON.stringify({
      OfficeList_main_id: assignDepartment.item.main_id,
      departments_main_id: selectedDepartment.main_id,
    });
    const { data } = await listingAPI.AllocateDepartmentHandler_POST({
      endPoint: "/Allocate/Department",
      data: myJsonServices,
    });

    if (data == "duplicate") {
      setAlert({
        modal: true,
        alert: `${selectedDepartment.name} department is already assign to ${assignDepartment.item.name}`,
      });
      return;
    } else {
      setGetAll_AllocatedDepartment(data);
      setSelectedDepartment();
      setAssignDepartment({ modal: false, item: "" });
      setAlert({
        modal: true,
        alert: `${selectedDepartment.name} department is successfully assign to ${assignDepartment.item.name}`,
      });
    }
  };

  const DepartmentStatusHandler = async (items) => {
    const myJsonServices = JSON.stringify({
      main_id: items.main_id,
      departments_main_id: items.departments_main_id,
      status: items.status == "active" ? "inactive" : "active",
    });
    const { data } = await listingAPI.AllocateDepartmentHandler_POST({
      endPoint: "/update/selected/AllocateDepartments/status",
      data: myJsonServices,
    });
    if (data == "not allow") {
      setAlert({
        modal: true,
        alert: "It's looks like, You are not allow to active.",
      });
    } else {
      setGetAll_AllocateDepartment(data);
    }
  };

  const DeleteAllocatedDepartment_ConformHandler = async (items) => {
    setDeleteAllocateDepartment({ modal: true, item: items });
  };

  const DeleteAllocatedDepartment = async (items) => {
    const myJsonServices = JSON.stringify({
      main_id: items.main_id,
    });
    const { data } = await listingAPI.AllocateDepartmentHandler_POST({
      endPoint: "/delete/selected/AllocateDepartments/status",
      data: myJsonServices,
    });

    setGetAll_AllocateDepartment(data);
    setDeleteAllocateDepartment({ modal: false, main_id: 0 });
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
        <AppButton
          width="20%"
          color="blue"
          label="Add New Office"
          onPress={() => setAddNewOfficeModal(true)}
        />
        <AppButton
          width="20%"
          label="Refresh"
          onPress={() => {
            GetAll_AllocateDepartmentName();
            GetAllOfficeName();
            GetAll_ActiveDepartmentName();
          }}
        />
      </View>

      <AppAlertYesNo
        isModalVisible={deleteOfficeModal.modal}
        mainViewStyles={{ height: "20%" }}
        Alert="Are you sure, Do you want to delete this office ?"
        noBtnPress={() => setDeleteOfficeModal({ modal: false, main_id: 0 })}
        yesBtnPress={() => onPressDeleteHandler(deleteOfficeModal)}
      />

      <AppAlertYesNo
        isModalVisible={deleteAllocateDepartment.modal}
        mainViewStyles={{ height: "20%" }}
        Alert="Are you sure, Do you want to delete ?"
        noBtnPress={() =>
          setDeleteAllocateDepartment({ modal: false, main_id: 0 })
        }
        yesBtnPress={() =>
          DeleteAllocatedDepartment(deleteAllocateDepartment.item)
        }
      />

      <AppAlertYesNo
        Alert="Add New office"
        mainViewStyles={styles.addnewOfficeView}
        isModalVisible={addNewOfficeModal}
        leftBtnLabel="Cancel"
        rightBtnLabel="Add"
        noBtnPress={() => {
          setAddNewOfficeModal(false);
          setOfficeName();
          setOfficeAddress();
          setOfficeContact();
          setOfficeIpAddress();
        }}
        yesBtnPress={() => AddOfficeHandler()}
      >
        <View style={styles.viewStyles}>
          <View
            style={{ flexDirection: "row", justifyContent: "space-around" }}
          >
            <AppInputText
              width="30%"
              iconName="office-building"
              placeholder="Office Name : "
              value={officeName ?? ""}
              onChangeText={(txt) => setOfficeName(txt)}
            />
            <AppInputText
              width="30%"
              iconName="cellphone-basic"
              placeholder="Contact No : "
              pattern="[0-9]*"
              value={officeContact ?? ""}
              onChangeText={(txt) =>
                setOfficeContact(txt.replace(/[^0-9]/g, ""))
              }
            />
          </View>
          <AppInputText
            IconType="Entypo"
            height={100}
            multiline
            iconName="location-pin"
            placeholder="Addess : "
            value={officeAddress ?? ""}
            onChangeText={(txt) => setOfficeAddress(txt)}
          />
          <AppInputText
            iconName="balloon"
            placeholder="Please add Office IP : "
            value={officeIpAddress ?? ""}
            onChangeText={(txt) =>
              setOfficeIpAddress(txt.replace(/[^0-9.]/, ""))
            }
          />
        </View>
      </AppAlertYesNo>

      <AppAlertYesNo
        Alert="Assign Department"
        mainViewStyles={styles.addnewOfficeView}
        isModalVisible={assignDepartment.modal}
        leftBtnLabel="Cancel"
        rightBtnLabel="Submit"
        noBtnPress={() => {
          setAssignDepartment({ modal: false, item: "" });
        }}
        yesBtnPress={() => OnClickAllocateDepartHandler()}
      >
        <View style={styles.allocateDepartmentView}>
          <Text style={{ fontSize: 35, fontWeight: "bold", marginBottom: 20 }}>
            {assignDepartment.item.name}
          </Text>
          <AppTouchableLabel
            width="50%"
            items={getAllDepartment}
            label="Select Department : "
            iconName="office-building-outline"
            menuLabel={"Select Department"}
            selectedMenu={(item) => setSelectedDepartment(item)}
          />
        </View>
      </AppAlertYesNo>

      <AppAlert
        alert={alert}
        okBtnPress={() => setAlert({ modal: false, alert: "" })}
      />

      <View style={styles.bottomViewStyles}>
        <AppOfficeCard
          items={getAllOffices}
          subItem={getAll_AllocateDepartment}
          allocate
          onPressAllocate={(item) => onPressConformAllocateDepartment(item)}
          onPressEdit={(item) => onPressEditHandler(item)}
          onPressDelete={(item) => onPressConformHandler(item)}
          departmentStatusHandler={(item) => DepartmentStatusHandler(item)}
          onPressDeleteAllocatedDepartment={(item) =>
            DeleteAllocatedDepartment_ConformHandler(item)
          }
          OnClickPositionStatusHandler={(item) =>
            OnClickPositionStatusHandler(item)
          }
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    padding: 20,
  },
  addnewOfficeView: {
    height: "50%",
    width: "70%",
    alignSelf: "center",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  viewStyles: {
    marginTop: 20,
    width: "100%",
    alignSelf: "center",
  },
  allocateDepartmentView: {
    width: "100%",
    height: "100%",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  bottomViewStyles: {
    height: "95%",
    width: "100%",
  },
});

export default RegisterOffice;
