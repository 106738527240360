import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text, FlatList } from "react-native";

import AppPicker from "../../../../components/AppPicker";
import AppButton from "../../../../components/AppButton";

import listingAPI from "../../../../api/listings";
import Colors from "../../../../config/Colors";
import AppInputText from "../../../../components/AppInputText";
import AppAlertYesNo from "../../../../components/AppAlertYesNo";
import AppAlert from "../../../../components/AppAlert";
import AppEmpCard from "../../../../components/AppEmpCard";
import AppTherapistCard from "../../../../components/AppTherapistCard";
import AppText from "../../../../components/AppText";

function TherapistListScreen({}) {
  const [alertDetails, setAlertDetails] = useState({
    modal: false,
    alert: "",
  });
  const [therapistList, setTherapistList] = useState([]);
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [dataForUpdate, setDataForUpdate] = useState();
  const [btnDesider, setBtnDesider] = useState("");
  const [therapistName, setTherapistName] = useState();
  const [therapistMain_id, setTherapistMain_id] = useState();
  const [therapistEmailPass, setTherapistEmailPass] = useState([]);

  const labelItem = {
    name: "Therapist name",
    position_name: "Service name",
  };

  useEffect(() => {
    GetAllTherapistList();
  }, []);

  const GetAllTherapistList = async () => {
    const { data } = await listingAPI.TherapyTeamGet({
      endPoint: "/Select/All/Therapy/Team",
    });

    setTherapistList(data);
  };

  const OnclickEditHandler = ({ items, emailpass }) => {
    setBtnDesider("Update");
    setTherapistMain_id(items.main_id);
    setTherapistName(items.name);
    setEmail(emailpass[0].email);
    setPassword(emailpass[0].password);
    setDataForUpdate(items);
    setModal(true);
  };

  const OnClickUpdateHandler = async () => {
    if (!email) {
      setAlertDetails({
        modal: true,
        alert: `Please add email.`,
      });
      return;
    } else if (!password) {
      setAlertDetails({
        modal: true,
        alert: `Please add password.`,
      });
      return;
    }

    if (btnDesider == "Active") {
      const myJsonServices = JSON.stringify({
        therapist_id: therapistMain_id,
        department_main_id: 3,
        name: therapistName,
        email: email.trim().toLowerCase(),
        password: password,
      });

      const { data } = await listingAPI.TherapyEmpPost({
        endPoint: "/insert/TherapyEmp",
        data: myJsonServices,
      });

      if (data == "dublicate") {
        setAlertDetails({
          modal: true,
          alert: "This email has used for another account, You can't use it.",
        });
      } else {
        setAlertDetails({
          modal: true,
          alert: "This account has been successfully active.",
        });
      }

      setModal(false);
      setEmail();
      setPassword();
      GetAllTherapistList();

      if (data == "inserted") {
        ActionActivation();
      }
    } else if (btnDesider == "Update") {
      const myJsonServices = JSON.stringify({
        name: therapistName,
        email: email.trim().toLowerCase(),
        password: password,
        therapist_id: therapistMain_id,
      });

      const { data } = await listingAPI.TherapyEmpPost({
        endPoint: "/update/TherapyEmp",
        data: myJsonServices,
      });

      if (data == "dublicate") {
        setAlertDetails({
          modal: true,
          alert: "This email has used for another account, You can't use it.",
        });
      } else if (data == "not active") {
        setAlertDetails({
          modal: true,
          alert:
            "Your update has been declined. Please active this account first.",
        });
      } else {
        setAlertDetails({
          modal: true,
          alert: "Your update has successfully done.",
        });
      }

      setModal(false);
      setEmail();
      setPassword();
      GetAllTherapistList();
    }
  };

  const ActionActivation = async () => {
    const myJsonServices = JSON.stringify({
      main_id: therapistMain_id,
    });

    const { data } = await listingAPI.TherapyTeamPost({
      endPoint: "/active/selected/therapist/account",
      data: myJsonServices,
    });

    setTherapistList(data);
  };

  const OnClickListViewHandler = async ({ items, edit }) => {
    const myJsonServices = JSON.stringify({
      therapist_id: items.main_id,
    });

    const { data } = await listingAPI.TherapyEmpPost({
      endPoint: "/selected/allDetails/of/TherapyEmp/status/notDeleted",
      data: myJsonServices,
    });

    setTherapistEmailPass(data);

    if (edit == true) {
      OnclickEditHandler({ items: items, emailpass: data });
    }
  };

  const AccountActiveHandler = (items) => {
    setTherapistMain_id(items.main_id);
    setTherapistName(items.name);
    setBtnDesider("Active");
    setModal(true);
  };

  return (
    <View style={styles.container}>
      <AppAlertYesNo
        mainViewStyles={{ height: "50%" }}
        isModalVisible={modal}
        leftBtnLabel="Cancel"
        noBtnPress={() => setModal(false)}
        yesBtnPress={() => OnClickUpdateHandler()}
        rightBtnLabel={btnDesider}
        Alert={`${btnDesider} Credentials`}
      >
        <AppText
          style={{
            alignSelf: "center",
            fontSize: "100%",
          }}
          label={therapistName}
        />

        <AppInputText
          width="50%"
          label="Email"
          value={email ?? ""}
          onChangeText={(txt) => setEmail(txt)}
        />
        <AppInputText
          width="50%"
          label="Password"
          iconName="lock"
          placeholder="Password : "
          touchAble
          value={password ?? ""}
          onChangeText={(txt) => setPassword(txt)}
          maxLength={20}
        />
      </AppAlertYesNo>
      <AppAlert
        alert={alertDetails}
        okBtnPress={() => setAlertDetails({ modal: false })}
      />
      <View style={styles.BottomViewStyles}>
        <AppEmpCard
          txtBold
          iconEnable={false}
          backgroundColor={Colors.white}
          items={labelItem}
          deleteEnable={false}
          switchEnable={false}
          editEnable={false}
        />

        <FlatList
          data={therapistList}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <AppTherapistCard
              listView
              items={item}
              onPressActivation
              backgroundColor={Colors.lightDark}
              deleteEnable={false}
              switchEnable={false}
              editEnable={item.account == "active" ? true : false}
              therapistEmailPass={therapistEmailPass}
              // onPress={(item) =>
              //   OnClickListViewHandler({ items: item, edit: false })
              // }
              onPressEdit={(item) => {
                OnClickListViewHandler({ items: item, edit: true });
              }}
              onPressAccountActive={(item) => AccountActiveHandler(item)}
            />
          )}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    paddingVertical: 10,
  },
  mainViewStyles: {
    width: "100%",
    alignItems: "center",
    borderBottomWidth: 1,
  },
  therapistMainViewStyles: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    marginBottom: 10,
  },
  BottomViewStyles: {
    width: "95%",
    height: "100%",
    borderRadius: 10,
    alignSelf: "center",
    backgroundColor: "#fff",
  },
});

export default TherapistListScreen;
