import React from "react";

import { createStackNavigator } from "@react-navigation/stack";

import DepartmentDeciderScreen from "../../screens/departments/DepartmentDeciderScreen";
import FormsRootScreen from "../../screens/FormsDepartment/FormsRootScreen";
import FormsLoginRootScreen from "../../screens/FormsDepartment/FormsLoginRootScreen";

const Stack = createStackNavigator();

const StackNavigator = () => (
  <Stack.Navigator
    presentation="modal"
    initialRouteName="STACK_FormsLoginRootScreen"
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen
      name="STACK_FormsLoginRootScreen"
      component={FormsLoginRootScreen}
      options={{ title: "Access Foundation" }}
    />
    <Stack.Screen
      name="STACK_FormsRootScreen"
      component={FormsRootScreen}
      options={{ title: "Access Foundation" }}
    />

    <Stack.Screen
      name="STACK_AllDepartmentScreen"
      component={DepartmentDeciderScreen}
      options={{ title: "Access Foundation" }}
    />
  </Stack.Navigator>
);

export default StackNavigator;
