import React, { useState, useContext, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  FlatList,
  Platform,
  Image,
  TouchableOpacity,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import * as WebBrowser from "expo-web-browser";

import AttendanceChecker from "./AttendanceChecker";

import Routes from "../../navigation/routes";
import AppDrawerTypeBtn from "../../components/AppDrawerTypeBtn";
import Colors from "../../config/Colors";
import Cache from "../../utility/cache";

function AttendanceHomeScreen({ navigation }) {
  const [currentScreen, setCurrentScreen] = useState("AttendanceChecker");
  const [therapistDetails, setTherapistDetails] = useState({ name: "" });

  const { height, width } = useWindowDimensions();

  let Screen;

  if (currentScreen == "AttendanceChecker") {
    Screen = AttendanceChecker;
  }

  const SignOutHandler = async () => {
    await Cache.removeItem("u_department");
    await Cache.store("signOut", true);
    navigation.navigate(Routes.STACK_Attendance_ROOTSCREEN);
  };

  return (
    <LinearGradient
      style={[styles.container, { height, width }]}
      colors={["#97D9E100", "#D9AFD900"]}
    >
      <LinearGradient
        style={styles.leftViewStyles}
        colors={["rgba(255, 255, 255, 0.9)"]}
      >
        <View style={styles.ProfilesStylesView}>
          <Text
            style={{
              color: "#fff",
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            Account Team
          </Text>
        </View>
        <ScrollView
          style={{ width: "100%", alignSelf: "center" }}
          showsVerticalScrollIndicator={false}
        >
          <AppDrawerTypeBtn
            border={1}
            iconName="account"
            label="Attendance Checker"
            onPress={() => setCurrentScreen("AttendanceChecker")}
            backgroundColor={
              currentScreen == "PendingClames" ? Colors.white : ""
            }
          />
        </ScrollView>
        <AppDrawerTypeBtn
          style={styles.btnSignOutStyles}
          border={1}
          IconType="Entypo"
          iconName="log-out"
          label="Sign Out"
          onPress={() => SignOutHandler("SignOut")}
          backgroundColor={currentScreen == "SignOut" ? Colors.white : ""}
        />
      </LinearGradient>

      <View style={styles.rightViewStyles}>
        <Screen navigation={navigation} />
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#eeeee4",
  },
  ProfilesStylesView: {
    height: "5%",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  ProfileImageStyles: {
    height: 70,
    width: 70,
    borderRadius: 20,
    borderWidth: 1,
    marginHorizontal: 10,
    backgroundColor: "#fff",
  },
  onlineTxtStyles: {
    color: "green",
    backgroundColor: "#fff",
    fontSize: 15,
    paddingVertical: 5,
    borderRadius: 10,
    alignSelf: "center",
    padding: 5,
  },
  leftViewStyles: {
    height: "90%",
    width: "15%",
    borderRadius: 20,
    borderWidth: 2,
    alignItems: "center",
    paddingHorizontal: 10,
    backgroundColor: "rgba(0,0,0, 0.4) 100%",
  },
  rightViewStyles: {
    height: "90%",
    width: "83%",
    borderRadius: 5,
    backgroundColor: Colors.lightDark,
    borderRadius: 20,
    borderWidth: 2,
    backgroundColor: "rgba(0,0,0,0.2) 100%",
    padding: 10,
    //backgroundColor: "rgba(52, 52, 52, alpha)",
  },
  btnSignOutStyles: {
    position: "absolute",
    bottom: 0,
  },
  webview: {
    flex: 1,
  },
});

export default AttendanceHomeScreen;
