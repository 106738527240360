import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text, TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import moment from "moment";

import listingAPI from "../api/listings";
import AppAlertYesNo from "./AppAlertYesNo";
import AppIcon from "./AppIcon";
import Colors from "../config/Colors";
import AppButton from "./AppButton";
import AppGetDocumentCard from "./AppGetDocumentCard";
import AppMultiFilesPicker from "./AppMultiFilesPicker";
import AppProgressbar from "./AppProgressbar";
import Cache from "../utility/cache";

function AppOnlyFolder({
  items,
  access = "full",
  name = "Foleder name",
  switchEnable,
}) {
  const [selectedOptions, setSelectedOptions] = useState({ modal: false });
  const [selectedFolder, setSelectedFolder] = useState("");
  const [uploadDocumentHandler, setUploadDocumentHandler] = useState(false);
  const [documentUploadAccess, setDocumentUploadAccess] = useState(false);
  const [allDocuments, setAllDocuments] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [fileUris, setFileUris] = useState([]);
  const [progressBar, setProgressBar] = useState(false);

  const documentUpload_cloud_Handler = async () => {
    setProgressBar(true);
    const myJsonServices = fileUris;

    for (let i = 0; i < myJsonServices.length; i++) {
      const { data } = await listingAPI.FileUploadInFolderPost({
        endPoint: "/file/Upload/InFolder",
        data: myJsonServices[i],
        Folder: `${"Templates/"}${selectedFolder.main_id}/`,
      });

      if (data == null) {
        setProgressBar(false);
        // setAlertDetails({
        //   modal: true,
        //   alert:
        //     "Something went wrong, File may be not supported or File size is more than 20MB.",
        // });
        return;
      } else {
        documentDataHandler_inTable(data.name);
        setUploadedFile(data);
      }
    }
    setProgressBar(false);
    //ClearUseStateHandler();
  };

  const documentDataHandler_inTable = async (documentName) => {
    const loginBymain_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      templates_service_main_id: selectedFolder.main_id,
      uploadPersion_main_id: loginBymain_id,
      uploadBy: "therapyAdmin",
      uploadDate: moment(Date.now()).format("YYYY/MM/DD"),
      documentName: documentName,
      status: "inactive",
    });

    const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
      endPoint: "/Insert/each/templates/documents",
      data: myJsonServices,
    });
  };

  const GetListOfDocuments = async (service) => {
    const myJsonServices = JSON.stringify({
      templates_service_main_id: service.main_id,
    });
    const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
      endPoint: "/Select/All/documents/templets/of/selected/as/per/service",
      data: myJsonServices,
    });

    setAllDocuments(data);
  };

  const DocumentDeleteFromTable = async (item) => {
    const myJsonServices = JSON.stringify({
      main_id: item.main_id,
      uploadBy: "therapyAdmin",
      templates_service_main_id: selectedFolder.main_id,
    });
    const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
      endPoint: "/delete/templates/by/Admin/from/folder",
      data: myJsonServices,
    });

    setAllDocuments(data);
  };

  return (
    <View style={[styles.folderListMainViewStyles]}>
      <AppAlertYesNo
        mainViewStyles={{ width: "90%", height: "90%" }}
        isModalVisible={documentUploadAccess}
        noBtnPress={() => {
          setDocumentUploadAccess(false);
        }}
        yesBtnEnable={false}
        leftBtnLabel="Back"
        Alert=""
      >
        <View style={{ width: "100%", height: "100%" }}>
          <Text style={{ fontWeight: "bold", fontSize: "100%" }}>
            Selected Folder : {selectedFolder.name}
          </Text>
          <View style={styles.BtnViewStyles}>
            <AppButton
              style={{ borderWidth: 1 }}
              color={uploadDocumentHandler ? "" : "InputText"}
              label="Documents List"
              onPress={() => {
                GetListOfDocuments(selectedFolder);
                setUploadDocumentHandler(false);
              }}
            />
            {access == "full" && (
              <AppButton
                style={{ borderWidth: 1 }}
                color={uploadDocumentHandler ? "InputText" : ""}
                label="Upload Documents"
                onPress={() => setUploadDocumentHandler(true)}
              />
            )}
          </View>

          <View style={styles.documentVierStyles}>
            {uploadDocumentHandler ? (
              <AppMultiFilesPicker
                height="80%"
                btnUploadEnable
                uploadedFiles={uploadedFile}
                onPressUploadHandler={() => documentUpload_cloud_Handler()}
                getAllFiles={(files) => setFileUris(files)}
              />
            ) : (
              <AppGetDocumentCard
                popUpStyles={{ height: "30%" }}
                height="80%"
                items={allDocuments}
                switchEnable={switchEnable}
                deleteEnable={access == "full" ? true : false}
                switchBtnChange={(sw) => UpdateDocumentStatusHandler(sw)}
                OnClickDeleteDocumentHandler={(details) => {
                  DocumentDeleteFromTable(details);
                }}
              />
            )}
          </View>
        </View>
      </AppAlertYesNo>

      <AppProgressbar modalVisible={progressBar} />

      <View style={styles.folderNameMainViewStyles}>
        <TouchableOpacity
          style={styles.folderNameStyles}
          onPress={() => {
            GetListOfDocuments(items);
            setSelectedFolder(items);
            setDocumentUploadAccess(true);
          }}
        >
          <MaterialCommunityIcons
            touchAble
            name="folder"
            size="200%"
            color="#F8D775"
          />
          <Text style={styles.txtNameStyles}>
            {items.name ? items.name : name}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    paddingVertical: 5,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  mainViewStyles: {
    height: "90%",
    width: "100%",
  },
  topBtnViewStyles: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 5,
  },
  folderListMainViewStyles: {
    margin: 1,
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    backgroundColor: Colors.InputText,
    width: "75%",
    alignSelf: "center",
  },
  folderNameMainViewStyles: {
    flexDirection: "row",
    width: "90%",
    alignSelf: "flex-start",
  },
  folderNameStyles: {
    width: "80%",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  shareIconViewStyles: {
    width: "1%",
    alignItems: "center",
  },
  txtNameStyles: {
    textAlign: "center",
    fontSize: "100%",
  },

  txtStyles: {
    textAlign: "center",
    fontSize: "100%",
  },

  labelViewStyles: {
    height: "3%",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  txtLabelStyles: {
    fontWeight: "bold",
    fontSize: "100%",
    textAlign: "center",
  },
  // CreateFolderViewStyles: {
  //   width: "100%",
  //   flexDirection: "row",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  BtnViewStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  documentVierStyles: {
    height: "100%",
    width: "100%",
  },
  CreatedByStyles: {
    backgroundColor: "#CEF9F9",
    marginVertical: 5,
    padding: 10,
    width: "90%",
    alignSelf: "center",
  },
  IconViewStyles: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "20%",
  },
});

export default AppOnlyFolder;
