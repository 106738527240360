import React from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  useWindowDimensions,
  ScrollView,
} from "react-native";
import moment from "moment";

import Colors from "../../config/Colors";

const AppCard = ({ item }) => {
  let date = moment(item.clamiedDate).format("DD/MM/YYYY");

  return (
    <View style={styles.clamiedSessionViewStyles}>
      <Text style={{ width: "10%" }}>{item.session_main_id}</Text>
      <Text style={{ width: "50%" }}>{item.name}</Text>
      <Text style={{ width: "30%" }}>{date}</Text>
    </View>
  );
};

function AppClamiedSessionCard({ data = [] }) {
  return (
    <View style={[styles.container]}>
      <View style={styles.clamiedSessionViewStyles}>
        <Text style={{ fontWeight: "bold", width: "10%" }}>Session Id</Text>
        <Text style={{ fontWeight: "bold", width: "50%" }}>Claimed By</Text>
        <Text style={{ fontWeight: "bold", width: "30%" }}>Claim Date</Text>
      </View>
      <View>
        <ScrollView>
          {data.length > 0 ? (
            // <FlatList
            //   data={data}
            //   keyExtractor={(item, index) => index.toString()}
            //   renderItem={({ item }) => <AppCard item={item} />}
            // />

            data.map((item) => (
              <AppCard key={item.session_main_id} item={item} />
            ))
          ) : (
            <Text style={{ alignSelf: "center", fontSize: 35 }}>
              No Data Found.
            </Text>
          )}
        </ScrollView>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  clamiedSessionViewStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    marginVertical: 5,
    padding: 5,
    backgroundColor: Colors.InputText,
  },
  txtStyles: {
    width: "50%",
  },
});

export default AppClamiedSessionCard;
