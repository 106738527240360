import React, { useState } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Modal,
  FlatList,
  Text,
} from "react-native";
import AppIcon from "../components/AppIcon";
import AppText from "../components/AppText";
import Colors from "../config/Colors";
import AppButton from "./AppButton";
import PickerItem from "./PickerItem";

function AppPicker({
  labelWidth,
  numColumns = 1,
  touchAble = true,
  icon,
  placeholder,
  items = [],
  selectedItem,
  onSelectItem,
  width = "50%",
  iconBackgroundColor = "",
  label,
}) {
  let TouchView;
  if (touchAble) {
    TouchView = TouchableOpacity;
  } else {
    TouchView = View;
  }

  const [modalVisable, setModalVisable] = useState(false);
  return (
    <>
      <View
        style={{
          width,
          alignItems: "center",
        }}
      >
        <AppText
          style={{ alignSelf: "flex-start", marginLeft: 20 }}
          label={label}
        />
        <TouchView
          style={styles.container}
          onPress={() => setModalVisable(true)}
        >
          <View style={styles.container}>
            {icon && (
              <AppIcon
                name={icon}
                size={35}
                backgroundColor={iconBackgroundColor}
              />
            )}
            <AppText
              style={styles.txtStyles}
              label={selectedItem ? selectedItem.name : placeholder}
            />
            <AppIcon name="chevron-down" size={35} backgroundColor="#E7E7E7" />
          </View>
        </TouchView>
        <Modal visible={modalVisable} animationType="slide">
          <View style={styles.popUpListViewStyles}>
            <AppButton
              style={{ alignSelf: "center" }}
              label="Back"
              onPress={() => setModalVisable(false)}
            />
            {items.length == 0 ? (
              <Text style={{ fontSize: 35, alignSelf: "center" }}>
                Data is not actived
              </Text>
            ) : (
              <FlatList
                numColumns={numColumns}
                scrollEnabled
                data={items}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item }) => (
                  <PickerItem
                    labelWidth={labelWidth}
                    label={item}
                    onPress={() => {
                      setModalVisable(false);
                      onSelectItem(item);
                    }}
                  />
                )}
              />
            )}
          </View>
        </Modal>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 40,
    width: "100%",
    backgroundColor: "#E7E7E7",
    alignItems: "center",
    borderRadius: 27,
    paddingHorizontal: 10,
    flexDirection: "row",
  },
  txtStyles: {
    flex: 1,
  },
  popUpListViewStyles: {
    height: "100%",
    width: "90%",
    alignSelf: "center",
    backgroundColor: Colors.lightDark,
  },
});

export default AppPicker;
