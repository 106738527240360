import React from "react";
import { View, StyleSheet, Text, TouchableOpacity, Switch } from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";

import Colors from "../config/Colors";
import AppIcon from "../components/AppIcon";

function AppFormList({
  onPress,
  items,
  btnEnable = false,
  switchBtnChange,
  OnClickDeleteHandler,
}) {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <iframe scrolling="no" style={{ height: "90%" }} src={items.link} />

      <View style={styles.FormListStyles}>
        <Text style={{ alignSelf: "center", width: "55%" }} numberOfLines={1}>
          ⬆️ {items.name}
        </Text>
        {btnEnable && (
          <View style={styles.btnView}>
            <Switch
              value={items.status == "active" ? true : false}
              onValueChange={() => {
                switchBtnChange(items);
              }}
            />
            <AppIcon
              backgroundColor=""
              touchAble
              name="delete"
              iconColor="red"
              onPress={() => OnClickDeleteHandler(items)}
            />
          </View>
        )}
        <TouchableOpacity
          onPress={() => Clipboard.setString(items.name + "\n" + items.link)}
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <AppIcon backgroundColor="" name="content-copy" iconColor="blue" />
          <Text style={{ color: "blue" }}>Copy Link</Text>
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 300,
    width: "45%",
    margin: 20,
    borderWidth: 1,
    borderRadius: 15,
    padding: 5,
  },
  FormListStyles: {
    backgroundColor: Colors.InputText,
    padding: 10,
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    height: "10%",
  },
  btnView: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "40%",
  },
});

export default AppFormList;
