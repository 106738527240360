import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  Alert,
  ScrollView,
  Dimensions,
} from "react-native";
import moment from "moment";

import listingAPI from "../../../../api/listings";
import Colors from "../../../../config/Colors";
import AppProgressbar from "../../../../components/AppProgressbar";
import AppButton from "../../../../components/AppButton";
import AppAlert from "../../../../components/AppAlert";
import AppCalander from "../../../../components/AppCalander";
import AppMultiFilesPicker from "../../../../components/AppMultiFilesPicker";
import AppInputText from "../../../../components/AppInputText";
import AppPicker from "../../../../components/AppPicker";
import AppGetDocumentCard from "../../../../components/AppGetDocumentCard";
import Routes from "../../../../navigation/routes";
import Cache from "../../../../utility/cache";

function NewSessionAdd({ navigation, data }) {
  const item = data;

  const [hhSession, setHHSession] = useState({ name: 0 });
  const [mmSession, setMMSession] = useState({ name: 0 });

  const [hhTravel, setHHTravel] = useState({ name: 0 });
  const [mmTravel, setMMTravel] = useState({ name: 0 });

  const [chargeHH, setChargeHH] = useState({ name: 0 });
  const [chargeMM, setChargeMM] = useState({ name: 0 });

  const [sessionDate, setSessionDate] = useState("dd/mm/yyyy");
  const [uploadedFile, setUploadedFile] = useState([]);
  const [brifTask, setbrifTask] = useState("");
  const [goingKM, setGoingKM] = useState();
  const [comingBackKM, setComingBackKM] = useState();
  const [parkingCharges, setParkingCharges] = useState();
  const [progressBar, setProgressBar] = useState(false);
  const [documentListShow, setDocumentListShow] = useState(true);
  const [alert, setAlert] = useState({ modal: false, alert: "" });
  const [fileUris, setFileUris] = useState([]);
  const [allDocuments, setAllDocuments] = useState([]);
  const [extraExpenses, setExtraExpenses] = useState();
  const [selectedExtraExpenses, setSelectedExtraExpenses] = useState();
  const [mainSubject, setMainSubject] = useState();

  useEffect(() => {
    GetAllExtraExpenses();
  }, []);

  useEffect(() => {
    GetListOfDocuments();
  }, []);

  const GetAllExtraExpenses = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.ExtraExpenses({
      endPoint: "/Select/All/ExtraExpenses/notDeleted",
      data: myJsonServices,
    });
    setExtraExpenses(data);
  };

  const GetListOfDocuments = async () => {
    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
      services_main_id: item.allocate_services_main_id,
      uploadBy: "therapist",
    });
    const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
      endPoint:
        "/Select/All/documents/of/selected/participant/as/per/service/and/selected/therapist",
      data: myJsonServices,
    });

    setAllDocuments(data);
  };

  const documentUpload_cloud_Handler = async (session_main_id) => {
    let department = await Cache.get("selected_department");
    let loginedBy_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = fileUris;

    setProgressBar(true);

    for (let i = 0; i < myJsonServices.length; i++) {
      const { data } = await listingAPI.FileUploadInFolderPost({
        endPoint: "/file/Upload/InFolder",
        data: myJsonServices[i],
        Folder: `${item.main_id + "/"}${department + "/"}${
          loginedBy_main_id + "/"
        }sessionDocument/`,
      });

      if (data == null) {
        setProgressBar(false);
        setAlert({
          modal: true,
          alert:
            "Something went wrong, File may be not supported or File size is more than 20MB.",
        });
        return;
      } else {
        documentDataHandler_inTable(data.name, session_main_id);
        setUploadedFile(data);
      }
    }
    setProgressBar(false);
  };

  const documentDataHandler_inTable = async (documentName, session_main_id) => {
    const myJsonServices = JSON.stringify({
      sessionMain_id: session_main_id,
      participant_main_id: item.main_id,
      services_main_id: item.allocate_services_main_id,
      uploadPersion_main_id: item.therapist_main_id,
      uploadBy: "therapist",
      uploadDate: moment(Date.now()).format("YYYY/MM/DD"),
      documentName: documentName,
      status: "active",
    });
    const { data } = await listingAPI.ParticipentsSessionDocument_dataIntoTable(
      {
        endPoint: "/Insert/each/participant/sessionDocumentsUpload",
        data: myJsonServices,
      }
    );
  };

  const OnclickSubmitHandler = async () => {
    let goingToParticipentKM = goingKM == undefined ? 0 : goingKM;
    let comingBackKMToParticipentKM =
      comingBackKM == undefined ? 0 : comingBackKM;
    let TotalKM =
      parseInt(goingToParticipentKM) + parseInt(comingBackKMToParticipentKM);

    if (sessionDate == "dd/mm/yyyy") {
      setAlert({ modal: true, alert: "Please Select Session date." });
      return;
    } else if (hhSession.name == 0 && mmSession.name == 0) {
      setAlert({ modal: true, alert: "Please Add Session time." });
      return;
    }

    let totalTravelTime = hhTravel.name * 60 + mmTravel.name;
    let totalChargeTravelTime = chargeHH.name * 60 + chargeMM.name;

    if (totalTravelTime < totalChargeTravelTime) {
      setAlert({
        modal: true,
        alert: "Charge Travel time must be less than total travel time.",
      });
      return;
    }

    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
      services_main_id: item.allocate_services_main_id,
      services_CurrentPrice: item.servicesPrice,
      therapist_main_id: item.therapist_main_id,
      sessionDate: moment(sessionDate, "DD/MM/YYYY").format("YYYY/MM/DD"),
      mainSubject: mainSubject != undefined ? mainSubject.trim() : "",
      totalSession_hours: hhSession.name,
      totalSession_mins: mmSession.name,
      totalTravel_hours: hhTravel.name,
      totalTravel_mins: mmTravel.name,
      chargeTravel_hours: chargeHH.name,
      chargeTravel_mins: chargeMM.name,
      briefNote: brifTask,
      currentPlanNumver: item.currentPlanNumver,
      extraExpense_main_id: extraExpenses[0].main_id,
      extraExpense_price: extraExpenses[0].price,
      totalExtraExpense: TotalKM,
      totalExtraExpense_Price: TotalKM * extraExpenses[0].price,
      goingToParticipantKM: parseFloat(goingToParticipentKM),
      comingBackToParticipantKM: parseFloat(comingBackKMToParticipentKM),
      parkingCharges: parseFloat(parkingCharges ?? 0)
    });

    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint: "/Insert/Everyday/session",
      data: myJsonServices,
    });

    if (data.length > 0) {
      documentUpload_cloud_Handler(data[0].session_main_id);
    } else {
      setProgressBar(false);
      setAlert({ modal: true, alert: "Something went wrong." });
    }
    ClearUseStateHandler();
  };

  const ClearUseStateHandler = () => {
    setHHSession({ name: 0 });
    setMMSession({ name: 0 });
    setHHTravel({ name: 0 });
    setMMTravel({ name: 0 });
    setChargeHH({ name: 0 });
    setChargeMM({ name: 0 });
    setMainSubject("");
    setbrifTask("");
    setGoingKM(undefined);
    setComingBackKM(undefined);
    setParkingCharges(undefined);
  };

  const OnClickDeleteDocumentHandler = async (item) => {
    const myJsonServices = { key: item.documentName };
    const { data } = await listingAPI.ImageTryDelete({
      endPoint: "/Delete/image",
      data: myJsonServices,
    });

    if (data == "File Deleted Successfully.") {
      DocumentDeleteFromTable(item);
    }
  };

  const DocumentDeleteFromTable = async (item) => {
    const myJsonServices = JSON.stringify({
      main_id: item.main_id,
      participant_main_id: item.participant_main_id,
      uploadBy: "therapist",
    });
    const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
      endPoint: "/delete/participant/document",
      data: myJsonServices,
    });

    setAllDocuments(data);
  };

  let totalHHSession = [{ name: 0 }];
  let totalMMSession = [{ name: 0 }];

  let totalHHTravel = [{ name: 0 }];
  let totalMMTravel = [{ name: 0 }];

  let totalChargeHHTravel = [{ name: 0 }];
  let totalChargeMMTravel = [{ name: 0 }];

  for (let i = 1; i <= 10; i++) {
    totalHHSession.push({ name: i });
  }
  for (let i = 1; i <= 59; i++) {
    totalMMSession.push({ name: i });
  }

  for (let i = 1; i <= 10; i++) {
    totalHHTravel.push({ name: i });
  }
  for (let i = 1; i <= 59; i++) {
    totalMMTravel.push({ name: i });
  }

  for (let i = 1; i <= 10; i++) {
    totalChargeHHTravel.push({ name: i });
  }
  for (let i = 1; i <= 59; i++) {
    totalChargeMMTravel.push({ name: i });
  }

  const OnClickBackHandler = () => {
    navigation.navigate(Routes.STACK_THERAPISTHOMESCREEN);
  };

  return (
    <View style={[styles.container]}>
      <AppProgressbar modalVisible={progressBar} />
      <AppAlert
        alertLabel=""
        alert={alert}
        okBtnPress={() => setAlert({ modal: false, alert: "" })}
      />

      <ScrollView>
        <View style={styles.mainViewStyles}>
          <View style={styles.titleStyles}>
            <Text style={{ fontSize: 25, fontWeight: "bold" }}>
              Today : {moment(Date.now()).format("DD/MM/YYYY")}
            </Text>
          </View>
          <View style={styles.dataViewStyles}>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <AppCalander
                width="30%"
                label="Session Date : "
                datePlaceholder={sessionDate}
                selectedDate={(date) => setSessionDate(date)}
                maxDate={new Date()}
              />
              <AppInputText
                label="Subject : "
                iconName="note"
                placeholder="Subject : "
                width="30%"
                value={mainSubject ?? ""}
                onChangeText={(txt) => setMainSubject(txt)}
              />
            </View>
            <View style={styles.sessionTimeSelection}>
              <Text style={styles.txtLabelStyles}>Session Time</Text>
              <View style={styles.timeViewStyles}>
                <AppPicker
                  numColumns={2}
                  labelWidth="30%"
                  label="HH"
                  width="40%"
                  items={totalHHSession}
                  selectedItem={hhSession}
                  onSelectItem={(item) => setHHSession(item)}
                />
                <AppPicker
                  numColumns={6}
                  labelWidth="10%"
                  label="MM"
                  width="40%"
                  items={totalMMSession}
                  selectedItem={mmSession}
                  onSelectItem={(item) => setMMSession(item)}
                />
              </View>
            </View>

            <View style={styles.sessionTimeSelection}>
              <Text style={styles.txtLabelStyles}>Total Travel Time</Text>
              <View style={styles.timeViewStyles}>
                <AppPicker
                  numColumns={2}
                  labelWidth="30%"
                  label="HH"
                  width="40%"
                  items={totalHHTravel}
                  selectedItem={hhTravel}
                  onSelectItem={(item) => setHHTravel(item)}
                />
                <AppPicker
                  numColumns={6}
                  labelWidth="10%"
                  label="MM"
                  width="40%"
                  items={totalMMTravel}
                  selectedItem={mmTravel}
                  onSelectItem={(item) => setMMTravel(item)}
                />
              </View>
            </View>

            <View style={styles.sessionTimeSelection}>
              <Text style={styles.txtLabelStyles}>Charge Travel Time</Text>
              <View style={styles.timeViewStyles}>
                <AppPicker
                  numColumns={2}
                  labelWidth="30%"
                  label="HH"
                  width="40%"
                  items={totalChargeHHTravel}
                  selectedItem={chargeHH}
                  onSelectItem={(item) => setChargeHH(item)}
                />
                <AppPicker
                  numColumns={6}
                  labelWidth="10%"
                  label="MM"
                  width="40%"
                  items={totalChargeMMTravel}
                  selectedItem={chargeMM}
                  onSelectItem={(item) => setChargeMM(item)}
                />
              </View>
            </View>

            <View style={styles.sessionTimeSelection}>
              <Text style={styles.txtLabelStyles}>KMs Charges</Text>
              {/* <View style={{ alignItems: "center" }}>
                <AppPicker
                  numColumns={2}
                  labelWidth="30%"
                  label="Select"
                  width="40%"
                  items={extraExpenses}
                  selectedItem={selectedExtraExpenses}
                  onSelectItem={(item) => setSelectedExtraExpenses(item)}
                />
              </View> */}
              <View style={styles.timeViewStyles}>
                <AppInputText
                  label="Going to participents"
                  width="30%"
                  placeholder="Total KMs : "
                  iconName="car-settings"
                  keyboardType="numeric"
                  pattern="[0-9]*"
                  value={goingKM ?? ""}
                  onChangeText={(txt) =>
                    setGoingKM(
                      txt.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
                    )
                  }
                />
                <AppInputText
                  label="Coming back from participant's"
                  width="30%"
                  placeholder="Total KMs : "
                  iconName="car-settings"
                  keyboardType="numeric"
                  pattern="[0-9]*"
                  value={comingBackKM ?? ""}
                  onChangeText={(txt) =>
                    setComingBackKM(
                      txt.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
                    )
                  }
                />
                <AppInputText
                  label="Parking Charges"
                  width="30%"
                  placeholder="Charges : "
                  iconName="car-settings"
                  keyboardType="numeric"
                  pattern="[0-9]*"
                  value={parkingCharges ?? ""}
                  onChangeText={(txt) =>
                    setParkingCharges(
                      txt.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
                    )
                  }
                />
              </View>
            </View>
            <AppInputText
              iconName="note"
              placeholder="Brief Notes : "
              multiline
              height={200}
              width="100%"
              value={brifTask ?? ""}
              onChangeText={(txt) => setbrifTask(txt)}
            />

            <View style={{ height: "50%", width: "100%" }}>
              <AppMultiFilesPicker
                uploadedFiles={uploadedFile}
                getAllFiles={(files) => setFileUris(files)}
              />
            </View>
          </View>
        </View>
      </ScrollView>
      <View style={styles.btnViewStyles}>
        <AppButton
          style={styles.btnBackStyles}
          label="Back"
          color="blue"
          onPress={() => OnClickBackHandler()}
        />
        <AppButton
          style={styles.btnBackStyles}
          label="Submit"
          onPress={() => OnclickSubmitHandler()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingVertical: 10,
  },
  btnUploasDocumentStyles: {
    alignSelf: "center",
    marginVertical: 20,
  },
  mainViewStyles: {
    height: "100%",
    width: "100%",
  },
  titleStyles: {
    height: "5%",
    alignItems: "center",
    width: "100%",
  },
  btnStylesUploadDocument: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    position: "absolute",
    bottom: 5,
    width: "100%",
  },
  dataViewStyles: {
    width: "90%",
    height: "80%",
    alignSelf: "center",
  },
  timeViewStyles: {
    marginTop: 10,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    alignSelf: "center",
  },
  sessionTimeSelection: {
    width: "100%",
    marginVertical: 10,
    alignSelf: "center",
    borderWidth: 1,
    padding: 5,
  },
  uploadFileViewStyles: {
    height: "70%",
    marginTop: 20,
  },
  txtLabelStyles: {
    alignSelf: "center",
    fontSize: 25,
    fontWeight: "bold",
  },
  btnViewStyles: {
    //bottom: 5,
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    //position: "absolute",
    alignSelf: "center",
  },
  btnStyles: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around",
  },
  documentListViewStyles: {
    height: "30%",
    marginTop: 20,
    borderWidth: 1,
  },
  fileListViewStyles: {
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "space-between",
    borderRadius: 25,
    paddingHorizontal: 20,
    margin: 5,
    flexDirection: "row",
    width: "70%",
    backgroundColor: Colors.InputText,
  },
});

export default NewSessionAdd;
