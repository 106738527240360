import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  FlatList,
} from "react-native";

import moment from "moment";

import Colors from "../../config/Colors";
import listingAPI from "../../api/listings";

let HH = 0;
let MM = 0;

const ListCard = ({ item }) => {
  let start_time_hh = moment(item.start_time, "hh:mm:ss A").format("HH");
  let end_time_hh = moment(item.end_time, "hh:mm:ss A").format("HH");

  let totalLeft_HH = end_time_hh - start_time_hh;

  let start_time_mm = moment(item.start_time, "hh:mm:ss A").format("mm");
  let end_time_mm = moment(item.end_time, "hh:mm:ss A").format("mm");

  let totalLeft_MM = end_time_mm - start_time_mm;

  let totalMin = totalLeft_HH * 60 + totalLeft_MM;
  if (totalMin > 455) {
    HH = 7;
    MM = 36;
  } else {
    let Hours = ~~(totalMin / 60);
    let mins = ~~(totalMin % 60);
    HH = Hours;
    MM = mins;
  }

  return (
    <View style={styles.attendanceListViewStyles}>
      <Text style={styles.txtStyles}>{item.start_date}</Text>
      <Text style={styles.txtStyles}>
        {moment(item.start_date, "DD/MM/YYYY").format("dddd")}
      </Text>
      <Text style={styles.txtStyles}>{item.start_time}</Text>
      <Text style={styles.txtStyles}>{item.end_time}</Text>
      <Text style={styles.txtStyles}>{`${HH}:${MM}`}</Text>
      <Text style={styles.txtStyles}>{item.approved_by}</Text>
    </View>
  );
};

function AppAttendanceCard({ item, date }) {
  const [attendance, setAttendance] = useState([]);
  const [open, setOpen] = useState(false);
  const [LayoutHeight, setLayoutHeight] = useState(0);

  useEffect(() => {
    if (open) {
      setLayoutHeight(null);
    } else {
      setLayoutHeight(0);
    }
  }, [open]);

  const OnClickAttendanceHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      emp_id: item.emp_id,
      fromDate: date[0].fDate,
      toDate: date[0].tDate,
    });
    const { data } = await listingAPI.Attendance_POST({
      endPoint: "/select/all/filtered/Attendance/of/selected/emp",
      data: myJsonServices,
    });
    setAttendance(data);
  };

  return (
    <>
      {item.name != "" && (
        <>
          <TouchableOpacity
            style={styles.container}
            onPress={() => {
              setOpen(!open);
              OnClickAttendanceHandler(item);
            }}
          >
            <Text>{item.name}</Text>
          </TouchableOpacity>
          <View
            style={{ height: LayoutHeight, overflow: "hidden", width: "80%" }}
          >
            <FlatList
              data={attendance}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item }) => <ListCard item={item} />}
            />
          </View>
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.InputText,
    width: "90%",
    padding: 10,
    borderRadius: 10,
    margin: 10,
  },
  attendanceListViewStyles: {
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: Colors.placeHolderColor,
    margin: 5,
    padding: 5,
  },
  txtStyles: {
    width: "30%",
  },
});

export default AppAttendanceCard;
