import React, { useState, useContext, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import * as WebBrowser from "expo-web-browser";

import AccessHandlerScreen from "./AccessHandler/AccessHandlerScreen";
import AssignAdminScreen from "./AssignAdmin/AssignAdminScreen";
import OfficeMainScreen from "./offices/OfficeMainScreen";
import AddFormsHandler from "./Forms/AddFormsHandler";
import Test from "./try/Test";

import listingAPI from "../../../api/listings";
import AuthContext from "../../../auth/context";
import Routes from "../../../navigation/routes";
import AppDrawerTypeBtn from "../../../components/AppDrawerTypeBtn";
import Colors from "../../../config/Colors";
import Cache from "../../../utility/cache";

function ITAdminHomeScreen({ navigation }) {
  const authContext = useContext(AuthContext);

  const { height, width } = useWindowDimensions();

  const [currentScreen, setCurrentScreen] = useState("AccessHandler");
  const [therapistDetails, setTherapistDetails] = useState({ name: "" });

  let Screen;

  useEffect(() => {
    //GetParticipantAllName();
  }, []);

  const GetParticipantAllName = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      therapist_id: therapist_main_id,
    });
    const { data } = await listingAPI.TherapyEmpPost({
      endPoint: "/selected/allDetails/of/TherapyEmp/status/notDeleted",
      data: myJsonServices,
    });

    if (data.length > 0) {
      setTherapistDetails(data[0]);
    } else {
      setTherapistDetails({ name: "" });
    }
  };

  if (currentScreen == "AssignAdmin") {
    Screen = AssignAdminScreen;
  } else if (currentScreen == "AccessHandler") {
    Screen = AccessHandlerScreen;
  } else if (currentScreen == "OfficeMainScreen") {
    Screen = OfficeMainScreen;
  } else if (currentScreen == "Forms") {
    Screen = AddFormsHandler;
  } else if (currentScreen == "Test") {
    Screen = Test;
  }

  const OutlookLoginHandler = async () => {
    let result = await WebBrowser.openBrowserAsync(
      "https://login.live.com/login.srf?wa=wsignin1.0&rpsnv=13&ct=1655456536&rver=7.0.6737.0&wp=MBI_SSL&wreply=https%3a%2f%2foutlook.live.com%2fowa%2f%3fnlp%3d1%26RpsCsrfState%3d812f5175-8afc-1c49-9076-0015d08561d3&id=292841&aadredir=1&CBCXT=out&lw=1&fl=dob%2cflname%2cwld&cobrandid=90015"
    );
  };

  const SignOutHandler = async () => {
    await Cache.removeItem("u_department");
    await Cache.store("signOut", true);
    navigation.navigate(Routes.STACK_ITROOTSCREEN);
  };

  return (
    <LinearGradient
      style={[styles.container, { height, width }]}
      colors={["#97D9E100", "#D9AFD900"]}
    >
      <LinearGradient
        style={styles.leftViewStyles}
        colors={["rgba(255, 255, 255, 0.9)"]}
      >
        <View style={styles.ProfilesStylesView}>
          <Text
            style={{
              color: "#fff",
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            IT Admin
          </Text>
        </View>
        <ScrollView
          style={{ width: "100%", alignSelf: "center" }}
          showsVerticalScrollIndicator={false}
        >
          <AppDrawerTypeBtn
            border={1}
            iconName="account"
            label="Access Handler"
            onPress={() => setCurrentScreen("AccessHandler")}
            backgroundColor={
              currentScreen == "AccessHandler" ? Colors.white : ""
            }
          />

          {/* <AppDrawerTypeBtn
            border={1}
            iconName="account"
            label="Assign Admin"
            onPress={() => setCurrentScreen("AssignAdmin")}
            backgroundColor={currentScreen == "AssignAdmin" ? Colors.white : ""}
          /> */}
          <AppDrawerTypeBtn
            border={1}
            iconName="account"
            label="Offices"
            onPress={() => setCurrentScreen("OfficeMainScreen")}
            backgroundColor={
              currentScreen == "OfficeMainScreen" ? Colors.white : ""
            }
          />

          <AppDrawerTypeBtn
            border={1}
            iconName="file-edit"
            label="Forms"
            backgroundColor={currentScreen == "Forms" ? Colors.white : ""}
            onPress={() => setCurrentScreen("Forms")}
          />
          {/* <AppDrawerTypeBtn
            border={1}
            iconName="file-edit"
            label="Test"
            backgroundColor={currentScreen == "Test" ? Colors.white : ""}
            onPress={() => setCurrentScreen("Test")}
          /> */}
          <AppDrawerTypeBtn
            border={1}
            iconName="email"
            label="OutLook"
            backgroundColor={currentScreen == "OutLook" ? Colors.white : ""}
            onPress={() => OutlookLoginHandler()}
          />
        </ScrollView>
        <AppDrawerTypeBtn
          style={styles.btnSignOutStyles}
          border={1}
          IconType="Entypo"
          iconName="log-out"
          label="Sign Out"
          onPress={() => SignOutHandler("SignOut")}
          backgroundColor={currentScreen == "SignOut" ? Colors.white : ""}
        />
      </LinearGradient>

      <View style={styles.rightViewStyles}>
        <Screen navigation={navigation} data={therapistDetails} />
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#eeeee4",
  },
  ProfilesStylesView: {
    height: "5%",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  ProfileImageStyles: {
    height: 70,
    width: 70,
    borderRadius: 20,
    borderWidth: 1,
    marginHorizontal: 10,
    backgroundColor: "#fff",
  },
  onlineTxtStyles: {
    color: "green",
    backgroundColor: "#fff",
    fontSize: 15,
    paddingVertical: 5,
    borderRadius: 10,
    alignSelf: "center",
    padding: 5,
  },
  leftViewStyles: {
    height: "90%",
    width: "15%",
    borderRadius: 20,
    borderWidth: 2,
    alignItems: "center",
    paddingHorizontal: 10,
    backgroundColor: "rgba(0,0,0, 0.4) 100%",
  },
  rightViewStyles: {
    height: "90%",
    width: "83%",
    borderRadius: 5,
    backgroundColor: Colors.lightDark,
    borderRadius: 20,
    borderWidth: 2,
    backgroundColor: "rgba(0,0,0,0.2) 100%",
    padding: 10,
    //backgroundColor: "rgba(52, 52, 52, alpha)",
  },
  btnSignOutStyles: {
    position: "absolute",
    bottom: 0,
  },
  webview: {
    flex: 1,
  },
});

export default ITAdminHomeScreen;
