import React, { useState } from "react";
import { View, StyleSheet, Text, FlatList } from "react-native";
import moment from "moment";

import Colors from "../../config/Colors";
import listingAPI from "../../api/listings";
import AppAlert from "../../components/AppAlert";
import AppButton from "../../components/AppButton";
import AppCalander from "../../components/AppCalander";
import AppAttendanceCard from "../../components/cards/AppAttendanceCard";
import AppText from "../../components/AppText";

function AttendanceChecker({}) {
  const [allEmp, setAllEmp] = useState([{ name: "" }]);
  const [btnSelected, setBtnSelected] = useState(false);
  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [dateList, setDateList] = useState();
  const [alertDetails, setAlertDetails] = useState({
    modal: false,
    alert: "",
  });

  const OnClickPresentFilterHandler = async () => {
    setBtnSelected(false);
    let f_date = moment(fromDate, "DD/MM/YYYY").format("YYYY/MM/DD");
    let t_date = moment(toDate, "DD/MM/YYYY").format("YYYY/MM/DD");

    if (fromDate == undefined) {
      setAlertDetails({
        modal: true,
        alert: "Please select 'From date'",
      });
      return;
    } else if (toDate == undefined) {
      setAlertDetails({
        modal: true,
        alert: "Please select 'To date'",
      });
      return;
    } else if (f_date >= t_date) {
      setAlertDetails({
        modal: true,
        alert: "'From date' must be smaller than 'To date'.",
      });
      return;
    }

    const myJsonServices = JSON.stringify({
      fromDate: fromDate,
      toDate: toDate,
    });
    const { data } = await listingAPI.Attendance_POST({
      endPoint: "/select/all/filtered/Attendance",
      data: myJsonServices,
    });

    if (data.length > 0) {
      setDateList(`List From ${fromDate} To ${toDate}`);
    } else {
      setDateList();
    }
    setAllEmp(data);
  };

  const OnClickAbsentFilterHandler = async () => {
    setBtnSelected(true);
    let f_date = moment(fromDate, "DD/MM/YYYY").format("YYYY/MM/DD");
    let t_date = moment(toDate, "DD/MM/YYYY").format("YYYY/MM/DD");

    if (fromDate == undefined) {
      setAlertDetails({
        modal: true,
        alert: "Please select 'From date'",
      });
      return;
    } else if (toDate == undefined) {
      setAlertDetails({
        modal: true,
        alert: "Please select 'To date'",
      });
      return;
    } else if (f_date >= t_date) {
      setAlertDetails({
        modal: true,
        alert: "'From date' must be smaller than 'To date'.",
      });
      return;
    }

    const myJsonServices = JSON.stringify({
      fromDate: fromDate,
      toDate: toDate,
    });
    const { data } = await listingAPI.Attendance_POST({
      endPoint: "/select/all/filtered/not/Attendance",
      data: myJsonServices,
    });

    if (data.length > 0) {
      setDateList(`List From ${fromDate} To ${toDate}`);
    } else {
      setDateList();
    }

    setAllEmp(data);
  };

  return (
    <View style={styles.container}>
      <AppAlert
        alert={alertDetails}
        okBtnPress={() =>
          setAlertDetails({
            modal: false,
            alert: "",
          })
        }
      />
      <View style={styles.topViewStyles}>
        <AppCalander
          width="40%"
          label="From Date : "
          datePlaceholder={fromDate ?? "DD/MM/YYYY"}
          selectedDate={(date) => setFromDate(date)}
          maxDate={new Date()}
        />
        <AppCalander
          width="40%"
          label="To Date : "
          datePlaceholder={toDate ?? "DD/MM/YYYY"}
          selectedDate={(date) => setToDate(date)}
          maxDate={new Date()}
        />
      </View>
      {fromDate != undefined && toDate != undefined && (
        <AppText
          style={{ alignSelf: "center", fontWeight: "bold" }}
          label={dateList}
        />
      )}
      <View style={styles.topViewStyles}>
        <AppButton
          label="List of Present"
          onPress={() => OnClickPresentFilterHandler()}
          border={1}
          color={!btnSelected ? "InputText" : ""}
        />
        <AppButton
          label="List of Not Absent"
          onPress={() => OnClickAbsentFilterHandler()}
          border={1}
          color={btnSelected ? "InputText" : ""}
        />
      </View>
      <View style={styles.bottomViewStyles}>
        <FlatList
          data={allEmp}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <AppAttendanceCard
              item={item}
              date={[{ fDate: fromDate, tDate: toDate }]}
            />
          )}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 20,
    padding: 10,
  },
  topViewStyles: {
    height: "10%",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },

  bottomViewStyles: {
    height: "75%",
    width: "100%",
  },
});

export default AttendanceChecker;
