import React, { useState, useEffect, useContext } from "react";
import { View, StyleSheet, Image, Text } from "react-native";

import DepartContext from "../../auth/DepartContext";
import "../../css/splashscreenCardImgLogo.css";

import Routes from "../../navigation/routes";

function SplashScreen({ navigation }) {
  const departContext = useContext(DepartContext);

  setTimeout(async () => {
    departContext.setDepartment("departments");
    //navigation.navigate(Routes.STACK_INTERNETCHECKERSCREEN);
  }, 7000);

  return (
    <>
      <div className="container">
        <div className="box">
          <View
            style={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              source={require("../../config/img/accessfoundationLogo.jpeg")}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 20,
              }}
            />
          </View>
        </div>
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  RegisterViewStyles: {
    height: "100%",
    width: "100%",
    backgroundColor: "red",
  },
});

export default SplashScreen;
