import React from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  useWindowDimensions,
  ScrollView,
} from "react-native";
import AppEmpWithHours from "../cards/AppEmpWithHours";

import Colors from "../../config/Colors";
import AppText from "../../components/AppText";

function AppParticipantDetails({
  items,
  onPressDocumentList,
  onPressPlanManage,
  onPressPlanManageEnable,
  onPressAddFileEnable,
  onPressDate,
  onPressName,
  switchBtnChange,
  onPressEdit,
  onPressDelete,
  editEnable,
  switchEnable,
  deleteEnable,
  EditDelete_Enable,
  firstLabel = "Name",
  secondLabel = "Service",
  thiredLabel = "Total Hours",
  fourthLabel = "Left Hours",
  fifthLabel = "Plan Expire",
  onPressServiceList,
  style,
}) {
  const { height: h, width: w } = useWindowDimensions();
  let windowHeight = h + w;

  return (
    <View style={[styles.container]}>
      <View style={{ flexDirection: "row", height: "5%", marginTop: 10 }}>
        <View style={{ width: "2%" }}></View>
        <View
          style={[
            styles.nameViewStyles,
            { width: EditDelete_Enable ? "70%" : "90%" },
          ]}
        >
          <TouchableOpacity style={styles.txtLabelStyles} onPress={onPressName}>
            <AppText
              style={{ fontWeight: "bold", fontSize: `${windowHeight / 30}%` }}
              label={firstLabel}
            />
          </TouchableOpacity>

          <AppText style={[styles.txtLabelStyles]} label={secondLabel} />
          <AppText style={[styles.txtLabelStyles]} label={thiredLabel} />
          <AppText style={[styles.txtLabelStyles]} label={fourthLabel} />
          <AppText style={[styles.txtLabelStyles]} label="Previous session" />
          <TouchableOpacity
            onPress={onPressDate}
            style={[styles.txtLabelStyles, { width: "15%" }]}
          >
            <AppText style={{ fontWeight: "bold" }} label={fifthLabel} />
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ height: "95%", width: "100%" }}>
        {/* <ScrollView>
          {items.map((item) => (
            <AppEmpWithHours
              key={
                item.main_id +
                item.allocate_services_main_id +
                item.servicesName
              }
              EditDelete_Enable={EditDelete_Enable}
              listView
              iconEnable
              items={item}
              switchEnable={switchEnable}
              editEnable={editEnable}
              deleteEnable={deleteEnable}
              btnSession
              onPressPlanManage={onPressPlanManage}
              onPressPlanManageEnable={onPressPlanManageEnable}
              onPressDocumentList={onPressDocumentList}
              onPressAddFileEnable={onPressAddFileEnable}
              backgroundColor={Colors.lightDark}
              onPressDelete={onPressDelete}
              onPressEdit={onPressEdit}
              switchBtnChange={switchBtnChange}
              onPressServiceList={onPressServiceList}
            />
          ))}
        </ScrollView> */}
        <FlatList
          data={items}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <AppEmpWithHours
              EditDelete_Enable={EditDelete_Enable}
              listView
              iconEnable
              items={item}
              switchEnable={switchEnable}
              editEnable={editEnable}
              deleteEnable={deleteEnable}
              btnSession
              onPressPlanManage={onPressPlanManage}
              onPressPlanManageEnable={onPressPlanManageEnable}
              onPressDocumentList={onPressDocumentList}
              onPressAddFileEnable={onPressAddFileEnable}
              backgroundColor={Colors.lightDark}
              onPressDelete={onPressDelete}
              onPressEdit={onPressEdit}
              switchBtnChange={switchBtnChange}
              onPressServiceList={onPressServiceList}
            />
          )}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
    width: "90%",
    height: "95%",
    alignSelf: "center",
    overflow: "hidden",
  },
  mainViewStyles: {
    padding: 5,
    width: "100%",
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 10,
  },

  nameViewStyles: {
    flexDirection: "row",
    width: "90%",
    alignItems: "center",
    justifyContent: "space-around",
  },
  txtLabelStyles: {
    width: "20%",
    fontWeight: "bold",
    alignItems: "flex-start",
  },
  btnViewStyles: {
    flexDirection: "row",
    width: "20%",
    justifyContent: "space-around",
    alignItems: "center",
  },
});

export default AppParticipantDetails;
