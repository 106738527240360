import React from "react";

import { createStackNavigator } from "@react-navigation/stack";

import TherapistHomeScreen from "../../../screens/TherapyTeam/Therapist/TherapistHomeScreen";
import SessionMainScreen from "../../../screens/TherapyTeam/Therapist/SessationHandler/SessionMainScreen";

const Stack = createStackNavigator();

const StackNavigator = () => (
  <Stack.Navigator
    presentation="modal"
    initialRouteName="AccessFoundation_TherapistHOMESCREEN"
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen
      name="AccessFoundation_TherapistHOMESCREEN"
      component={TherapistHomeScreen}
      options={{ title: "Access Foundation" }}
    />
    <Stack.Screen
      name="AccessFoundation_SessionHOMESCREEN"
      component={SessionMainScreen}
      options={{ title: "Access Foundation" }}
    />
  </Stack.Navigator>
);

export default StackNavigator;
