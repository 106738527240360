import React, { useState } from "react";
import { View, StyleSheet, FlatList } from "react-native";

import Colors from "../../config/Colors";
import AppButton from "../AppButton";
import AppSearchBar from "../AppSearchBar";
import AppFormList from "../AppFormList";

// const AppFormList = ({
//   item,
//   onPress,
//   switchBtnChange,
//   OnClickDeleteHandler,
//   btnEnable = false,
// }) => {
//   return (
//     <TouchableOpacity
//       style={{
//         width: "45%",
//         height: "100%",
//         margin: 40,
//       }}
//       onPress={onPress}
//     >
//       <View style={{ height: "90%", width: "100%" }}>
//         <iframe
//           // scrolling="no"
//           style={{ height: "100%" }}
//           src={item.link}
//         ></iframe>
//       </View>
//       <View style={styles.FormListStyles}>
//         <Text style={{ width: "50%", alignSelf: "center" }} numberOfLines={1}>
//           {item.name}
//         </Text>

//         {btnEnable && (
//           <View style={styles.btnView}>
//             <Switch
//               value={item.status == "active" ? true : false}
//               onValueChange={() => {
//                 switchBtnChange(item);
//               }}
//             />
//             <AppIcon
//               backgroundColor=""
//               touchAble
//               name="delete"
//               iconColor="red"
//               onPress={() => OnClickDeleteHandler(item)}
//             />
//           </View>
//         )}
//       </View>
//     </TouchableOpacity>
//   );
// };

function AppFormsCard({
  Items,
  onPress,
  switchBtnChange,
  OnClickDeleteHandler,
  btnEnable,
  OnPressSwitchDepartmentHandler,
}) {
  const [btnSelecter, setBtnSelecter] = useState(false);
  const [selectedForm, setSelectedForm] = useState();

  return (
    <View style={styles.container}>
      <View style={styles.mainViewStyles}>
        <View style={styles.btnViewStyles}>
          <AppButton
            width="20%"
            label="Switch Departments"
            onPress={OnPressSwitchDepartmentHandler}
          />
          <AppButton
            width="15%"
            style={{ borderWidth: 1 }}
            label="Form's List"
            color={!btnSelecter ? "InputText" : ""}
            onPress={() => setBtnSelecter(false)}
          />
          <AppButton
            width="15%"
            style={{ borderWidth: 1 }}
            label="Selected Form"
            color={btnSelecter ? "InputText" : ""}
            //onPress={() => setBtnSelecter(true)}
          />
        </View>

        {!btnSelecter ? (
          <View style={{ height: "95%", width: "100%" }}>
            <View style={{ height: "25%", width: "100%" }}>
              <AppSearchBar
                // height="15%"
                placeholder="Search Form Name ..."
                selectedAllForms
                getSelectedItem={(item) => {
                  setSelectedForm(item);
                  setBtnSelecter(true);
                }}
              />
            </View>
            <View style={styles.formListView}>
              <FlatList
                numColumns={2}
                data={Items}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item }) => (
                  <AppFormList
                    items={item}
                    switchBtnChange={switchBtnChange}
                    OnClickDeleteHandler={OnClickDeleteHandler}
                    btnEnable={btnEnable}
                    onPress={() => {
                      setSelectedForm(item);
                      setBtnSelecter(true);
                    }}
                  />
                )}
              />
            </View>
          </View>
        ) : (
          <View style={styles.formView}>
            <iframe style={{ height: "100%" }} src={selectedForm.link}></iframe>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  FormListStyles: {
    backgroundColor: Colors.InputText,
    padding: 10,
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    height: "10%",
  },
  formListView: {
    height: "75%",
    width: "100%",
  },
  formView: {
    height: "95%",
    width: "100%",
  },
  btnViewStyles: {
    height: "5%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  mainViewStyles: {
    height: "100%",
    width: "100%",
    position: "absolute",
    bottom: 0,
    borderWidth: 1,
    borderRadius: 10,
  },
  btnView: {
    alignItems: "center",
    flexDirection: "row",
    width: "50%",
    justifyContent: "space-around",
  },
});

export default AppFormsCard;
