import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Switch,
  TouchableOpacity,
  Text,
  useWindowDimensions,
} from "react-native";
import moment from "moment";

import AppButton from "../components/AppButton";
import AppText from "../components/AppText";
import AppIcon from "./AppIcon";
import Colors from "../config/Colors";

function AppEmpCard({
  labelTouchable = false,
  onPressDate,
  onPressName,
  items,
  editEnable = true,
  switchEnable = true,
  deleteEnable = true,
  btnSession = false,
  onPressDelete,
  onPressEdit,
  onPressAddFileEnable,
  listView = false,
  switchBtnChange,
  backgroundColor = "#fff",
  onPressActivation = false,
  onPress,
  txtBold = false,
  iconBackgroundColor = "#DBD7DC",
  iconEnable = true,
  onPressDocumentList,
}) {
  const [onClick, setOnClick] = useState(0);

  const [switchBtn, setSwitchBtn] = useState(
    items.status == "active" ? true : false
  );

  const { height, width } = useWindowDimensions();
  let windowHeight = width + height;

  let LabelComponent;

  if (labelTouchable == true) {
    LabelComponent = TouchableOpacity;
  } else {
    LabelComponent = View;
  }

  let totalHours =
    parseInt(items.total_hours * 60) + parseInt(items.total_mins);
  let totalUsedHours =
    parseInt(items.used_hours * 60) + parseInt(items.used_mins);

  let totalLeftMin = totalHours - totalUsedHours;
  let leftHours = ~~(totalLeftMin / 60);
  let leftMin = ~~(totalLeftMin % 60);

  let referralDate = moment(items.referralDate).format(
    "DD-MM-YYYY"
  );
  let planStartDate = moment(items.planStartDate).format(
    "DD-MM-YYYY"
  );
  let planEndDate = moment(items.planEndDate).format(
    "DD-MM-YYYY"
  );

  let birthDate = moment(items.ParticipantBirthDate).format(
    "DD-MM-YYYY"
  );

  let rightIconName = "eye-off";
  if (onClick != 0) {
    rightIconName = "eye";
  } else {
    rightIconName = "eye-off";
  }

  let planExpireDate;
  let dateColor;
  let fColor = "#000";

  const today = moment().format("YYYY-MM-DD");
  const after58DaysDate = moment()
    .add(58, "day")
    .format("YYYY-MM-DD");

  const planExpireDateForCompare = moment(
    items.planEndDate
  ).format("YYYY-MM-DD");

  if (items.planEndDate == "Plan Expiry Date") {
    planExpireDate = "Plan Expiry Date";
  } else {
    planExpireDate = moment(items.planEndDate).format(
      "DD-MM-YYYY"
    );
  }

  if (today > planExpireDateForCompare) {
    dateColor = "#FBB49C";
    fColor = "red";
  } else if (after58DaysDate > planExpireDateForCompare) {
    dateColor = "yellow";
  } else {
    dateColor = "#BBF7D1";
  }

  let TouchView;

  if (listView == true || onPressActivation == true) {
    TouchView = TouchableOpacity;
  } else {
    TouchView = View;
  }

  const OnClickPositionStatusHandler = async (items) => {
    setSwitchBtn(!switchBtn);
    switchBtnChange(items);
  };

  return (
    <View style={styles.container}>
      <TouchView
        style={[
          styles.mainViewStyles,
          {
            backgroundColor: backgroundColor,
          },
        ]}
        onPress={
          listView ? () => setOnClick(onClick == null ? 0 : null) : onPress
        }
      >
        <View style={styles.nameViewStyles}>
          {iconEnable ? (
            <View style={{ width: "30%" }}>
              <AppIcon name={rightIconName} size="30%" backgroundColor="" />
            </View>
          ) : (
            <View style={{ width: "30%" }}></View>
          )}

          <LabelComponent style={{ width: "30%" }} onPress={onPressName}>
            <AppText
              style={[
                { fontWeight: txtBold ? "bold" : "normal" },
                styles.txtLabelStyles,
              ]}
              label={items.name}
            />
          </LabelComponent>

          <AppText
            style={[
              { fontWeight: txtBold ? "bold" : "normal" },
              styles.txtLabelStyles,
            ]}
            label={items.position_name}
          />

          {items.servicesName && (
            <AppText
              style={[
                {
                  borderRadius: 10,
                  textAlign: "center",
                  backgroundColor:
                    items.servicesName == "Service" ? Colors.white : "",
                  fontWeight: txtBold ? "bold" : "normal",
                  alignSelf: "center",
                },

                styles.txtLabelStyles,
              ]}
              label={items.servicesName}
            />
          )}
          {items.servicesName && (
            <AppText
              style={[
                {
                  borderRadius: 10,
                  textAlign: "center",
                  backgroundColor:
                    items.servicesName == "Service" ? Colors.white : "",
                  fontWeight: txtBold ? "bold" : "normal",
                  alignSelf: "center",
                },

                styles.txtLabelStyles,
              ]}
              label={
                items.servicesName == "Service"
                  ? "Left Hours"
                  : `${leftHours}:${leftMin}`
              }
            />
          )}
          {items.planEndDate && (
            <LabelComponent style={{ width: "30%" }} onPress={onPressDate}>
              <AppText
                style={[
                  {
                    borderWidth:
                      items.planEndDate == "Plan Expiry Date" ? 0 : 0.2,
                    borderRadius: 10,
                    textAlign: "center",
                    color: fColor,
                    backgroundColor:
                      items.planEndDate == "Plan Expiry Date"
                        ? Colors.white
                        : dateColor,
                    fontWeight: txtBold ? "bold" : "normal",
                    alignSelf: "center",
                  },

                  styles.txtLabelStyles,
                ]}
                label={planExpireDate}
              />
            </LabelComponent>
          )}
        </View>
        <View style={styles.btnViewStyles}>
          {switchEnable && (
            <Switch
              onValueChange={() => OnClickPositionStatusHandler(items)}
              value={switchBtn}
            />
          )}
          {editEnable && (
            <AppIcon
              size="20%"
              touchAble
              IconType="FontAwesome5"
              name="pen"
              onPress={() => onPressEdit(items)}
              backgroundColor={iconBackgroundColor}
            />
          )}

          {deleteEnable && (
            <AppIcon
              size="20%"
              iconColor="red"
              touchAble
              name="delete"
              onPress={() => onPressDelete(items)}
              backgroundColor={iconBackgroundColor}
            />
          )}
        </View>
      </TouchView>

      {onClick == null ? (
        <View
          style={[
            {
              height: onClick,
              padding: onClick == 0 ? 0 : 10,
            },
            styles.listViewStyles,
          ]}
        >
          <View style={styles.itemViewList}>
            <View style={styles.leftViewStyles}>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Participant Name :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Participant Phone :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Participant Email :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Participant Address :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Participant Birth Date :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Participant Gender:
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Participant Diagnosis :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Guardian Name :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Guardian Contact :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Guardian Email :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Guardian Address :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Support coordinator Name :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Support coordinator Contact :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Support coordinator Email :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Support coordinator Address :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Referral Date :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Brief Task :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Plan Start Date :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                Plan End Date :
              </Text>
              <Text style={styles.laftLabelStyles} numberOfLines={1}>
                NDIS No :
              </Text>
            </View>
            <View style={styles.rightViewStyles}>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.name}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.contactNumber}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.ParticipantEmail}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.ParticipantAddress}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {birthDate}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.ParticipantGender}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.ParticipantDiagnosis}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.guardianName}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.guardianContact}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.guardianEmail}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.guardianAddress}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.supportCoordinatorName}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.supportCoordinatorNumber}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.supportCoordinatorEmail}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.supportCoordinatorAddress}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {referralDate}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {items.briefTask}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {planStartDate}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {planEndDate}
              </Text>
              <Text style={styles.labelStyles} numberOfLines={1}>
                {" "}
                {items.ndisNo}{" "}
              </Text>
            </View>
          </View>
          {btnSession && (
            <View style={[styles.btnViewStyles, { width: "100%" }]}>
              <AppButton
                color="blue"
                label="Document List"
                onPress={() => onPressDocumentList(items)}
              />
              <AppButton
                label="Submit Session"
                onPress={() => onPressAddFileEnable(items)}
              />
            </View>
          )}
        </View>
      ) : (
        <></>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
    width: "90%",
    alignSelf: "center",
    overflow: "hidden",
  },
  mainViewStyles: {
    padding: 5,
    width: "100%",
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 10,
  },

  txtLabelStyles: {
    paddingHorizontal: 10,
    width: "95%",
    //fontSize: "100%",
  },
  nameViewStyles: {
    flexDirection: "row",
    width: "70%",
    alignItems: "center",
  },
  btnViewStyles: {
    flexDirection: "row",
    width: "30%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  listViewStyles: {
    width: "100%",
    backgroundColor: Colors.darkBlue,
    borderRadius: 10,
  },
  itemViewList: {
    flexDirection: "row",
  },
  leftViewStyles: {
    width: "30%",
    height: "100%",
  },
  rightViewStyles: {
    height: "100%",
    width: "70%",
  },
  laftLabelStyles: {
    padding: 5,
    borderWidth: 0.5,
    fontWeight: "bold",
    height: "100%",
  },
  labelStyles: {
    height: "100%",
    padding: 5,
    borderWidth: 0.5,
  },
});

export default AppEmpCard;
