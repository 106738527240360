import React from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";

import AppIcon from "../components/AppIcon";
import AppText from "../components/AppText";
import Colors from "../config/Colors";

function AppDrawerTypeBtn({
  border = 0,
  iconName,
  IconType,
  label,
  onPress,
  backgroundColor,
  style,
}) {
  const { height, width } = useWindowDimensions();

  let windowHeight = 0;

  if (height < width) {
    windowHeight = width;
  } else {
    windowHeight = height;
  }

  return (
    <TouchableOpacity
      style={[
        styles.container,
        { backgroundColor: backgroundColor, borderWidth: border },
        style,
      ]}
      onPress={onPress}
    >
      <AppText
        style={{ width: "70%", fontSize: `${windowHeight / 20}%` }}
        label={label}
      />
      <AppIcon
        size="10%"
        name={iconName}
        IconType={IconType}
        backgroundColor={backgroundColor}
      />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 30,
    width: "90%",
    paddingVertical: 10,
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    borderRadius: 20,
    marginVertical: 5,
  },
});

export default AppDrawerTypeBtn;
