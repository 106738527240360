import React from "react";
import { View, StyleSheet, Text, Dimensions } from "react-native";

function ReportScreen({}) {
  return (
    <View style={styles.container}>
      <View style={styles.mainCardStyles}></View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    height: "98%",
    width: "98%",
    borderRadius: 5,
  },
  mainCardStyles: {},
});

export default ReportScreen;
