import React, { useState, useContext, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  FlatList,
  Platform,
  Image,
  TouchableOpacity,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import * as WebBrowser from "expo-web-browser";

import TherapistParticipantListScreen from "./participant/TherapistParticipantListScreen";
import TherapyTemplatesHomeScreen from "./TherapyTemplates/TherapyTemplatesHomeScreen";
import MyWorkScreen from "./MyWork/MyWorkScreen";

import listingAPI from "../../../api/listings";
import AuthContext from "../../../auth/context";
import AppDrawerTypeBtn from "../../../components/AppDrawerTypeBtn";
import Colors from "../../../config/Colors";
import Cache from "../../../utility/cache";

// const windowWidth = Dimensions.get("window").width;
// const windowHeight = Dimensions.get("window").height;

function TherapistHomeScreen({ navigation }) {
  const authContext = useContext(AuthContext);

  const [currentScreen, setCurrentScreen] = useState(
    "TherapistParticipantListScreen"
  );
  const [therapistDetails, setTherapistDetails] = useState({ name: "" });

  const { height, width } = useWindowDimensions();

  let windowHeight = width + height;

  // if (height < width) {
  //   windowHeight = width;
  // } else {
  //   windowHeight = height;
  // }

  let Screen;

  useEffect(() => {
    GetParticipantAllName();
  }, []);

  const GetParticipantAllName = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      therapist_id: therapist_main_id,
    });
    const { data } = await listingAPI.TherapyEmpPost({
      endPoint: "/selected/allDetails/of/TherapyEmp/status/notDeleted",
      data: myJsonServices,
    });

    if (data.length > 0) {
      setTherapistDetails(data[0]);
    } else {
      setTherapistDetails({ name: "" });
    }
  };

  if (currentScreen == "TherapistParticipantListScreen") {
    Screen = TherapistParticipantListScreen;
  } else if (currentScreen == "MyWork") {
    Screen = MyWorkScreen;
  } else if (currentScreen == "Templates") {
    Screen = TherapyTemplatesHomeScreen;
  }

  const OutlookLoginHandler = async () => {
    let result = await WebBrowser.openBrowserAsync(
      "https://login.live.com/login.srf?wa=wsignin1.0&rpsnv=13&ct=1655456536&rver=7.0.6737.0&wp=MBI_SSL&wreply=https%3a%2f%2foutlook.live.com%2fowa%2f%3fnlp%3d1%26RpsCsrfState%3d812f5175-8afc-1c49-9076-0015d08561d3&id=292841&aadredir=1&CBCXT=out&lw=1&fl=dob%2cflname%2cwld&cobrandid=90015"
    );
  };

  const SignOutHandler = async () => {
    await Cache.store("signOut", true);
    Cache.removeItem("u_department");
    authContext.setUser("login");
  };

  return (
    <LinearGradient
      style={[styles.container, { height, width }]}
      colors={["#97D9E100", "#D9AFD900"]}
    >
      {/* <View style={styles.leftViewStyles}> */}

      <LinearGradient
        style={styles.leftViewStyles}
        colors={["rgba(255, 255, 255, 0.9)"]}
      >
        <View style={[styles.ProfilesStylesView]}>
          <TouchableOpacity style={{ width: "50%" }}>
            <Image
              style={[
                styles.ProfileImageStyles,
                { height: windowHeight / 45, width: windowHeight / 45 },
              ]}
              source={require("../../../config/img/profile.png")}
            />
          </TouchableOpacity>
          <View style={{ width: "50%" }}>
            <div>
              <marquee
                scrollamount="4"
                style={{ color: "#fff", fontSize: "100%", fontWeight: "bold" }}
              >
                {therapistDetails.name}
              </marquee>
            </div>
            {/* <View
              style={{
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <Text
                style={[
                  styles.onlineTxtStyles,
                  { fontSize: `${windowHeight / 30}%` },
                ]}
              >
                Online
              </Text>
            </View> */}
          </View>
        </View>
        <ScrollView
          style={{ width: "100%", alignSelf: "center" }}
          showsVerticalScrollIndicator={false}
        >
          <AppDrawerTypeBtn
            border={1}
            iconName="wheelchair"
            IconType="FontAwesome"
            label="Participant"
            onPress={() => setCurrentScreen("TherapistParticipantListScreen")}
            backgroundColor={
              currentScreen == "TherapistParticipantListScreen"
                ? Colors.white
                : ""
            }
          />

          <AppDrawerTypeBtn
            border={1}
            iconName="account"
            label="My Work"
            onPress={() => setCurrentScreen("MyWork")}
            backgroundColor={currentScreen == "MyWork" ? Colors.white : ""}
          />

          <AppDrawerTypeBtn
            border={1}
            iconName="account"
            label="Templates"
            onPress={() => setCurrentScreen("Templates")}
            backgroundColor={currentScreen == "Templates" ? Colors.white : ""}
          />
          {/* <AppDrawerTypeBtn
            border={1}
            iconName="email"
            label="OutLook"
            //onPress={() => setCurrentScreen("OutLook")}
            backgroundColor={currentScreen == "OutLook" ? Colors.white : ""}
            onPress={() => OutlookLoginHandler()}
          /> */}
        </ScrollView>
        <AppDrawerTypeBtn
          style={styles.btnSignOutStyles}
          border={1}
          IconType="Entypo"
          iconName="log-out"
          label="Sign Out"
          onPress={() => SignOutHandler("SignOut")}
          backgroundColor={currentScreen == "SignOut" ? Colors.white : ""}
        />
      </LinearGradient>

      {/* </View> */}
      <View style={styles.rightViewStyles}>
        <Screen navigation={navigation} data={therapistDetails} />
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    // height: windowHeight,
    // width: windowWidth,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#eeeee4",
  },
  ProfilesStylesView: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  ProfileImageStyles: {
    // height: 70,
    // width: 70,
    borderRadius: 20,
    borderWidth: 1,
    marginHorizontal: 10,
    backgroundColor: "#fff",
  },
  onlineTxtStyles: {
    color: "green",
    backgroundColor: "#fff",
    //fontSize: "50%",
    borderRadius: 10,
    alignSelf: "center",
    padding: 5,
  },
  leftViewStyles: {
    height: "90%",
    width: "15%",
    borderRadius: 20,
    borderWidth: 2,
    alignItems: "center",
    paddingHorizontal: 10,
    backgroundColor: "rgba(0,0,0, 0.4) 100%",
  },
  rightViewStyles: {
    alignItems: "center",
    justifyContent: "center",
    height: "90%",
    width: "83%",
    borderRadius: 5,
    backgroundColor: Colors.lightDark,
    borderRadius: 20,
    borderWidth: 2,
    backgroundColor: "rgba(0,0,0,0.2) 100%",
    padding: 5,
    //backgroundColor: "rgba(52, 52, 52, alpha)",
  },
  btnSignOutStyles: {
    position: "absolute",
    bottom: 0,
  },
  webview: {
    flex: 1,
  },
});

export default TherapistHomeScreen;
