import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text, FlatList } from "react-native";
import moment from "moment";
import Modal from "react-native-modal";

import Cache from "../../../../utility/cache";
import listingAPI from "../../../../api/listings";
import AppAlert from "../../../../components/AppAlert";
import AppButton from "../../../../components/AppButton";
import AppCalander from "../../../../components/AppCalander";
import AppTouchableLabel from "../../../../components/AppTouchableLabel";
import AppPicker from "../../../../components/AppPicker";
import AppSessionYearSelecter from "../../../../components/cards/AppSessionYearSelecter";
import AppInputText from "../../../../components/AppInputText";
import AppSeccionCard from "../../../../components/AppSeccionCard";
import AppReportGenerator from "../../../../components/AppReportGenerator";

function AllSessionsDetails({ navigation, tempData }) {
  let item = tempData;

  const [refreshData, setRefreshData] = useState();
  const [pdfModal, setPdfModal] = useState(false);
  const [allSessionYears, setAllSessionYears] = useState([]);
  const [allSessionDetails, setAllSessionDetails] = useState([]);
  const [allSessionDetailsForFilter, setAllSessionDetailsForFilter] = useState(
    []
  );
  const [filteredBy, setFilteredBy] = useState({ main_id: -1 });
  const [sessionId, setSessionId] = useState(0);
  const [subject, setSubject] = useState();
  const [selectTherapistName, setSelectTherapistName] = useState();
  const [allTherapist, setAllTherapist] = useState([]);
  const [alert, setAlert] = useState({ modal: false, alert: "" });
  const [date, setDate] = useState("DD/MM/YYYY");

  useEffect(() => {
    GetAllSessionsGroupByYear();
    GetAllTherapist();
    GetAllSessionsData();
  }, []);

  useEffect(() => {
    GetAllSessionsData();
  }, [refreshData]);

  const GetAllSessionsGroupByYear = async () => {
    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint:
        "/select/all/session/of/particular/Participants/for/admin/group/by/year",
      data: myJsonServices,
    });

    setAllSessionYears(data);
  };

  const GetAllTherapist = async () => {
    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint:
        "/select/all/therapist/of/particular/services/of/particular/Participants/for/admin",
      data: myJsonServices,
    });

    setAllTherapist(data);
  };

  const GetAllSessionsData = async () => {
    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint: "/select/all/session/of/particular/Participants/for/Admin",
      data: myJsonServices,
    });

    setAllSessionDetails(data);
    setAllSessionDetailsForFilter(data);
  };

  const OnPressFilterHandler = async () => {
    if (filteredBy.main_id == 1) {
      if (date === "DD/MM/YYYY") {
        setAlert({ modal: true, alert: "Please,Select Date" });
        return;
      }

      let sDate = moment(date, "DD/MM/YYYY").format("YYYY/MM/DD");

      let data = allSessionDetailsForFilter.filter(
        (i) => i.sessionDate <= sDate
      );

      setAllSessionDetails(data);
    } else if (filteredBy.main_id == 2) {
      if (sessionId === 0) {
        setAlert({ modal: true, alert: "Please,Select Session Id" });
        return;
      }

      let data = allSessionDetailsForFilter.filter(
        (i) => i.main_id == sessionId
      );

      setAllSessionDetails(data);
    } else if (filteredBy.main_id == 3) {
      if (!subject) {
        setAlert({ modal: true, alert: "Please,Select Subject" });
        return;
      }

      let data = allSessionDetailsForFilter.filter(
        (i) => i.mainSubject == subject.trim()
      );

      setAllSessionDetails(data);
    } else if (filteredBy.main_id == 4) {
      if (!selectTherapistName) {
        setAlert({ modal: true, alert: "Please,Select Therapist" });
        return;
      }

      let data = allSessionDetailsForFilter.filter(
        (i) => i.therapist_main_id == selectTherapistName.main_id
      );

      setAllSessionDetails(data);
    }
  };

  const OnPressReportGeneratorHandler = () => {
    setPdfModal(true);
  };

  const filter = [
    { main_id: 0, name: "No Filter" },
    { main_id: 1, name: "Date" },
    { main_id: 2, name: "Session Id" },
    { main_id: 3, name: "Subject" },
    { main_id: 4, name: "Therapist" },
  ];

  return (
    <View style={styles.container}>
      <AppAlert
        alert={alert}
        okBtnPress={() => setAlert({ modal: false, alert: "" })}
      />
      <Modal
        style={{ height: "80%", alignItems: "center" }}
        isVisible={pdfModal}
      >
        <AppReportGenerator items={allSessionDetails} />
        <AppButton
          style={{ alignSelf: "center" }}
          label="Back"
          onPress={() => setPdfModal(false)}
        />
      </Modal>
      <View style={styles.filterViewStyles}>
        <AppPicker
          width="20%"
          label="Filter By : "
          items={filter}
          selectedItem={filteredBy}
          onSelectItem={(item) => {
            if (item.main_id === 0) {
              setDate("DD/MM/YYYY");
              setSessionId(0);
              setSubject("");
              setSelectTherapistName("");
              GetAllSessionsData();
            }
            setFilteredBy(item);
          }}
        />

        {/* <AppTouchableLabel
          items={filter}
          width="20%"
          placeholder="Filter By : "
          label="Filter By : "
          iconName="account"
          menuLabel={"Filter By"}
          selectedMenu={(item) => {
            if (item.main_id === 0) {
              setDate("DD/MM/YYYY");
              setSessionId(0);
              setSubject("");
              setSelectTherapistName("");

              GetAllSessionsData();
            }
            setFilteredBy(item);
          }}
        /> */}

        {filteredBy.main_id == 1 && (
          <AppCalander
            width="50%"
            label="Date : "
            datePlaceholder={date}
            selectedDate={(date) => setDate(date)}
            maxDate={new Date()}
          />
        )}
        {filteredBy.main_id == 2 && (
          <AppInputText
            width="50%"
            placeholder="Session Id : "
            label="Session Id : "
            iconName="numeric"
            value={sessionId ?? ""}
            onChangeText={(txt) => setSessionId(txt)}
          />
        )}
        {filteredBy.main_id == 3 && (
          <AppInputText
            width="50%"
            placeholder="Subject : "
            label="Subject : "
            iconName="numeric"
            value={subject ?? ""}
            onChangeText={(txt) => setSubject(txt)}
          />
        )}
        {filteredBy.main_id == 4 && (
          // <AppTouchableLabel
          //   items={allTherapist}
          //   width="50%"
          //   placeholder="Select Therapist name : "
          //   label="Select Therapist name : "
          //   iconName="account"
          //   menuLabel={"Select Therapist name"}
          //   selectedMenu={(item) => setSelectTherapistName(item)}
          // />

          <AppPicker
            width="50%"
            label="Filter By : "
            items={allTherapist}
            selectedItem={selectTherapistName}
            onSelectItem={(item) => setSelectTherapistName(item)}
          />
        )}
        {filteredBy.main_id > 0 && (
          <>
            <AppButton
              style={{ marginTop: 20 }}
              label="Filter"
              width="15%"
              onPress={() => OnPressFilterHandler()}
            />
          </>
        )}
      </View>
      <View style={{ height: "90%" }}>
        {filteredBy.main_id < 1 ? (
          <>
            {allSessionYears.length > 0 ? (
              // allSessionYears.map((item) => (
              //   <AppSessionYearSelecter
              //     key={item.sessionYear}
              //     item={item}
              //     editAndDelete={false}
              //     OnClickSessionDateHandler={() => OnClickSessionDateHandler()}
              //   />
              // ))

              <FlatList
                data={allSessionYears}
                showsVerticalScrollIndicator={false}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item }) => (
                  <AppSessionYearSelecter
                    key={item.sessionYear}
                    item={item}
                    editAndDelete={false}
                    OnClickSessionDateHandler={() =>
                      OnClickSessionDateHandler()
                    }
                  />
                )}
              />
            ) : (
              <Text style={{ alignSelf: "center", fontWeight: "bold" }}>
                No data found
              </Text>
            )}
          </>
        ) : (
          <View style={{ height: "100%" }}>
            <View style={{ height: "90%" }}>
              <AppSeccionCard
                editAndDelete
                items={allSessionDetails}
                refreshData={(item) => setRefreshData(item)}
              />
            </View>

            <AppButton
              style={{ marginTop: 20, alignSelf: "center" }}
              width="15%"
              color="blue"
              label="Report Generator"
              onPress={() => OnPressReportGeneratorHandler()}
            />
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: 10,
    padding: 10,
    overflow: "hidden",
  },
  btnViewStyles: {
    height: 50,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  filterViewStyles: {
    height: 70,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  pdfModalStyles: {
    height: "90%",
    width: "90%",
    backgroundColor: "white",
  },
});

export default AllSessionsDetails;
