import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet, Text, FlatList, Dimensions } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import moment from "moment";

import listingAPI from "../../../../api/listings";
import Routes from "../../../../navigation/routes";
import AllSessionsDetails from "../SessationHandler/AllSessionsDetails";
import NewSessionAdd from "../SessationHandler/NewSessionAdd";
import AuthContext from "../../../../auth/context";
import AppDrawerTypeBtn from "../../../../components/AppDrawerTypeBtn";
import Colors from "../../../../config/Colors";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

function SessionMainScreen({ navigation, route }) {
  const authContext = useContext(AuthContext);
  const items = route.params.items;
  const [currentScreen, setCurrentScreen] = useState("AllSessions");
  const [sessionData, setSessionData] = useState([]);

  const [selectedSessionData, setSelectedSessionData] = useState([]);

  let Screen;

  if (currentScreen == "NewSession") {
    Screen = NewSessionAdd;
  } else if (currentScreen == "AllSessions") {
    Screen = AllSessionsDetails;
  }

  const OnClickBackHandler = () => {
    navigation.goBack();
  };

  return (
    <LinearGradient
      style={styles.container}
      colors={["#97D9E100", "#D9AFD900"]}
    >
      <LinearGradient
        style={[styles.leftViewStyles, { height: windowHeight - 100 }]}
        colors={["rgba(255, 255, 255, 0.9)"]}
      >
        <Text style={styles.labelStyles}>{items.name}</Text>

        <AppDrawerTypeBtn
          border={1}
          iconName="yoga"
          label="New Session"
          onPress={() => setCurrentScreen("NewSession")}
          backgroundColor={Colors.blue}
        />
        <AppDrawerTypeBtn
          border={1}
          iconName="yoga"
          label="All Sessions"
          onPress={() => setCurrentScreen("AllSessions")}
          backgroundColor={currentScreen == "AllSessions" ? Colors.white : ""}
        />
        <AppDrawerTypeBtn
          style={{ position: "absolute", bottom: 0 }}
          border={1}
          iconName="keyboard-backspace"
          label="Back"
          onPress={() => OnClickBackHandler()}
          backgroundColor={Colors.yellow}
        />
      </LinearGradient>

      <View style={[styles.rightViewStyles, { height: windowHeight - 100 }]}>
        <Screen
          navigation={navigation}
          data={currentScreen == "NewSession" ? items : selectedSessionData}
          tempData={items}
        />
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#eeeee4",
  },
  flatListViewStyles: {
    width: "90%",
    height: "90%",
    alignSelf: "center",
  },
  ProfilesStylesView: {
    height: "10%",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  ProfileImageStyles: {
    height: 70,
    width: 70,
    borderRadius: 20,
    borderWidth: 1,
    marginHorizontal: 10,
    backgroundColor: "#fff",
  },
  onlineTxtStyles: {
    color: "green",
    backgroundColor: "#fff",
    fontSize: 15,
    paddingVertical: 5,
    borderRadius: 10,
    alignSelf: "center",
    padding: 5,
  },
  leftViewStyles: {
    height: "90%",
    width: "15%",
    borderRadius: 20,
    borderWidth: 2,
    alignItems: "center",
    paddingHorizontal: 1,
    backgroundColor: "rgba(0,0,0, 0.4) 100%",
  },
  rightViewStyles: {
    padding: 10,
    height: "90%",
    width: "83%",
    borderRadius: 5,
    backgroundColor: Colors.lightDark,
    borderRadius: 20,
    borderWidth: 2,
    backgroundColor: "rgba(0,0,0,0.2) 100%",

    //backgroundColor: "rgba(52, 52, 52, alpha)",
  },
  btnSignOutStyles: {
    position: "absolute",
    bottom: 0,
  },
  webview: {
    flex: 1,
  },
  labelStyles: {
    color: "#fff",
    fontSize: 20,
    fontWeight: "bold",
    marginVertical: 20,
  },
});

export default SessionMainScreen;
