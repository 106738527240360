import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text, FlatList } from "react-native";

import AppTherapyDepartmentCart from "../../../../components/cards/AppTherapyDepartmentCart";
import AppInputText from "../../../../components/AppInputText";
import AppTouchableLabel from "../../../../components/AppTouchableLabel";
import AppPicker from "../../../../components/AppPicker";
import AppButton from "../../../../components/AppButton";
import AppAlert from "../../../../components/AppAlert";
import AppAlertYesNo from "../../../../components/AppAlertYesNo";
import listingAPI from "../../../../api/listings";

function AddAdmin({}) {
  const [allTherapist, setAllTherapist] = useState([]);
  const [update, setUpdate] = useState({
    update: false,
    main_id: 0,
  });
  const [allDepartment, setAllDepartment] = useState([]);
  const [departmentName, setDepartmentName] = useState();
  const [touchablePlaceholder, setTouchablePlaceholder] = useState(
    "Select Therapist name"
  );
  const [deleteModal, setDeleteModal] = useState({ item: "", modal: false });
  const [alertDetails, setAlertDetails] = useState({
    modal: false,
    alert: "",
  });
  const [selectTherapistName, setSelectTherapistName] = useState({
    name: "",
    therapist_id: 0,
  });

  useEffect(() => {
    GetAllEmployees();
    GetAllTherapyDepartment();
  }, []);

  const GetAllEmployees = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.TherapyTeamGet({
      endPoint: "/Select/All/Therapy/Team",
      data: myJsonServices,
    });
    setAllTherapist(data);
  };

  const GetAllTherapyDepartment = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.TherapyDepartment({
      endPoint: "/Select/All/therapyDepartment",
      data: myJsonServices,
    });

    setAllDepartment(data);
  };

  const OnClickAddDepartmentHandler = async () => {
    if (selectTherapistName.therapist_id == 0) {
      setAlertDetails({
        modal: true,
        alert: "Please, Select Therapist",
      });
      return;
    } else if (!departmentName) {
      setAlertDetails({
        modal: true,
        alert: "Please, Add Department Name",
      });
      return;
    }

    if (!update.update) {
      const myJsonServices = JSON.stringify({
        therapist_main_id: selectTherapistName.therapist_id,
        departmentName: departmentName.toUpperCase().trim(),
      });
      const { data } = await listingAPI.TherapyDepartment({
        endPoint: "/Insert/therapyDepartment",
        data: myJsonServices,
      });

      if (data == "dublicate") {
        setAlertDetails({
          modal: true,
          alert: "This department is exists.",
        });
        return;
      }

      setAllDepartment(data);
      setSelectTherapistName({ name: "", therapist_id: 0 });
      setDepartmentName("");
    } else {
      const myJsonServices = JSON.stringify({
        therapist_main_id: selectTherapistName.therapist_id,
        departmentName: departmentName.toUpperCase().trim(),
        main_id: update.main_id,
      });
      const { data } = await listingAPI.TherapyDepartment({
        endPoint: "/Update/therapyDepartment",
        data: myJsonServices,
      });

      if (data == "dublicate") {
        setAlertDetails({
          modal: true,
          alert: "This department is exists.",
        });
        return;
      }
      setAllDepartment(data);
      setSelectTherapistName({ name: "", therapist_id: 0 });
      setDepartmentName("");
    }
  };

  const OnClickDeleteYesHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      main_id: deleteModal.item.main_id,
    });
    const { data } = await listingAPI.TherapyDepartment({
      endPoint: "/Delete/therapyDepartment",
      data: myJsonServices,
    });

    setAllDepartment(data);
    setDeleteModal({ item: "", modal: false });
  };

  const OnClickEditHandler = async (item) => {
    setSelectTherapistName({
      name: item.therapistName,
      therapist_id: item.therapist_main_id,
    });
    setDepartmentName(item.departmentName);
    setUpdate({ update: true, main_id: item.main_id });
  };

  return (
    <View style={styles.container}>
      <AppAlertYesNo
        mainViewStyles={{ width: "90%", height: "30%" }}
        isModalVisible={deleteModal.modal}
        leftBtnLabel="No"
        noBtnPress={() => setDeleteModal({ item: "", modal: false })}
        yesBtnPress={() => OnClickDeleteYesHandler()}
        rightBtnLabel="Yes"
        Alert="Are you sure, Do you want to delete ?"
      />
      <AppAlert
        alert={alertDetails}
        okBtnPress={() =>
          setAlertDetails({
            modal: false,
            alert: "",
          })
        }
      />
      <View style={styles.topViewStyles}>
        <AppPicker
          width="30%"
          label="Select Therapist"
          items={allTherapist}
          selectedItem={selectTherapistName}
          onSelectItem={(item) =>
            setSelectTherapistName({
              name: item.name,
              therapist_id: item.main_id,
            })
          }
        />

        {/* <AppTouchableLabel
          items={allTherapist}
          width="30%"
          placeholder={touchablePlaceholder}
          iconName="account"
          menuLabel={touchablePlaceholder}
          selectedMenu={(item) =>
            setSelectTherapistName({
              name: item.name,
              therapist_id: item.main_id,
            })
          }
        /> */}
        <AppInputText
          label="Ebter Department Name "
          iconName="tasks"
          IconType="FontAwesome"
          width="30%"
          placeholder="Department Name : "
          value={departmentName ?? ""}
          onChangeText={(txt) => setDepartmentName(txt)}
        />
        <AppButton
          color={update.update == true ? "yellow" : "blue"}
          label={update.update == true ? "Update" : "Add"}
          onPress={() => OnClickAddDepartmentHandler()}
        />
      </View>
      <View style={styles.bottomViewStyles}>
        <FlatList
          data={allDepartment}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <AppTherapyDepartmentCart
              data={item}
              OnClickEditHandler={(item) => OnClickEditHandler(item)}
              OnClickDeleteHandler={(item) =>
                setDeleteModal({ item: item, modal: true })
              }
            />
          )}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
  },
  topViewStyles: {
    marginTop: 10,
    flexDirection: "row",
    width: "100%",
    height: "10%",
    justifyContent: "space-around",
  },
  bottomViewStyles: {
    marginTop: 10,
    width: "100%",
    height: "85%",
  },
});

export default AddAdmin;
