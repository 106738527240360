import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text, Alert } from "react-native";

import TherapistWorkFilter from "../../../../components/CommanScreen/TherapistWorkFilter";

function MyWorkScreen({ data }) {
  let item = data;

  return (
    <View style={styles.container}>
      <TherapistWorkFilter data={item} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 20,
    padding: 10,
  },
});

export default MyWorkScreen;
