import client from "./client";

//Office
const endPoint_Office = "/AddNewOffice";
const endPoint_LocationChecker = "/LocationChecker";

//ClamiedBySession
const endPoint_ClamiedBySession = "/ClamiedBySession";

// Attendance
const endPoint_Attendance = "/Attendance";

// Department
const endPoint_Department = "/AddNewDepartment";

// Allocate Department
const endPoint_AllocateDepartment = "/AllocateDepartment";
const endPoint_AllocateManager = "/AllocateManager";

// Try FolderHandler
const endPoint_DocumnetHandler = "/DocumnetHandler";

//Penal
const endPoint_Panel = "/Panel";
const endPoint_Account = "/Account";

// Forms
const endPoint_FormHandler = "/FormHandler";

//Therapy
//   admin
const endPoint_Services = "/Services";
const endPoint_TherapyTeam = "/TherapyTeam";
const endPoint_ParticipantPlanManagement = "/ParticipantPlanManagement";
const endPoint_Participant = "/Participant";
const endPoint_AllocateServices = "/AllocateServices";
const endPoint_AllocateTherapist = "/AllocateTherapist";
const endPoint_TherapyEmp = "/TherapyEmp";
const endPoint_TherapyDepartment = "/therapyDepartment";
const endPoint_ExtraExpenses = "/ExtraExpenses";
//   therapist

const endPoint_ParticipantForTherapis = "/ParticipantForTherapis";
const endPoint_EveryDaySessionHandler = "/EveryDaySessionHandler";
const endPoint_ParticipentsDocument = "/ParticipentsDocument";
const endPoint_ParticipentSessionDocument = "/SessionDocument";

const endPoint_documentUpload = "/documentUpload";
const endPoint_Email = "/EmailSender";

// start Office ........
const OfficesHandler_POST = async (listing) => {
  return client.post(endPoint_Office + listing.endPoint, listing.data);
};
// End Office ........

// start Office ........
const SendEmailHandler_POST = async (listing) => {
  return client.post(endPoint_Email + listing.endPoint, listing.data);
};
// End Office ........

// start Office ........
const ClamiedBySession_POST = async (listing) => {
  return client.post(
    endPoint_ClamiedBySession + listing.endPoint,
    listing.data
  );
};
// End Office ........

// start Attendance ........
const Attendance_POST = async (listing) => {
  return client.post(endPoint_Attendance + listing.endPoint, listing.data);
};
// End Attendance ........

// start Department ........
const DepartmentHandler_POST = async (listing) => {
  return client.post(endPoint_Department + listing.endPoint, listing.data);
};
// End Department ........

// start Department ........
const LocationChecker_POST = async (listing) => {
  return client.post(endPoint_LocationChecker + listing.endPoint, listing.data);
};
// End Department ........

// start Department ........
const AllocateDepartmentHandler_POST = async (listing) => {
  return client.post(
    endPoint_AllocateDepartment + listing.endPoint,
    listing.data
  );
};
// End Department ........

// start Department ........
const AllocateManagerHandler_POST = async (listing) => {
  return client.post(endPoint_AllocateManager + listing.endPoint, listing.data);
};
// End Department ........

// start Penal ........
const PelanPost = async (listing) => {
  return client.post(endPoint_Panel + listing.endPoint, listing.data);
};
// End Penal ........

// start TRY Document Handler ........
const DocumnetHandler = async (listing) => {
  return client.post(endPoint_DocumnetHandler + listing.endPoint, listing.data);
};
// End TRY Document Handler ........

// start Form ........
const FormHandlerPost = async (listing) => {
  return client.post(endPoint_FormHandler + listing.endPoint, listing.data);
};
// End Form ........

// start Therapy ........

const ExtraExpenses = async (listing) => {
  return client.post(endPoint_ExtraExpenses + listing.endPoint, listing.data);
};

const Services = async (listing) => {
  return client.post(endPoint_Services + listing.endPoint, listing.data);
};

const TherapyDepartment = async (listing) => {
  return client.post(
    endPoint_TherapyDepartment + listing.endPoint,
    listing.data
  );
};

const ParticipantPlanManagement = async (listing) => {
  return client.post(
    endPoint_ParticipantPlanManagement + listing.endPoint,
    listing.data
  );
};

const TherapyTeamGet = async (listing) => {
  return client.get(endPoint_TherapyTeam + listing.endPoint);
};

const TherapyTeamPost = async (listing) => {
  return client.post(endPoint_TherapyTeam + listing.endPoint, listing.data);
};

const ParticipantPost = async (listing) => {
  return client.post(endPoint_Participant + listing.endPoint, listing.data);
};

const ParticipantForTherapisPost = async (listing) => {
  return client.post(
    endPoint_ParticipantForTherapis + listing.endPoint,
    listing.data
  );
};

const EveryDaySessionHandler = async (listing) => {
  return client.post(
    endPoint_EveryDaySessionHandler + listing.endPoint,
    listing.data
  );
};

const ParticipentsSessionDocument_dataIntoTable = async (listing) => {
  return client.post(
    endPoint_ParticipentSessionDocument + listing.endPoint,
    listing.data
  );
};

const ParticipentsDocument_dataIntoTable = async (listing) => {
  return client.post(
    endPoint_ParticipentsDocument + listing.endPoint,
    listing.data
  );
};

const AllocateServicesPost = async (listing) => {
  return client.post(
    endPoint_AllocateServices + listing.endPoint,
    listing.data
  );
};

const AllocateTherapistPost = async (listing) => {
  return client.post(
    endPoint_AllocateTherapist + listing.endPoint,
    listing.data
  );
};

const TherapyEmpPost = async (listing) => {
  return client.post(endPoint_TherapyEmp + listing.endPoint, listing.data);
};
// End Therapy ........

// start Account .....
const AccountPost = async (listing) => {
  return client.post(endPoint_Account + listing.endPoint, listing.data);
};
// end Account .....

const ImageTryPost = async (listing) => {
  const data = new FormData();
  data.append("image", listing.data.file);
  return client.post(endPoint_documentUpload + listing.endPoint, data);
};

const FileUploadInFolderPost = async (listing) => {
  const data = new FormData();
  data.append("image", listing.data.file);
  data.append("Folder", listing.Folder);
  return client.post(endPoint_documentUpload + listing.endPoint, data);
};

const FileDownloadPost = async (listing) => {
  return client.post(endPoint_documentUpload + listing.endPoint, listing.data);
};
const ImageTryDelete = async (listing) => {
  return client.post(endPoint_documentUpload + listing.endPoint, listing.data);
};

export default {
  // email
  SendEmailHandler_POST,
  //try document handler
  DocumnetHandler,
  ClamiedBySession_POST,
  //Office handler
  OfficesHandler_POST,
  LocationChecker_POST,

  //department handler
  DepartmentHandler_POST,
  AllocateDepartmentHandler_POST,
  AllocateManagerHandler_POST,

  //Penal
  PelanPost,
  AccountPost,
  //form
  FormHandlerPost,
  // Attendance
  Attendance_POST,
  //Therapy
  //  admin
  Services,
  ParticipantPlanManagement,
  TherapyTeamGet,
  TherapyTeamPost,
  ParticipantPost,
  AllocateServicesPost,
  AllocateTherapistPost,
  TherapyEmpPost,
  TherapyDepartment,
  ExtraExpenses,
  // therapist
  ParticipantForTherapisPost,
  EveryDaySessionHandler,
  ParticipentsDocument_dataIntoTable,
  ParticipentsSessionDocument_dataIntoTable,
  FileUploadInFolderPost,

  ImageTryPost,
  ImageTryDelete,
  FileDownloadPost,
};
