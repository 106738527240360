import React, { useContext, useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import moment from "moment";
import * as Network from "expo-network";

import Cache from "../../utility/cache";

import DepartContext from "../../auth/DepartContext";
import AppDepartmentCard from "../../components/cards/AppDepartmentCard";

const cards = [
  {
    id: 1,
    department: "IT",
    cardName: "IT Department",
    imgSource:
      "https://png.pngtree.com/png-vector/20220517/ourmid/pngtree-cyberspace-management-concept-in-flat-design-png-image_4695305.png",
  },
  {
    id: 2,
    department: "Therapy",
    cardName: "Therapy Department",
    imgSource:
      "https://www.pngitem.com/pimgs/m/463-4636538_physical-therapy-clipart-png-download-physical-therapy-clipart.png",
  },
  // {
  //   id: 3,
  //   department: "Forms",
  //   cardName: "Forms",
  //   imgSource:
  //     "https://ticaboouid.com/images/images_rwi/rwi_1446_google-forms_1352902479_5893.png",
  // },
  {
    id: 4,
    department: "Account",
    cardName: "Account Department",
    imgSource:
      "https://static.wixstatic.com/media/70e0d1_98e952bfbc9d4e09b01b60c98b39476f~mv2.png/v1/fill/w_320,h_320,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Untitled%20design.png",
  },
  {
    id: 5,
    department: "Account",
    cardName: "Account Department (New)",
    imgSource:
      "https://static.wixstatic.com/media/70e0d1_98e952bfbc9d4e09b01b60c98b39476f~mv2.png/v1/fill/w_320,h_320,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Untitled%20design.png",
  },
  // {
  //   id: 5,
  //   department: "Attendance",
  //   cardName: "Attendance",
  //   imgSource:
  //     "https://www.pngfind.com/pngs/m/187-1877051_raised-hands-of-different-colors-hd-png-download.png",
  // },
];

function DepartmentDeciderScreen({ navigation }) {
  const { height, width } = useWindowDimensions();
  const departContext = useContext(DepartContext);

  useEffect(() => {
    LoginChecker();
  }, []);

  const LoginChecker = async () => {
    const department_id = await Cache.get("u_department");

    const loginDate = await Cache.get("loginDate");
    const cDate = moment(new Date()).format("DD/MM/YYYY");

    if (department_id != null && cDate == loginDate) {
      if (department_id == 2 || department_id == 3 || department_id == 16) {
        departContext.setDepartment("Therapy");
        return;
      } else if (department_id == 6) {
        departContext.setDepartment("Account");
        return;
      } else if (department_id == 15) {
        departContext.setDepartment("IT");
        return;
      } else if (department_id == 17) {
        departContext.setDepartment("Attendance");
        return;
      }
    }
  };

  const OnClickDepartmentHandler = async (item) => {
    if (item.id === 1) {
      window.open('https://it.accessfoundation.click', '_self');
      return;
    }
    if (item.id === 5) {
      window.open('https://accounts.accessfoundation.click', '_self');
      return;
    }
    // if loop is just for development porpose...
    if (item.id > 5) return;
    await Cache.store("selected_department", item.id);
    departContext.setDepartment(item.department);
  };

  return (
    <View style={[styles.container, { height: height, width: width }]}>
      <FlatList
        showsVerticalScrollIndicator={false}
        numColumns={3}
        data={cards}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => (
          <AppDepartmentCard
            Items={item}
            onPress={() => OnClickDepartmentHandler(item)}
          />
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // height: "100%",
    // width: "100%",
    alignItems: "center",
    padding: 20,
  },
});

export default DepartmentDeciderScreen;
