import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text } from "react-native";
import moment from "moment";

import PlanListScreen from "./PlanListScreen";
import listingAPI from "../../../../../api/listings";

import AppCalander from "../../../../../components/AppCalander";
import AppButton from "../../../../../components/AppButton";
import AppAlert from "../../../../../components/AppAlert";

function PlanManagementHomeScreen({ items }) {
  const [allPlans, setAllPlans] = useState([]);
  const [planStartDate, setPlanStartDate] = useState("DD/MM/YYYY");
  const [planEndDate, setPlanEndDate] = useState("DD/MM/YYYY");
  const [newPlan, setNewPlan] = useState(true);
  const [updatePlan, setUpdatePlan] = useState({ update: false, main_id: 0 });
  const [alert, setAlert] = useState({ modal: false, alert: "" });

  useEffect(() => {
    GetAllPlanHandler();
  }, []);

  const GetAllPlanHandler = async () => {
    const myJsonServices = JSON.stringify({
      participant_main_id: items.main_id,
    });
    const { data } = await listingAPI.ParticipantPlanManagement({
      endPoint: "/select/All/plans",
      data: myJsonServices,
    });

    setAllPlans(data);
  };

  const OnClickAddNewPlandHandler = async () => {
    let p_startDate = moment(planStartDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    let p_endDate = moment(planEndDate, "DD/MM/YYYY").format("YYYY-MM-DD");

    if (planStartDate == "DD/MM/YYYY") {
      setAlert({
        modal: true,
        alert: "Please, Select plan start Date.",
      });
      return;
    } else if (planEndDate == "DD/MM/YYYY") {
      setAlert({
        modal: true,
        alert: "Please, Select plan end Date.",
      });
      return;
    } else if (p_startDate >= p_endDate) {
      setAlert({
        modal: true,
        alert: "'Plan End date' must be after 'Plan start start date'.",
      });
      return;
    }
    if (!updatePlan.update) {
      const myJsonServices = JSON.stringify({
        participant_main_id: items.main_id,
        planStartDate:
          planStartDate != undefined
            ? moment(planStartDate, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
        planEndDate:
          planEndDate != undefined
            ? moment(planEndDate, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
      });
      const { data } = await listingAPI.ParticipantPlanManagement({
        endPoint: "/Add/NewPlan",
        data: myJsonServices,
      });

      setAllPlans(data);
      CleanUp();
    } else {
      const myJsonServices = JSON.stringify({
        participant_main_id: items.main_id,
        planNumver: items.currentPlanNumver,
        planStartDate:
          planStartDate != undefined
            ? moment(planStartDate, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
        planEndDate:
          planEndDate != undefined
            ? moment(planEndDate, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,

        main_id: updatePlan.main_id,
      });
      const { data } = await listingAPI.ParticipantPlanManagement({
        endPoint: "/update/NewPlan",
        data: myJsonServices,
      });

      setAllPlans(data);
      CleanUp();
    }
  };

  const CleanUp = () => {
    setNewPlan(true);
    setPlanStartDate("DD/MM/YYYY");
    setPlanEndDate("DD/MM/YYYY");
    setUpdatePlan({ update: false, main_id: 0 });
  };

  const OnPressEditHandler = (item) => {
    let p_startDate = moment(item.planStartDate).format(
      "DD/MM/YYYY"
    );
    let p_endDate = moment(item.planEndDate).format("DD/MM/YYYY");

    setPlanStartDate(p_startDate);
    setPlanEndDate(p_endDate);
    setUpdatePlan({ update: true, main_id: item.main_id });
    setNewPlan(false);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.participantNameStyle}>{items.name}</Text>
      {newPlan ? (
        <AppButton
          label="New Plan"
          color="blue"
          onPress={() => setNewPlan(false)}
        />
      ) : (
        <View style={styles.mainViewStyles}>
          <AppAlert
            alert={alert}
            okBtnPress={() => setAlert({ modal: false, alert: "" })}
          />
          <View style={styles.calanderNewPlanViewStyles}>
            <AppCalander
              width="30%"
              label="Plan Start Date : *"
              datePlaceholder={planStartDate ?? ""}
              selectedDate={(date) => setPlanStartDate(date)}
              maxDate={new Date()}
            />
            <AppCalander
              width="30%"
              label="Plan End Date : *"
              datePlaceholder={planEndDate ?? ""}
              selectedDate={(date) => setPlanEndDate(date)}
            />
            <View
              style={{
                width: "40%",
              }}
            >
              <AppButton
                label={updatePlan.update ? "Update" : "Add"}
                width="20%"
                color={updatePlan.update ? "pink" : "blue"}
                onPress={() => OnClickAddNewPlandHandler()}
              />
              <AppButton
                label="Cancel"
                width="20%"
                color="yellow"
                onPress={() => CleanUp()}
              />
            </View>
          </View>
        </View>
      )}
      <View style={styles.secoundViewStyles}>
        <PlanListScreen
          items={allPlans}
          OnPressEditHandler={(txt) => OnPressEditHandler(txt)}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    alignSelf: "center",
    paddingHorizontal: 5,
  },
  participantNameStyle: {
    fontSize: 35,
    fontWeight: "bold",
    height: "5%",
  },
  mainViewStyles: {
    height: "10%",
    width: "100%",
    margin: 5,
  },
  secoundViewStyles: {
    height: "80%",
    width: "100%",
    margin: 5,
  },
  calanderNewPlanViewStyles: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
});

export default PlanManagementHomeScreen;
