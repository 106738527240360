import React, { useState } from "react";
import { View, StyleSheet, Text } from "react-native";

import AddAdmin from "../Therapy/AddAdmin";
import ManageService from "../Therapy/ManageService";
import AppButton from "../../../../components/AppButton";

function TherapyHandler({}) {
  const [btnSelected, setBtnSelected] = useState("Admin");

  return (
    <View style={styles.container}>
      <View style={styles.BtnViewStyles}>
        <AppButton
          border={1}
          label="Admin"
          color={btnSelected == "Admin" ? "InputText" : ""}
          onPress={() => setBtnSelected("Admin")}
        />
        <AppButton
          border={1}
          color={btnSelected == "Service" ? "InputText" : ""}
          label="Service"
          onPress={() => setBtnSelected("Service")}
        />
      </View>
      <View style={styles.BottomViewStyles}>
        {btnSelected == "Admin" ? <AddAdmin /> : <ManageService />}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 10,
  },
  BtnViewStyles: {
    justifyContent: "space-around",
    flexDirection: "row",
    width: "100%",
    height: "10%",
    borderBottomWidth: 1,
  },
  BottomViewStyles: {
    height: "90%",
    width: "100%",
  },
});

export default TherapyHandler;
