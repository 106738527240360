import React, { useState, useEffect } from "react";
import { View, StyleSheet, Switch, TouchableOpacity, Text } from "react-native";
import moment from "moment";

import AppText from "../../components/AppText";
import AppIcon from "../../components/AppIcon";
import Colors from "../../config/Colors";

function AppAllocatTherapist({
  items,
  editEnable = true,
  switchEnable = true,
  deleteEnable = true,
  onPressDelete,
  onPressEdit,
  listView = false,
  switchBtnChange,
  backgroundColor = "#fff",
  txtBold,
}) {
  const [onClick, setOnClick] = useState(0);
  const [switchBtn, setSwitchBtn] = useState(
    items.status == "active" ? true : false
  );
  let TouchView;

  if (listView == false) {
    TouchView = View;
  } else {
    TouchView = TouchableOpacity;
  }

  const OnClickPositionStatusHandler = async (items) => {
    setSwitchBtn(!switchBtn);
    switchBtnChange(items);
  };

  return (
    <View style={styles.container}>
      <TouchView
        style={[
          styles.mainViewStyles,
          {
            backgroundColor: backgroundColor,
          },
        ]}
        onPress={() => setOnClick(onClick == null ? 0 : null)}
      >
        <View style={styles.nameViewStyles}>
          {/* <AppText
            style={styles.txtLabelStyles}
            label={items.participant_name}
          /> */}
          <AppText
            style={[
              { fontWeight: txtBold ? "bold" : "normal" },
              styles.txtLabelStyles,
            ]}
            label={!items.therapist_name ? "not active" : items.therapist_name}
          />
          <AppText
            style={[
              { fontWeight: txtBold ? "bold" : "normal" },
              styles.txtLabelStyles,
            ]}
            label={items.service_name}
          />
        </View>
        <View style={styles.btnViewStyles}>
          {switchEnable && (
            <Switch
              onValueChange={() => OnClickPositionStatusHandler(items)}
              value={switchBtn}
            />
          )}
          {editEnable && (
            <AppIcon
              size={20}
              touchAble
              name="pen"
              backgroundColor={backgroundColor}
              IconType="FontAwesome5"
              onPress={() => onPressEdit(items)}
            />
          )}

          {deleteEnable && (
            <AppIcon
              size={20}
              touchAble
              name="delete"
              backgroundColor={backgroundColor}
              onPress={() => onPressDelete(items)}
              iconColor="red"
            />
          )}
        </View>
      </TouchView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
    width: "90%",
    alignSelf: "center",
  },
  mainViewStyles: {
    padding: 5,
    width: "100%",
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 10,
  },

  txtLabelStyles: {
    paddingHorizontal: 10,
    width: "95%",
  },
  nameViewStyles: {
    flexDirection: "row",
    width: "70%",
    alignItems: "center",
  },
  btnViewStyles: {
    flexDirection: "row",
    width: "30%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  listViewStyles: {
    width: "100%",
    backgroundColor: Colors.darkBlue,
    borderRadius: 10,
  },
  itemViewList: {
    flexDirection: "row",
  },
  leftViewStyles: {
    width: "30%",
    height: "100%",
  },
  rightViewStyles: {
    height: "100%",
    width: "70%",
  },
  laftLabelStyles: {
    height: "100%",
    padding: 5,
    borderWidth: 0.5,
    fontWeight: "bold",
  },
  labelStyles: {
    padding: 5,
    height: "100%",
    borderWidth: 0.5,
  },
});

export default AppAllocatTherapist;
