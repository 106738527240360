import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
} from "react-native";
import moment from "moment";

import Colors from "../../../../../config/Colors";
import AppIcon from "../../../../../components/AppIcon";

const AppCurrentPlan = ({ items, OnPressEditHandler }) => {
  let p_startDate = moment(items.planStartDate).format(
    "DD/MM/YYYY"
  );
  let p_endDate = moment(items.planEndDate).format("DD/MM/YYYY");

  return (
    <TouchableOpacity
      style={[styles.touchViewStyles, { backgroundColor: Colors.InputText }]}
    >
      <Text style={{ width: "15%" }}>{items.planNumver}</Text>
      <Text style={{ width: "15%" }}>{p_startDate}</Text>
      <Text style={{ width: "15%" }}>{p_endDate}</Text>
      <View style={styles.iconViewStyles}>
        <AppIcon
          touchAble
          name="pen"
          iconColor="red"
          size="10%"
          onPress={() => OnPressEditHandler(items)}
        />
        {/* <AppIcon touchAble name="delete" iconColor="red" size="10%" /> */}
      </View>
    </TouchableOpacity>
  );
};

function PlanListScreen({ items, OnPressEditHandler }) {
  return (
    <View style={styles.container}>
      <View style={styles.touchViewStyles}>
        <Text style={{ fontWeight: "bold", width: "15%" }}>Plan Number</Text>
        <Text style={{ fontWeight: "bold", width: "15%" }}>
          Plan Start Date
        </Text>
        <Text style={{ fontWeight: "bold", width: "15%" }}>Plan End Date</Text>
        <Text style={{ width: "15%" }}></Text>
      </View>
      <FlatList
        data={items}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => (
          <AppCurrentPlan
            items={item}
            OnPressEditHandler={OnPressEditHandler}
          />
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "95%",
    alignSelf: "center",
  },
  touchViewStyles: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 5,
    marginVertical: 5,
    borderRadius: 10,
    width: "100%",
  },
  iconViewStyles: {
    width: "15%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
});

export default PlanListScreen;
