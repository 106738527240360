import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";

const prefix = "cache";
const expiryInMinutes = 5;
const key1 = "@storage_Key";

const store = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(key, jsonValue);
  } catch (e) {
    console.log(e);
  }
};

const get = async (key) => {
  try {
    const jsonValue = await AsyncStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    console.log(e);
  }
};

const removeItem = async (key) => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (error) {
    console.log(error);
  }
};

// if (isExpired(item)) {
//   await AsyncStorage.removeItem();
//   return null;
// }

// const isExpired = (item) => {
//   const now = moment(Date.now());
//   const storedTime = moment(item.timestamp);
//   return now.diff(storedTime, "minutes") > expiryInMinutes;
// };

// const store = async (key, value) => {
//   try {
//     const item = {
//       value,
//       timestamp: Date.now(),
//     };
//     await AsyncStorage.setItem(prefix + key, JSON.stringify(item));
//   } catch (error) {
//     console.log(error);
//   }
// };

// const get = async (key) => {
//   try {
//     const value = await AsyncStorage.getItem(prefix + key);
//     const item = JSON.parse(value);

//     if (!item) return null;

//     return item.value;
//   } catch (error) {
//     console.log(error);
//   }
// };

export default {
  store,
  get,
  removeItem,
};
