import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text, FlatList, Switch } from "react-native";

import listingAPI from "../../../../api/listings";
import AppIcon from "../../../../components/AppIcon";
import AppTouchableLabel from "../../../../components/AppTouchableLabel";
import AppSearchBar from "../../../../components/AppSearchBar";
import AppButton from "../../../../components/AppButton";
import Colors from "../../../../config/Colors";

const LocalCard = ({
  items,
  onPressEdit,
  onPressDelete,
  OnClickPositionStatusHandler,
}) => {
  return (
    <View style={styles.officeCardStyles}>
      <Text style={{ width: "20%", fontWeight: "bold" }}>
        {items.office_name}
      </Text>
      <Text style={{ width: "20%", fontWeight: "bold" }}>
        {items.department_name}
      </Text>
      <Text style={{ width: "20%", fontWeight: "bold" }}>{items.emp_name}</Text>
      <View style={styles.btnStylesView}>
        <Switch
          value={items.status == "active" ? true : false}
          onValueChange={() => OnClickPositionStatusHandler(items)}
        />

        <AppIcon
          touchAble
          backgroundColor=""
          name="pen"
          onPress={() => onPressEdit(items)}
        />
        <AppIcon
          touchAble
          backgroundColor=""
          name="delete"
          onPress={() => onPressDelete(items)}
        />
      </View>
    </View>
  );
};

function Managers({}) {
  const [allDepartments, setAllDepartments] = useState();
  const [allActiveOffice, setAllActiveOffice] = useState();
  const [selectedEmp, setSelectedEmp] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [allManagers, setAllManagers] = useState();
  const [activeEmp, setActiveEmp] = useState();

  useEffect(() => {
    GetAllActiveDepartment();
    GetAllActiveOffice();
    GetAll_ActiveManagers();
  }, []);

  const GetAllActiveDepartment = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.DepartmentHandler_POST({
      endPoint: "/Select/All/department/active",
      data: myJsonServices,
    });

    setAllDepartments(data);
  };

  const GetAllActiveOffice = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.OfficesHandler_POST({
      endPoint: "/Select/All/Active/offices",
      data: myJsonServices,
    });

    setAllActiveOffice(data);
  };

  const GetAll_ActiveManagers = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.AllocateManagerHandler_POST({
      endPoint: "/Select/All/Alocated/Active/managers",
      data: myJsonServices,
    });

    setAllManagers(data);
  };

  const OnClickAssignDepartment = async () => {
    const myJsonServices = JSON.stringify({
      OfficeList_main_id: selectedOrganization.main_id,
      departments_main_id: selectedDepartment.main_id,
      empMain_id: selectedEmp.main_id,
    });
    const { data } = await listingAPI.AllocateManagerHandler_POST({
      endPoint: "/Allocate/manager",
      data: myJsonServices,
    });

    setAllManagers(data);
  };

  const OnClickPositionStatusHandler = (item) => {
    console.log(item);
  };

  return (
    <View style={styles.container}>
      <View style={styles.searchBarViewStyles}>
        <AppTouchableLabel
          width="40%"
          items={allActiveOffice}
          label="Select Organization : "
          iconName="office-building-outline"
          menuLabel={"Select Organization"}
          selectedMenu={(item) => setSelectedOrganization(item)}
        />
        <AppTouchableLabel
          width="40%"
          items={allDepartments}
          label="Select Offices : "
          iconName="office-building-outline"
          menuLabel={"Select Department"}
          selectedMenu={(item) => setSelectedDepartment(item)}
        />
      </View>
      <View style={[styles.searchBarViewStyles, { height: "20%" }]}>
        <AppSearchBar
          width="70%"
          selectedAllActiveEmployee
          getSelectedItem={(item) => setSelectedEmp(item)}
        />
        <AppButton
          style={{ marginTop: 20 }}
          width="15%"
          color="blue"
          label="Assign"
          onPress={() => OnClickAssignDepartment()}
        />
      </View>

      <View style={styles.bottomViewStyles}>
        <FlatList
          data={allManagers}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <LocalCard
              items={item}
              OnClickPositionStatusHandler={(item) =>
                OnClickPositionStatusHandler(item)
              }
            />
          )}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    padding: 20,
  },
  searchBarViewStyles: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  bottomViewStyles: {
    bottom: 0,
    height: "70%",
    width: "95%",
    position: "absolute",
    alignSelf: "center",
  },
  officeCardStyles: {
    backgroundColor: Colors.InputText,
    padding: 5,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginVertical: 5,
  },
  btnStylesView: {
    width: "40%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
});

export default Managers;
