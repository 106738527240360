import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text, TouchableOpacity, Alert } from "react-native";

import listingAPI from "../../api/listings";
import Cache from "../../utility/cache";
import AppSeccionCard from "../../components/AppSeccionCard";

const AppMonthCard = ({ item, editAndDelete, OnClickSessionDateHandler }) => {
  const [openMonth, setOpenMonth] = useState(false);
  const [LayoutHeightMonth, setLayoutHeightMonth] = useState(0);
  const [allSessionDetails, setAllSessionDetails] = useState([]);

  useEffect(() => {
    if (openMonth) {
      setLayoutHeightMonth(null);
    } else {
      setLayoutHeightMonth(0);
    }
  }, [openMonth]);

  const GetAllSessionsData = async (selectedMonth) => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      participant_main_id: item.participant_main_id,
      services_main_id: item.services_main_id,
      therapist_main_id: therapist_main_id,
      selectedMonth: selectedMonth,
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint:
        "/select/all/session/of/particular/servicesof/particular/Participants/for/LoginedTherapist/asper/selectedMonths",
      data: myJsonServices,
    });

    setAllSessionDetails(data);
  };

  const GetAllOthersSessionsData = async (selectedMonth) => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      participant_main_id: item.participant_main_id,
      services_main_id: item.services_main_id,
      therapist_main_id: therapist_main_id,
      selectedMonth: selectedMonth,
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint:
        "/select/all/session/of/particular/Participants/for/Others/Therapist/as/per/selectedMonths",
      data: myJsonServices,
    });

    setAllSessionDetails(data);
  };

  const onClickMonthHandler = async (selectedMonth) => {
    if (editAndDelete) {
      GetAllSessionsData(selectedMonth);
    } else {
      GetAllOthersSessionsData(selectedMonth);
    }
    setOpenMonth(!openMonth);
  };

  return (
    <View>
      <TouchableOpacity
        style={[styles.cardViewStyles, { backgroundColor: "#CDC9CA" }]}
        onPress={() => {
          onClickMonthHandler(item.sessionMonth);
        }}
      >
        <Text>{item.sessionMonth}</Text>
      </TouchableOpacity>
      {
        <View style={{ height: LayoutHeightMonth, overflow: "hidden" }}>
          <AppSeccionCard
            items={allSessionDetails}
            editAndDelete={editAndDelete}
            OnClickSessionDateHandler={OnClickSessionDateHandler}
            refreshData={(item) => setRefreshData(item)}
          />
        </View>
      }
    </View>
  );
};

function AppSessionYearSelecter({
  item,
  editAndDelete,
  OnClickSessionDateHandler,
}) {
  const [open, setOpen] = useState(false);
  const [LayoutHeight, setLayoutHeight] = useState(0);
  const [allSessionMonths, setAllSessionMonths] = useState([]);

  useEffect(() => {
    if (open) {
      setLayoutHeight(null);
    } else {
      setLayoutHeight(0);
    }
  }, [open]);

  const onClickHandler = async (selectedYear) => {
    if (editAndDelete) {
      GetAllSessionsGroupByMonths(selectedYear);
    } else {
      GetAllSessionsGroupByMonthsofOthers(selectedYear);
    }
    setOpen(!open);
  };

  const GetAllSessionsGroupByMonths = async (selectedYear) => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      participant_main_id: item.participant_main_id,
      services_main_id: item.services_main_id,
      therapist_main_id: therapist_main_id,
      selectedYear: selectedYear,
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint:
        "/select/all/session/of/particular/services/of/particular/Participants/for/LoginedTherapist/selected/year/of/all/month",
      data: myJsonServices,
    });

    setAllSessionMonths(data);
  };

  const GetAllSessionsGroupByMonthsofOthers = async (selectedYear) => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      participant_main_id: item.participant_main_id,
      services_main_id: item.services_main_id,
      therapist_main_id: therapist_main_id,
      selectedYear: selectedYear,
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint:
        "/select/all/session/of/particular/Participants/for/others/LoginedTherapist/selected/year/of/all/month",
      data: myJsonServices,
    });

    setAllSessionMonths(data);
  };

  return (
    <View>
      <TouchableOpacity
        style={[styles.cardViewStyles, { backgroundColor: "#CDC9CA" }]}
        onPress={() => {
          onClickHandler(item.sessionYear);
        }}
      >
        <Text>{item.sessionYear}</Text>
      </TouchableOpacity>
      {
        <View style={{ height: LayoutHeight, overflow: "hidden" }}>
          {allSessionMonths.map((month) => (
            <AppMonthCard
              key={month.sessionMonth}
              item={month}
              editAndDelete={editAndDelete}
              OnClickSessionDateHandler={OnClickSessionDateHandler}
            />
          ))}
        </View>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  cardViewStyles: {
    marginVertical: 5,
    padding: 5,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
});

export default AppSessionYearSelecter;
