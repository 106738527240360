import React, { useState } from "react";
import { View, StyleSheet, Text } from "react-native";

import ManageOffice from "./ManageOffice";
import ManageDepartment from "./ManageDepartment";
import Managers from "./Managers";

import AppButton from "../../../../components/AppButton";
import Colors from "../../../../config/Colors";

function OfficeMainScreen({}) {
  const [currentScreen, setCurrentScreen] = useState("Managers");

  let Screen;

  if (currentScreen == "ManageOffice") {
    Screen = ManageOffice;
  } else if (currentScreen == "ManageDepartment") {
    Screen = ManageDepartment;
  } else if (currentScreen == "Managers") {
    Screen = Managers;
  }

  return (
    <View style={styles.container}>
      <View style={styles.topViewStyles}>
        <AppButton
          border={1}
          label="Manage Office"
          onPress={() => setCurrentScreen("ManageOffice")}
          color={currentScreen == "ManageOffice" ? "white" : "InputText"}
        />
        <AppButton
          border={1}
          label="Manage Department"
          onPress={() => setCurrentScreen("ManageDepartment")}
          color={currentScreen == "ManageDepartment" ? "white" : "InputText"}
        />
        <AppButton
          border={1}
          label="Managers"
          onPress={() => setCurrentScreen("Managers")}
          color={currentScreen == "Managers" ? "white" : "InputText"}
        />
      </View>
      <View style={styles.bottomViewStyles}>
        <Screen />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  topViewStyles: {
    height: 50,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: Colors.InputText,
    paddingTop: 10,

    shadowColor: "#171717",
    shadowOffset: { width: 5, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  bottomViewStyles: {
    height: "95%",
    width: "100%",
  },
});

export default OfficeMainScreen;
