import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
} from "react-native";
import moment from "moment";

import listingAPI from "../../api/listings";
import AppClamiedSessionCard from "../../components/cards/AppClamiedSessionCard";
import Cache from "../../utility/cache";
import Colors from "../../config/Colors";
import AppProgressbar from "../AppProgressbar";

const AppCard = ({ item, clamiedDesider }) => {
  let clamedDate = moment(item.clamiedDate).format("DD/MM/YYYY");
  const [open, setOpen] = useState(false);
  const [LayoutHeight, setLayoutHeight] = useState(0);
  const [touchClame, setTouchClame] = useState(true);
  const [allUnClames, setAllUnClames] = useState([]);
  const [progressBar, setProgressBar] = useState(false);

  useEffect(() => {
    if (open) {
      setLayoutHeight(null);
    } else {
      setLayoutHeight(0);
    }
  }, [open]);

  const onClickHandler = async (item) => {
    let clamedDate = moment(item.clamiedDate).format(
      "YYYY-MM-DD"
    );

    // setOpen(!open);

    setProgressBar(true);
    const myJsonServices = JSON.stringify({
      clamied_by_main_id: item.clamied_by_main_id,
      selectedClamiedDate: clamedDate,
    });
    const { data } = await listingAPI.ClamiedBySession_POST({
      endPoint: "/select/all/my/clamied/session/details",
      data: myJsonServices,
    });

    setAllUnClames(data);
    setProgressBar(false);
  };

  let TouchView;

  if (touchClame) {
    TouchView = TouchableOpacity;
  } else {
    TouchView = View;
  }

  return (
    <View>
      <AppProgressbar modalVisible={progressBar} />
      <TouchView
        style={[
          styles.cardViewStyles,
          { backgroundColor: touchClame ? "#CDC9CA" : Colors.InputText },
        ]}
        onPress={() => {
          if (!open) {
            onClickHandler(item);
            setOpen(!open);
          } else {
            setOpen(!open);
          }
        }}
      >
        <Text style={[styles.labelStyles]}>{item.name}</Text>
        <Text style={[styles.labelStyles]}>{clamedDate}</Text>
      </TouchView>

      <View style={{ height: LayoutHeight, overflow: "hidden" }}>
        <AppClamiedSessionCard data={allUnClames} />
      </View>
    </View>
  );
};

function AppClamiedBySessionCard({
  items = [],
  subItems = [],
  clamiedDesider,
}) {
  return (
    <View style={styles.container}>
      <View style={[styles.cardViewStyles, { backgroundColor: Colors.blue }]}>
        <Text style={styles.headerStyles}>Name</Text>
        <Text style={styles.headerStyles}>Date</Text>
      </View>

      <FlatList
        data={items}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => (
          <AppCard
            clamiedDesider={clamiedDesider}
            item={item}
            subItems={subItems}
          />
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
  },
  cardViewStyles: {
    marginVertical: 5,
    padding: 5,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  headerStyles: {
    fontWeight: "bold",
    fontSize: "100%",
    width: "25%",
    marginHorizontal: 2,
  },
  labelStyles: {
    fontSize: "100%",
    width: "25%",
    marginHorizontal: 2,
  },
});

export default AppClamiedBySessionCard;
