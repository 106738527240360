import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from "react-native";

import {
  MaterialCommunityIcons,
  Entypo,
  FontAwesome5,
  Octicons,
  Fontisto,
  FontAwesome,
} from "@expo/vector-icons";
import AppText from "./AppText";

// onChangeText={(txt) =>
//   setPrice(
//     txt.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
//   )
// }

function AppInputText({
  inputTextNotTouchAble = true,
  textLabel,
  label,
  iconName = "email",
  iconSize = 25,
  width = "90%",
  height = 40,
  onPress,
  touchAble = false,
  rightIconName = "eye",
  backgroundColor = "#E7E7E7",
  IconType = MaterialCommunityIcons,
  ViewStyle,
  mainInputViewStyles,
  ...otherProps
}) {
  const [eye, setEye] = useState(false);

  let TouchView;

  if (IconType === "Entypo") {
    IconType = Entypo;
  } else if (IconType === "FontAwesome5") {
    IconType = FontAwesome5;
  } else if (IconType === "Octicons") {
    IconType = Octicons;
  } else if (IconType === "Fontisto") {
    IconType = Fontisto;
  } else if (IconType === "FontAwesome") {
    IconType = FontAwesome;
  } else {
    IconType = MaterialCommunityIcons;
  }

  if (eye) {
    rightIconName = "eye";
  } else {
    rightIconName = "eye-off";
  }

  return (
    <View
      style={[styles.mainViewStyles, mainInputViewStyles, { width: width }]}
    >
      <AppText style={{ marginLeft: 30 }} label={label} />
      <View
        style={[
          styles.container,
          // { width: width },
          { height: height },
          { backgroundColor },
          ViewStyle,
        ]}
      >
        <IconType name={iconName} size={iconSize} />
        {/* {entypo ? (
        <Entypo name={iconName} size={iconSize} />
      ) : (
        <MaterialCommunityIcons name={iconName} size={iconSize} />
      )}
      {fontAwesome5 && <FontAwesome5 name={iconName} size={iconSize} />} */}
        {inputTextNotTouchAble ? (
          <TextInput
            style={styles.textStyles}
            placeholder="Email :"
            secureTextEntry={touchAble ? !eye : eye}
            {...otherProps}
          />
        ) : (
          <Text style={styles.labelStyles}>{textLabel}</Text>
        )}
        {touchAble && (
          <TouchableOpacity onPress={() => setEye(!eye)}>
            <MaterialCommunityIcons name={rightIconName} size={iconSize} />
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainViewStyles: {
    // alignItems: "center",
  },
  container: {
    width: "100%",
    paddingHorizontal: 10,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 25,
    marginVertical: 2,
    alignSelf: "center",
  },

  textStyles: {
    paddingHorizontal: 10,
    width: "95%",
    height: "80%",
  },
  labelStyles: {
    paddingHorizontal: 10,
    width: "85%",
  },
});

export default AppInputText;
