import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text } from "react-native";

import AppClameParticipantCard from "../../../components/cards/AppClameParticipantCard";
import listingAPI from "../../../api/listings";
import Cache from "../../../utility/cache";

function PendingClaimScreen({}) {
  const [allUnClamesName, setAllUnClamesName] = useState([]);
  const [allUnClames, setAllUnClames] = useState([]);

  useEffect(() => {
    GetListOfAllUnClamedParticipantNameGroupBy();
  }, []);

  const GetListOfAllUnClamedParticipantNameGroupBy = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint: "/select/all/unClame/sessions/participantsName",
      data: myJsonServices,
    });

    setAllUnClamesName(data);
  };

  return (
    <View style={styles.container}>
      <AppClameParticipantCard items={allUnClamesName} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 10,
  },
});

export default PendingClaimScreen;
