import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text } from "react-native";

import listingAPI from "../../../../api/listings";
import AppTouchableLabel from "../../../../components/AppTouchableLabel";
import AppButton from "../../../../components/AppButton";
import TherapistWorkFilter from "../../../../components/CommanScreen/TherapistWorkFilter";
import AppPicker from "../../../../components/AppPicker";

function WorkTrackerScreen({}) {
  const [allTherapist, setAllTherapist] = useState([]);
  const [selectTherapistName, setSelectTherapistName] = useState({
    name: "",
    therapist_id: 0,
  });

  useEffect(() => {
    GetAllTherapist();
  }, []);

  const GetAllTherapist = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.TherapyTeamGet({
      endPoint: "/Select/All/Therapy/Team",
      data: myJsonServices,
    });

    setAllTherapist(data);
  };

  return (
    <View style={styles.container}>
      <View style={styles.topViewStyles}>
        {/* <AppTouchableLabel
            items={allTherapist}
            width="50%"
            placeholder="Select Therapist name : "
            label="Select Therapist name : "
            iconName="account"
            menuLabel={"Select Therapist name"}
            selectedMenu={(item) =>
              setSelectTherapistName({
                name: item.name,
                therapist_id: item.main_id,
              })
            }
          /> */}

        <AppPicker
          width="40%"
          label="Select Therapist"
          items={allTherapist}
          selectedItem={selectTherapistName}
          onSelectItem={(item) =>
            setSelectTherapistName({
              name: item.name,
              therapist_id: item.main_id,
            })
          }
        />
      </View>

      <View style={styles.bottomViewStyles}>
        <TherapistWorkFilter data={selectTherapistName} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 20,
    padding: 10,
  },
  topViewStyles: {
    width: "100%",
    height: "10%",
  },
  bottomViewStyles: {
    width: "100%",
    height: "85%",
  },
});

export default WorkTrackerScreen;
