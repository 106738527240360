import React from "react";

import { createStackNavigator } from "@react-navigation/stack";

import SplashScreen from "../../screens/SplashScreen/SplashScreen";
import AuthCheckScreen from "../../screens/SplashScreen/AuthCheckScreen";
import InterNetCheckScreen from "../../screens/SplashScreen/InterNetCheckScreen";

const Stack = createStackNavigator();

const StackNavigator = () => (
  <Stack.Navigator
    presentation="modal"
    initialRouteName="STACK_SplashScreen"
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen
      name="STACK_SplashScreen"
      component={SplashScreen}
      options={{ title: "Access Foundation" }}
    />
    <Stack.Screen
      name="STACK_AuthCheckScreen"
      component={AuthCheckScreen}
      options={{ title: "Access Foundation" }}
    />
    <Stack.Screen
      name="STACK_InterNetCheckScreen"
      component={InterNetCheckScreen}
      options={{ title: "Access Foundation" }}
    />
  </Stack.Navigator>
);

export default StackNavigator;
