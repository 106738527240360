import React from "react";
import { View, StyleSheet, Text, FlatList } from "react-native";
import Modal from "react-native-modal";

import AppButton from "./AppButton";

function AppAlert({
  alert,
  arrayAlert = [],
  alertLabel = "Alert",
  okBtnPress,
  style,
}) {
  return (
    <Modal style={[{ alignItems: "center" }, style]} isVisible={alert.modal}>
      <View style={styles.container}>
        <Text style={{ fontSize: 45, color: "red" }}>{alertLabel}</Text>
        <Text style={{ fontSize: 25 }}>{alert.alert}</Text>
        {arrayAlert.length > 0 && (
          <FlatList
            data={arrayAlert}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item }) => <Text>{item.name}</Text>}
          />
        )}
        <AppButton label="Ok" color="blue" onPress={okBtnPress} />
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    height: "30%",
    width: "70%",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    backgroundColor: "white",
    borderRadius: 5,
  },
});

export default AppAlert;
