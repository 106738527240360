import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text, Alert } from "react-native";
import moment from "moment";

import AppCalander from "../AppCalander";
import AppButton from "../AppButton";
import AppAlert from "../AppAlert";
import AppParticipantsGroupByCard from "../cards/AppParticipantsGroupByCard";
import listingAPI from "../../api/listings";

function TherapistWorkFilter({ data }) {
  let item = data;

  const [fromDate, setFromDate] = useState("DD/MM/YYYY");
  const [toDate, setToDate] = useState("DD/MM/YYYY");
  const [alertModal, setAlertModal] = useState({ modal: false, alert: "" });
  const [getParticipantsName, setGetParticipantsName] = useState();
  const [totalSessionChargeHours, setTotalSessionChargeHours] = useState({
    hours: "0",
  });
  const [totalSessionTime, setTotalSessionTime] = useState({
    hours: "0",
  });
  const [totalChargeTravelTime, setTotalChargeTravelTime] = useState({
    hours: "0",
  });

  const [total_TravelHours, setTotal_TravelHours] = useState({
    hours: "0",
  });

  const [total_NonTravelHours, setTotal_NonTravelHours] = useState({
    hours: "0",
  });

  const [totalKmTravelled, setTotalKmTravelled] = useState(0);

  const GetAllSessionsOfSelectedTherapist = async () => {
    if (fromDate == "DD/MM/YYYY" || toDate == "DD/MM/YYYY") {
      setAlertModal({ modal: true, alert: "Please Select Date" });
    }
    let p_startDate = moment(fromDate, "DD/MM/YYYY").format("YYYY/MM/DD");
    let p_endDate = moment(toDate, "DD/MM/YYYY").format("YYYY/MM/DD");

    const myJsonServices = JSON.stringify({
      sessionDateFromDate: p_startDate,
      sessionDateToDate: p_endDate,
      therapist_main_id: item.therapist_id,
    });

    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint:
        "/select/all/sessions/of/selected/participantsName/between/selected/date",
      data: myJsonServices,
    });

    Calculation(data);
    setGetParticipantsName(data);
  };

  const Calculation = (data) => {
    var totalSessionChargeMinutes = 0;

    var totalSessionHours = 0;
    var totalSessionMins = 0;

    var chargeTravel_hours = 0;
    var chargeTravel_min = 0;

    var totalTravel_hours = 0;
    var totalTravel_min = 0;

    var totalKmTravelled = 0;

    for (var key in data) {
      totalSessionChargeMinutes += parseFloat(data[key].fullSessionMins);

      totalSessionHours += parseFloat(data[key].totalSession_hours);
      totalSessionMins += parseFloat(data[key].totalSession_mins);

      chargeTravel_hours += parseFloat(data[key].chargeTravel_hours);
      chargeTravel_min += parseFloat(data[key].chargeTravel_mins);

      totalTravel_hours += parseFloat(data[key].totalTravel_hours);
      totalTravel_min += parseFloat(data[key].totalTravel_mins);

      totalKmTravelled += data[key].totalKmTravelled;
    }

    setTotalKmTravelled(totalKmTravelled);

    // total charge session calculation...
    let totalHours = ~~(totalSessionChargeMinutes / 60);
    let totalMins = ~~(totalSessionChargeMinutes % 60);

    setTotalSessionChargeHours({
      hours: `${totalHours}:${totalMins < 10 ? `0${totalMins}` : totalMins}`,
    });

    // total session calculation...
    let sessionMin = totalSessionHours * 60 + totalSessionMins;
    let totalSession_H = ~~(sessionMin / 60);
    let totalSession_M = ~~(sessionMin % 60);

    setTotalSessionTime({
      hours: `${totalSession_H}:${
        totalSession_M < 10 ? `0${totalSession_M}` : totalSession_M
      }`,
    });

    // total travel Charge calculation...
    let chargeSessionMin = chargeTravel_hours * 60 + chargeTravel_min;
    let totalChargeSession_H = ~~(chargeSessionMin / 60);
    let totalChargeSession_M = ~~(chargeSessionMin % 60);

    setTotalChargeTravelTime({
      hours: `${totalChargeSession_H}:${
        totalChargeSession_M < 10
          ? `0${totalChargeSession_M}`
          : totalChargeSession_M
      }`,
    });

    // total travel calculation...

    let total_TravelMin = totalTravel_hours * 60 + totalTravel_min;

    let totalTravel_H = ~~(total_TravelMin / 60);
    let totalTravel_M = ~~(total_TravelMin % 60);

    setTotal_TravelHours({
      hours: `${totalTravel_H}:${
        totalTravel_M < 10 ? `0${totalTravel_M}` : totalTravel_M
      }`,
    });

    // total Non Charge travel calculation...
    let totalTravelMin = totalTravel_hours * 60 + totalTravel_min;

    let total_NonChargeTravelMin =
      parseInt(totalTravelMin) - parseInt(chargeSessionMin);

    let nonTotalChargeSession_H = ~~(total_NonChargeTravelMin / 60);
    let nonTotalChargeSession_M = ~~(total_NonChargeTravelMin % 60);

    setTotal_NonTravelHours({
      hours: `${nonTotalChargeSession_H}:${
        nonTotalChargeSession_M < 10
          ? `0${nonTotalChargeSession_M}`
          : nonTotalChargeSession_M
      }`,
    });
  };

  return (
    <View style={styles.container}>
      <AppAlert
        alert={alertModal}
        okBtnPress={() => setAlertModal({ modal: false, alert: "" })}
      />
      <View style={styles.topViewStyles}>
        <AppCalander
          width="30%"
          label="Date : "
          datePlaceholder={fromDate}
          selectedDate={(date) => setFromDate(date)}
          maxDate={new Date()}
        />
        <AppCalander
          width="30%"
          label="Date : "
          datePlaceholder={toDate}
          selectedDate={(date) => setToDate(date)}
          maxDate={new Date()}
        />
        <AppButton
          label="Filter"
          color="blue"
          onPress={() => GetAllSessionsOfSelectedTherapist()}
        />
      </View>
      <View style={styles.BottomViewStyles}>
        <AppParticipantsGroupByCard
          items={getParticipantsName}
          date={[{ from: fromDate, to: toDate }]}
        />
      </View>
      {totalSessionChargeHours.hours != "0" ? (
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View>
            <Text style={{ fontWeight: "bold", fontSize: 15 }}>
              Total Session Hours :{totalSessionTime.hours}
            </Text>
            <Text style={{ fontWeight: "bold", fontSize: 15 }}>
              Total Charge Travel Hours :{totalChargeTravelTime.hours}
            </Text>
            <Text style={{ fontWeight: "bold", fontSize: 15 }}>
              Total km Travel :{totalKmTravelled}
            </Text>
            <Text style={{ fontWeight: "bold", fontSize: 25 }}>
              Total Hours :{totalSessionChargeHours.hours}
            </Text>
          </View>
          <View>
            <Text style={{ fontWeight: "bold", fontSize: 15 }}>
              Total No Charge Travel Hours :{total_NonTravelHours.hours}
            </Text>
            <Text style={{ fontWeight: "bold", fontSize: 15 }}>
              Total Charge Travel Hours :{totalChargeTravelTime.hours}
            </Text>
            <Text style={{ fontWeight: "bold", fontSize: 25 }}>
              Total Travel Hours :{total_TravelHours.hours}
            </Text>
          </View>
        </View>
      ) : (
        <Text></Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 20,
    padding: 10,
  },
  topViewStyles: {
    height: "10%",
    width: "100%",
    borderBottomWidth: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  BottomViewStyles: {
    height: "80%",
    width: "100%",
  },
});

export default TherapistWorkFilter;
