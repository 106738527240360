import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";

import Logo from "../config/img/logo.png";

const AppReceptHandler = ({ item }) => {
  let date = moment(item.sessionDate, "YYYYY/MM/DD").format("DD/MM/YYYY");

  let Hours = ~~(item.fullSessionMins / 60);
  let mins = ~~(item.fullSessionMins % 60);

  return (
    <View style={styles.descriptionStyles}>
      <Text style={[styles.txtDescriptionStyles, { width: "15%" }]}>
        {date}
      </Text>
      <Text style={[styles.txtDescriptionStyles, { width: "30%" }]}>
        {item.serviceName}
      </Text>
      <Text style={[styles.txtDescriptionStyles, { width: "11%" }]}>
        {Hours}:{mins < 10 ? `0${mins}` : mins}
      </Text>
      <Text style={[styles.txtDescriptionStyles, { width: "11%" }]}>
        {item.services_CurrentPrice}
      </Text>
      <Text style={[styles.txtDescriptionStyles, { width: "11%" }]}>
        GST Free
      </Text>
      <Text style={[styles.txtDescriptionStyles, { width: "11%" }]}>
        {item.totalExtraExpense_Price ?? 0}
      </Text>
      <Text style={[styles.txtDescriptionStyles, { width: "11%" }]}>
        {item.totalSessionPrice}
      </Text>
    </View>
  );
};

function AppPdfGenerator({ items, participant, selectedService, toDate, fromDate }) {
  let Invoice_Date = moment(new Date(), "YYYYY/MM/DD").format(
    "DD/MM/YYYY"
  );
  let serviceCharges = 0;
  let kmCharges = 0;
  items.forEach((item) => {
    kmCharges += parseFloat(item.totalExtraExpense_Price ?? 0);
    serviceCharges += parseFloat(item.totalSessionPrice);
  });
  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
          <View style={styles.imageViewStyles}>
            <Image style={{ height: 100, width: 200 }} source={{ uri: Logo }} />
          </View>

          <View>
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
              TAX INVOICE
            </Text>
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-around" }}
          >
            <View>
              <Text style={styles.txtStyles}>
                {participant.name} ({participant.ndisNo})
              </Text>
              <Text style={styles.txtStyles}>Attention: {participant.name}</Text>
              <Text style={styles.txtStyles}>{participant.ParticipantAddress}</Text>
              {/* <Text style={styles.txtStyles}>STRATTON WA 6056</Text>
              <Text style={styles.txtStyles}>AUSTRALIA</Text> */}
            </View>
            <View>
              <Text style={styles.txtBoldStyles}>Invoice Date</Text>
              <Text style={styles.txtStyles}>{Invoice_Date}</Text>
              <Text style={styles.txtBoldStyles}>Reference</Text>
              <Text style={styles.txtStyles}>{selectedService.name} {fromDate} to {toDate}</Text>
              <Text style={styles.txtBoldStyles}>ABN</Text>
              <Text style={styles.txtStyles}>34 621 191 348</Text>
            </View>
            <View>
              <Text style={styles.txtStyles}>ACCESS FOUNDATION WA</Text>
              <Text style={styles.txtStyles}>37 Mirrabooka Avenue,</Text>
              <Text style={styles.txtStyles}>Westminster, WA, 6061</Text>
            </View>
          </View>
          <View style={styles.descriptionLabelStyles}>
            <Text style={[styles.txtBoldHeaderStyles, { width: "15%" }]}>
              Service Date
            </Text>
            <Text style={[styles.txtBoldHeaderStyles, { width: "30%" }]}>
              Description
            </Text>
            <Text style={[styles.txtBoldHeaderStyles, { width: "11%" }]}>Quantity</Text>
            <Text style={[styles.txtBoldHeaderStyles, { width: "11%" }]}>Unit Price</Text>
            <Text style={[styles.txtBoldHeaderStyles, { width: "11%" }]}>GST</Text>
            <Text style={[styles.txtBoldHeaderStyles, { width: "11%" }]}>km Charges</Text>
            <Text style={[styles.txtBoldHeaderStyles, { width: "11%" }]}>Amount AUD</Text>
          </View>
          <View>
            {items.map((item) => {
              return <AppReceptHandler key={item.main_id} item={item} />;
            })}
          </View>
          <View style={{ alignItems: "flex-end", margin: 20 }}>
            <View
              style={{
                flexDirection: "row",
                marginVertical: 10,
                paddingBottom: 10,
                borderBottomWidth: 1,
              }}
            >
              <Text style={{ fontSize: 12 }}>km Charges :</Text>
              <Text style={{ fontSize: 12 }}>{kmCharges.toFixed(3)}</Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                marginVertical: 10,
                paddingBottom: 10,
                borderBottomWidth: 1,
                justifyContent: 'space-between'
              }}
            >
              <Text style={{ fontSize: 12 }}>Service Charges :</Text>
              <Text style={{ fontSize: 12 }}>{serviceCharges.toFixed(3)}</Text>
            </View>
            <View style={{ flexDirection: "row", marginVertical: 10 }}>
              <Text>TOTAL AUD :</Text>
              <Text>{(kmCharges + serviceCharges).toFixed(3)}</Text>
            </View>
          </View>
          <View style={{ alignItems: "flex-start", margin: 20 }}>
        
            <Text style={styles.bottomTextStyles}>
              For payment, Please mention the Invoice number and name in the
              reference.
            </Text>
            <Text style={styles.bottomTextStyles}>
              Forward the remittance advice to accounts@accessfoundation.com.au
            </Text>
            <Text style={styles.bottomTextStyles}>Bank Details</Text>
            <Text style={styles.bottomTextStyles}>
              Name: Access Foundation WA
            </Text>
            <Text style={styles.bottomTextStyles}>BSB: 016-006</Text>
            <Text style={styles.bottomTextStyles}>Acc : 422599906</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    padding: 20,
  },
  section: {
    margin: 10,
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  txtColor: {
    color: "balck",
  },
  imageViewStyles: {
    alignItems: "flex-end",
    padding: 25,
  },
  txtStyles: {
    fontSize: 10,
  },
  txtBoldStyles: {
    fontSize: 13,
    fontWeight: "bold",
  },
  txtBoldHeaderStyles: {
    fontSize: 12,
    fontWeight: "bold",
  },
  descriptionLabelStyles: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottomWidth: 2,
    paddingVertical: 5,
  },

  descriptionStyles: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottomWidth: 0.2,
    paddingVertical: 1,
  },
  txtDescriptionStyles: {
    fontSize: 10,
    fontWeight: "bold",
  },
  bottomTextStyles: {
    fontSize: 10,
  },
});
export default AppPdfGenerator;
