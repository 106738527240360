import React from "react";

import { createStackNavigator } from "@react-navigation/stack";

import AdminHomeScreen from "../../../screens/TherapyTeam/admin/AdminHomeScreen";
import SessionMainScreen from "../../../screens/TherapyTeam/admin/AdminSessationHandler/SessionMainScreen";

const Stack = createStackNavigator();

const StackNavigator = () => (
  <Stack.Navigator
    presentation="modal"
    initialRouteName="AccessFoundation_ADMINHOMESCREEN"
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen
      name="AccessFoundation_ADMINHOMESCREEN"
      component={AdminHomeScreen}
      options={{ title: "Access Foundation" }}
    />
    <Stack.Screen
      name="AccessFoundation_AdminSessionMainScreen"
      component={SessionMainScreen}
      options={{ title: "Access Foundation" }}
    />
  </Stack.Navigator>
);

export default StackNavigator;
