import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";

import Logo from "../config/img/logo.png";

const AppReceptHandler = ({ item }) => {
  let date = moment(item.sessionDate, "YYYYY/MM/DD").format("DD/MM/YYYY");

  let Hours = ~~(item.fullSessionMins / 60);
  let mins = ~~(item.fullSessionMins % 60);

  return (
    <View style={styles.descriptionStyles}>
      <Text style={[styles.txtDescriptionStyles]}>{date}</Text>
      <Text style={[styles.txtDescriptionStyles]}>{item.serviceName}</Text>
      <Text style={[styles.txtDescriptionStyles]}>
        {item.totalExtraExpense}/kms
      </Text>
      <Text style={[styles.txtDescriptionStyles]}>
        ${item.totalExtraExpense_Price}
      </Text>
      <Text style={[styles.txtDescriptionStyles]}>
        {Hours}:{mins}/h
      </Text>
      <Text style={[styles.txtDescriptionStyles]}>
        ${item.services_CurrentPrice}
      </Text>
      <Text style={[styles.txtDescriptionStyles]}>GST Free</Text>
      <Text style={[styles.txtDescriptionStyles]}>
        ${item.totalSessionPrice}
      </Text>
    </View>
  );
};

function AppPdfReportGenerator({ items }) {
  let Invoice_Date = moment(items[0].sessionDate, "YYYYY/MM/DD").format(
    "DD/MM/YYYY"
  );
  let serviceCharges = 0;
  let kmCharges = 0;
  items.forEach((item) => {
    kmCharges += parseFloat(item.totalExtraExpense_Price ?? 0);
    serviceCharges += parseFloat(item.totalSessionPrice);
  });
  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
          <View style={styles.imageViewStyles}>
            <Image style={{ height: 100, width: 200 }} source={{ uri: Logo }} />
          </View>

          <View style={styles.descriptionLabelStyles}>
            <Text style={[styles.txtBoldStyles]}>Service Date</Text>
            <Text style={[styles.txtBoldStyles]}>Description</Text>
            <Text style={styles.txtBoldStyles}>Total kms</Text>
            <Text style={styles.txtBoldStyles}>Total Charges (kms)</Text>
            <Text style={styles.txtBoldStyles}>Session Hours</Text>
            <Text style={styles.txtBoldStyles}>Service Price</Text>
            <Text style={styles.txtBoldStyles}>GST</Text>
            <Text style={styles.txtBoldStyles}>Amount AUD</Text>
          </View>
          <View>
            {items.map((item) => {
              return <AppReceptHandler key={item.main_id} item={item} />;
            })}
          </View>
          <View style={{ alignItems: "flex-end", margin: 20 }}>
            <View
              style={{
                flexDirection: "row",
                marginVertical: 10,
                paddingBottom: 10,
                borderBottomWidth: 1,
              }}
            >
              <Text style={{ fontSize: 12 }}>km Charges :</Text>
              <Text style={{ fontSize: 12 }}>{kmCharges.toFixed(3)}</Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                marginVertical: 10,
                paddingBottom: 10,
                borderBottomWidth: 1,
              }}
            >
              <Text style={{ fontSize: 12 }}>Service Charges :</Text>
              <Text style={{ fontSize: 12 }}>{serviceCharges.toFixed(3)}</Text>
            </View>
            <View style={{ flexDirection: "row", marginVertical: 10 }}>
              <Text>TOTAL AUD :</Text>
              <Text>{(kmCharges + serviceCharges).toFixed(3)}</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    padding: 20,
  },
  section: {
    margin: 10,
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  txtColor: {
    color: "balck",
  },
  imageViewStyles: {
    alignItems: "flex-end",
    padding: 25,
  },
  txtStyles: {
    fontSize: 10,
  },
  txtBoldStyles: {
    fontSize: 8,
    fontWeight: "bold",
    width: "20%",
  },
  descriptionLabelStyles: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottomWidth: 2,
    paddingVertical: 5,
  },

  descriptionStyles: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottomWidth: 0.2,
    paddingVertical: 1,
  },
  txtDescriptionStyles: {
    fontSize: 7,
    fontWeight: "bold",
    width: "20%",
  },
  bottomTextStyles: {
    fontSize: 10,
  },
});
export default AppPdfReportGenerator;
