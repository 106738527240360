import React, { useState, useEffect, useContext } from "react";
import {
  View,
  StyleSheet,
  Text,
  Image,
  FlatList,
  useWindowDimensions,
} from "react-native";
import moment from "moment";

import listingAPI from "../../api/listings";
import DepartContext from "../../auth/DepartContext";
import AppCard from "../../components/AppCard";
import AppInputText from "../../components/AppInputText";
import AppButton from "../../components/AppButton";
import AppPicker from "../../components/AppPicker";
import AppTouchableLabel from "../../components/AppTouchableLabel";
import AppAlert from "../../components/AppAlert";
import Routes from "../../navigation/routes";
import Cache from "../../utility/cache";

const dapartmentSelect = [{ main_id: 6, name: "Admin" }];

function AccountRootScreen({ navigation }) {
  const departContext = useContext(DepartContext);
  const { height, width } = useWindowDimensions();

  const [allPenals, setAllPenals] = useState([]);
  const [alert, setAlert] = useState({
    modal: false,
    alert: "",
  });
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [category, setCategory] = useState({
    main_id: 0,
    name: "Select Login As",
  });

  let windowHeight = 0;

  if (height > width) {
    windowHeight = width;
  } else {
    windowHeight = height;
  }

  useEffect(() => {
    LoginChecker();
  }, []);

  const LoginChecker = async () => {
    const department_id = await Cache.get("u_department");
    const loginDate = await Cache.get("loginDate");

    const cDate = moment(new Date()).format("DD/MM/YYYY");

    if (department_id == 6 && cDate == loginDate) {
      navigation.navigate(Routes.STACK_ACCOUNT_HOMESCREEN);
    }
  };

  const OnPressClickHandler = async () => {
    const cDate = moment(new Date()).format("DD/MM/YYYY");

    if (!email) {
      setAlert({
        modal: true,
        alert: "Please, Enter email",
      });
      return;
    } else if (!password) {
      setAlert({
        modal: true,
        alert: "Please, Enter password",
      });
      return;
    }

    const myJsonServices = JSON.stringify({
      email: email.trim().toLowerCase(),
      password: password,
      department_main_id: category.main_id,
    });

    const { data } = await listingAPI.TherapyEmpPost({
      endPoint: "/login/auth/status/notDeleted",
      data: myJsonServices,
    });

    if (data.length > 0) {
      if (category.main_id == 6) {
        setEmail();
        setPassword();
        setCategory({
          main_id: 0,
          name: "Select Login As",
        });
        await Cache.store("loginedBy_main_id", data[0].therapist_id);
        await Cache.store("u_department", category.main_id);
        await Cache.store("loginDate", cDate);
        navigation.navigate(Routes.STACK_ACCOUNT_HOMESCREEN);
        return;
      }
    } else {
      setAlert({
        modal: true,
        alert: "Department or username or password is incorrect ",
      });
    }
  };

  const OnPressSwitchDepartmentHandler = async () => {
    await Cache.removeItem("u_department");
    departContext.setDepartment("departments");
  };

  return (
    <View style={[styles.container, { height, width }]}>
      <AppAlert alert={alert} okBtnPress={() => setAlert({ modal: false })} />

      <AppCard style={styles.cardStyles} width={width / 2} height={height / 2}>
        <Text style={{ fontWeight: "bold", fontSize: windowHeight / 50 }}>
          Welcome to Account Department
        </Text>
        <Image
          source={require("../../config/img/logo.png")}
          style={{
            marginBottom: 50,
            height: windowHeight / 6,
            width: windowHeight / 3,
          }}
        />

        <View style={{ width: "100%", alignItems: "center" }}>
          <AppPicker
            width="80%"
            items={dapartmentSelect}
            selectedItem={category}
            onSelectItem={(item) => setCategory(item)}
          />

          {category.main_id > 0 && (
            <>
              <AppInputText
                width="80%"
                label="service name"
                placeholder="Email : "
                iconName="email"
                value={email ?? ""}
                onChangeText={(txt) => setEmail(txt)}
              />
              <AppInputText
                width="80%"
                placeholder="Password : "
                iconName="lock"
                touchAble
                label="service name"
                value={password ?? ""}
                onChangeText={(txt) => setPassword(txt)}
              />
              <AppButton
                width="80%"
                label="Login"
                color="blue"
                onPress={() => OnPressClickHandler()}
              />
            </>
          )}
          <AppButton
            width="80%"
            label="Switch Departments"
            onPress={() => OnPressSwitchDepartmentHandler()}
          />
        </View>
      </AppCard>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // height: "100%",
    // width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },

  cardStyles: {
    alignItems: "center",
    justifyContent: "center",
  },
  LogoStyles: {
    height: "20%",
    width: "40%",
  },
});

export default AccountRootScreen;
