import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { NavigationContainer } from "@react-navigation/native";

import AuthContext from "../../auth/context";

import MasterAdminStackNavigator from "../../navigation/TherapyTeam/MasterAdmin/MasterAdminStackNavigator";
import TherapyTeamAdminStackNavigator from "../../navigation/TherapyTeam/Admin/TherapyTeamAdminStackNavigator";
import TherapistStackNavigator from "../../navigation/TherapyTeam/therapist/TherapistStackNavigator";
import TherapyRootScreen from "../../screens/TherapyTeam/TherapyRootScreen";

function TherapyRootNavigation({ navigation, screen }) {
  const [user, setUser] = useState("login");

  let CurrentNacigation;
  if (user == "login") {
    CurrentNacigation = TherapyRootScreen;
  } else if (user == "MasterAdmin") {
    CurrentNacigation = MasterAdminStackNavigator;
  } else if (user == "therapyAdmin") {
    CurrentNacigation = TherapyTeamAdminStackNavigator;
  } else if (user == "therapist") {
    CurrentNacigation = TherapistStackNavigator;
  }

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <CurrentNacigation />
    </AuthContext.Provider>
  );
}

export default TherapyRootNavigation;
