import React from "react";

import { createStackNavigator } from "@react-navigation/stack";

import DepartmentDeciderScreen from "../../screens/departments/DepartmentDeciderScreen";
import AccountRootScreen from "../../screens/AccountDepartment/AccountRootScreen";
import AccountHomeScreen from "../../screens/AccountDepartment/Accounts/AccountHomeScreen";

const Stack = createStackNavigator();

const StackNavigator = () => (
  <Stack.Navigator
    presentation="modal"
    initialRouteName="STACK_AccountRootScreen"
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen
      name="STACK_AccountRootScreen"
      component={AccountRootScreen}
      options={{ title: "Access Foundation" }}
    />
    <Stack.Screen
      name="STACK_AccountHomeScreen"
      component={AccountHomeScreen}
      options={{ title: "Access Foundation" }}
    />
    <Stack.Screen
      name="STACK_AllDepartmentScreen"
      component={DepartmentDeciderScreen}
      options={{ title: "Access Foundation" }}
    />
  </Stack.Navigator>
);

export default StackNavigator;
