import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text, FlatList } from "react-native";

import Colors from "../config/Colors";
import AppIcon from "../components/AppIcon";
import AppAlert from "../components/AppAlert";
import AppButton from "./AppButton";

function AppMultiFilesPicker({
  getAllFiles,
  uploadedFiles,
  height = "90%",
  width = "90%",
  btnUploadEnable = false,
  onPressUploadHandler,
}) {
  const [selectedImage, setSelectedImage] = useState([]);
  const [alert, setAlert] = useState({ modal: false, alert: "" });

  useEffect(() => {
    OnClickSetup(uploadedFiles);
  }, [uploadedFiles]);

  const OnClickSetup = (file) => {
    if (file.name != undefined) {
      //let fileName = file.name.split("<=>")[0];
      let fileName = file.name.substring(file.name.indexOf("<=>") + 3);
      OnClickDeleteHandler({ name: fileName });
    }
  };

  const ImageHandleChange = (e) => {
    let filteredFile = [];
    let notSupportedFiles = [];

    const maxFileSize = 100e6; //20MB
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size < maxFileSize) {
        if (
          e.target.files[i].type == "image/jpg" ||
          e.target.files[i].type == "image/jpeg" ||
          e.target.files[i].type == "image/png" ||
          e.target.files[i].type == "application/msword" ||
          e.target.files[i].type == "application/epub+zip" ||
          e.target.files[i].type == "image/gif" ||
          e.target.files[i].type == "video/mp4" ||
          e.target.files[i].type == "application/pdf" ||
          e.target.files[i].type == "application/vnd.ms-powerpoint" ||
          e.target.files[i].type == "application/vnd.rar" ||
          e.target.files[i].type == "text/plain" ||
          e.target.files[i].type == "image/webp" ||
          e.target.files[i].type == "application/x-7z-compressed" ||
          e.target.files[i].type == "pplication/zip" ||
          e.target.files[i].type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          e.target.files[i].type ==
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        ) {
          filteredFile.push(e.target.files[i]);
        } else {
          notSupportedFiles.push(e.target.files[i]);
        }
      } else {
        notSupportedFiles.push(e.target.files[i]);
      }
    }

    if (notSupportedFiles.length > 0) {
      setAlert({
        data: notSupportedFiles,
        modal: true,
        alert: "file is over size. Can't upload it.",
      });
    }

    if (filteredFile) {
      const fileArray = Array.from(filteredFile).map((file) => ({
        name: file.name,
        file: file,
        //file: URL.createObjectURL(file),
      }));
      getAllFiles(fileArray);
      setSelectedImage((priveImages) => priveImages.concat(fileArray));
      Array.from(filteredFile).map((file) => URL.revokeObjectURL(file));
    }
  };

  const OnClickDeleteHandler = (file) => {
    let filesArray = selectedImage;
    let updatedData = filesArray.filter((item) => item.name !== file.name);
    setSelectedImage(updatedData);
    getAllFiles(updatedData);
  };

  const RenderPhotos = (photo) => {
    return (
      <View key={photo.items.name} style={styles.fileListViewStyles}>
        <Text
          style={{ fontSize: "100%", fontWeight: "bold", paddingHorizontal: 5 }}
        >
          {photo.items.name}
        </Text>
        <AppIcon
          size="20%"
          touchAble
          iconColor="red"
          name="delete"
          backgroundColor={Colors.InputText}
          onPress={() => OnClickDeleteHandler(photo.items)}
        />
      </View>
    );
  };

  return (
    <View style={[styles.container, { height, width }]}>
      <Text style={{ fontSize: 20, fontWeight: "bold" }}>
        Upload Documents :
      </Text>
      <View style={[styles.mainViewStyles]}>
        <AppAlert
          alert={alert}
          arrayAlert={alert.data}
          okBtnPress={() => setAlert({ modal: false, alert: "" })}
        />

        <div>
          <input multiple type="file" id="file" onChange={ImageHandleChange} />
          <Text style={{ fontSize: 25, fontWeight: "bold" }}>
            Selected Files : {selectedImage.length}
          </Text>

          <FlatList
            data={selectedImage}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item }) => <RenderPhotos items={item} />}
          />
        </div>

        {selectedImage.length <= 0 && (
          <View
            style={{
              height: "80%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AppIcon size={100} name="cloud-upload" />
          </View>
        )}
      </View>
      {btnUploadEnable && (
        <AppButton
          style={{ alignSelf: "center" }}
          label="Upload Document"
          onPress={onPressUploadHandler}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    backgroundColor: "#fff",
  },
  mainViewStyles: {
    height: "80%",
    borderWidth: 1,
  },
  fileListViewStyles: {
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 25,
    paddingHorizontal: 20,
    margin: 5,
    flexDirection: "row",
    width: "90%",
    backgroundColor: Colors.InputText,
  },
});

export default AppMultiFilesPicker;
