export default {
  primary: "#4877D0",
  secondry: "#169654",
  green: "#b5f6ca",
  darkRed: "#f46d6d",
  pink: "#EC609B",
  yellow: "#FFCE2E",
  opium: "#998D8D",
  blue: "#30AADD",
  darkBlue: "#c7e5f6",
  keshri: "#F88615",

  darkBermuda: "#B4DFDC",
  gainsboro: "#8d8c8c",
  whiteSmoke: "#F2F2F2",
  fb: "#3b5996",
  lightWhite: "#ECECEC",

  black: "#000",
  white: "#fff",
  medium: "#6e6969",
  light: "#f8f4f4",
  dark: "#0c0c0c",
  danger: "#ff5252",
  txtDark: "#E3E0DF",
  //darkBlue: "#4042D3",
  red: "#ee4037",
  lightDark: "#DBD7DC",
  darkBlack: "#F1F2F1",
  InputText: "#E7E7E7",
  placeHolderColor: "#ACADAD",
  darkRed: "#F7B7C7",
};
