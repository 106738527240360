import React, { useState, useEffect, useContext } from "react";
import {
  View,
  StyleSheet,
  Text,
  Image,
  FlatList,
  useWindowDimensions,
} from "react-native";
import moment from "moment";
import * as Network from "expo-network";

import listingAPI from "../../api/listings";
import AuthContext from "../../auth/context";
import DepartContext from "../../auth/DepartContext";
import AppCard from "../../components/AppCard";
import AppInputText from "../../components/AppInputText";
import AppButton from "../../components/AppButton";
import AppAlert from "../../components/AppAlert";
import AppPicker from "../../components/AppPicker";
import Cache from "../../utility/cache";

const dapartmentSelect = [
  { main_id: 16, name: "Master Admin" },
  { main_id: 2, name: "Admin" },
  { main_id: 3, name: "Therapist" },
];

function TherapyRootScreen({ navigation }) {
  const authContext = useContext(AuthContext);
  const departContext = useContext(DepartContext);

  const { height, width } = useWindowDimensions();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [alert, setAlert] = useState({
    modal: false,
    alert: "",
  });
  const [category, setCategory] = useState({
    main_id: 0,
    name: "Select Login As",
  });

  let windowHeight = 0;

  if (height > width) {
    windowHeight = width;
  } else {
    windowHeight = height;
  }

  useEffect(() => {
    LoginChecker();
  }, []);

  const LoginChecker = async () => {
    const department_id = await Cache.get("u_department");
    const loginDate = await Cache.get("loginDate");

    const cDate = moment(new Date()).format("DD/MM/YYYY");

    if (department_id != null && cDate == loginDate) {
      DirectLockedIn(department_id);
    }
  };

  const DirectLockedIn = async (department_id) => {
    if (department_id == 16) {
      authContext.setUser("MasterAdmin");
    } else if (department_id == 2) {
      authContext.setUser("therapyAdmin");
      return;
    } else if (department_id == 3) {
      authContext.setUser("therapist");
      return;
    }
  };

  const OnPressClickHandler = async (email, password, department_id) => {
    const IP = await Network.getIpAddressAsync();
    if (!email) {
      setAlert({
        modal: true,
        alert: "Please, Enter email",
      });
      return;
    } else if (!password) {
      setAlert({
        modal: true,
        alert: "Please, Enter password",
      });
      return;
    }

    const myJsonServices = JSON.stringify({
      email: email.trim().toLowerCase(),
      password: password,
      department_main_id: department_id,
    });

    const { data } = await listingAPI.TherapyEmpPost({
      endPoint: "/login/auth/status/notDeleted",
      data: myJsonServices,
    });

    if (data.length > 0) {
      const cDate = moment(new Date()).format("DD/MM/YYYY");

      if (department_id == 16) {
        await Cache.store("loginedBy_main_id", data[0].therapist_id);
        await Cache.store("u_department", department_id);
        await Cache.store("current_IP", IP);
        await Cache.store("loginDate", cDate);
        authContext.setUser("MasterAdmin");
        return;
      } else if (department_id == 2) {
        await Cache.store("loginedBy_main_id", data[0].therapist_id);
        await Cache.store("u_department", department_id);
        await Cache.store("current_IP", IP);
        await Cache.store("loginDate", cDate);
        await Cache.store("serviceDepartmentId", data[0].serviceDepartmentId);
        authContext.setUser("therapyAdmin");
        return;
      } else if (department_id == 3) {
        await Cache.store("loginedBy_main_id", data[0].therapist_id);
        await Cache.store("u_department", department_id);
        await Cache.store("current_IP", IP);
        await Cache.store("loginDate", cDate);
        authContext.setUser("therapist");
        return;
      }
    } else {
      setAlert({
        modal: true,
        alert: "Depart or username or password is incorrect ",
      });
    }
  };

  const OnPressSwitchDepartmentHandler = async () => {
    await Cache.removeItem("u_department");
    departContext.setDepartment("departments");
  };

  return (
    <View style={[styles.container, { height, width }]}>
      <AppAlert alert={alert} okBtnPress={() => setAlert({ modal: false })} />

      <AppCard style={styles.cardStyles} width={width / 2} height={height / 2}>
        <Text style={{ fontWeight: "bold", fontSize: windowHeight / 50 }}>
          Welcome to Therapy Department
        </Text>
        <Image
          source={require("../../config/img/logo.png")}
          style={{
            marginBottom: 50,
            height: windowHeight / 6,
            width: windowHeight / 3,
          }}
        />

        <View style={{ width: "100%", alignItems: "center" }}>
          <AppPicker
            width="80%"
            items={dapartmentSelect}
            selectedItem={category}
            onSelectItem={(item) => setCategory(item)}
          />

          {category.main_id > 0 && (
            <>
              <AppInputText
                width="80%"
                label="User name"
                placeholder="Email : "
                iconName="email"
                value={email ?? ""}
                onChangeText={(txt) => setEmail(txt)}
              />
              <AppInputText
                width="80%"
                placeholder="Password : "
                iconName="lock"
                touchAble
                label="Password"
                value={password ?? ""}
                onChangeText={(txt) => setPassword(txt)}
              />
              <AppButton
                width="80%"
                label="Login"
                color="blue"
                onPress={() =>
                  OnPressClickHandler(email, password, category.main_id)
                }
              />
            </>
          )}
          <AppButton
            width="80%"
            label="Switch Departments"
            onPress={() => OnPressSwitchDepartmentHandler()}
          />
        </View>
      </AppCard>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // height: "100%",
    // width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },

  cardStyles: {
    alignItems: "center",
    justifyContent: "center",
  },
  LogoStyles: {
    height: "20%",
    width: "40%",
  },
});

export default TherapyRootScreen;
