import React from "react";
import { View, StyleSheet, Text } from "react-native";

import Colors from "../../config/Colors";
import AppIcon from "../../components/AppIcon";

function AppTherapyDepartmentCart({
  data,
  OnClickEditHandler,
  OnClickDeleteHandler,
}) {
  return (
    <View style={styles.container}>
      <View style={styles.labelViewStyles}>
        <Text>{data.therapistName}</Text>
        <Text>{data.departmentName}</Text>
      </View>
      <View style={styles.iconViewStyles}>
        <AppIcon
          touchAble
          name="pen"
          backgroundColor=""
          onPress={() => OnClickEditHandler(data)}
        />
        <AppIcon
          touchAble
          name="delete"
          backgroundColor=""
          onPress={() => OnClickDeleteHandler(data)}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    backgroundColor: Colors.InputText,
    padding: 5,
    margin: 5,
    justifyContent: "space-around",
  },
  labelViewStyles: {
    width: "70%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  iconViewStyles: {
    width: "30%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
});

export default AppTherapyDepartmentCart;
