import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text, ScrollView, Dimensions } from "react-native";
import moment from "moment";

import Colors from "../../../../config/Colors";
import listingAPI from "../../../../api/listings";
import Cache from "../../../../utility/cache";
import AppCalander from "../../../../components/AppCalander";
import AppProgressbar from "../../../../components/AppProgressbar";
import AppCard from "../../../../components/AppCard";
import AppButton from "../../../../components/AppButton";
import AppAlert from "../../../../components/AppAlert";
import AppInputText from "../../../../components/AppInputText";
import AppTouchableLabel from "../../../../components/AppTouchableLabel";
import AppPicker from "../../../../components/AppPicker";
import AppMultiFilesPicker from "../../../../components/AppMultiFilesPicker";
import AppFolder from "../../../../components/AppFolder";

function AddParticipantScreen({ refreshPage }) {
  const [alertDetails, setAlertDetails] = useState({
    modal: false,
    alert: "",
  });
  const [selectDepartmentName, setSelectDepartmentName] = useState({
    name: "",
    departmentId: 0,
  });

  const [name, setName] = useState();
  const [contact, setContact] = useState();
  const [guardianName, setGuardianName] = useState();
  const [guardianContact, setGuardianContact] = useState();
  const [referralDate, setReferralDate] = useState();
  const [birthDate, setBirthDate] = useState();
  const [selectedGender, setSelectedGender] = useState();
  const [participantDiagnosis, setParticipantDiagnosis] = useState();
  const [allDepartment, setAllDepartment] = useState([]);
  const [task, setTask] = useState();
  const [planStartDate, setPlanStartDate] = useState();
  const [planEndDate, setPlanEndDate] = useState();
  const [ndisNo, setNdisNo] = useState();
  const [address, setAddress] = useState();
  const [participantEmail, setParticipantEmail] = useState();
  const [guardianEmail, setGuardianEmail] = useState();
  const [guardianAddress, setGuardianAddress] = useState();
  const [supportCoordinatorName, setSupportCoordinatorName] = useState();
  const [supportCoordinatorNumber, setSupportCoordinatorNumber] = useState();
  const [supportCoordinatorEmail, setSupportCoordinatorEmail] = useState();
  const [supportCoordinatorAddress, setSupportCoordinatorAddress] = useState();

  const [fileUris, setFileUris] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [progressBar, setProgressBar] = useState(false);

  const genderList = [
    { main_id: 1, name: "Male" },
    { main_id: 2, name: "Female" },
    { main_id: 3, name: "Others" },
    { main_id: 4, name: "don't disclose" },
  ];

  useEffect(() => {
    GetAllTherapyDepartment();
  }, []);

  const GetAllTherapyDepartment = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");
    const myJsonServices = JSON.stringify({
      therapist_main_id: therapist_main_id,
    });
    const { data } = await listingAPI.TherapyDepartment({
      endPoint: "/Select/All/therapyDepartment/of/selected/admin",
      data: myJsonServices,
    });

    setAllDepartment(data);
  };

  const OnClickAddHandler = async () => {
    let p_startDate = moment(planStartDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    let p_endDate = moment(planEndDate, "DD/MM/YYYY").format("YYYY-MM-DD");

    if (p_startDate >= p_endDate) {
      setAlertDetails({
        modal: true,
        alert: "'Plan End date' must be after 'Plan start start date'.",
      });
      return;
    }
    if (selectDepartmentName.departmentId == 0) {
      setAlertDetails({
        modal: true,
        alert: "Please, Select Department",
      });
      return;
    } else if (!name) {
      setAlertDetails({
        modal: true,
        alert: "Please, Add Participant's name.",
      });
      return;
    } else if (!birthDate || birthDate == "DD/MM/YYYY") {
      setAlertDetails({
        modal: true,
        alert: "Please, Select BirthDate.",
      });
      return;
    } else if (!selectedGender || selectedGender == "Select Gender") {
      setAlertDetails({
        modal: true,
        alert: "Please, Select Participant Gender.",
      });
      return;
    } else if (!planStartDate) {
      setAlertDetails({
        modal: true,
        alert: "Please, Select plan start Date.",
      });
      return;
    } else if (!planEndDate) {
      setAlertDetails({
        modal: true,
        alert: "Please, Select plan end Date.",
      });
      return;
    } else if (!ndisNo) {
      setAlertDetails({
        modal: true,
        alert: "Please, Add NDIS No.",
      });
      return;
    }

    const myJsonServices = JSON.stringify({
      department_main_id: selectDepartmentName.departmentId,
      name: name && name.toUpperCase(),
      contactNumber: contact,
      ParticipantEmail:
        participantEmail != undefined ? participantEmail.trim() : "",
      ParticipantAddress: address && address.toUpperCase(),
      ParticipantBirthDate: moment(birthDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
      ParticipantGender: selectedGender.name,
      ParticipantDiagnosis: participantDiagnosis,
      guardianName: guardianName && guardianName.toUpperCase(),
      guardianContact: guardianContact,
      guardianEmail: guardianEmail,
      guardianAddress: guardianAddress,
      supportCoordinatorName: supportCoordinatorName,
      supportCoordinatorNumber: supportCoordinatorNumber,
      supportCoordinatorEmail: supportCoordinatorEmail,
      supportCoordinatorAddress: supportCoordinatorAddress,
      referralDate:
        referralDate != undefined
          ? moment(referralDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
      briefTask: task,
      planStartDate: moment(planStartDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      planEndDate: moment(planEndDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      ndisNo: ndisNo.trim(),
    });

    const { data } = await listingAPI.ParticipantPost({
      endPoint: "/insert/participant",
      data: myJsonServices,
    });

    if (data.duplicate == "true") {
      setAlertDetails({
        modal: true,
        alert: `It looks like, this participant is already registered in ${data.result[0].departmentName} department.`,
      });
      return;
    } else {
      documentUpload_cloud_Handler(data.result[0].Perticipant_Main_id);
      OnClickCancelHandler();
    }
  };

  const OnClickCancelHandler = () => {
    setName();
    setContact();
    setGuardianName();
    setGuardianContact();
    setReferralDate();
    setTask();
    setPlanStartDate();
    setPlanEndDate();
    setNdisNo();
  };

  const documentDataHandler_inTable = async (
    documentName,
    participant_id,
    folder_main_id
  ) => {
    const loginBymain_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      participant_main_id: participant_id,
      folderMain_id: folder_main_id,
      services_main_id: null,
      uploadPersion_main_id: loginBymain_id,
      uploadBy: "therapyAdmin",
      uploadDate: moment(Date.now()).format("YYYY/MM/DD"),
      documentName: documentName,
      status: "inactive",
    });

    const { data } = await listingAPI.ParticipentsDocument_dataIntoTable({
      endPoint: "/Insert/each/participant/documents",
      data: myJsonServices,
    });
  };

  const documentUpload_cloud_Handler = async (participant_id) => {
    setProgressBar(true);

    let department = await Cache.get("selected_department");
    let loginedBy_main_id = await Cache.get("loginedBy_main_id");

    const newFolderName = "about me";

    const myJsonServicesCreateFolder = JSON.stringify({
      participant_main_id: participant_id,
      departmentMain_id: department,
      created_by_main_id: loginedBy_main_id,
      documentPath: `${newFolderName.toUpperCase().trim() + "/"}`,
    });

    const { data } = await listingAPI.DocumnetHandler({
      endPoint: "/Insert/folder",
      data: myJsonServicesCreateFolder,
    });

    const folder_main_id = data[0].main_id;

    const myJsonServices = fileUris;

    for (let i = 0; i < myJsonServices.length; i++) {
      const { data } = await listingAPI.FileUploadInFolderPost({
        endPoint: "/file/Upload/InFolder",
        data: myJsonServices[i],
        Folder: `${participant_id + "/"}${department + "/"}${
          loginedBy_main_id + "/"
        }${newFolderName + "/"}`,
      });

      if (data == null) {
        setProgressBar(false);
        setAlertDetails({
          modal: true,
          alert:
            "Something went wrong, File may be not supported or File size is more than 20MB.",
        });
        return;
      } else {
        documentDataHandler_inTable(data.name, participant_id, folder_main_id);
        setUploadedFile(data);
      }
    }
    setProgressBar(false);
    refreshPage("done");
    //ClearUseStateHandler();
  };

  return (
    <View style={styles.container}>
      <AppProgressbar modalVisible={progressBar} />
      <AppAlert
        alert={alertDetails}
        okBtnPress={() =>
          setAlertDetails({
            modal: false,
            alert: "",
          })
        }
      />

      <AppCard style={styles.cardStyles} height="85%" width="90%">
        <ScrollView style={{ width: "90%" }}>
          <View style={{ alignItems: "center", width: "100%" }}>
            <Text
              style={{
                fontSize: "100%",
                marginBottom: 20,
                alignSelf: "center",
                fontWeight: "bold",
              }}
            >
              Add Participant
            </Text>
            <View style={{ width: "100%" }}>
              <AppPicker
                width="40%"
                label="Select Department : "
                items={allDepartment}
                selectedItem={selectDepartmentName}
                onSelectItem={(item) =>
                  setSelectDepartmentName({
                    name: item.name,
                    departmentId: item.main_id,
                  })
                }
              />
              {/* <AppTouchableLabel
                items={allDepartment}
                width="30%"
                placeholder="Select Department : "
                label="Select Department : "
                iconName="account"
                menuLabel={"Select Department"}
                selectedMenu={(item) =>
                  setSelectDepartmentName({
                    name: item.name,
                    departmentId: item.main_id,
                  })
                }
              /> */}
            </View>

            <View style={styles.detailRowViewStyles}>
              <AppInputText
                width="20%"
                placeholder="Participant Name  : "
                label="Participant Name : *"
                iconName="account"
                value={name ?? ""}
                onChangeText={(txt) => setName(txt.toUpperCase())}
              />
              <AppInputText
                width="20%"
                placeholder="Participant Contact : "
                label="Participant Contact : "
                iconName="phone"
                keyboardType="numeric"
                pattern="[0-9]*"
                maxLength={10}
                value={contact ?? ""}
                onChangeText={(txt) => setContact(txt.replace(/[^0-9]/g, ""))}
              />
              <AppInputText
                width="20%"
                placeholder="Participant Email : "
                label="Participant Email : "
                value={participantEmail ?? ""}
                onChangeText={(txt) => setParticipantEmail(txt.toUpperCase())}
              />
              <AppInputText
                width="20%"
                placeholder="Participant Address : "
                label="Participant Address : "
                iconName="location-pin"
                IconType="Entypo"
                value={address ?? ""}
                onChangeText={(txt) => setAddress(txt.toUpperCase())}
              />
            </View>
            <View style={styles.detailRowViewStyles}>
              <AppCalander
                width="20%"
                label="Participant Birth Date : *"
                datePlaceholder={birthDate}
                selectedDate={(date) => setBirthDate(date)}
                maxDate={new Date()}
              />

              <AppPicker
                width="20%"
                label="Participant Gender : "
                items={genderList}
                selectedItem={selectedGender}
                onSelectItem={(item) => {
                  console.log(item);
                  setSelectedGender(item);
                }}
              />
              {/* <AppTouchableLabel
                items={genderList}
                width="20%"
                placeholder="Participant Gender : "
                label="Participant Gender : "
                iconName="account"
                menuLabel={"Select Gender"}
                selectedMenu={(item) => setSelectedGender(item)}
              /> */}
              <AppInputText
                iconName="tasks"
                IconType="FontAwesome"
                width="45%"
                placeholder="Participant Diagnosis : "
                label="Participant Diagnosis : "
                multiline
                height={150}
                value={participantDiagnosis ?? ""}
                onChangeText={(txt) =>
                  setParticipantDiagnosis(txt.toUpperCase())
                }
              />
            </View>
            <View style={styles.detailRowViewStyles}>
              <AppInputText
                width="20%"
                placeholder="Guardian Name : "
                label="Guardian Name : "
                iconName="account"
                value={guardianName ?? ""}
                onChangeText={(txt) => setGuardianName(txt.toUpperCase())}
              />
              <AppInputText
                width="20%"
                placeholder="Guardian Contact : "
                label="Guardian Contact : "
                iconName="phone"
                keyboardType="numeric"
                pattern="[0-9]*"
                maxLength={10}
                value={guardianContact ?? ""}
                onChangeText={(txt) =>
                  setGuardianContact(txt.replace(/[^0-9]/g, ""))
                }
              />
              <AppInputText
                width="20%"
                placeholder="Guardian Email : "
                label="Guardian Email : "
                value={guardianEmail ?? ""}
                onChangeText={(txt) => setGuardianEmail(txt.toUpperCase())}
              />
              <AppInputText
                width="20%"
                placeholder="Guardian Address : "
                label="Guardian Address : "
                iconName="location-pin"
                IconType="Entypo"
                value={guardianAddress ?? ""}
                onChangeText={(txt) => setGuardianAddress(txt.toUpperCase())}
              />
            </View>
            <View style={styles.detailRowViewStyles}>
              <AppInputText
                width="20%"
                placeholder="Support coordinator Name  : "
                label="Support coordinator Name  : "
                iconName="account"
                value={supportCoordinatorName ?? ""}
                onChangeText={(txt) =>
                  setSupportCoordinatorName(txt.toUpperCase())
                }
              />
              <AppInputText
                width="20%"
                placeholder="Support coordinator Contact : "
                label="Support coordinator Contact : "
                iconName="phone"
                keyboardType="numeric"
                pattern="[0-9]*"
                maxLength={10}
                value={supportCoordinatorNumber ?? ""}
                onChangeText={(txt) =>
                  setSupportCoordinatorNumber(txt.replace(/[^0-9]/g, ""))
                }
              />
              <AppInputText
                width="20%"
                placeholder="Support coordinator Email : "
                label="Support coordinator Email : "
                value={supportCoordinatorEmail ?? ""}
                onChangeText={(txt) =>
                  setSupportCoordinatorEmail(txt.toUpperCase())
                }
              />
              <AppInputText
                width="20%"
                placeholder="Support coordinator Address : "
                label="Support coordinator Address : "
                iconName="location-pin"
                IconType="Entypo"
                value={supportCoordinatorAddress ?? ""}
                onChangeText={(txt) =>
                  setSupportCoordinatorAddress(txt.toUpperCase())
                }
              />
            </View>
            <View style={styles.detailRowViewStyles}>
              <AppCalander
                width="20%"
                label="Referral Date : *"
                datePlaceholder={referralDate}
                selectedDate={(date) => setReferralDate(date)}
              />

              <AppCalander
                width="20%"
                label="Plan Start Date : *"
                datePlaceholder={planStartDate}
                selectedDate={(date) => setPlanStartDate(date)}
                maxDate={new Date()}
              />
              <AppCalander
                width="20%"
                label="Plan End Date : *"
                datePlaceholder={planEndDate}
                selectedDate={(date) => setPlanEndDate(date)}
              />
              <AppInputText
                width="20%"
                placeholder="NDIS No    : *"
                label="NDIS No   : "
                iconName="numeric"
                maxLength={20}
                value={ndisNo ?? ""}
                onChangeText={(txt) => setNdisNo(txt.replace(/[^0-9]/g, ""))}
                keyboardType="numeric"
                pattern="[0-9]*"
              />
            </View>

            <AppInputText
              placeholder="Tasks in brief     : "
              label="Tasks in brief    : "
              iconName="tasks"
              IconType="FontAwesome"
              value={task ?? ""}
              onChangeText={(txt) => setTask(txt)}
              multiline
              height={150}
            />

            <View style={styles.documentVierStyles}>
              <AppMultiFilesPicker
                uploadedFiles={uploadedFile}
                getAllFiles={(files) => setFileUris(files)}
              />
            </View>
            <View style={styles.btnViewStyles}>
              <AppButton
                color="blue"
                label="Cancel"
                onPress={() => OnClickCancelHandler()}
              />
              <AppButton label="Add" onPress={() => OnClickAddHandler()} />
            </View>
          </View>
        </ScrollView>
      </AppCard>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    justifyContent: "space-around",
  },
  cardStyles: {
    borderWidth: 1,
  },
  btnViewStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  documentVierStyles: {
    height: "40%",
    width: "90%",
    marginVertical: 10,
  },
  documentBtnStyles: {
    width: "90%",
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  detailRowViewStyles: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    marginVertical: 10,
  },
});

export default AddParticipantScreen;
