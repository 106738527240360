import React from "react";
import { View, StyleSheet, Text } from "react-native";

import Colors from "../config/Colors";

function AppCard({ height = "20%", width = "20%", style, children }) {
  return (
    <View style={[styles.container, { height, width }]}>
      <View style={[styles.mainViewStyles, style]}>{children}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    padding: 20,
    alignItems: "center",
  },
  mainViewStyles: {
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
});

export default AppCard;
