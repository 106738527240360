import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text, ScrollView } from "react-native";
import moment from "moment";
import Modal from "react-native-modal";

import listingAPI from "../../../api/listings";
import AppSearchBar from "../../../components/AppSearchBar";
import AppTouchableLabel from "../../../components/AppTouchableLabel";
import AppPicker from "../../../components/AppPicker";
import AppCalander from "../../../components/AppCalander";
import AppAlert from "../../../components/AppAlert";
import AppButton from "../../../components/AppButton";
import AppPdfGenerator from "../../../components/AppPdfGenerator";
import AppClameDetailsCard from "../../../components/cards/AppClameDetailsCard";

function ReceiptGeneratorScreen({}) {
  const [selectedParticipant, setSelectedParticipant] = useState();
  const [serviceList, setServiceList] = useState();
  const [pdfModal, setPdfModal] = useState(false);
  const [alert, setAlert] = useState({ modal: false, item: "" });
  const [selectedService, setSelectedService] = useState({ main_id: 0 });
  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [getSelectedSessions, setGetSelectedSessions] = useState([]);

  const GetServisisOfSelectedParticipant = async (item) => {
    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
    });
    const { data } = await listingAPI.AllocateServicesPost({
      endPoint:
        "/select/all/Allocated/Services/of/selected/participant/for/Account/Team",
      data: myJsonServices,
    });

    if (data.length > 0) {
      setServiceList(data);
    } else {
      setServiceList([{ main_id: 0, name: "no data found" }]);
    }
  };

  const OnClickClamedReportHandler = async () => {
    if (selectedService.main_id > 0) {
      let f_date = moment(fromDate, "DD/MM/YYYY").format("YYYY/MM/DD");
      let t_date = moment(toDate, "DD/MM/YYYY").format("YYYY/MM/DD");
      const myJsonServices = JSON.stringify({
        participant_main_id: selectedParticipant.main_id,
        fromDate: f_date,
        toDate: t_date,
        services_main_id: selectedService.services_main_id,
      });
      const { data } = await listingAPI.EveryDaySessionHandler({
        endPoint:
          "/select/Clamed/sessions/of/selected/Participant/asPer/date/and/selected/service",
        data: myJsonServices,
      });
      setGetSelectedSessions(data);
    } else {
      let f_date = moment(fromDate, "DD/MM/YYYY").format("YYYY/MM/DD");
      let t_date = moment(toDate, "DD/MM/YYYY").format("YYYY/MM/DD");

      const myJsonServices = JSON.stringify({
        participant_main_id: selectedParticipant.main_id,
        fromDate: f_date,
        toDate: t_date,
      });
      const { data } = await listingAPI.EveryDaySessionHandler({
        endPoint: "/select/Clamed/sessions/of/selected/Participant/asPer/date",
        data: myJsonServices,
      });

      setGetSelectedSessions(data);
    }
  };

  const OnClickClearHandler = () => {
    setSelectedParticipant();
    setGetSelectedSessions([]);
    setSelectedService({ main_id: 0 });
    setFromDate("DD/MM/YYYY");
    setToDate("DD/MM/YYYY");
  };
  return (
    <View style={styles.container}>
      <AppAlert alert={alert} />
      <Modal style={{ alignItems: "center" }} isVisible={pdfModal}>
        <AppPdfGenerator items={getSelectedSessions} selectedService={selectedService} toDate={toDate} fromDate={fromDate} participant={selectedParticipant} />
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            alignItems: "center",
          }}
        >
          <AppButton
            label="Back"
            color="pink"
            onPress={() => setPdfModal(false)}
          />
        </View>
      </Modal>
      <View style={styles.searchBarStyles}>
        <AppSearchBar
          searchAllParticipent
          getSelectedItem={(item) => {
            GetServisisOfSelectedParticipant(item);
            setSelectedParticipant(item);
          }}
        />
      </View>
      {selectedParticipant && (
        <View style={styles.secondViewStyles}>
          <Text style={styles.txtNameStyles}>{selectedParticipant.name}</Text>
          <View style={styles.dateAndServiceViewStyles}>
            <AppPicker
              width="30%"
              label="Select Service"
              items={serviceList}
              selectedItem={selectedService}
              onSelectItem={(item) => setSelectedService(item)}
            />
            {/* 
            <AppTouchableLabel
              iconName="wheelchair-accessibility"
              menuLabel={selectedService.main_id == 0 && "Select Service"}
              width="20%"
              items={serviceList}
              selectedMenu={(item) => setSelectedService(item)}
            /> */}

            <AppCalander
              width="20%"
              label="From Date : "
              datePlaceholder={fromDate ?? "DD/MM/YYYY"}
              selectedDate={(date) => setFromDate(date)}
              maxDate={new Date()}
            />
            <AppCalander
              width="20%"
              label="To Date : "
              datePlaceholder={toDate ?? "DD/MM/YYYY"}
              selectedDate={(date) => setToDate(date)}
              maxDate={new Date()}
            />
            <View style={{ width: "20%" }}>
              <AppButton
                width="70%"
                label="Report"
                color="blue"
                onPress={() => OnClickClamedReportHandler()}
              />
              <AppButton
                width="70%"
                label="Clear"
                onPress={() => OnClickClearHandler()}
              />
            </View>
          </View>
          <View style={styles.reportViewStyles}>
            {getSelectedSessions.length > 0 ? (
              <AppClameDetailsCard
                btnEnable={false}
                items={getSelectedSessions}
                onPressOutsideHandlerEnable
                onPressOutsideHandler={(item) => console.log(item)}
              />
            ) : (
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: "100%",
                  alignSelf: "center",
                }}
              >
                No data Found
              </Text>
            )}
          </View>
          <View style={styles.receiptrBtnStyles}>
            {getSelectedSessions.length > 0 && (
              <AppButton
                label="Generate Receipt"
                onPress={() => setPdfModal(true)}
              />
            )}
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  searchBarStyles: {
    height: "15%",
    width: "100%",
  },
  secondViewStyles: {
    height: "85%",
    width: "95%",
    alignItems: "center",
    alignSelf: "center",
  },
  txtNameStyles: {
    fontWeight: "bold",
    fontSize: "100%",
    borderWidth: 1,
    padding: 5,
    borderRadius: 5,
  },
  dateAndServiceViewStyles: {
    width: "100%",
    height: "10%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    borderBottomWidth: 1,
  },
  reportViewStyles: {
    width: "100%",
    height: "80%",
    alignSelf: "center",
  },
  receiptrBtnStyles: {
    height: "5%",
    width: "100%",
    alignItems: "center",
  },
});

export default ReceiptGeneratorScreen;
