import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  Switch,
  TouchableOpacity,
} from "react-native";
import GoogleMapReact from "google-map-react";

import listingAPI from "../../../../api/listings";
import AppButton from "../../../../components/AppButton";
import AppIcon from "../../../../components/AppIcon";
import AppAlert from "../../../../components/AppAlert";
import Colors from "../../../../config/Colors";
import AppText from "../../../../components/AppText";

const place = [
  { main_id: 1, name: "Office" },
  { main_id: 2, name: "Work From Home" },
];

const LocalCard = ({ items, onPressDelete, OnClickPositionStatusHandler }) => {
  const [open, setOpen] = useState(false);
  console.log(items);
  const defaultProps = {
    center: {
      lat: items.latitude,
      lng: items.longitude,
    },
    zoom: 11,
  };

  return (
    <>
      <TouchableOpacity
        style={styles.officeCardStyles}
        onPress={() => setOpen(!open)}
      >
        <Text style={{ width: "20%", fontWeight: "bold" }}>{items.name}</Text>
        <Text style={{ width: "30%", fontWeight: "bold" }}>{items.email}</Text>
        <Text style={{ width: "20%", fontWeight: "bold" }}>
          {items.placeType}
        </Text>
        <Text style={{ width: "20%", fontWeight: "bold" }}>{items.phone}</Text>
        <View style={styles.btnStylesView}>
          {items.status != "active" ? (
            <AppButton
              label={items.status == "active" ? "cative" : "inactive"}
              color={items.status == "active" ? "blue" : "red"}
              onPress={() => OnClickPositionStatusHandler(items)}
            />
          ) : (
            <AppText label="Active" />
          )}

          <AppIcon
            touchAble
            backgroundColor=""
            name="delete"
            onPress={() => onPressDelete(items)}
          />
        </View>
      </TouchableOpacity>
      {/* <div style={{ height: "100%", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" />
        </GoogleMapReact>
      </div> */}
    </>
  );
};

function AccessHandlerScreen({}) {
  const [allOffices, setAllOffices] = useState();
  const [alert, setAlert] = useState({
    modal: false,
    alert: "",
  });

  useEffect(() => {
    GetAllNotDeletedOffices();
  }, []);

  const GetAllNotDeletedOffices = async () => {
    const myJsonServices = JSON.stringify({});

    const { data } = await listingAPI.LocationChecker_POST({
      endPoint: "/Select/All/offices/not/deleted",
      data: myJsonServices,
    });

    setAllOffices(data);
  };

  const OnClickPositionStatusHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      status: item.status == "active" ? "inactive" : "active",
      main_id: item.main_id,
    });

    const { data } = await listingAPI.LocationChecker_POST({
      endPoint: "/update/selected/office/status",
      data: myJsonServices,
    });

    setAllOffices(data);
  };

  const OnClickDeleteHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      main_id: item.main_id,
    });

    const { data } = await listingAPI.LocationChecker_POST({
      endPoint: "/delete/selected/office",
      data: myJsonServices,
    });

    setAllOffices(data);
  };

  return (
    <View style={styles.container}>
      <AppAlert
        alert={alert}
        okBtnPress={() =>
          setAlert({
            modal: false,
            alert: "",
          })
        }
      />

      <View style={styles.bottomViewStyles}>
        <FlatList
          data={allOffices}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <LocalCard
              items={item}
              OnClickPositionStatusHandler={(item) =>
                OnClickPositionStatusHandler(item)
              }
              onPressDelete={(item) => OnClickDeleteHandler(item)}
            />
          )}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 10,
  },

  bottomViewStyles: {
    height: "100%",
    width: "100%",
  },
  officeCardStyles: {
    backgroundColor: Colors.InputText,
    padding: 5,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginVertical: 5,
  },
  btnStylesView: {
    width: "20%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
});

export default AccessHandlerScreen;
