import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text, FlatList } from "react-native";

import AppOnlyFolder from "../../../../components/AppOnlyFolder";
import listingAPI from "../../../../api/listings";
import Cache from "../../../../utility/cache";

function TemplatesHomeScreen({}) {
  const [allServices, setAllServices] = useState([]);

  useEffect(() => {
    GelAllServicesData();
  }, []);

  const GelAllServicesData = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");
    const myJsonServices = JSON.stringify({
      therapist_main_id: therapist_main_id,
    });
    const { data } = await listingAPI.Services({
      endPoint: "/select/allServices/status/notDeleted",
      data: myJsonServices,
    });

    setAllServices(data);
  };

  return (
    <View style={styles.container}>
      <FlatList
        data={allServices}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => <AppOnlyFolder items={item} />}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 20,
    padding: 10,
  },
});

export default TemplatesHomeScreen;
