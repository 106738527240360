import React, { useState, useEffect, useContext } from "react";
import { View, StyleSheet, Image, Text } from "react-native";
import * as Network from "expo-network";
import moment from "moment";

import DepartContext from "../../auth/DepartContext";
import listingAPI from "../../api/listings";
import Cache from "../../utility/cache";
import AppAlert from "../../components/AppAlert";
import AppIcon from "../../components/AppIcon";

import Routes from "../../navigation/routes";
import AppText from "../../components/AppText";

function InterNetCheckScreen({ navigation }) {
  const departContext = useContext(DepartContext);
  const [netChecker, setNetChecker] = useState(true);

  useEffect(() => {
    IPCheckerHandler();
  }, []);

  const IPCheckerHandler = async () => {
    const { isConnected } = await Network.getNetworkStateAsync();

    setNetChecker(isConnected);
    if (!isConnected) return;

    const IP = await Network.getIpAddressAsync();

    if (IP) {
      const myJsonServices = JSON.stringify({
        ipAddress: IP.trim(),
      });

      const { data } = await listingAPI.LocationChecker_POST({
        endPoint: "/Selected/IP/Checker/not/deleted",
        data: myJsonServices,
      });

      if (data.length <= 0) {
        navigation.navigate(Routes.STACK_AUTHCHECKSCREEN);
      } else {
        departContext.setDepartment("departments");
        await Cache.store("current_IP", IP);
      }
    } else {
      navigation.navigate(Routes.STACK_AUTHCHECKSCREEN);
    }
  };

  return (
    <View style={styles.container}>
      {!netChecker ? (
        <>
          <AppIcon
            backgroundColor=""
            size={300}
            iconColor="red"
            name="wifi-off"
          />
          <AppText style={styles.txtStyles} label="No internet connection" />
          <AppText
            style={styles.txtStyles}
            label="Please check your internet connection and refresh page"
          />
        </>
      ) : (
        <Image
          source={require("../../config/img/accessfoundationLogo.jpeg")}
          style={{
            width: "10%",
            height: "10%",
            borderRadius: 20,
          }}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  txtStyles: {
    fontSize: 25,
    fontWeight: "bold",
  },
});

export default InterNetCheckScreen;
