import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text, ScrollView } from "react-native";
import Modal from "react-native-modal";
import moment from "moment";

import listingAPI from "../../../api/listings";
import AppPicker from "../../../components/AppPicker";
import AppTouchableLabel from "../../../components/AppTouchableLabel";
import AppInputText from "../../../components/AppInputText";
import AppButton from "../../../components/AppButton";
import AppClameDetailsCard from "../../../components/cards/AppClameDetailsCard";
import AppCalander from "../../../components/AppCalander";
import AppAlert from "../../../components/AppAlert";
import AppPdfReportGenerator from "../../../components/AppPdfReportGenerator";

const filter = [
  { main_id: 0, name: "No Filter" },
  { main_id: 1, name: "Date" },
  { main_id: 2, name: "Therapist" },
  { main_id: 3, name: "Service" },
  { main_id: 4, name: "Session Id" },
];

function ReportGeneratorScreen({}) {
  const [filteredBy, setFilteredBy] = useState({ main_id: -1, name: "" });
  const [allService, setAllService] = useState();
  const [sessionId, setDessionId] = useState(0);
  const [selectTherapistName, setSelectTherapistName] = useState({
    main_id: 0,
    name: "",
  });
  const [selectServiceName, setSelectServiceName] = useState();
  const [allTherapist, setAllTherapist] = useState([]);
  const [allGetSessions, setAllGetSessions] = useState([]);
  const [alert, setAlert] = useState({ modal: false, alert: "" });
  const [fromDate, setFromDate] = useState("DD/MM/YYYY");
  const [toDate, setToDate] = useState("DD/MM/YYYY");
  const [pdfModal, setPdfModal] = useState(false);
  const [totalAmount, setTotalAmount] = useState({
    amount: 0,
    hours: 0,
  });
  const [unClamiedAmount, setUnClamiedAmount] = useState({
    amount: 0,
    hours: 0,
  });

  useEffect(() => {
    GetAllServices();
  }, []);

  useEffect(() => {
    GetAllTherapistList();
  }, []);

  const GetAllServices = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.Services({
      endPoint: "/select/allServices/status/active",
      data: myJsonServices,
    });

    setAllService(data);
  };

  const GetAllTherapistList = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.TherapyTeamGet({
      endPoint: "/Select/All/Therapy/Team",
      data: myJsonServices,
    });

    setAllTherapist(data);
  };

  const GetDataFromBackEnd = async () => {
    if (filteredBy.main_id < 4) {
      if (fromDate == "DD/MM/YYYY") {
        setAlert({ modal: true, alert: "Please, Select From Date" });
        return;
      } else if (toDate == "DD/MM/YYYY") {
        setAlert({ modal: true, alert: "Please, Select To Date" });
        return;
      }
    }

    let f_date = moment(fromDate, "DD/MM/YYYY").format("YYYY/MM/DD");
    let t_date = moment(toDate, "DD/MM/YYYY").format("YYYY/MM/DD");

    if (filteredBy.main_id == 1) {
      const myJsonServices = JSON.stringify({
        fromDate: f_date,
        toDate: t_date,
      });
      const { data } = await listingAPI.EveryDaySessionHandler({
        endPoint: "/select/all/sessions",
        data: myJsonServices,
      });

      if (data.length > 0) {
        Calculation(data);
      }

      setAllGetSessions(data);
    } else if (filteredBy.main_id == 2) {
      if (!selectTherapistName) {
        setAlert({ modal: true, alert: "Please, Select Therapist" });
        return;
      }
      const myJsonServices = JSON.stringify({
        fromDate: f_date,
        toDate: t_date,
        therapist_main_id: selectTherapistName.main_id,
      });
      const { data } = await listingAPI.EveryDaySessionHandler({
        endPoint: "/select/all/sessions/selected/therapist",
        data: myJsonServices,
      });

      if (data.length > 0) {
        Calculation(data);
      }

      setAllGetSessions(data);
    } else if (filteredBy.main_id == 3) {
      if (!selectServiceName) {
        setAlert({ modal: true, alert: "Please, Select Service" });
        return;
      }
      const myJsonServices = JSON.stringify({
        fromDate: f_date,
        toDate: t_date,
        services_main_id: selectServiceName.main_id,
      });
      const { data } = await listingAPI.EveryDaySessionHandler({
        endPoint: "/select/all/sessions/selected/service",
        data: myJsonServices,
      });

      if (data.length > 0) {
        Calculation(data);
      }

      setAllGetSessions(data);
    } else if (filteredBy.main_id == 4) {
      if (!sessionId) {
        setAlert({ modal: true, alert: "Please, Select Service" });
        return;
      }
      const myJsonServices = JSON.stringify({
        session_main_id: sessionId,
      });
      const { data } = await listingAPI.EveryDaySessionHandler({
        endPoint: "/selected/session/by/sessionId",
        data: myJsonServices,
      });

      if (data.length > 0) {
        Calculation(data);
      }

      setAllGetSessions(data);
    }
  };

  const Calculation = (data) => {
    var totalAmountSummed = 0;
    var totalMinutes = 0;
    for (var key in data) {
      totalAmountSummed += parseFloat(data[key].totalSessionPrice);
      totalMinutes += parseFloat(data[key].fullSessionMins);
    }

    let totalHours = ~~(totalMinutes / 60);
    let totalMins = ~~(totalMinutes % 60);

    setTotalAmount({
      amount: totalAmountSummed,
      hours: `${totalHours}:${totalMins}`,
    });

    const unClamedData = data.filter((i) => i.sessionClamed == "false");
    var totalUnClamiedAmount = 0;
    var totalUnClamiedMinutes = 0;
    for (var key in unClamedData) {
      totalUnClamiedAmount += parseFloat(unClamedData[key].totalSessionPrice);
      totalUnClamiedMinutes += parseFloat(unClamedData[key].fullSessionMins);
    }

    let totalUnclamiedHours = ~~(totalUnClamiedMinutes / 60);
    let totalUnclamiedMins = ~~(totalUnClamiedMinutes % 60);

    setUnClamiedAmount({
      amount: totalUnClamiedAmount,
      hours: `${totalUnclamiedHours}:${totalUnclamiedMins}`,
    });
  };

  return (
    <View style={styles.container}>
      <AppAlert
        alert={alert}
        okBtnPress={() => setAlert({ modal: false, alert: "" })}
      />
      <Modal
        style={{ height: "100%", alignItems: "center" }}
        isVisible={pdfModal}
      >
        <AppPdfReportGenerator items={allGetSessions} />
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            alignItems: "center",
          }}
        >
          <AppButton
            label="Back"
            color="pink"
            onPress={() => setPdfModal(false)}
          />
        </View>
      </Modal>
      <View style={styles.topViewStyles}>
        <AppPicker
          width="20%"
          label="Filter By : "
          items={filter}
          selectedItem={filteredBy}
          onSelectItem={(item) => {
            if (item.main_id === 0) {
              setFromDate("DD/MM/YYYY");
              setToDate("DD/MM/YYYY");
              setSelectServiceName("");
              setSelectTherapistName("");
            }
            setFilteredBy(item);
          }}
        />

        {/* <AppTouchableLabel
          items={filter}
          width="20%"
          placeholder="Filter By : "
          label="Filter By : "
          iconName="account"
          menuLabel={"Filter By"}
          selectedMenu={(item) => {
            if (item.main_id === 0) {
              setFromDate("DD/MM/YYYY");
              setToDate("DD/MM/YYYY");
              setSelectServiceName("");
              setSelectTherapistName("");
            }
            setFilteredBy(item);
          }}
        /> */}
        <View style={styles.filterViewStyles}>
          {filteredBy.main_id == 1 && (
            <View style={styles.viewStyles}>
              <AppCalander
                width="50%"
                label="From : "
                datePlaceholder={fromDate}
                selectedDate={(date) => setFromDate(date)}
                maxDate={new Date()}
              />
              <AppCalander
                width="50%"
                label="To : "
                datePlaceholder={toDate}
                selectedDate={(date) => setToDate(date)}
                maxDate={new Date()}
              />
            </View>
          )}
          {filteredBy.main_id == 2 && (
            <View style={styles.viewStyles}>
              <AppPicker
                width="40%"
                label="Select Therapist"
                items={allTherapist}
                selectedItem={selectTherapistName}
                onSelectItem={(txt) => setSelectTherapistName(txt)}
              />

              <AppCalander
                width="30%"
                label="From : "
                datePlaceholder={fromDate}
                selectedDate={(date) => setFromDate(date)}
                maxDate={new Date()}
              />
              <AppCalander
                width="30%"
                label="To : "
                datePlaceholder={toDate}
                selectedDate={(date) => setToDate(date)}
                maxDate={new Date()}
              />
            </View>
          )}
          {filteredBy.main_id == 3 && (
            <View style={styles.viewStyles}>
              <AppPicker
                width="40%"
                label="Select Service"
                items={allService}
                selectedItem={selectServiceName}
                onSelectItem={(txt) => setSelectServiceName(txt)}
              />
              <AppCalander
                width="30%"
                label="From : "
                datePlaceholder={fromDate}
                selectedDate={(date) => setFromDate(date)}
                maxDate={new Date()}
              />
              <AppCalander
                width="30%"
                label="To : "
                datePlaceholder={toDate}
                selectedDate={(date) => setToDate(date)}
                maxDate={new Date()}
              />
            </View>
          )}
          {filteredBy.main_id == 4 && (
            <View style={styles.viewStyles}>
              <AppInputText
                width="30%"
                placeholder="Session Id : "
                label="Session Id : "
                iconName="numeric"
                keyboardType="numeric"
                pattern="[0-9]*"
                value={sessionId ?? ""}
                onChangeText={(txt) => setDessionId(txt.replace(/[^0-9]/g, ""))}
              />
            </View>
          )}
          {filteredBy.main_id > 0 && (
            <AppButton
              style={{ marginTop: 20 }}
              label="Filter"
              width="15%"
              onPress={() => GetDataFromBackEnd()}
            />
          )}
        </View>
      </View>
      <View style={styles.bottomViewStyles}>
        {allGetSessions.length > 0 ? (
          <AppClameDetailsCard
            clamiedChecker
            btnEnable={false}
            items={allGetSessions}
            onPressOutsideHandlerEnable
            onPressOutsideHandler={(item) => console.log(item)}
          />
        ) : (
          <Text style={{ alignSelf: "center", fontSize: 35 }}>
            No Data Found
          </Text>
        )}
      </View>
      <View style={styles.receiptrBtnStyles}>
        {allGetSessions.length > 0 && (
          <View style={styles.receptGenerterViewStyles}>
            <AppButton
              label="Generate Receipt"
              onPress={() => setPdfModal(true)}
            />
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                width: "50%",
              }}
            >
              <View>
                <Text style={styles.txtStyles}>
                  Total Amount : ${totalAmount.amount.toFixed(3)}
                </Text>
                <Text style={styles.txtStyles}>
                  Total Hours : {totalAmount.hours}/h
                </Text>
              </View>
              <View>
                <Text style={styles.txtStyles}>
                  UnClamied Amount : ${unClamiedAmount.amount.toFixed(3)}
                </Text>
                <Text style={styles.txtStyles}>
                  UnClamied Hours : {unClamiedAmount.hours}/h
                </Text>
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 10,
  },
  filterViewStyles: {
    height: 70,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 0.5,
  },
  viewStyles: {
    flexDirection: "row",
    width: "70%",
    justifyContent: "center",
  },
  topViewStyles: {
    width: "100%",
    height: "20%",
  },
  bottomViewStyles: {
    width: "100%",
    height: "75%",
  },
  receiptrBtnStyles: {
    height: "5%",
    width: "100%",
    alignItems: "center",
  },
  receptGenerterViewStyles: {
    width: "90%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  txtStyles: {
    fontWeight: "bold",
  },
});

export default ReportGeneratorScreen;
