import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";

import listingAPI from "../../../../api/listings";
import AppInputText from "../../../../components/AppInputText";
import AppAlert from "../../../../components/AppAlert";
import AppAlertYesNo from "../../../../components/AppAlertYesNo";
import AppButton from "../../../../components/AppButton";
import AppOfficeCard from "../../../../components/cards/AppOfficeCard";

function AssignDepartment({}) {
  const [addNewDepartmentModal, setAddNewDepartmentModal] = useState(false);
  const [getAllDepartment, setGetAllDepartment] = useState(false);

  const [departmentName, setDepartmentName] = useState();

  const [main_idForUpdate, setMain_idForUpdate] = useState();

  const [updateInsert, setUpdateInsert] = useState(false);
  const [alert, setAlert] = useState({ modal: false, alert: "" });
  const [deleteDepartmentModal, setDeleteDepartmentModal] = useState({
    modal: false,
    main_id: 0,
  });

  useEffect(() => {
    GetAllDepartmentName();
  }, []);

  const GetAllDepartmentName = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.DepartmentHandler_POST({
      endPoint: "/Select/All/department/not/deleted",
      data: myJsonServices,
    });

    setGetAllDepartment(data);
  };

  const AddOfficeHandler = async () => {
    if (!departmentName) {
      setAlert({ modal: true, alert: "Please, Add Department name" });
      return;
    }

    if (!updateInsert) {
      const myJsonServices = JSON.stringify({
        name: departmentName ? departmentName.toUpperCase().trim() : "",
      });
      const { data } = await listingAPI.DepartmentHandler_POST({
        endPoint: "/Insert/department",
        data: myJsonServices,
      });
      if (data == "duplicate") {
        setAlert({
          modal: true,
          alert: "This department is already register.",
        });
        return;
      }

      setGetAllDepartment(data);
      setAddNewDepartmentModal(false);
      setDepartmentName();
    } else {
      const myJsonServices = JSON.stringify({
        name: departmentName ? departmentName.toUpperCase().trim() : "",
        main_id: main_idForUpdate,
      });
      const { data } = await listingAPI.DepartmentHandler_POST({
        endPoint: "/update/selected/department/details",
        data: myJsonServices,
      });

      if (data == "duplicate") {
        setAlert({
          modal: true,
          alert: "This department is already register.",
        });
        return;
      }

      setGetAllDepartment(data);
      setAddNewDepartmentModal(false);
      setDepartmentName();
      setMain_idForUpdate();
    }
  };

  const onPressEditHandler = (item) => {
    setMain_idForUpdate(item.main_id);
    setDepartmentName(item.name);
    setUpdateInsert(true);
    setAddNewDepartmentModal(true);
  };

  const onPressConformHandler = async (item) => {
    setDeleteDepartmentModal({ modal: true, main_id: item.main_id });
  };

  const onPressDeleteHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      main_id: item.main_id,
    });
    const { data } = await listingAPI.DepartmentHandler_POST({
      endPoint: "/delete/selected/department",
      data: myJsonServices,
    });

    setGetAllDepartment(data);
    setDeleteDepartmentModal({ modal: false, main_id: 0 });
  };

  const OnClickPositionStatusHandler = async (item) => {
    const myJsonServices = JSON.stringify({
      status: item.status == "active" ? "inactive" : "active",
      main_id: item.main_id,
    });
    const { data } = await listingAPI.DepartmentHandler_POST({
      endPoint: "/update/selected/department/status",
      data: myJsonServices,
    });

    setGetAllDepartment(data);
  };

  return (
    <View style={styles.container}>
      <AppButton
        width="20%"
        color="blue"
        label="Add New Department"
        onPress={() => setAddNewDepartmentModal(true)}
      />

      <AppAlertYesNo
        isModalVisible={deleteDepartmentModal.modal}
        mainViewStyles={{ height: "20%" }}
        Alert="Are you sure, Do you want to delete this office ?"
        noBtnPress={() =>
          setDeleteDepartmentModal({ modal: false, main_id: 0 })
        }
        yesBtnPress={() => onPressDeleteHandler(deleteDepartmentModal)}
      />

      <AppAlertYesNo
        Alert="Add New Department"
        mainViewStyles={styles.addnewOfficeView}
        isModalVisible={addNewDepartmentModal}
        leftBtnLabel="Cancel"
        rightBtnLabel="Add"
        noBtnPress={() => {
          setDepartmentName();
          setAddNewDepartmentModal(false);
        }}
        yesBtnPress={() => AddOfficeHandler()}
      >
        <View style={styles.viewStyles}>
          <AppInputText
            width="50%"
            iconName="office-building"
            placeholder="Office Name : "
            value={departmentName ?? ""}
            onChangeText={(txt) => setDepartmentName(txt)}
          />
        </View>
      </AppAlertYesNo>

      <AppAlert
        alert={alert}
        okBtnPress={() => setAlert({ modal: false, alert: "" })}
      />

      <View style={styles.bottomViewStyles}>
        <AppOfficeCard
          items={getAllDepartment}
          onPressEdit={(item) => onPressEditHandler(item)}
          onPressDelete={(item) => onPressConformHandler(item)}
          OnClickPositionStatusHandler={(item) =>
            OnClickPositionStatusHandler(item)
          }
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    padding: 20,
  },
  addnewOfficeView: {
    height: "50%",
    width: "70%",
    alignSelf: "center",
    alignItems: "center",
  },
  viewStyles: {
    width: "100%",
    height: "100%",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  bottomViewStyles: {
    height: "95%",
    width: "100%",
  },
});

export default AssignDepartment;
