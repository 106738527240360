import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";

import Logo from "../config/img/logo.png";

const LabelStyles = ({ label, item }) => {
  return (
    <View style={styles.labelStyles}>
      <Text style={styles.txtLabelStyles}>{label} </Text>
      <Text style={{ fontSize: 10 }}>{item}</Text>
    </View>
  );
};

const EachSessionHandler = ({ item }) => {
  let sessionDate = moment(item.sessionDate, "YYYY/MM/DD").format("DD/MM/YYYY");
  return (
    <View style={{ marginVertical: 10, borderBottomWidth: 1 }}>
      <LabelStyles label="Session id :" item={item.main_id} />
      <LabelStyles label="Session Date : " item={sessionDate} />
      <LabelStyles label="Therapist Name : " item={item.therapistName} />
      <LabelStyles label="Service : " item={item.serviceName} />
      <LabelStyles
        label="Session Time : "
        item={`${item.totalSession_hours}:${item.totalSession_mins}/h`}
      />
      <LabelStyles
        label="Session Charge Travel Time :"
        item={`${item.chargeTravel_hours}:${item.chargeTravel_mins}/h`}
      />
      <LabelStyles label="Session Brief Note : " item={item.briefNote} />
    </View>
  );
};

function AppReportGenerator({ items }) {
  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View>
            <View style={styles.imageViewStyles}>
              <Image
                style={{ height: 50, width: 100 }}
                source={{ uri: Logo }}
              />
            </View>

            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text>Name : {items[0].partisipamntName}</Text>
              <Text>NDIS No : {items[0].ndisNo}</Text>
            </View>
            <View>
              {items.map((item) => {
                return <EachSessionHandler key={item.main_id} item={item} />;
              })}
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    padding: 20,
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  imageViewStyles: {
    alignItems: "flex-end",
    padding: 25,
  },
  labelStyles: {
    flexDirection: "row",
  },
  txtLabelStyles: {
    fontWeight: "black",
    fontSize: 10,
    marginRight: 10,
  },
});

export default AppReportGenerator;
