import React from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from "react-native";

import Colors from "../../config/Colors";

function AppDepartmentCard({
  onPress,
  Items = {
    cardName: "Card",
    imgSource:
      "https://www.speechpathologygraduateprograms.org/wp-content/uploads/2021/08/speech-therapist-child.jpg",
  },
}) {
  const { height, width } = useWindowDimensions();

  let windowHeight = height + width;

  // if (height > width) {
  //   windowHeight = width;
  // } else {
  //   windowHeight = height;
  // }
  return (
    <TouchableOpacity
      style={[
        styles.container,
        { height: windowHeight / 10, width: windowHeight / 10 },
      ]}
      onPress={onPress}
    >
      <View style={{ width: "100%", height: "90%", alignItems: "center" }}>
        <Image source={{ uri: Items.imgSource }} style={styles.imageStyles} />
      </View>
      <View style={{ width: "100%", height: "10%", alignItems: "center" }}>
        <Text style={{ fontWeight: "bold", fontSize: `${windowHeight / 30}%` }}>
          {Items.cardName}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    // height: 200,
    // width: 200,
    backgroundColor: Colors.InputText,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    margin: 50,

    shadowColor: "#171717",
    shadowOffset: { width: 6, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  imageStyles: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
  },
});

export default AppDepartmentCard;
