import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Switch,
  TouchableOpacity,
  Text,
  useWindowDimensions,
} from "react-native";

import AppText from "../AppText";
import AppIcon from "../AppIcon";
import Colors from "../../config/Colors";

function AppServicesCard({
  items,
  editEnable = true,
  switchEnable = true,
  deleteEnable = true,
  onPressDelete,
  onPressEdit,
  listView = false,
  switchBtnChange,
  backgroundColor = "#fff",
  onPressActivation = false,
  onPress,
  txtBold = false,
}) {
  const [onClick, setOnClick] = useState(0);
  const [switchBtn, setSwitchBtn] = useState(
    items.status == "active" ? true : false
  );
  const { height: h, width: w } = useWindowDimensions();
  let windowHeight = h + w;

  let TouchView;

  if (listView == true || onPressActivation == true) {
    TouchView = TouchableOpacity;
  } else {
    TouchView = View;
  }

  const OnClickPositionStatusHandler = async (items) => {
    setSwitchBtn(!switchBtn);
    switchBtnChange(items);
  };

  return (
    <View style={styles.container}>
      <TouchView
        style={[
          styles.mainViewStyles,
          {
            backgroundColor: backgroundColor,
          },
        ]}
        onPress={
          listView ? () => setOnClick(onClick == null ? 0 : null) : onPress
        }
      >
        <View style={styles.nameViewStyles}>
          <AppText
            style={[
              { fontWeight: txtBold ? "bold" : "normal" },
              styles.txtLabelStyles,
            ]}
            label={items.name}
          />
          <AppText
            style={[
              { fontWeight: txtBold ? "bold" : "normal" },
              styles.txtLabelStyles,
            ]}
            label={editEnable ? `$${items.price}/h` : items.price}
          />
        </View>
        <View style={styles.btnViewStyles}>
          {switchEnable && (
            <Switch
              onValueChange={() => OnClickPositionStatusHandler(items)}
              value={switchBtn}
            />
          )}
          {editEnable && (
            <AppIcon
              size="20%"
              touchAble
              IconType="FontAwesome5"
              name="pen"
              onPress={() => onPressEdit(items)}
              backgroundColor={backgroundColor}
            />
          )}

          {deleteEnable && (
            <AppIcon
              size="20%"
              touchAble
              name="delete"
              onPress={() => onPressDelete(items)}
              backgroundColor={backgroundColor}
              iconColor="red"
            />
          )}
        </View>
      </TouchView>

      {onClick == null ? (
        <View
          style={[
            {
              height: onClick,
              padding: onClick == 0 ? 0 : 10,
            },
            styles.listViewStyles,
          ]}
        >
          <Text>Name : {items.name}</Text>
          <Text>Phone : {items.contactNumber}</Text>
          <Text>Guardian Name : {items.guardianName}</Text>
          <Text>Guardian Contact : {items.guardianContact}</Text>
          <Text>ReferralDate : {items.referralDate} </Text>
          <Text>Brief Task : {items.briefTask} </Text>
          <Text>Plan Start Date : {items.planStartDate} </Text>
          <Text>Plan End Date : {items.planEndDate} </Text>
          <Text>NdisNo : {items.ndisNo} </Text>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
    width: "90%",
    alignSelf: "center",
  },
  mainViewStyles: {
    padding: 5,
    width: "100%",
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
  },

  txtLabelStyles: {
    paddingHorizontal: 10,
    width: "95%",
  },
  nameViewStyles: {
    flexDirection: "row",
    width: "70%",
    alignItems: "center",
  },
  btnViewStyles: {
    flexDirection: "row",
    width: "30%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  listViewStyles: {
    width: "100%",
    backgroundColor: Colors.blue,
    borderRadius: 10,
  },
});

export default AppServicesCard;
