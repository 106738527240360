import React, { useState } from "react";
import { View, StyleSheet, Text } from "react-native";

import AppSearchBar from "../../../../components/AppSearchBar";

function AssignAdminScreen({}) {
  const [search, setSearch] = useState(false);
  const [detailsShow, setDetailsShow] = useState();

  const onClickGetSelectedItemHandler = (item) => {
    setSearch(true);
    setDetailsShow(item);
  };

  return (
    <View style={styles.container}>
      <View style={styles.searchViewStyles}>
        <AppSearchBar
          placeholder="Search Employees..."
          selectedAllEmployee
          getSelectedItem={(item) => onClickGetSelectedItemHandler(item)}
        />
      </View>
      <View style={styles.BottomViewStyles}>
        {detailsShow && (
          <Text style={styles.txtLabelStyles}>{detailsShow.name}</Text>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 10,
  },
  searchViewStyles: {
    height: "20%",
    width: "100%",
  },
  BottomViewStyles: {
    height: "80%",
    width: "100%",
  },
  txtLabelStyles: {
    fontSize: "100%",
    alignSelf: "center",
    fontWeight: "bold",
  },
});

export default AssignAdminScreen;
