import React, { useState, useEffect, useContext } from "react";
import {
  View,
  StyleSheet,
  Text,
  Image,
  FlatList,
  Dimensions,
} from "react-native";

import listingAPI from "../../api/listings";
import DepartContext from "../../auth/DepartContext";
import AppButton from "../../components/AppButton";
import AppFormsCard from "../../components/cards/AppFormsCard";

const dapartmentSelect = [{ main_id: 6, name: "Admin" }];

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

function AccountRootScreen({ navigation }) {
  const departContext = useContext(DepartContext);

  const [allForms, setAllForms] = useState();

  useEffect(() => {
    GetAllForms();
  }, []);

  const GetAllForms = async () => {
    const myJsonServices = JSON.stringify({});
    const { data } = await listingAPI.FormHandlerPost({
      endPoint: "/select/AllForms/notDeleted",
      data: myJsonServices,
    });
    setAllForms(data);
  };

  const OnPressSwitchDepartmentHandler = () => {
    departContext.setDepartment("departments");
  };

  return (
    <View style={[styles.container]}>
      <AppFormsCard
        Items={allForms}
        OnPressSwitchDepartmentHandler={() => OnPressSwitchDepartmentHandler()}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
  },
});

export default AccountRootScreen;
