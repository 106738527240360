import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text, FlatList } from "react-native";
import moment from "moment";

import listingAPI from "../../../../api/listings";
import Cache from "../../../../utility/cache";
import AppAlert from "../../../../components/AppAlert";
import AppButton from "../../../../components/AppButton";
import AppSessionYearSelecter from "../../../../components/cards/AppSessionYearSelecter";
import AppSeccionCard from "../../../../components/AppSeccionCard";
import AppTouchableLabel from "../../../../components/AppTouchableLabel";
import AppCalander from "../../../../components/AppCalander";
import AppInputText from "../../../../components/AppInputText";
import AppPicker from "../../../../components/AppPicker";

function AllSessionsDetails({ navigation, tempData }) {
  let item = tempData;

  const [allSessionYears, setAllSessionYears] = useState([]);
  const [allSessionDetails, setAllSessionDetails] = useState([]);
  const [allSessionDetailsForFilter, setAllSessionDetailsForFilter] = useState(
    []
  );
  const [refreshData, setRefreshData] = useState();
  const [btnChoice, setBtnChoice] = useState(true);
  const [filteredBy, setFilteredBy] = useState({ main_id: -1 });
  const [sessionId, setSessionId] = useState(0);
  const [subject, setSubject] = useState();
  const [selectTherapistName, setSelectTherapistName] = useState();
  const [allTherapist, setAllTherapist] = useState([]);
  const [alert, setAlert] = useState({ modal: false, alert: "" });
  const [date, setDate] = useState("DD/MM/YYYY");

  useEffect(() => {
    GetAllSessionsData();
    GetAllSessionsGroupByYear();
    GetAllTherapist();
  }, []);

  // useEffect(() => {
  //   //GetAllSessionsData();
  //   GetAllSessionsData();
  //   GetAllSessionsGroupByYear();
  // }, [refreshData]);

  const GetAllTherapist = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
      services_main_id: item.allocate_services_main_id,
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint:
        "/select/all/therapist/of/particular/services/of/particular/Participants",
      data: myJsonServices,
    });

    let filter = data.filter((i) => i.main_id != therapist_main_id);

    setAllTherapist(filter);
  };

  const GetAllSessionsGroupByYear = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
      services_main_id: item.allocate_services_main_id,
      therapist_main_id: therapist_main_id,
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint:
        "/select/all/session/of/particular/servicesof/particular/Participants/for/LoginedTherapist/group/by/year",
      data: myJsonServices,
    });

    setAllSessionYears(data);
  };

  const GetAllSessionsData = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
      services_main_id: item.allocate_services_main_id,
      therapist_main_id: therapist_main_id,
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint:
        "/select/all/session/of/particular/servicesof/particular/Participants/for/LoginedTherapist",
      data: myJsonServices,
    });

    //setAllSessionDetails(data);
    setAllSessionDetailsForFilter(data);
  };

  const OnclickOthersSessionsHandler = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
      // services_main_id: item.allocate_services_main_id,
      therapist_main_id: therapist_main_id,
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint:
        "/select/all/session/of/particular/servicesof/particular/Participants/for/otherTherapistSession",
      data: myJsonServices,
    });

    setAllSessionYears(data);
  };

  const OnPressFilterHandler = async () => {
    if (filteredBy.main_id == 1) {
      if (date === "DD/MM/YYYY") {
        setAlert({ modal: true, alert: "Please,Select Date" });
        return;
      }

      let sDate = moment(date, "DD/MM/YYYY").format("YYYY/MM/DD");

      let data = allSessionDetailsForFilter.filter(
        (i) => i.sessionDate <= sDate
      );

      setAllSessionDetails(data);
    } else if (filteredBy.main_id == 2) {
      if (sessionId === 0) {
        setAlert({ modal: true, alert: "Please,Select Session Id" });
        return;
      }

      let data = allSessionDetailsForFilter.filter(
        (i) => i.main_id == sessionId
      );

      setAllSessionDetails(data);
    } else if (filteredBy.main_id == 3) {
      if (!subject) {
        setAlert({ modal: true, alert: "Please,Select Subject" });
        return;
      }

      let data = allSessionDetailsForFilter.filter(
        (i) => i.mainSubject == subject.trim()
      );

      setAllSessionDetails(data);
    } else if (filteredBy.main_id == 4) {
      if (!selectTherapistName) {
        setAlert({ modal: true, alert: "Please,Select Therapist" });
        return;
      }

      let data = allSessionDetailsForFilter.filter(
        (i) => i.therapist_main_id == selectTherapistName.main_id
      );

      setAllSessionDetails(data);
    }
  };

  const OnClickSessionDateHandler = async () => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      participant_main_id: item.main_id,
      services_main_id: item.allocate_services_main_id,
      therapist_main_id: therapist_main_id,
    });
    const { data } = await listingAPI.EveryDaySessionHandler({
      endPoint:
        "/select/all/session/of/particular/servicesof/particular/Participants/for/LoginedTherapist/orderByDate",
      data: myJsonServices,
    });

    setAllSessionDetails(data);
    setAllSessionDetailsForFilter(data);
  };

  const filter = [];

  if (btnChoice) {
    filter.push(
      { main_id: 0, name: "No Filter" },
      { main_id: 1, name: "Date" },
      { main_id: 2, name: "Session Id" },
      { main_id: 3, name: "Subject" }
    );
  } else {
    filter.push(
      { main_id: 0, name: "No Filter" },
      { main_id: 1, name: "Date" },
      { main_id: 2, name: "Session Id" },
      { main_id: 3, name: "Subject" },
      { main_id: 4, name: "Therapist" }
    );
  }

  return (
    <View style={styles.container}>
      <AppAlert
        alert={alert}
        okBtnPress={() => setAlert({ modal: false, alert: "" })}
      />
      <View style={styles.btnViewStyles}>
        <AppButton
          label="My Sessions"
          color={btnChoice ? "InputText" : ""}
          border={1}
          onPress={() => {
            setBtnChoice(true);
            GetAllSessionsGroupByYear();
          }}
        />
        <AppButton
          label="Other's Sessions"
          color={!btnChoice ? "InputText" : ""}
          border={1}
          onPress={() => {
            setBtnChoice(false);
            OnclickOthersSessionsHandler();
          }}
        />
      </View>
      <View style={styles.filterViewStyles}>
        <AppPicker
          width="20%"
          label="Filter By : "
          items={filter}
          selectedItem={filteredBy}
          onSelectItem={(item) => {
            if (item.main_id === 0) {
              setDate("DD/MM/YYYY");
              setSessionId(0);
              setSubject("");
              setSelectTherapistName("");
              if (btnChoice) {
                GetAllSessionsGroupByYear();
              } else {
                OnclickOthersSessionsHandler();
              }
            }
            setFilteredBy(item);
          }}
        />

        {/* <AppTouchableLabel
          items={filter}
          width="20%"
          placeholder="Filter By : "
          label="Filter By : "
          iconName="account"
          menuLabel={"Filter By"}
          selectedMenu={(item) => {
            if (item.main_id === 0) {
              setDate("DD/MM/YYYY");
              setSessionId(0);
              setSubject("");
              setSelectTherapistName("");
              if (btnChoice) {
                GetAllSessionsGroupByYear();
              } else {
                OnclickOthersSessionsHandler();
              }
            }
            setFilteredBy(item);
          }}
        /> */}

        {filteredBy.main_id == 1 && (
          <AppCalander
            width="50%"
            label="Date : "
            datePlaceholder={date}
            selectedDate={(date) => setDate(date)}
            maxDate={new Date()}
          />
        )}
        {filteredBy.main_id == 2 && (
          <AppInputText
            width="50%"
            placeholder="Session Id : "
            label="Session Id : "
            iconName="numeric"
            value={sessionId ?? ""}
            onChangeText={(txt) => setSessionId(txt)}
          />
        )}
        {filteredBy.main_id == 3 && (
          <AppInputText
            width="50%"
            placeholder="Subject : "
            label="Subject : "
            iconName="numeric"
            value={subject ?? ""}
            onChangeText={(txt) => setSubject(txt)}
          />
        )}
        {filteredBy.main_id == 4 && (
          <AppTouchableLabel
            items={allTherapist}
            width="50%"
            placeholder="Select Therapist name : "
            label="Select Therapist name : "
            iconName="account"
            menuLabel={"Select Therapist name"}
            selectedMenu={(item) => setSelectTherapistName(item)}
          />
        )}
        {filteredBy.main_id > 0 && (
          <AppButton
            style={{ marginTop: 20 }}
            label="Filter"
            width="15%"
            onPress={() => OnPressFilterHandler()}
          />
        )}
      </View>
      <View style={{ height: "80%" }}>
        {filteredBy.main_id < 1 ? (
          <>
            {allSessionYears.length > 0 ? (
              // allSessionYears.map((item) => (
              //   <AppSessionYearSelecter
              //     item={item}
              //     key={item.sessionYear}
              //     editAndDelete={btnChoice}
              //     OnClickSessionDateHandler={() => OnClickSessionDateHandler()}
              //   />
              // ))

              <FlatList
                data={allSessionYears}
                showsVerticalScrollIndicator={false}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item }) => (
                  <AppSessionYearSelecter
                    item={item}
                    key={item.sessionYear}
                    editAndDelete={btnChoice}
                    OnClickSessionDateHandler={() =>
                      OnClickSessionDateHandler()
                    }
                  />
                )}
              />
            ) : (
              <Text style={{ alignSelf: "center", fontWeight: "bold" }}>
                No data found
              </Text>
            )}
          </>
        ) : (
          <AppSeccionCard
            editAndDelete={btnChoice}
            items={allSessionDetails}
            OnClickSessionDateHandler={() => OnClickSessionDateHandler()}
            // refreshData={
            //  setRefreshData(item)
            //}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: 10,
    padding: 10,
    overflow: "hidden",
  },
  btnViewStyles: {
    height: 50,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  filterViewStyles: {
    height: 70,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
});

export default AllSessionsDetails;
