import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  Alert,
  ScrollView,
} from "react-native";

import listingAPI from "../../api/listings";
import Cache from "../../utility/cache";
import AppClamiedBySessionCard from "../../components/cards/AppClamiedBySessionCard";

const AppMonthCard = ({ item, editAndDelete, OnClickSessionDateHandler }) => {
  const [openMonth, setOpenMonth] = useState(false);
  const [LayoutHeightMonth, setLayoutHeightMonth] = useState(0);
  const [allSessionDetails, setAllSessionDetails] = useState([]);

  useEffect(() => {
    if (openMonth) {
      setLayoutHeightMonth(null);
    } else {
      setLayoutHeightMonth(0);
    }
  }, [openMonth]);

  const GetAllSessionsData = async (item) => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      clamied_by_main_id: therapist_main_id,
      selectedMonthYear: item.clamiedDate,
    });
    const { data } = await listingAPI.ClamiedBySession_POST({
      endPoint:
        "/select/all/my/clamied/by/sessions/in/details/asper/selectedMonths",
      data: myJsonServices,
    });

    setAllSessionDetails(data);
  };

  const GetAllOthersSessionsData = async (item) => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      clamied_by_main_id: therapist_main_id,
      selectedMonthYear: item.clamiedDate,
    });
    const { data } = await listingAPI.ClamiedBySession_POST({
      endPoint:
        "/select/all/others/clamied/by/sessions/in/details/asper/selectedMonths",
      data: myJsonServices,
    });

    setAllSessionDetails(data);
  };

  const onClickMonthHandler = async (item) => {
    if (editAndDelete) {
      GetAllSessionsData(item);
    } else {
      GetAllOthersSessionsData(item);
    }

    setOpenMonth(!openMonth);
  };

  return (
    <View>
      <TouchableOpacity
        style={[styles.cardViewStyles, { backgroundColor: "#AEC6F4" }]}
        onPress={() => {
          onClickMonthHandler(item);
        }}
      >
        <Text>{item.sessionMonth}</Text>
      </TouchableOpacity>
      {
        <View style={{ height: LayoutHeightMonth, overflow: "hidden" }}>
          <AppClamiedBySessionCard
            items={allSessionDetails}
            // clamiedDesider={btnChoice}
          />
        </View>
      }
    </View>
  );
};

function AppSessionClamiedYearSelecter({
  item,
  editAndDelete,
  OnClickSessionDateHandler,
}) {
  const [open, setOpen] = useState(false);
  const [LayoutHeight, setLayoutHeight] = useState(0);
  const [allSessionMonths, setAllSessionMonths] = useState([]);

  useEffect(() => {
    if (open) {
      setLayoutHeight(null);
    } else {
      setLayoutHeight(0);
    }
  }, [open]);

  const onClickHandler = async (item) => {
    if (editAndDelete) {
      GetAllSessionsGroupByMonths(item);
    } else {
      GetAllSessionsGroupByMonthsofOthers(item);
    }
    setOpen(!open);
  };

  const GetAllSessionsGroupByMonths = async (item) => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      clamied_by_main_id: therapist_main_id,
      selectedYear: item.sessionClamiedYear,
    });
    const { data } = await listingAPI.ClamiedBySession_POST({
      endPoint:
        "/select/all/my/clamied/group/by/date/session/details/groupBy/month",
      data: myJsonServices,
    });

    setAllSessionMonths(data);
  };

  const GetAllSessionsGroupByMonthsofOthers = async (item) => {
    const therapist_main_id = await Cache.get("loginedBy_main_id");

    const myJsonServices = JSON.stringify({
      clamied_by_main_id: therapist_main_id,
      selectedYear: item.sessionClamiedYear,
    });
    const { data } = await listingAPI.ClamiedBySession_POST({
      endPoint:
        "/select/all/session/of/particular/Participants/for/others/LoginedTherapist/selected/year/of/all/month",
      data: myJsonServices,
    });

    setAllSessionMonths(data);
  };

  return (
    <View style={{ height: "100%" }}>
      <TouchableOpacity
        style={[styles.cardViewStyles, { backgroundColor: "#AEEFF4" }]}
        onPress={() => {
          onClickHandler(item);
        }}
      >
        <Text>{item.sessionClamiedYear}</Text>
      </TouchableOpacity>
      {
        <View style={{ height: LayoutHeight, overflow: "hidden" }}>
          <ScrollView>
            {allSessionMonths.map((month) => (
              <AppMonthCard
                key={month.sessionMonth}
                item={month}
                editAndDelete={editAndDelete}
                OnClickSessionDateHandler={OnClickSessionDateHandler}
              />
            ))}
          </ScrollView>
        </View>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  cardViewStyles: {
    marginVertical: 5,
    padding: 5,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
});

export default AppSessionClamiedYearSelecter;
