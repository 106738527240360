import React, { useState, useEffect, useContext } from "react";
import {
  View,
  StyleSheet,
  Text,
  Image,
  FlatList,
  useWindowDimensions,
} from "react-native";
import moment from "moment";

import listingAPI from "../../api/listings";
import DepartContext from "../../auth/DepartContext";
import AppCard from "../../components/AppCard";
import AppInputText from "../../components/AppInputText";
import AppButton from "../../components/AppButton";
import AppPicker from "../../components/AppPicker";
import AppTouchableLabel from "../../components/AppTouchableLabel";
import AppAlert from "../../components/AppAlert";
import Routes from "../../navigation/routes";
import Cache from "../../utility/cache";

const dapartmentSelect = [{ main_id: 17, name: "Admin" }];

function FormsLoginRootScreen({ navigation }) {
  const departContext = useContext(DepartContext);

  const { height, width } = useWindowDimensions();

  const [alert, setAlert] = useState({
    modal: false,
    alert: "",
  });
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [category, setCategory] = useState({
    main_id: 0,
    name: "Select Login As",
  });

  let windowHeight = 0;

  if (height > width) {
    windowHeight = width;
  } else {
    windowHeight = height;
  }

  const OnPressClickHandler = async () => {
    if (!email) {
      setAlert({
        modal: true,
        alert: "Please, Enter email",
      });
      return;
    } else if (!password) {
      setAlert({
        modal: true,
        alert: "Please, Enter password",
      });
      return;
    }
    if (email == "Forms" && password == "Forms123") {
      setEmail();
      setPassword();
      navigation.navigate(Routes.STACK_FORMSROOTSCREEN);
    } else {
      setEmail();
      setPassword();
      setAlert({
        modal: true,
        alert: "Username or Password is incorrect.",
      });
      return;
    }
  };

  const OnPressSwitchDepartmentHandler = async () => {
    await Cache.removeItem("u_department");
    departContext.setDepartment("departments");
  };

  return (
    <View style={[styles.container, { height, width }]}>
      <AppAlert alert={alert} okBtnPress={() => setAlert({ modal: false })} />

      <AppCard style={styles.cardStyles} width={width / 2} height={height / 2}>
        <Text style={{ fontWeight: "bold", fontSize: windowHeight / 50 }}>
          Welcome to Forms Department
        </Text>
        <Image
          source={require("../../config/img/logo.png")}
          style={{
            marginBottom: 50,
            height: windowHeight / 6,
            width: windowHeight / 3,
          }}
        />

        <View style={{ width: "100%", alignItems: "center" }}>
          <AppInputText
            width="80%"
            label="service name"
            placeholder="Email : "
            iconName="email"
            value={email ?? ""}
            onChangeText={(txt) => setEmail(txt)}
          />
          <AppInputText
            width="80%"
            placeholder="Password : "
            iconName="lock"
            touchAble
            label="service name"
            value={password ?? ""}
            onChangeText={(txt) => setPassword(txt)}
          />
          <AppButton
            width="80%"
            label="Login"
            color="blue"
            onPress={() => OnPressClickHandler()}
          />
          {/* </>
          )} */}
          <AppButton
            width="80%"
            label="Switch Departments"
            onPress={() => OnPressSwitchDepartmentHandler()}
          />
        </View>
      </AppCard>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // height: "100%",
    // width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },

  cardStyles: {
    alignItems: "center",
    justifyContent: "center",
  },
  LogoStyles: {
    height: "20%",
    width: "40%",
  },
});

export default FormsLoginRootScreen;
