import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from "react-native";

import {
  MaterialCommunityIcons,
  Entypo,
  FontAwesome5,
  Octicons,
  Fontisto,
  FontAwesome,
} from "@expo/vector-icons";

import AppMenuItems from "../components/AppMenuItems";

function AppTouchableLabel({
  items,
  label,
  iconName = "email",
  iconSize = 25,
  width = "90%",
  height = 40,
  menuWidth,
  onPress,
  menuLabel,
  selectedMenu,
  backgroundColor = "#E7E7E7",
  IconType = MaterialCommunityIcons,
  ViewStyle,
  mainInputViewStyles,
}) {
  return (
    <View
      style={[styles.mainViewStyles, mainInputViewStyles, { width: width }]}
    >
      <Text style={{ marginLeft: 30 }}>{label}</Text>
      <View
        style={[
          styles.container,
          { height: height },
          { backgroundColor },
          ViewStyle,
        ]}
      >
        <IconType name={iconName} size={iconSize} />
        <AppMenuItems
          menuWidth={menuWidth}
          label={menuLabel}
          items={items}
          selectedMenu={selectedMenu}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainViewStyles: {
    // alignItems: "center",
  },
  container: {
    width: "100%",
    paddingHorizontal: 10,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 25,
    marginVertical: 2,
    alignSelf: "center",
  },

  textStyles: {
    paddingHorizontal: 10,
    width: "95%",
    height: "80%",
  },
  labelStyles: {
    paddingHorizontal: 10,
    width: "85%",
  },
});

export default AppTouchableLabel;
