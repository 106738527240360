import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text } from "react-native";
import Modal from "react-native-modal";
import * as Progress from "react-native-progress";

import Colors from "../config/Colors";

function AppProgressbar({ modalVisible = false, message = "It's Uploading" }) {
  return (
    <Modal style={styles.container} isVisible={modalVisible}>
      <View style={styles.setStyles}>
        <Progress.CircleSnail color={["red", "green", "blue"]} />
        <Text
          style={{ fontSize: 20 }}
        >{`Please wait, while ${message}...`}</Text>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  setStyles: {
    padding: 40,
    height: "10%",
    width: "100%",
    borderRadius: 40,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default AppProgressbar;
