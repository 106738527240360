import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { NavigationContainer } from "@react-navigation/native";

import DepartmentContext from "../auth/DepartContext";

import SplashStackNavigation from "../navigation/SplashScreen/SplashStackNavigation";
import DepartmentStack from "../navigation/Department/DepartmentStack";
import ITAdminStackAdminStackNavigator from "../navigation/ITAdmin/ITAdminStackAdminStackNavigator";
import TherapyRootNavigation from "../navigation/TherapyTeam/TherapyRootNavigation";
import AccountStackAdminStackNavigator from "../navigation/AccountDepartment/AccountStackAdminStackNavigator";
import FormsStackAdminStackNavigator from "../navigation/Forms/FormsStackAdminStackNavigator";
import AttendanceStackNavigation from "../navigation/Attendance/AttendanceStackNavigation";

function AppNavigation({}) {
  const [department, setDepartment] = useState("SplashScreen");

  let CurrentNacigation;
  if (department == "SplashScreen") {
    CurrentNacigation = SplashStackNavigation;
  } else if (department == "departments") {
    CurrentNacigation = DepartmentStack;
  } else if (department == "IT") {
    CurrentNacigation = ITAdminStackAdminStackNavigator;
  } else if (department == "Therapy") {
    CurrentNacigation = TherapyRootNavigation;
  } else if (department == "Account") {
    CurrentNacigation = AccountStackAdminStackNavigator;
  } else if (department == "Forms") {
    CurrentNacigation = FormsStackAdminStackNavigator;
  } else if (department == "Attendance") {
    CurrentNacigation = AttendanceStackNavigation;
  }

  return (
    <DepartmentContext.Provider value={{ department, setDepartment }}>
      <NavigationContainer>
        <CurrentNacigation />
      </NavigationContainer>
    </DepartmentContext.Provider>
  );
}

export default AppNavigation;
