import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View } from "react-native";
import React, { useState, useEffect, useContext } from "react";

import "react-native-gesture-handler";

import AppNavigation from "./app/navigation/AppNavigation";

export default function App() {
  return <AppNavigation />;
}
